import { MdPersonAddAlt1 } from "react-icons/md";
import AddButton from "../../../components/buttons/AddButton";
import Dashboard from "../../../components/Dashboard";
import Table, { ActionButton, Badge } from "../../../components/Table";
import PreviousButton from "./../../../components/buttons/PreviousButton";
import { FaPlus } from "react-icons/fa6";
import {
  // getDrugCodeTableData,
  drugCodeTableHeadData,
} from "../../../constants/data/drugs/drugCodeData";
import DrugCodeModal from "../../../components/Modals/Drugcode";
import { useModal } from "../../../context/modalContext";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HttpClient } from "../../../Redux/controllers/HttpClient";
import { Spinner } from "@material-tailwind/react";
import axios from "axios";
import Utility from "../../../Utils/Utility";
// import { ActionButton } from "./../../../components/Table";

const deleteData = async (id) => {
  let config = {
    method: "delete",
    url: `${HttpClient.API_BASE_URL}admin/delete-drug-code/${id}`,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log(JSON.stringify(response.data));
      Utility.sToast("Data deleted");
      // setStateUpdate(true);
    })
    .catch((error) => {
      console.log(error);
      Utility.eToast("Failed!");
    });
  // .finally(() => setStateUpdate(false));
};

const getDrugCodeTableData = (dataSet) => {
  const tableData = dataSet?.map((item, idx) => {
    return {
      codeName: item?.name,
      status: <Badge status={item?.status} />,
      action: (
        <div className="flex gap-3">
          {/* pen */}
          <ActionButton
            action="edit"
            user={item}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="frn"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                />
              </svg>
            }
          />
          {/* cross */}
          <ActionButton
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="frn"
                onClick={() => deleteData(item?._id)}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            }
          />
        </div>
      ),
    };
  });
  return tableData;
};

const DrugCodePage = () => {
  const { showEditModal, showModal, openModal, closeModal, closeEditModal } =
    useModal();
  const [showSpin, setShowSpin] = useState(false);
  const { token } = useSelector((state) => state.user);
  const [drugCodeTableData, setDrugCodeTableData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setShowSpin(true);
      try {
        const { data } = await HttpClient.apiCaller(
          "admin/get-drug-code",
          "GET",
          null,
          token,
          null
        );
        console.log(data?.code);
        setDrugCodeTableData(data?.code);
      } catch (error) {
        console.log(error);
      } finally {
        setShowSpin(false);
      }
    };

    fetchData();

    return () => {};
  }, [closeModal, closeEditModal]);

  return (
    <Dashboard>
      <div className="flex xs:flex-col-reverse xs:gap-2 sm:flex-row sm:items-center sm:justify-between mb-5">
        <div>
          <p className="poppins-medium text-lg leading-[21px]  text-theme-darkBlue mb-2">
            Manage Drug Code{" "}
          </p>
          <p className="poppins-medium text-xs  ">
            <span className="underline  text-theme-blue cursor-pointer">
              Drug
            </span>{" "}
            /{" "}
            <span className="underline  text-theme-blue cursor-pointer">
              Manage Drug Code
            </span>
          </p>
        </div>
        {/*  */}

        <AddButton
          onClick={openModal}
          buttonName={"Add Drug Code"}
          icon={<FaPlus className="size-5" />}
        />
      </div>

      {/*  table */}
      {showSpin ? (
        <div className="w-full mt-20 flex items-center justify-center">
          <Spinner className="h-12 w-12" />{" "}
        </div>
      ) : (
        <Table
          tableData={getDrugCodeTableData(drugCodeTableData)}
          tableHeadData={drugCodeTableHeadData}
        />
      )}
      {/* show modal */}
      {showModal ? <DrugCodeModal onClose={closeModal} /> : <></>}
      {showEditModal ? (
        <DrugCodeModal edit={true} onClose={closeEditModal} />
      ) : (
        <></>
      )}
    </Dashboard>
  );
};

export default DrugCodePage;
