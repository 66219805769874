import { Badge } from "../../components/Table";
import Edit from "../../components/buttons/Edit";
import Toggle from "../../components/buttons/Toggle";

export const physicianTableHeadData = [
  {
    accessorKey: "no",
    header: "Sl No",
    size: 30,
  },
  {
    accessorKey: "uid",
    header: "UID",
    size: 100,
  },
  {
    accessorKey: "firstName",
    header: "First Name",
    size: 100,
  },
  {
    accessorKey: "lastName",
    header: "Last Name",
    size: 100,
  },
  {
    accessorKey: "email",
    header: "Email",
    size: 100,
  },
  {
    accessorKey: "mobile",
    header: "Phone",
    size: 100,
  },
  // {
  //   accessorKey: "npiNumber",
  //   header: "NPI No",
  //   size: 100,
  // },
  // {
  //   accessorKey: "visibilityOfPhysician",
  //   header: "Visibility of physician result",
  //   size: 300,
  // },
  // {
  //   accessorKey: "status",
  //   header: "Status",
  //   size: 100,
  // },
  {
    accessorKey: "action",
    header: "Action",
    size: 100,
  },
];

export const getPhysicianTableData = (dataSet) => {
  let tableData = dataSet?.map((item, idx) => {
    return {
      no: idx + 1,
      uid: item?.uid,
      firstName: item?.first_name || "N/A",
      lastName: item?.last_name || "N/A",
      email: item?.email || "N/A",
      mobile: item?.mobile_number,
      // npiNumber: item?.npi,
      // visibilityOfPhysician: (
      //   <div className="flex items-center gap-2">
      //     Visibility turned {item?.visibility} <Toggle />
      //   </div>
      // ),
      status: <Badge status={item?.status} />,
      action: <Edit user={item} />,
    };
  });
  return tableData;
};
