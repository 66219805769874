import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import CommonModal from "./CommonModal";
import Cancel from "../buttons/Cancel";
import Reset from "./../buttons/Reset";
import Submit from "./../buttons/Submit";
import { useSelector } from "react-redux";
import { Spinner } from "@material-tailwind/react";
import Utility from "../../Utils/Utility";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { SlReload } from "react-icons/sl";
import { useModal } from "../../context/modalContext";
import ToggleButton from "../buttons/Toggle";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Category Name is required"),
  drug: Yup.string().required("Drug required"),
});

const DrugCategory = ({ onClose, edit }) => {
  const { userID } = useModal();

  const { token } = useSelector((state) => state.user);
  const [showSpin, setShowSpin] = useState(false);
  const [drugdata, setDrugData] = useState([]);

  const formik = useFormik({
    initialValues: {
      name: edit ? userID?.name : "",
      drug: edit ? userID?.drug : "",
    },
    validationSchema: validationSchema,
    onSubmit: edit ? handleEditSubmit : handleSubmit,
  });

  async function handleSubmit(values) {
    setShowSpin(true);
    let userData = {
      name: values?.name,
      drug_code: values?.drug,
    };
    try {
      const res = await HttpClient.apiCaller(
        "admin/category",
        "POST",
        userData,
        token,
        null
      );
      console.log(res);
      Utility.sToast("Data posted successfully");
      onClose();
      formik.resetForm();
    } catch (err) {
      console.log(err);
      Utility.eToast(err?.response?.data?.err || "Something went wrong");
    } finally {
      setShowSpin(false);
    }
  }

  async function handleEditSubmit(values) {
    setShowSpin(true);
    let userData = {
      _id: userID._id,
      name: values?.name,
      drug_code: values?.drug,
    };
    try {
      const res = await HttpClient.apiCaller(
        "admin/category-edit",
        "PUT",
        userData,
        token,
        null
      );
      console.log(res);
      Utility.sToast("User Updated successfully");
      onClose();
      formik.resetForm();
    } catch (err) {
      console.log(err);
      Utility.eToast("Something went wrong");
    } finally {
      setShowSpin(false);
    }
  }

  const handleReset = () => {
    formik.resetForm();
  };

  useEffect(() => {
    getCodes();
  }, []);
  const getCodes = async () => {
    await HttpClient.apiCaller("admin/code", "GET", null, token, null)
      .then((res) => {
        console.log(res);
        setDrugData(res?.data?.code);
      })
      .catch((err) => console.log(err));
  };

  return (
    <CommonModal
      heading={edit ? "Edit Drug Category" : "Add Drug Category"}
      onClose={onClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="mt-9">
          <p className="mb-2 poppins-medium">Category name</p>
          <input
            type={"text"}
            placeholder="Enter category name"
            className="w-full h-10 mb-2 rounded poppins-regular bg-[#ECF1F6] p-2"
            onChange={formik.handleChange}
            name="name"
            value={formik.values.name}
          />
          {formik.errors.name && formik.touched.name && (
            <div className="text-red-500 poppins-regular ml-1 text-sm">
              {formik.errors.name}
            </div>
          )}
          <p className="mb-2 poppins-medium">Select drug</p>
          <select
            id="countries"
            className="custom-input"
            value={formik.values.drug}
            onChange={formik.handleChange}
            name="drug"
          >
            <option value="">Select Type</option>
            {drugdata?.map((item, i) => (
              <>
                <option value={item?.code} key={i}>
                  {item?.name}
                </option>
              </>
            ))}
          </select>
          {formik.errors.drug && formik.touched.drug && (
            <div className="text-red-500 poppins-regular ml-1 text-sm">
              {formik.errors.drug}
            </div>
          )}
        </div>

        {/* <div className="mt-4 mb-7">
          <ToggleButton onClick={() => { }} />
        </div> */}

        {/* Footer */}
        <div className="flex justify-between mt-3">
          <div>
            {!edit ? (
              <button
                type="button"
                onClick={handleReset}
                className="flex items-center px-2 py-1  poppins-medium border-2  border-gray-400 rounded hover:opacity-80 bg-[#ECF1F6]"
              >
                <SlReload className="mr-1" />
                Reset
              </button>
            ) : (
              <></>
            )}
          </div>
          <div className="flex gap-4 ml-auto">
            <Cancel onClick={onClose} />
            <Submit
              label={
                showSpin ? (
                  <Spinner color="white" />
                ) : edit ? (
                  "Save Changes"
                ) : (
                  "submit"
                )
              }
            />
          </div>
        </div>
      </form>
    </CommonModal>
  );
};

export default DrugCategory;
