import { ActionButton, Badge, Edit } from "../../components/Table";
import eyeIcon from "../../assets/table/eyeIcon.png";
import crossIcon from "../../assets/table/crossIcon.png";
import pdfIcon from "../../assets/table/pdficon.png";
import penIcon from "../../assets/table/penIcon.png";
import qrIcon from "../../assets/table/qrIcon.png";

export const forensicTestTableHeadData = [
  {
    accessorKey: "slno",
    header: "Sl No",
    size: 100,
  },
  {
    accessorKey: "donorName",
    header: "Donor Name",
    size: 100,
  },
  {
    accessorKey: "clinic",
    header: "Clinic",
    size: 150,
  },
  {
    accessorKey: "physician",
    header: "Physician",
    size: 150,
  },
  {
    accessorKey: "archive",
    header: "Archive",
    size: 200,
  },
  {
    accessorKey: "action",
    header: "Action",
    size: 300,
  },
];

export const getForensicTestTableData = (dataSet) => {
  const tableData = dataSet?.map((item, idx) => {
    return {
      slno: idx + 1,
      requisitionNumber: item.requisitionNumber,
      donorName: `${item?.donor_first_name} ${item?.donor_last_name}`,
      clinic: item?.site_details?.name,
      physician: item?.primary_specimen_report?.scientist_name,
      archive: (
        <div className="text-white poppins-medium px-2 py-1 bg-[#0CAF60] w-fit rounded">
          {item.archive || "archive"}
        </div>
      ),
      action: (
        <div className="flex gap-1">
          <ActionButton icon={eyeIcon} />
          <ActionButton icon={qrIcon} />
          <ActionButton icon={pdfIcon} />
          <ActionButton icon={eyeIcon} />
          <ActionButton icon={penIcon} action="edit" user={item} />
          <ActionButton icon={crossIcon} />
        </div>
      ),
    };
  });
  return tableData;
};
