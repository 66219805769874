import React, { useState } from "react";
import "react-toggle/style.css";
import Toggle from "react-toggle";

function ToggleComp({ isToggled, handleToggle = () => {} }) {
  return (
    <div>
      <Toggle defaultChecked={isToggled} onChange={handleToggle} />
    </div>
  );
}

export default ToggleComp;
