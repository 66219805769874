import React, { useState } from "react";
import Dashboard from "../../components/Dashboard";
import { SlArrowLeft, SlReload, SlArrowDown, SlArrowUp } from "react-icons/sl";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
  LanguageSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { IoIosEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";
import Reset from "../buttons/Reset";
import Toggle from "../buttons/Toggle";
import Submit from "../buttons/Submit";
import Cancelsmall from "../buttons/Cancelsmall";
import "./Physician.css";
import Utility from "../../Utils/Utility";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { useSelector } from "react-redux";
import PreviousButton from "./../buttons/PreviousButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Spinner } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import Constants from "../../Utils/Constants";

const AddCollectionSite = ({ toggle, onClose, isSignUpPage }) => {
  const { token, user_id } = useSelector((state) => state.user);
  const [showSpin, setShowSpin] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [yesClicked, setYesClicked] = useState(true);
  const [noClicked, setNoClicked] = useState(false);

  const [countryid, setCountryid] = useState(233);
  const [stateid, setStateid] = useState("");

  const validationSchema = Yup.object().shape({
    streetAddress: Yup.string().required("Street address is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    zip: Yup.string()
      .required("Zip code is required")
      .matches(/^\d{5}$/, "Zip code must be a 5-digit number"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    mobile: Yup.string()
      .matches(/^[0-9]+$/, "Mobile number must contain only digits")
      .min(10, "Mobile number must be at least 10 digits")
      .max(10, "Mobile number must not exceed 10 digits")
      .required("Mobile number is required"),
    first_name: Yup.string().required("site name is required"),
    fax_num: Yup.string().required("fax number is required"),
    // lastName: Yup.string().required("Last name is required"),
    // haveCollectionSite: Yup.string().required(
    //   "Have collection site is required"
    // ),
    // collectionSite: Yup.string().required("Collection site is required"),

    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const userDetail = useSelector(
    (state) => state?.user?.userInfo?.data?.user?._id
  );

  const navigate = useNavigate();

  async function handleSubmit() {
    // e.preventDefault();
    // e.stopPropagation();
    setShowSpin(true);
    let userData = {
      ...formik.values,
      user_type: "collectionsite",
      first_name: formik.values.first_name,
      faxNo: formik.values.fax_num,
      // last_name: formik.values.lastName,
      mobile_number: formik.values.mobile,
      address: formik.values.streetAddress,
      country: formik.values.country,
      state: formik.values.state,
      city: formik.values.city,
      password: formik.values.password,
      email: formik.values?.email,
      postal_code: formik.values.zip,
      created_by: userDetail ? userDetail : "",
    };
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>", userData);
    try {
      const res = await HttpClient.apiCaller(
        "register",
        "POST",
        userData,
        token,
        null
      );
      console.log(res);
      Utility.sToast("Data posted successfully");
      // onClose();
      formik.resetForm();
      if (res && window.location.pathname === "/signup") {
        navigate("/");
      }
    } catch (err) {
      console.log("=============", err);
      Utility.eToast("Something went wrong");
    } finally {
      setShowSpin(false);
    }
  }
  const formik = useFormik({
    initialValues: {
      first_name: "",
      fax_num: "",
      streetAddress: "",
      country: "United States",
      state: "",
      city: "",
      zip: "",
      email: "",
      mobile: "",
      // haveCollectionSite: [],
      // collectionSite: [],
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={`${isSignUpPage ? "-mt-7" : ""}`}
    >
      <div className={` ${isSignUpPage ? "" : " p-2"} bg-white shadow h-full `}>
        {/* heading  section */}
        {!isSignUpPage && (
          <div className="flex justify-between ">
            <div>
              <div className="flex gap-2 items-center">
                <PreviousButton onClick={onClose} />
                <div onClick={toggle} className="">
                  <p className="text-[18px] poppins-medium">
                    Create a Collection Site
                  </p>

                  <p className="poppins-medium text-xs  ">
                    <span className="underline  text-theme-blue cursor-pointer">
                      collection site
                    </span>{" "}
                    /{" "}
                    <span className="underline  text-theme-blue cursor-pointer">
                      Create a collection site
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="">
              <Reset onClick={() => formik.resetForm()} />
            </div>
          </div>
        )}
        {/* street address */}
        <div className="grid grid-cols-12 gap-4 mt-5">
          <div className="sm:col-span-12 col-span-12">
            <div className="sm:col-span-8 col-span-12">
              <p className="poppins-medium mb-1">Site name</p>
              <input
                type="text"
                placeholder="Enter site name"
                className="w-full h-11 rounded-lg border-none focus:outline-none bg-[#ECF1F6] p-2 poppins-regular"
                name="first_name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
              />
              {formik.touched.first_name && formik.errors.first_name && (
                <div className="text-red-500 poppins-regular text-sm ml-1">
                  {formik.errors.first_name}
                </div>
              )}
            </div>
          </div>

          {/* <div className="sm:col-span-6 col-span-12">
            <p className="poppins-medium mb-1">Last name</p>
            <input
              type="text"
              placeholder="Enter your last name"
              className="w-full h-11 rounded border-none focus:outline-none bg-[#ECF1F6] p-2 poppins-regular"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.lastName}
              </div>
            )}
          </div> */}
        </div>
        <div className={` ${isSignUpPage ? "" : "mt-10"}`}>
          <p className="poppins-medium mb-1">Street address</p>
          <textarea
            className="w-full p-1 rounded border-none focus:outline-none  bg-[#ECF1F6] poppins-regular"
            id=""
            cols="15"
            rows="2"
            placeholder="Add your address"
            name="streetAddress"
            value={formik.values.streetAddress}
            onChange={formik.handleChange}
          />
          {formik.touched.streetAddress && formik.errors.streetAddress && (
            <div className="text-red-500 poppins-regular text-sm ml-1">
              {formik.errors.streetAddress}
            </div>
          )}
        </div>
        <div className="grid grid-cols-12 gap-5 mt-2">
          <div className="sm:col-span-4 col-span-12">
            <h6 className="poppins-medium mb-1">Country</h6>
            <div className="poppins-medium">
              <CountrySelect
                defaultValue={Constants.usaObject}
                value={formik.values.country}
                onChange={(e) => {
                  formik.setFieldValue("country", e.name);
                  setCountryid(e.id);
                }}
                placeHolder="Select Country"
                showFlag={true}
              />
            </div>
            {formik.touched.country && formik.errors.country && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.country}
              </div>
            )}
          </div>

          {/* select state */}
          <div className="sm:col-span-4 col-span-12">
            <div>
              <h6 className=" mb-1 poppins-medium">State</h6>
              <div className="poppins-medium">
                {/* <StateSelect
                  value={formik.values.state}
                  onChange={(e) => {
                    formik.setFieldValue("state", e.name);
                    setStateid(e.id);
                  }}
                  countryid={countryid}
                  placeHolder="Select State"
                /> */}
                <input
                  type="text"
                  value={formik.values.state}
                  onChange={(e) => {
                    formik.setFieldValue("state", e.target.value);
                  }}
                  placeHolder="Select State"
                  className="h-11 w-full rounded border-none focus:outline-none bg-[#ECF1F6] p-2 poppins-regular"
                />
              </div>
              {formik.touched.state && formik.errors.state && (
                <div className="text-red-500 poppins-regular text-sm ml-1">
                  {formik.errors.state}
                </div>
              )}
            </div>
          </div>

          {/* select city */}
          <div className="sm:col-span-4 col-span-12">
            <h6 className=" mb-1 poppins-medium">City</h6>
            <div className="poppins-regular">
              {/* <CitySelect
                value={formik.values.city}
                onChange={(e) => {
                  formik.setFieldValue("city", e.name);
                }}
                countryid={countryid}
                stateid={stateid}
                placeHolder="Select City"
              /> */}
              <input
                type="text"
                value={formik.values.city}
                onChange={(e) => {
                  formik.setFieldValue("city", e.target.value);
                }}
                placeHolder="Select City"
                className="h-11 w-full rounded border-none focus:outline-none bg-[#ECF1F6] p-2 poppins-regular"
              />
            </div>
            {formik.touched.city && formik.errors.city && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.city}
              </div>
            )}
          </div>
        </div>
        <div className="grid grid-cols-12 mt-5 gap-4 ">
          <div className="sm:col-span-4 col-span-12">
            <p className="poppins-medium mb-1">Zip or Postal code</p>
            <input
              type="text"
              className="h-11 rounded w-full border-none focus:outline-none bg-[#ECF1F6] p-2 poppins-regular"
              placeholder="Enter zip or postal code"
              name="zip"
              value={formik.values.zip}
              onChange={formik.handleChange}
            />
            {formik.touched.zip && formik.errors.zip && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.zip}
              </div>
            )}
          </div>

          <div className="sm:col-span-4 col-span-12">
            <p className="poppins-medium mb-1">Phone</p>
            <input
              type="text"
              placeholder="Enter your phone number"
              className="w-full h-11 rounded border-none focus:outline-none bg-[#ECF1F6] p-2 poppins-regular"
              name="mobile"
              value={formik.values.mobile}
              onChange={formik.handleChange}
            />
            {formik.touched.mobile && formik.errors.mobile && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.mobile}
              </div>
            )}
          </div>

          <div className="sm:col-span-4 col-span-12">
            <p className="poppins-medium mb-1">Fax Number</p>
            <input
              type="text"
              placeholder="Enter fax number"
              className="w-full h-11 rounded-lg border-none focus:outline-none bg-[#ECF1F6] p-2 poppins-regular"
              name="fax_num"
              value={formik.values.fax_num}
              onChange={formik.handleChange}
            />
            {formik.touched.fax_num && formik.errors.fax_num && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.fax_num}
              </div>
            )}
          </div>
        </div>

        {/* <div className="flex xs:flex-col sm:flex-row mt-5 sm:items-center sm:justify-between gap-5 mt-5">
          <div className="xs:w-full sm:w-1/2 ">
            <div className="flex gap-3  items-center">
              <p>
                <b>Do you have a collection site? </b>
              </p>
              <div className="flex gap-3">
                <button
                  className={`px-2 py-1 rounded poppins-medium  ${
                    yesClicked ? "bg-theme-blue text-white" : "bg-[#ECF1F6]"
                  } `}
                  onClick={() => {
                    formik.setFieldValue("haveCollectionSite", "yes");
                    setYesClicked(true);
                    setNoClicked(false);
                  }}
                >
                  Yes
                </button>
                <button
                  onClick={() => {
                    formik.setFieldValue("haveCollectionSite", "no");
                    setYesClicked(false);
                    setNoClicked(true);
                  }}
                  className={`px-2 py-1 poppins-medium border-2  border-gray-400 rounded bg-[#ECF1F6]   ${
                    noClicked ? "bg-theme-blue text-white" : "bg-[#ECF1F6]"
                  }`}
                >
                  No
                </button>
              </div>
            </div>
          </div>

          <div className="xs:w-full sm:w-1/2">
            <p className="poppins-medium">Choose Collection site(multiple)</p>
            <select
              name="collectionSite"
              value={formik.values.collectionSite}
              onChange={(e) => {
                formik.setFieldValue("collectionSite", e.target.value);
              }}
              id="countries"
              className="bg-[#ECF1F6] border-none focus:outline-none text-gray-900 text-sm rounded block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
            >
              <option selected className="text-[#F3F7FA] bg-[#F3F7FA]">
                Choose Collector
              </option>
              <option value="US">United States</option>
              <option value="CA">Canada</option>
              <option value="FR">France</option>
              <option value="DE">Germany</option>
            </select>
            {formik.touched.collectionSite && formik.errors.collectionSite && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.collectionSite}
              </div>
            )}
          </div>
        </div> */}

        <div className="grid grid-cols-12 sm:gap-4 gap-2 mt-5">
          <div className="sm:col-span-4 col-span-12">
            <p className="poppins-medium mb-1">Email</p>
            <input
              type="email"
              placeholder="Enter your email id"
              className="w-full h-11 rounded border-none focus:outline-none bg-[#ECF1F6] p-2 poppins-regular"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.email}
              </div>
            )}
          </div>

          <div className="sm:col-span-4 col-span-12 ">
            <p className=" m poppins-medium">Password</p>
            <div className="flex items-center">
              <input
                type={showPassword ? "text" : "password"}
                className="w-full h-11 border-none focus:outline-none rounded bg-[#ECF1F6] p-2 poppins-regular"
                placeholder="Enter password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
              />

              {showPassword ? (
                <IoIosEye
                  className="relative sm:ml-[-3vw] ml-[-5vw]"
                  onClick={() => setShowPassword(!showPassword)}
                />
              ) : (
                <IoMdEyeOff
                  className="relative sm:ml-[-2vw] ml-[-5vw]"
                  onClick={() => setShowPassword(!showPassword)}
                />
              )}
            </div>
            {formik.touched.password && formik.errors.password && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.password}
              </div>
            )}
          </div>

          {/* confirm password */}
          <div className="sm:col-span-4 col-span-12 ">
            <p className="  poppins-medium">Confirm Password</p>
            <div className="flex items-center">
              <input
                type={showConfirmPassword ? "text" : "password"}
                className="w-full h-11 rounded border-none focus:outline-none bg-[#ECF1F6] p-2 poppins-regular"
                placeholder="Conform  password"
                name="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
              />

              {showConfirmPassword ? (
                <IoIosEye
                  className="relative sm:ml-[-2vw] ml-[-5vw]"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              ) : (
                <IoMdEyeOff
                  className="relative sm:ml-[-2vw] ml-[-5vw]"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              )}
            </div>
            {formik.touched.confirmPassword &&
              formik.errors.confirmPassword && (
                <div className="text-red-500 poppins-regular text-sm ml-1">
                  {formik.errors.confirmPassword}
                </div>
              )}
          </div>
        </div>

        {/* footer section*/}
        <div className="grid grid-cols-12 mt-2 mb-2 gap-2 pb-2 ">
          <div className="sm:col-span-3 col-span-12 grid grid-cols-12 items-center">
            <div className="col-span-6">
              <p className=" poppins-medium">
                {/* <b className="poppins-medium">Collection Site is active</b> */}
              </p>
            </div>
            <div className="col-span-6">{/* <Toggle /> */}</div>
          </div>
          <div className="sm:col-span-7 none"></div>
          <div className=" mt-4 sm:gap-3 gap-6 flex sm:justify-start justify-evenly">
            <div>
              <Cancelsmall onClick={onClose} />
            </div>
            <div>
              <button
                type="submit"
                className="px-2 py-1  poppins-medium rounded bg-blue-600 hover:opacity-80 text-white"
              >
                {showSpin ? <Spinner color="white" /> : "submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddCollectionSite;
