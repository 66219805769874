import Edit from "../../../components/buttons/Edit";

export const myTestDetailsTableHeadData = [
  {
    accessorKey: "dateCreated",
    header: "Date Created",
    size: 150,
  },
  {
    accessorKey: "email",
    header: "Email",
    size: 150,
  },

  {
    accessorKey: "action",
    header: "Action",
    size: 50,
  },
];

export const getMyTestQRDetailsTableData = (dataSet) => {
  const tableData = dataSet.map((item, idx) => {
    return {
      dateCreated: item?.dateCreated,
      email: item?.email,

      action: <Edit />,
    };
  });
  return tableData;
};
