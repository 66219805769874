import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { UserController } from "../controllers/UserController";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  isProfileCompleted: false,
  email: null,
  name: null,
  phonenumber: null,
  token: null,
  status: null,
  user_type: null,
  userInfo: {},
};

export const login = createAsyncThunk(
  "login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserController.userLogin(data);
      console.log("userSlice=====>", response);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      console.log("USER INFO.....", action.payload);
      state.userInfo = action.payload;
    },
    clear: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.token = null;
      state.phonenumber = null;
      state.name = null;
      state.email = null;
      state.status = null;
      state.user_type = null;
      state.userInfo = {};
    },
  },
  extraReducers: (builder) => {
    //user login case
    builder.addCase(login.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.token = action?.payload?.data?.token;
      state.email = action?.payload?.data?.user?.email;
      state.name = action?.payload?.data?.user?.name;
      state.phonenumber = action?.payload?.data?.user?.phone_number;
      state.status = action?.payload?.data?.user?.status;
      state.user_type = action?.payload?.data?.user?.user_type;
      state.userInfo = action?.payload;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
    });

    // //user trending course case
    // builder.addCase(trendingCoursedetails.pending, (state, action) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(trendingCoursedetails.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.isSuccess = true;
    //   state.isError = false;
    // });
    // builder.addCase(trendingCoursedetails.rejected, (state, action) => {
    //   state.isLoading = false;
    //   state.isSuccess = false;
    //   state.isError = true;
    // });
  },
});

export const { clear, setUserInfo } = userSlice.actions;
export default userSlice.reducer;
