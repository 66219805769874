import AddButton from "../../components/buttons/AddButton";
import PreviousButton from "../../components/buttons/PreviousButton";
import Dashboard from "../../components/Dashboard";
import Table from "../../components/Table";
import { FaPlus } from "react-icons/fa6";
// import { useState } from "react";
import { FaFileDownload } from "react-icons/fa";
import {
  getCollectionSiteTableData,
  collectionSiteTableHeadData,
} from "../../constants/data/collectionSiteData";
import { useEffect, useState } from "react";
import { useForm } from "../../context/formContext";
import { useSelector } from "react-redux";
import { useModal } from "../../context/modalContext";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { Spinner } from "@material-tailwind/react";
import CollectionModal from "../../components/Modals/Collectionmodal";
import AddCollectionSite from "./../../components/forms/AddCollectionSite";

const CollectionSitePage = () => {
  const { showForm, openForm, closeForm } = useForm();
  const { showEditModal, showModal, openModal, closeModal, closeEditModal } =
    useModal();
  const [showSpin, setShowSpin] = useState(false);
  const { token } = useSelector((state) => state.user);
  const [collectionSiteTableData, setCollectionSiteTableData] = useState([]);
  const userId = useSelector((state) => state?.user?.userInfo?.data.user?._id);
  useEffect(() => {
    const fetchData = async () => {
      setShowSpin(true);
      try {
        const { data } = await HttpClient.apiCaller(
          // admin/get-users-createdby/6614d500c12909f1960b0791?user_type=physician
          `admin/get-users-createdby/${userId}?user_type=collectionsite`,
          "GET",
          null,
          token,
          null
        );
        console.log(data.user);
        setCollectionSiteTableData(data.user);
      } catch (error) {
        console.log(error);
      } finally {
        setShowSpin(false);
      }
    };

    fetchData();

    return () => { };
  }, [closeEditModal, closeForm]);
  return (
    <Dashboard>
      {showForm ? (
        <AddCollectionSite onClose={closeForm} />
      ) : (
        <>
          {" "}
          <div className="flex xs:flex-col-reverse xs:gap-2 sm:flex-row sm:items-center sm:justify-between mb-5">
            <div>
              <p className="poppins-medium text-lg leading-[21px]  text-theme-darkBlue mb-2">
                Manage Collection Site{" "}
              </p>
              <p className="poppins-medium text-xs   ">
                <span className="underline text-theme-blue cursor-pointer">
                  {" "}
                  Dashboard
                </span>{" "}
                /{" "}
                <span className="underline text-theme-blue cursor-pointer">
                  collection site
                </span>
              </p>
            </div>

            {/*  */}

            <AddButton
              onClick={openForm}
              buttonName={"Add New Collection Site"}
              icon={<FaPlus className="size-5" />}
            />
          </div>
          {/*  table */}
          {showSpin ? (
            <div className="w-full sm:mt-[36vh] flex items-center justify-center">
              <Spinner className="h-12 w-12" />{" "}
            </div>
          ) : (
            <Table
              tableData={getCollectionSiteTableData(collectionSiteTableData)}
              tableHeadData={collectionSiteTableHeadData}
            />
          )}
        </>
      )}
      {/*Edit modal */}
      {showEditModal ? (
        <CollectionModal edit={true} onClose={closeEditModal} />
      ) : (
        <></>
      )}
    </Dashboard>
  );
};

export default CollectionSitePage;
