import { Badge } from "../../components/Table";
import Edit from "./../../components/buttons/Edit";

export const collectorTableHeadData = [
  {
    accessorKey: "slno",
    header: "Sl No",
    size: 100,
  },
  {
    accessorKey: "uid",
    header: "UID",
    size: 100,
  },
  {
    accessorKey: "firstName",
    header: "First Name",
    size: 150,
  },
  {
    accessorKey: "lastName",
    header: "Last Name",
    size: 150,
  },
  {
    accessorKey: "email",
    header: "Eamil",
    size: 200,
  },
  {
    accessorKey: "mobile",
    header: "Phone",
    size: 150,
  },

  // {
  //   accessorKey: "status",
  //   header: "Status",
  //   size: 100,
  // },
  {
    accessorKey: "action",
    header: "Action",
    size: 100,
  },
];

export const getCollectorTableData = (dataSet) => {
  const tableData = dataSet?.map((item, idx) => {
    return {
      slno: idx + 1,
      uid: item?.uid,
      firstName: item?.first_name || "N/A",
      lastName: item?.last_name || "N/A",
      email: item?.email || "N/A",
      mobile: item?.mobile_number,
      status: <Badge status={item?.status} />,
      action: <Edit user={item} />,
    };
  });
  return tableData;
};
