import { Spinner } from "@material-tailwind/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AddClinic from "../../components/forms/AddClinic";
import PhysicianForm from "../../components/forms/PhysicianForm";
import AddPatient from "../../components/forms/AddPatient";
import AddCollectionSite from "../../components/forms/AddCollectionSite";
import AddCollector from "../../components/forms/AddCollector";
import AddOrganization from "../../components/forms/AddOrganization";
import AddTechnician from "../../components/forms/AddTechnician";
import AddClinicalScientist from "../../components/forms/AddClinicalScientist";

function SignUp() {
  const [spin, setSpin] = useState(false);
  const [role, setRole] = useState("MEDICAL REVIEW OFFICER");
  const navigate = useNavigate();

  // const handleFormSubmit = (e) => {
  //   e.preventDefault();
  //   // Handle form submission logic
  // };

  const onCancelClick = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 min-h-screen min-w-full overflow-auto flex  justify-center">
      <div className="max-h-[95vh] min-h-[95vh] w-[80%] ">
        {/* sign Up as */}
        <div className="poppins-medium bg-gray-400 px-2 py-1 rounded w-fit mt-3">
          SIGNUP AS <span clas>{role}</span>
        </div>
        {/* drop down box */}
        <div className="bg-white px-5 pt-3 ">
          <select
            value={role}
            onChange={(e) => setRole(e.target.value)}
            className="w-full py-2 px-2 border rounded poppins-medium"
          >
            <option value="MEDICAL REVIEW OFFICER">
              MEDICAL REVIEW OFFICER
            </option>
            <option value="PATIENT">PATIENT</option>
            <option value="CLINIC">CLINIC </option>
            <option value="COLLECTION SITE">COLLECTION SITE</option>
            <option value="COLLECTOR">COLLECTOR</option>
            <option value="ORGANIZATION">ORGANIZATION</option>
            <option value="TECHNICIAN">TECHNICIAN</option>
            <option value="CLINICAL SCIENTIST">CLINICAL SCIENTIST</option>
          </select>
        </div>
        {/* signup form */}
        <div className="h-[80vh] overflow-y-auto px-5 pt-5  bg-white">
          {/* for PHYSICIAN  */}
          {role === "MEDICAL REVIEW OFFICER" && (
            <PhysicianForm isSignUpPage={true} onClose={onCancelClick} />
          )}
          {/* for PATIENT  */}
          {role === "PATIENT" && (
            <AddPatient isSignUpPage={true} onClose={onCancelClick} />
          )}
          {/* for CLINIC  */}
          {role === "CLINIC" && (
            <AddClinic isSignUpPage={true} onClose={onCancelClick} />
          )}
          {/* for CLINIC  */}
          {role === "ORGANIZATION" && (
            <AddOrganization isSignUpPage={true} onClose={onCancelClick} />
          )}
          {/* for COLLECTION SITE  */}
          {role === "COLLECTION SITE" && (
            <AddCollectionSite isSignUpPage={true} onClose={onCancelClick} />
          )}
          {/* for COLLECTOR  */}
          {role === "COLLECTOR" && (
            <AddCollector isSignUpPage={true} onClose={onCancelClick} />
          )}
          {/* for ORGANIZATION  */}
          {/* {role === "ORGANIZATION" && (
            <AddOrganization isSignUpPage={true} onClose={onCancelClick} />
          )} */}
          {/* for TECHNICIAN  */}
          {role === "TECHNICIAN" && (
            <AddTechnician isSignUpPage={true} onClose={onCancelClick} />
          )}
          {/* for CLINICAL SCIENTIST  */}
          {role === "CLINICAL SCIENTIST" && (
            <AddClinicalScientist isSignUpPage={true} onClose={onCancelClick} />
          )}
        </div>
      </div>
    </div>
  );
}

export default SignUp;
