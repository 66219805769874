import { useEffect, useState } from "react";
import { MdPersonAddAlt1 } from "react-icons/md";
import AddButton from "../../components/buttons/AddButton";
import Dashboard from "../../components/Dashboard";
import Table from "../../components/Table";
import PhysicianForm from "../../components/forms/PhysicianForm";
import {
  physicianTableHeadData,
  getPhysicianTableData,
} from "../../constants/data/physiciansData";
import { useForm } from "../../context/formContext";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { useSelector } from "react-redux";
import { Spinner } from "@material-tailwind/react";
import { useModal } from "../../context/modalContext";
import Physician from "../../components/Modals/Physician";
const PhysiciansPage = () => {
  const { showForm, openForm, closeForm } = useForm();
  const [showSpin, setShowSpin] = useState(false);
  const { token } = useSelector((state) => state.user);
  const [physicianTableData, setPhysicianTableData] = useState([]);
  const { showEditModal, closeEditModal } = useModal();

  const userDetail = useSelector(
    (state) => state?.user?.userInfo?.data?.user?._id
  );

  useEffect(() => {
    const fetchData = async () => {
      setShowSpin(true);
      try {
        const { data } = await HttpClient.apiCaller(
          `admin/get-users-createdby/${userDetail}?user_type=mro`,
          "GET",
          null,
          token,
          null
        );
        console.log(data.user);
        setPhysicianTableData(data.user);
      } catch (error) {
        console.log(error);
      } finally {
        setShowSpin(false);
      }
    };

    fetchData();

    return () => { };
  }, [closeForm, closeEditModal]);

  return (
    <Dashboard>
      {showForm ? (
        <PhysicianForm onClose={closeForm} />
      ) : (
        <>
          <div className="flex xs:flex-col-reverse xs:gap-2 sm:flex-row sm:items-center sm:justify-between mb-5">
            <div>
              <p className="poppins-medium  leading-[21px]  text-gray-700 mb-2">
                List of mro
              </p>
              <p className="poppins-regular text-xs text-[#B5B5C3] ">
                {physicianTableData?.length} available mro
              </p>
            </div>
            {/*  */}
            <AddButton
              onClick={openForm}
              buttonName={"Add New mro"}
              icon={<MdPersonAddAlt1 className="size-5" />}
            />
          </div>
          {/*  table */}
          {showSpin ? (
            <div className="w-full mt-[36vh] flex items-center justify-center">
              <Spinner className="h-12 w-12" />{" "}
            </div>
          ) : (
            <Table
              tableData={getPhysicianTableData(physicianTableData)}
              tableHeadData={physicianTableHeadData}
            />
          )}
        </>
      )}
      {/*Edit modal */}
      {showEditModal ? (
        <Physician edit={true} onClose={closeEditModal} />
      ) : (
        <></>
      )}
    </Dashboard>
  );
};

export default PhysiciansPage;
