import { accordion } from "@material-tailwind/react";
import { Badge } from "../../components/Table";
import LoginAsClinic from "../../components/buttons/LoginAsClinic";
import Toggle from "../../components/buttons/Toggle";
import Edit from "./../../components/buttons/Edit";

export const organizationTableHeadData = [
  {
    accessorKey: "no",
    header: "Sl No",
    size: 50,
  },
  {
    accessorKey: "uid",
    header: "UID",
    size: 50,
  },
  {
    accessorKey: "organization",
    header: "Organization Name",
    size: 100,
  },
  {
    accessorKey: "employecode",
    header: "Employer Code",
    size: 150,
  },
  {
    accessorKey: "email",
    header: "Email",
    size: 150,
  },
  {
    accessorKey: "mobile",
    header: "Phone",
    size: 150,
  },

  // {
  //   accessorKey: "visibilityOfClinic",
  //   header: "Visibility of clinic",
  //   size: 300,
  // },
  // {
  //   accessorKey: "status",
  //   header: "Status",
  //   size: 50,
  // },
  {
    accessorKey: "action",
    header: "Action",
    size: 50,
  },
  // {
  //   accessorKey: "login",
  //   header: "",
  //   enableSorting: false,
  //   enableColumnActions: false,
  // },
];

export const getOrganizationTableData = (dataSet) => {
  const tableData = dataSet.map((item, idx) => {
    return {
      no: idx + 1,
      uid: item?.uid,
      organization: item?.organizationName,
      employecode: item?.employer_code,
      email: item?.email,
      mobile: item?.mobile_number,

      // visibilityOfClinic: (
      //   <div className="flex items-center gap-2">
      //     <Toggle visibility={item?.login} />s
      //   </div>
      // ),
      status: <Badge status={item?.status} />,
      action: <Edit user={item} />,
      // login: <LoginAsClinic />,
    };
  });
  return tableData;
};
