import { FaPlus } from "react-icons/fa6";
import AddButton from "../../components/buttons/AddButton";
import Dashboard from "../../components/Dashboard";
import Table from "../../components/Table";
import {
  getCollectorTableData,
  collectorTableHeadData,
} from "../../constants/data/collectorData";
import { useModal } from "../../context/modalContext";
import Collector from "./../../components/Modals/Collector";
import { useSelector } from "react-redux";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { useEffect, useState } from "react";
import { Spinner } from "@material-tailwind/react";
import AddCollector from "../../components/forms/AddCollector";
import { useForm } from "../../context/formContext";

const PhysicianCollectorPage = () => {
  const { showEditModal, showModal, openModal, closeModal, closeEditModal } =
    useModal();
  const [showSpin, setShowSpin] = useState(false);
  const { token } = useSelector((state) => state.user);
  const [collectorTableData, setCollectorTableData] = useState([]);
  const { showForm, openForm, closeForm } = useForm();

  const userDetail = useSelector(
    (state) => state?.user?.userInfo?.data?.user?._id
  );

  useEffect(() => {
    setShowSpin(true);
    HttpClient.apiCaller(
      `admin/get-users-createdby/${userDetail}?user_type=collector`,
      "GET",
      null,
      token,
      null
    )
      .then((res) => {
        console.log("MMMMMMMMM", res);
        setCollectorTableData(res?.data?.user);
      })
      .catch((error) => console.log(error))
      .finally(() => setShowSpin(false));
  }, [closeForm, closeEditModal]);
  return (
    <Dashboard>
      {showForm ? (
        <AddCollector onClose={closeForm} />
      ) : (
        <>
          {" "}
          <div className="flex xs:flex-col-reverse xs:gap-2 sm:flex-row sm:items-center sm:justify-between mb-5">
            <div>
              <p className="poppins-medium  leading-[21px]  text-gray-700 mb-2">
                List of Collectors
              </p>
              <p className="poppins-regular text-xs text-[#B5B5C3]  ">
                {collectorTableData?.length} available collectors
              </p>
            </div>
            {/*  */}
            <AddButton
              onClick={openForm}
              buttonName={"Add New Collector"}
              icon={<FaPlus className="size-5" />}
            />
          </div>
          {/*  table */}
          {showSpin ? (
            <div className="w-full mt-20 flex items-center justify-center">
              <Spinner className="h-12 w-12" />{" "}
            </div>
          ) : (
            <Table
              tableData={getCollectorTableData(collectorTableData)}
              tableHeadData={collectorTableHeadData}
            />
          )}
          {/*Add modal */}
          {/* {showModal ? <Collector edit={false} onClose={closeModal} /> : <></>} */}
          {/*Edit modal */}
          {showEditModal ? (
            <Collector edit={true} onClose={closeEditModal} />
          ) : (
            <></>
          )}
        </>
      )}
    </Dashboard>
  );
};

export default PhysicianCollectorPage;
