// import { useState } from "react";
// import { RiEdit2Line } from "react-icons/ri";
// import { useModal } from "../../context/modalContext";
// function Edit() {
//   const { openEditModal } = useModal();
//   return (
//     <div
//       className="flex  items-center gap-2 text-theme-blue poppins-bold underline cursor-pointer font-semibold"
//       onClick={openEditModal}
//     >
//       <RiEdit2Line /> Edit
//     </div>
//   );
// }

// export default Edit;
import { useState } from "react";
import { RiEdit2Line } from "react-icons/ri";
import { useModal } from "../../context/modalContext";
function Edit({ user }) {
  const { openEditModal, setUserID } = useModal();
  return (
    <div
      className="flex  items-center gap-2 text-theme-blue poppins-bold underline cursor-pointer font-semibold"
      onClick={() => {
        openEditModal();
        setUserID(user);
      }}
    >
      <RiEdit2Line /> Edit
    </div>
  );
}

export default Edit;
