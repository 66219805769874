import React, { useState } from "react";
import Dashboard from "../../components/Dashboard";
import { Spinner } from "@material-tailwind/react";
import Table from "../../components/Table";
import {
  getMyTestQRDetailsTableData,
  myTestDetailsTableHeadData,
} from "../../constants/data/patient/myTestQrData";

const PatientMyTestQr = () => {
  const [showSpin, setShowSpin] = useState(false);
  const [myTestQrDetailsTableData, setMyTestQrTableTableData] = useState([]);
  return (
    <Dashboard>
      <div className="flex xs:flex-col-reverse xs:gap-2 sm:flex-row sm:items-center sm:justify-between mb-5">
        <div>
          <p className="poppins-medium text-lg leading-[21px]  text-theme-darkBlue mb-2">
            Manage QR Details
          </p>
          <p className="poppins-medium text-xs  ">
            <span className="underline  text-theme-blue cursor-pointer">
              Dashboard
            </span>{" "}
            /{" "}
            <span className="underline  text-theme-blue cursor-pointer">
              Manage QR Details
            </span>
          </p>
        </div>
        {/*  */}
        {/* 
        <AddButton
          onClick={openForm}
          buttonName={"Add New Patient"}
          icon={<FaPlus className="size-5" />}
        /> */}
      </div>

      {/*  table */}
      {showSpin ? (
        <div className="w-full mt-20 flex items-center justify-center">
          <Spinner className="h-12 w-12" />{" "}
        </div>
      ) : (
        <Table
          tableData={getMyTestQRDetailsTableData(myTestQrDetailsTableData)}
          tableHeadData={myTestDetailsTableHeadData}
        />
      )}
    </Dashboard>
  );
};

export default PatientMyTestQr;
