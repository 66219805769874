import Delete from "../../components/buttons/DeleteButton";
import { Badge } from "../../components/Table";
import Edit from "./../../components/buttons/Edit";

export const adminTableHeadData = [
  {
    accessorKey: "no",
    header: "Sl No",
    size: 100,
  },
  // {
  //   accessorKey: "firstName",
  //   header: "First Name",
  //   size: 150,
  // },
  // {
  //   accessorKey: "lastName",
  //   header: "Last Name",
  //   size: 150,
  // },
  {
    accessorKey: "uid",
    header: "UID",
    size: 100,
  },
  {
    accessorKey: "email",
    header: "Eamil",
    size: 250,
  },
  {
    accessorKey: "phone",
    header: "Phone",
    size: 100,
  },
  // {
  //   accessorKey: "status",
  //   header: "Status",
  //   size: 150,
  // },
  {
    accessorKey: "action",
    header: "Action",
    size: 150,
  },
];
export const getAdminTableData = (dataSet) => {
  const tableData = dataSet?.map((item, idx) => {
    return {
      no: idx + 1,
      // firstName: item?.first_name || "N/A",
      // lastName: item?.last_name || "N/A",
      uid: item?.uid,
      email: item?.email || "N/A",
      phone: item?.phone_number || "N/A",
      status: <Badge status={item?.status} />,
      action: (
        <div className="flex gap-2">
          <Edit user={item} />
          <Delete />
        </div>
      ),
    };
  });
  return tableData;
};
