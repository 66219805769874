import { useEffect, useState } from "react";
import AddButton from "../../components/buttons/AddButton";
import PreviousButton from "../../components/buttons/PreviousButton";
import Dashboard from "../../components/Dashboard";
import Table, { Badge } from "../../components/Table";
import {
  // getSpecimenInfoTableData,
  specimenInfoTableHeadData,
} from "../../constants/data/specimenInfoData";
import Specimencode from "../../components/Modals/Specimencode";
import { FaPlus } from "react-icons/fa6";
import { useModal } from "../../context/modalContext";
import { useSelector } from "react-redux";
import { Spinner } from "@material-tailwind/react";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import Edit from "../../components/buttons/Edit";
import Delete from "../../components/buttons/DeleteButton";
import DeleteUserModal from "../../components/Modals/DeleteUserModal";
import axios from "axios";
import Utility from "../../Utils/Utility";

function SpecimenInfoPage() {
  const {
    showEditModal,
    showModal,
    openModal,
    closeModal,
    closeEditModal,
    openDeleteModal,
    closeDeleteModal,
    showDeleteModal,
  } = useModal();
  const [showSpin, setShowSpin] = useState(false);
  const { token } = useSelector((state) => state.user);
  const [specimenTableData, setSpecimenTableData] = useState([]);
  const [stateUpdate, setStateUpdate] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setShowSpin(true);
      try {
        const { data } = await HttpClient.apiCaller(
          "admin/code?type=SPECIMEN",
          "GET",
          null,
          token,
          null
        );
        console.log(data?.code);
        setSpecimenTableData(data?.code);
      } catch (error) {
        console.log(error);
      } finally {
        setShowSpin(false);
      }
    };

    fetchData();

    return () => {};
  }, [closeModal, closeEditModal, stateUpdate]);

  const deleteData = async (id) => {
    let config = {
      method: "delete",
      url: `${HttpClient.API_BASE_URL}admin/delete-spicimen-code/${id}`,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        Utility.sToast("Data deleted");
        setStateUpdate(true);
      })
      .catch((error) => {
        console.log(error);
        Utility.eToast("Failed!");
      })
      .finally(() => setStateUpdate(false));
  };

  const getSpecimenInfoTableData = (dataSet) => {
    const tableData = dataSet?.map((item, idx) => {
      return {
        sl: idx + 1,
        testName: item?.name,
        uid: item?.uid,
        test: item?.code,
        status: <Badge status={item?.status} />,
        action: (
          <div className="flex gap-3">
            <Edit user={item} />
            {/* <Delete user={item} /> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
              style={{
                color: "red",
                height: "1em",
                width: "1em",
                marginTop: "0.2em",
                cursor: "pointer",
              }}
              onClick={() => deleteData(item?._id)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          </div>
        ),
      };
    });
    return tableData;
  };
  return (
    <Dashboard>
      <div className="flex xs:flex-col-reverse xs:gap-2 sm:flex-row sm:items-center sm:justify-between mb-5">
        <div>
          <p className="poppins-medium text-lg leading-[21px]  text-theme-darkBlue mb-2">
            Manage Specimen Code{" "}
          </p>
          <p className="poppins-medium text-xs  ">
            <span className="underline  text-theme-blue cursor-pointer">
              Dashboard
            </span>{" "}
            /{" "}
            <span className="underline  text-theme-blue cursor-pointer">
              Manage Specimen Code
            </span>
          </p>
        </div>
        {/*  */}

        <AddButton
          onClick={openModal}
          buttonName={"Add Specimen Code"}
          icon={<FaPlus className="size-5" />}
        />
      </div>

      {/*  table */}
      {showSpin ? (
        <div className="w-full mt-20 flex items-center justify-center">
          <Spinner className="h-12 w-12" />{" "}
        </div>
      ) : (
        <Table
          tableData={getSpecimenInfoTableData(specimenTableData)}
          tableHeadData={specimenInfoTableHeadData}
        />
      )}
      {/*  */}
      {showModal ? <Specimencode onClose={closeModal} /> : <></>}
      {showEditModal ? (
        <Specimencode edit={true} onClose={closeEditModal} />
      ) : (
        <></>
      )}
      {/* delete modal */}
      {showDeleteModal && <DeleteUserModal onClose={closeDeleteModal} />}
    </Dashboard>
  );
}

export default SpecimenInfoPage;
