import AddButton from "../../components/buttons/AddButton";
import Dashboard from "../../components/Dashboard";
import Table from "../../components/Table";
import { MdPersonAddAlt1 } from "react-icons/md";
import {
    getClinicTableData,
    clinicTableHeadData,
} from "../../constants/data/clinicData";
import AddClinic from "../../components/forms/AddClinic";
import { useForm } from "../../context/formContext";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ClinicModal from "../../components/Modals/ClinicModal";
import { useModal } from "../../context/modalContext";
import { Spinner } from "@material-tailwind/react";
const SClinics = () => {
    const { showForm, openForm, closeForm } = useForm();
    const { showEditModal, showModal, openModal, closeModal, closeEditModal } =
        useModal();
    const [showSpin, setShowSpin] = useState(false);
    const { token } = useSelector((state) => state.user);
    const [clinicTableData, setClinicTableData] = useState([]);
    const userDetail = useSelector(
        (state) => state?.user?.userInfo?.data?.user?._id
    );
    useEffect(() => {
        const fetchData = async () => {
            setShowSpin(true);
            try {
                const { data } = await HttpClient.apiCaller(
                    `admin/get-users-createdby/${userDetail}?user_type=clinic`,
                    "GET",
                    null,
                    token,
                    null
                );
                console.log(data.user);
                setClinicTableData(data.user);
            } catch (error) {
                console.log(error);
            } finally {
                setShowSpin(false);
            }
        };

        fetchData();

        return () => { };
    }, [closeForm, closeEditModal]);
    return (
        <Dashboard>
            {showForm ? (
                <AddClinic onClose={closeForm} />
            ) : (
                <>
                    {" "}
                    <div className="flex xs:flex-col-reverse xs:gap-2 sm:flex-row sm:items-center sm:justify-between mb-5">
                        <div>
                            <p className="poppins-medium  leading-[21px]  text-gray-700 mb-2">
                                List of Clinics
                            </p>
                            <p className="poppins-regular text-xs text-[#B5B5C3] ">
                                {clinicTableData?.length} available clinics
                            </p>
                        </div>
                        {/*  */}
                        <AddButton
                            onClick={openForm}
                            buttonName={"Add New Cliinic"}
                            icon={<MdPersonAddAlt1 className="size-5" />}
                        />
                    </div>
                    {/*  table */}
                    {showSpin ? (
                        <div className="w-full mt-20 flex items-center justify-center">
                            <Spinner className="h-12 w-12" />{" "}
                        </div>
                    ) : (
                        <Table
                            tableData={getClinicTableData(clinicTableData)}
                            tableHeadData={clinicTableHeadData}
                        />
                    )}
                </>
            )}
            {/*Edit modal */}
            {showEditModal ? (
                <ClinicModal edit={true} onClose={closeEditModal} />
            ) : (
                <></>
            )}
        </Dashboard>
    );
};

export default SClinics;
