// import React, { useState, useRef, useEffect } from "react";
// import mask from "../../assets/Mask group.jpg";
// import "./Form.css";
// import { LuPencil } from "react-icons/lu";
// import SignatureCanvas from "react-signature-canvas";
// import Datetime from "../../components/buttons/Datetime";
// import Cancel from "../../components/buttons/Cancel";
// import GreenSubmit from "../../components/buttons/GreenSubmit";
// import Todaydate from "../../components/buttons/Todaydate";
// import PreviousButton from "../buttons/PreviousButton";
// import Cancelsmall from "../buttons/Cancelsmall";
// import Utility from "../../Utils/Utility";
// import { HttpClient } from "../../Redux/controllers/HttpClient";
// import { useSelector } from "react-redux";
// import { useModal } from "../../context/modalContext";
// const ForensicOrderFormEdit = ({ onClose }) => {
//   const signatureRef = useRef();

//   // saving & fetching in & from local storage
//   // const [localData, setLocalData] = useState({});
//   // const saveItem = (keyy, valuee) => {
//   //   const existingData =
//   //     JSON.parse(localStorage.getItem("forensicDrugForm")) || {};
//   //   existingData[keyy] = valuee;
//   //   localStorage.setItem("forensicDrugForm", JSON.stringify(existingData));
//   // };
//   // useEffect(() => {
//   //   const getLocalStorageValues = () => {
//   //     const data = localStorage.getItem("forensicDrugForm");
//   //     if (data) {
//   //       const parsedData = JSON.parse(data);
//   //       setLocalData(parsedData);
//   //       return parsedData;
//   //     } else {
//   //       return {};
//   //     }
//   //   };
//   //   getLocalStorageValues();
//   // }, []);

//   const currentDateTime = new Date();
//   const formattedDate = currentDateTime.toISOString().slice(0, 10);
//   const formattedTime = currentDateTime.toTimeString().slice(0, 5);

//   const handleStep4Date = () => {
//     set_collection_date(formattedDate);
//     set_collection_time(formattedTime);
//   };
//   const handleStepLabDate = () => {
//     set_r_date(formattedDate);
//   };
//   const handleSplitExpDate = () => {
//     set_primary_specimen_expiration(formattedDate);
//     set_split_specimen_expiration(formattedDate);
//   };
//   const handleTechniDate = () => {
//     set_p_date(formattedDate);
//   };
//   const handleSplitTestDate = () => {
//     set_s_date(formattedDate);
//   };
//   const handleMRODate = () => {
//     setm_date(formattedDate);
//   };
//   const handleMROSDate = () => {
//     set_mr_date(formattedDate);
//   };

//   const { userID, setUserID } = useModal();
//   console.log("<><><><>", userID);

//   const [specimen_id, set_specimen_id] = useState(userID?.specimen_id);
//   const [accession_no, set_accession_no] = useState(userID?.accession_no);
//   const [employer_name, set_employer_name] = useState(userID?.employer_name);
//   const [employer_code, set_employer_code] = useState(userID?.employer_code);
//   const [employer_address, set_employer_address] = useState(
//     userID?.employer_address
//   );
//   const [employer_id, set_employer_id] = useState(userID?.employer_id);
//   //mro
//   const [mro_name, set_mro_name] = useState(userID?.mro?.name);
//   const [mro_phone, set_mro_phone] = useState(userID?.mro?.phone_number);
//   const [mro_fax, set_mro_fax] = useState(userID?.mro?.fax_number);
//   const [mro_addr, set_mro_addr] = useState(userID?.mro?.address);
//   //donor
//   const [donor_first_name, set_donor_first_name] = useState(
//     userID?.donor_first_name
//   );
//   const [donor_dob, set_donor_dob] = useState(userID?.donor_dob);
//   const [donor_sign, set_donor_sign] = useState(userID?.donor_sign); //signature image
//   const [donor_day_no, set_donor_day_no] = useState(userID?.donor_day_no);
//   const [donor_eve_no, set_donor_eve_no] = useState(userID?.donor_eve_no);
//   const [donor_last_name, set_donor_last_name] = useState(
//     userID?.donor_last_name
//   );
//   const [donor_id, set_donor_id] = useState(userID?.donor_id);
//   //donor verification
//   const [photo_id, set_photo_id] = useState(
//     userID?.donor_verification?.photo_id
//   );
//   // const [employer, set_employer] = useState("");
//   // const [representive, set_representive] = useState("");
//   const [id_type, set_id_type] = useState(userID?.donor_verification?.id_type);
//   const handleId = (e) => set_id_type(e.target.value);

//   const [test_reason, set_test_reason] = useState(userID?.test_reason);
//   const testReason = (e) => set_test_reason(e.target.value);

//   const [reason_remark, set_reason_remark] = useState("");

//   const [custom_drug_test, set_custom_drug_test] = useState(
//     userID?.custom_drug_test
//   );
//   const [additional_drug_test, set_additional_drug_test] = useState(
//     userID?.additional_drug_test
//   );
//   //site details
//   const [site_name, set_site_name] = useState(userID?.site_details?.name);
//   const [site_phone, set_site_phone] = useState(
//     userID?.site_details?.phone_number
//   );
//   const [site_fax, set_site_fax] = useState(userID?.site_details?.fax_number);
//   const [site_addr, set_site_addr] = useState(userID?.site_details?.address);
//   const [site_pin, set_site_pin] = useState(userID?.site_details?.postal_code);

//   const [test_type, set_test_type] = useState(userID?.test_type);
//   const testType = (e) => set_test_type(e.target.value);

//   //collections
//   const [collection_type, set_collection_type] = useState(
//     userID?.collections?.collection_type
//   );
//   const collectionType = (e) => set_collection_type(e.target.value);

//   const [split_type, set_split_type] = useState(
//     userID?.collections?.split_type
//   );
//   const splitType = (e) => set_split_type(e.target.value);

//   const [expiration_date, set_expiration_date] = useState(
//     userID?.collections?.expiration_date
//   );
//   const expired = (e) => set_expiration_date(e.target.value);

//   const [urine_temperature, set_urine_temperature] = useState("");
//   const [volume_indicator, set_volume_indicator] = useState(
//     userID?.collections?.volume_indicator
//   );
//   const volumeIndicator = (e) => set_volume_indicator(e.target.value);

//   const [remark, set_remark] = useState(userID?.collections?.remark);
//   //custody
//   const [collector_sign, set_collector_sign] = useState(""); //image
//   const [collector_name, set_collector_name] = useState(
//     userID?.custody?.collector_name
//   );
//   const [collection_date, set_collection_date] = useState(
//     userID?.custody?.date
//   );
//   const [collection_time, set_collection_time] = useState(
//     userID?.custody?.time
//   );

//   const [released_to, set_released_to] = useState(userID?.custody?.released_to);
//   const releasedTo = (e) => set_released_to(e.target.value);

//   //received at
//   const [r_collector_sign, set_r_collector_sign] = useState(""); //image
//   const [r_collector_name, set_r_collector_name] = useState(
//     userID?.received_at?.collector_name
//   );
//   const [r_date, set_r_date] = useState(userID?.received_at?.date);
//   const [r_time, set_r_time] = useState(userID?.received_at?.time);

//   const [r_seal, set_r_seal] = useState(userID?.received_at?.seal);
//   const sealed = (e) => set_r_seal(e.target.value);

//   const [r_remark, set_r_remark] = useState(userID?.received_at?.remark);
//   const [r_released_to, set_r_released_to] = useState(
//     userID?.received_at?.released_to
//   );
//   const [primary_specimen_expiration, set_primary_specimen_expiration] =
//     useState(userID?.received_at?.primary_specimen_expiration);
//   const [split_specimen_expiration, set_split_specimen_expiration] = useState(
//     userID?.received_at?.split_specimen_expiration
//   );
//   // primary_specimen_report
//   const [report_result, set_report_result] = useState(
//     userID?.primary_specimen_report?.report_result
//   );
//   const reportResult = (e) => set_report_result(e.target.value);

//   const [remark_for_positive, set_remark_for_positive] = useState(
//     userID?.primary_specimen_report?.remark_for_positive
//   );
//   const [p_remark, set_p_remark] = useState(
//     userID?.primary_specimen_report?.remark
//   );
//   const [p_facility, set_p_facility] = useState(
//     userID?.primary_specimen_report?.facility
//   );
//   const [scientist_name, set_scientist_name] = useState(
//     userID?.primary_specimen_report?.scientist_name
//   );
//   const [p_date, set_p_date] = useState(userID?.primary_specimen_report?.date);
//   // split_testing_labrotory
//   const [reconfirm, set_reconfirm] = useState(
//     userID?.split_testing_labrotory?.reconfirm
//   );
//   const reconfirmm = (e) => set_reconfirm(e.target.value);

//   const [delivery_service_name, set_delivery_service_name] = useState(
//     userID?.split_testing_labrotory?.delivery_service_name
//   );
//   const [s_scientist_name, set_s_scientist_name] = useState(
//     userID?.split_testing_labrotory?.scientist_name
//   );
//   const [s_date, set_s_date] = useState(userID?.split_testing_labrotory?.date);
//   // medical_review_officer_primary_specimen
//   const [m_report_result, set_m_report_result] = useState(
//     userID?.medical_review_officer_primary_specimen?.report_result
//   );
//   const reportResultt = (e) => set_m_report_result(e.target.value);

//   const [m_remark_for_positive, set_m_remark_for_positive] = useState(
//     userID?.medical_review_officer_primary_specimen?.remark_for_positive
//   );
//   const [m_remark, set_m_remark] = useState(
//     userID?.medical_review_officer_primary_specimen?.remark
//   );
//   const [m_signature, set_m_signature] = useState("");
//   const [m_officer_name, set_m_officer_name] = useState(
//     userID?.medical_review_officer_primary_specimen?.officer_name
//   );
//   const [m_date, setm_date] = useState(
//     userID?.medical_review_officer_primary_specimen?.date
//   );
//   // medical_review_officer_secondary_specimen
//   const [mr_reconfirm, set_mr_reconfirm] = useState(
//     userID?.medical_review_officer_secondary_specimen?.reconfirm
//   );
//   const reconfirmedd = (e) => set_mr_reconfirm(e.target.value);

//   const [mr_delivery_service_name, set_mr_delivery_service_name] = useState(
//     userID?.medical_review_officer_secondary_specimen?.delivery_service_name
//   );
//   const [mr_officer_name, set_mr_officer_name] = useState(
//     userID?.medical_review_officer_secondary_specimen?.officer_name
//   );
//   const [mr_date, set_mr_date] = useState(
//     userID?.medical_review_officer_secondary_specimen?.date
//   );

//   const [spin, setSpin] = useState(false);

//   const validation = () => {
//     if (specimen_id === "" || specimen_id === undefined) {
//       Utility.eToast("Specimen Id Required!");
//       return false;
//     } else if (accession_no === "" || accession_no === undefined) {
//       Utility.eToast("Accession No required!");
//       return false;
//     } else if (employer_name === "" || employer_name === undefined) {
//       Utility.eToast("Employer name required!");
//       return false;
//     } else if (employer_code === "" || employer_code === undefined) {
//       Utility.eToast("Employer code required!");
//       return false;
//     } else if (employer_address === "" || employer_address === undefined) {
//       Utility.eToast("Employer Address required!");
//       return false;
//     } else if (employer_id === "" || employer_id === undefined) {
//       Utility.eToast("Employer Id required!");
//       return false;
//     } else if (mro_name === "" || mro_name === undefined) {
//       Utility.eToast(" Mro namerequired!");
//       return false;
//     } else if (mro_phone === "" || mro_phone === undefined) {
//       Utility.eToast("Mro Phone required!");
//       return false;
//     } else if (mro_addr === "" || mro_addr === undefined) {
//       Utility.eToast("Mro Addr required!");
//       return false;
//     } else if (donor_first_name === "" || donor_first_name === undefined) {
//       Utility.eToast("Donor first name required!");
//       return false;
//     } else if (donor_dob === "" || donor_dob === undefined) {
//       Utility.eToast("Donor dob required!");
//       return false;
//     } else if (donor_day_no === "" || donor_day_no === undefined) {
//       Utility.eToast(" Donor day no required!");
//       return false;
//     } else if (donor_eve_no === "" || donor_eve_no === undefined) {
//       Utility.eToast("Donor evening no required!");
//       return false;
//     } else if (donor_last_name === "" || donor_last_name === undefined) {
//       Utility.eToast("Donor last name required!");
//       return false;
//     } else if (donor_id === "" || donor_id === undefined) {
//       Utility.eToast(" Donor id required!");
//       return false;
//     } else if (photo_id === "" || photo_id === undefined) {
//       Utility.eToast("Photo id required!");
//       return false;
//     } else if (id_type === "" || id_type === undefined) {
//       Utility.eToast("Id type required!");
//       return false;
//     } else if (test_reason === "" || test_reason === undefined) {
//       Utility.eToast(" Test Reason required!");
//       return false;
//     } else if (custom_drug_test === "" || custom_drug_test === undefined) {
//       Utility.eToast("Custom drug test required!");
//       return false;
//     } else if (
//       additional_drug_test === "" ||
//       additional_drug_test === undefined
//     ) {
//       Utility.eToast("Additional drug test required!");
//       return false;
//     } else if (site_name === "" || site_name === undefined) {
//       Utility.eToast("Site name required!");
//       return false;
//     } else if (site_phone === "" || site_phone === undefined) {
//       Utility.eToast("Site phone required!");
//       return false;
//     } else if (site_addr === "" || site_addr === undefined) {
//       Utility.eToast("Site addr required!");
//       return false;
//     } else if (site_pin === "" || site_pin === undefined) {
//       Utility.eToast("Site pin required!");
//       return false;
//     } else if (test_type === "" || test_type === undefined) {
//       Utility.eToast("Test type required!");
//       return false;
//     } else if (collection_type === "" || collection_type === undefined) {
//       Utility.eToast("Collection type required!");
//       return false;
//     } else if (split_type === "" || split_type === undefined) {
//       Utility.eToast("Split type required!");
//       return false;
//     } else if (expiration_date === "" || expiration_date === undefined) {
//       Utility.eToast("Expiration date required!");
//       return false;
//     } else if (volume_indicator === "" || volume_indicator === undefined) {
//       Utility.eToast("Volume indicator required!");
//       return false;
//     } else if (remark === "" || remark === undefined) {
//       Utility.eToast("remark required!");
//       return false;
//     } else if (collector_name === "" || collector_name === undefined) {
//       Utility.eToast("Collector name required!");
//       return false;
//     } else if (collection_date === "" || collection_date === undefined) {
//       Utility.eToast("Collection date required!");
//       return false;
//     } else if (collection_time === "" || collection_time === undefined) {
//       Utility.eToast("Collection time required!");
//       return false;
//     } else if (released_to === "" || released_to === undefined) {
//       Utility.eToast(" Released to required!");
//       return false;
//     } else if (r_collector_name === "" || r_collector_name === undefined) {
//       Utility.eToast("r_collector_name required!");
//       return false;
//     } else if (r_date === "" || r_date === undefined) {
//       Utility.eToast("Collection date required!");
//       return false;
//     }
//     // else if (r_time === "" || r_time === undefined) {
//     //   Utility.eToast("collection time required!");
//     //   return false;
//     // }
//     else if (r_seal === "" || r_seal === undefined) {
//       Utility.eToast("seal required!");
//       return false;
//     } else if (r_remark === "" || r_remark === undefined) {
//       Utility.eToast("Seal remark required!");
//       return false;
//     } else if (r_released_to === "" || r_released_to === undefined) {
//       Utility.eToast("Released to required!");
//       return false;
//     } else if (
//       primary_specimen_expiration === "" ||
//       primary_specimen_expiration === undefined
//     ) {
//       Utility.eToast("Primary specimen expiration required!");
//       return false;
//     } else if (
//       split_specimen_expiration === "" ||
//       split_specimen_expiration === undefined
//     ) {
//       Utility.eToast("Split specimen expiration required!");
//       return false;
//     } else if (report_result === "" || report_result === undefined) {
//       Utility.eToast("Report result required!");
//       return false;
//     } else if (
//       remark_for_positive === "" ||
//       remark_for_positive === undefined
//     ) {
//       Utility.eToast("Remark for positive required!");
//       return false;
//     } else if (p_remark === "" || p_remark === undefined) {
//       Utility.eToast("positive remark required!");
//       return false;
//     } else if (p_facility === "" || p_facility === undefined) {
//       Utility.eToast("Facility required!");
//       return false;
//     } else if (scientist_name === "" || scientist_name === undefined) {
//       Utility.eToast("Scientist name required!");
//       return false;
//     } else if (p_date === "" || p_date === undefined) {
//       Utility.eToast("date required!");
//       return false;
//     } else if (reconfirm === "" || reconfirm === undefined) {
//       Utility.eToast("reconfirm required!");
//       return false;
//     } else if (
//       delivery_service_name === "" ||
//       delivery_service_name === undefined
//     ) {
//       Utility.eToast("Delivery service name required!");
//       return false;
//     } else if (s_scientist_name === "" || s_scientist_name === undefined) {
//       Utility.eToast("scientist name required!");
//       return false;
//     } else if (s_date === "" || s_date === undefined) {
//       Utility.eToast("date required!");
//       return false;
//     } else if (m_report_result === "" || m_report_result === undefined) {
//       Utility.eToast("Report result required!");
//       return false;
//     } else if (
//       m_remark_for_positive === "" ||
//       m_remark_for_positive === undefined
//     ) {
//       Utility.eToast("Remark for positive required!");
//       return false;
//     } else if (m_remark === "" || m_remark === undefined) {
//       Utility.eToast("Remark required!");
//       return false;
//     } else if (m_officer_name === "" || m_officer_name === undefined) {
//       Utility.eToast("Officer name required!");
//       return false;
//     } else if (m_date === "" || m_date === undefined) {
//       Utility.eToast("Date required!");
//       return false;
//     } else if (mr_reconfirm === "" || mr_reconfirm === undefined) {
//       Utility.eToast("Reconfirm required!");
//       return false;
//     } else if (
//       mr_delivery_service_name === "" ||
//       mr_delivery_service_name === undefined
//     ) {
//       Utility.eToast("Delivery service name required!");
//       return false;
//     } else if (mr_officer_name === "" || mr_officer_name === undefined) {
//       Utility.eToast("Officer name required!");
//       return false;
//     } else if (mr_date === "" || mr_date === undefined) {
//       Utility.eToast("Date required!");
//       return false;
//     } else {
//       return true;
//     }
//   };

//   const token = useSelector((state) => state?.user?.token);

//   const forensicDrugFromSave = async () => {
//     if (validation()) {
//       setSpin(true);
//       const postData = {
//         _id: userID?._id,
//         specimen_id: specimen_id,
//         accession_no: accession_no,
//         employer_name: employer_name,
//         employer_code: employer_code,
//         employer_address: employer_address,
//         employer_id: employer_id,
//         mro: {
//           name: mro_name,
//           phone_number: mro_phone,
//           fax_number: mro_fax,
//           address: mro_addr,
//         },
//         donor_first_name: donor_first_name,
//         donor_dob: donor_dob,
//         donor_sign: donor_sign,
//         donor_day_no: donor_day_no,
//         donor_eve_no: donor_eve_no,
//         donor_last_name: donor_last_name,
//         donor_id: donor_id,
//         donor_verification: {
//           photo_id: photo_id,
//           id_type: id_type,
//         },
//         test_reason: test_reason,
//         reason_remark: reason_remark,
//         custom_drug_test: custom_drug_test,
//         additional_drug_test: additional_drug_test,
//         site_details: {
//           name: site_name,
//           phone_number: site_phone,
//           fax_number: site_fax,
//           address: site_addr,
//           postal_code: site_pin,
//         },
//         test_type: test_type,
//         collections: {
//           collection_type: collection_type,
//           split_type: split_type,
//           expiration_date: expiration_date,
//           // urine_temperature: urine_temperature,
//           volume_indicator: volume_indicator,
//           remark: remark,
//         },
//         custody: {
//           collector_sign: collector_sign,
//           collector_name: collector_name,
//           date: collection_date,
//           time: collection_time,
//           released_to: released_to,
//         },
//         received_at: {
//           collector_sign: r_collector_sign,
//           collector_name: r_collector_name,
//           date: r_date,
//           time: r_time,
//           seal: r_seal,
//           remark: r_remark,
//           released_to: r_released_to,
//           primary_specimen_expiration: primary_specimen_expiration,
//           split_specimen_expiration: split_specimen_expiration,
//         },
//         primary_specimen_report: {
//           report_result: report_result,
//           remark_for_positive: remark_for_positive,
//           remark: p_remark,
//           facility: p_facility,
//           scientist_name: scientist_name,
//           date: p_date,
//         },
//         split_testing_labrotory: {
//           reconfirm: reconfirm,
//           delivery_service_name: delivery_service_name,
//           scientist_name: s_scientist_name,
//           date: s_date,
//         },
//         medical_review_officer_primary_specimen: {
//           report_result: m_report_result,
//           remark_for_positive: m_remark_for_positive,
//           remark: m_remark,
//           signature: m_signature,
//           officer_name: m_officer_name,
//           date: m_date,
//         },
//         medical_review_officer_secondary_specimen: {
//           reconfirm: mr_reconfirm,
//           delivery_service_name: mr_delivery_service_name,
//           officer_name: mr_officer_name,
//           date: mr_date,
//         },
//       };
//       console.log(">>>>>>>>>", postData);
//       await HttpClient.apiCaller("admin/test", "PUT", postData, token, null)
//         .then((res) => {
//           console.log("<><><>", res);
//           Utility.sToast("Report Updated successfully");
//           setUserID({});
//           onClose();
//         })
//         .catch((err) => console.log("?????", err))
//         .finally(() => setSpin(false));
//     }
//   };

//   return (
//     <>
//       <div className="">
//         <div className=" ">
//           <div className="p-5 ml-3 bg-white shadow h-fit">
//             <div className="grid grid-cols-12 gap-3">
//               <div className="col-span-12" onClick={onClose}>
//                 <PreviousButton />
//               </div>
//               <div className="sm:col-span-3 col-span-12">
//                 <img src={mask} alt="" className="h-12" />
//               </div>
//               <div className="sm:col-span-6 col-span-12">
//                 <h3 className="font-Poppins  font-semibold">
//                   NON-FEDERAL DRUG TESTING CUSTODY AND CONTROL FORM
//                 </h3>
//                 <div className="sm:col-span-6 col-span-12 mt-4 grid grid-cols-12">
//                   <div className="sm:col-span-6 col-span-12">
//                     <p className="text-[13px] sm:col-span-6 sm:mb-0 mb-3  col-span-12 text-[#9CA5AE]">
//                       REQUISITION SPECIMEN ID NO.
//                     </p>
//                   </div>
//                   <div className="App sm:col-span-6 col-span-12 grid grid-cols-12 gap-2  sm:ml-[-6vw]">
//                     <div className="single-line-input-container mt-[-0.5vh] sm:col-span-10 col-span-10 ">
//                       <input
//                         type="text"
//                         className="border-2 focus:outline-none rounded-lg w-full "
//                         value={specimen_id}
//                         onChange={(e) => {
//                           set_specimen_id(e.target.value);
//                         }}
//                       />
//                     </div>
//                     <LuPencil className="col-span-2 " />
//                   </div>
//                 </div>
//               </div>
//               <div className="sm:col-span-3 col-span-12 grid grid-cols-12">
//                 <p className="sm:text-[#9CA5AE] sm:text-[15px] font-Poppins sm:font-semibold col-span-12 text-[13px] text-[#9CA5AE]">
//                   ACCESSION NO.
//                 </p>
//                 <div className="App col-span-12 grid grid-cols-12 gap-2 mt-4">
//                   <div className="single-line-input-container mt-[-0.5vh] col-span-10">
//                     <input
//                       type="text"
//                       className="border-2 focus:outline-none rounded-lg w-full "
//                       value={accession_no}
//                       onChange={(e) => {
//                         set_accession_no(e.target.value);
//                       }}
//                     />
//                   </div>
//                   <LuPencil className="col-span-2" />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* step-1 containt */}
//         <div className=" mt-1">
//           <div className="p-6 ml-3 bg-white shadow h-fit">
//             {/* A and B section */}
//             <p className="font-poppins font-medium text-[18px] mb-3">
//               Step 1: Completed by collector or employer representative
//             </p>
//             <div className="border border-blue-200 p-4 rounded-lg">
//               <div className="grid sm:grid-cols-12 sm:gap-12 gap-4">
//                 <div className="sm:col-span-6 col-span-6">
//                   <h1 className="text-[#919AA3] font-semibold font-Poppins">
//                     A.Employer
//                   </h1>
//                   <div>
//                     <p className="font-medium">Employer name</p>
//                     <input
//                       type="text"
//                       placeholder="Enter Employee name"
//                       className="w-full h-11 mb-2 mt-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
//                       value={employer_name}
//                       onChange={(e) => {
//                         set_employer_name(e.target.value);
//                       }}
//                     />
//                     <p className="font-medium">Employer code</p>
//                     <input
//                       type="text"
//                       placeholder="Enter Employee code"
//                       className="w-full h-11 mb-2 mt-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
//                       value={employer_code}
//                       onChange={(e) => set_employer_code(e.target.value)}
//                     />
//                     <p className="font-medium">Address</p>
//                     <input
//                       type="text"
//                       placeholder="Enter adress"
//                       className="w-full h-11 mb-2 mt-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
//                       value={employer_address}
//                       onChange={(e) => set_employer_address(e.target.value)}
//                     />
//                     <p className="font-medium">ID No</p>
//                     <input
//                       type="text"
//                       placeholder="Enter ID No"
//                       className="w-full h-11 mb-2 mt-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
//                       value={employer_id}
//                       onChange={(e) => set_employer_id(e.target.value)}
//                     />
//                   </div>
//                 </div>
//                 <div className="sm:col-span-6 col-span-6">
//                   <h1 className="text-[#919AA3] font-semibold font-Poppins">
//                     B.MRO
//                   </h1>
//                   <div>
//                     <p className="font-medium mb-3">Select a representative</p>
//                     <select
//                       id="countries"
//                       className="custom-input"
//                       // className="bg-[#ECF1F6] h-10 border-none focus:outline-none text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
//                       value={mro_name}
//                       onChange={(e) => set_mro_name(e.target.value)}
//                     >
//                       <option
//                         selected
//                         // className="text-[#919AA3] font-semibold font-Poppins w-[5rem]"
//                         style={{}}
//                       >
//                         representative
//                       </option>
//                       <option value="US">United States</option>
//                       <option value="CA">Canada</option>
//                       <option value="FR">France</option>
//                       <option value="DE">Germany</option>
//                     </select>
//                     <p className="font-medium mt-2">Adress</p>
//                     <input
//                       type="text"
//                       placeholder="Enter adress"
//                       className="w-full h-11 mb-2 mt-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
//                       value={mro_addr}
//                       onChange={(e) => set_mro_addr(e.target.value)}
//                     />
//                     <p className="font-medium">Phone number</p>
//                     <input
//                       type="text"
//                       placeholder="Enter Phone number"
//                       className="w-full h-11 mb-2 mt-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
//                       value={mro_phone}
//                       onChange={(e) => set_mro_phone(e.target.value)}
//                     />
//                     <p className="font-medium">Fax No.</p>
//                     <input
//                       type="text"
//                       placeholder="Enter Fax No."
//                       className="w-full h-11 mb-2 mt-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
//                       value={mro_fax}
//                       onChange={(e) => set_mro_fax(e.target.value)}
//                     />
//                   </div>
//                 </div>
//               </div>

//               <div className="dotted-line mt-5"></div>

//               {/* C section */}
//               <div className="mt-3">
//                 <h1 className="text-[#919AA3] font-semibold font-Poppins">
//                   C.Donor
//                 </h1>
//                 <div className="grid grid-cols-12 sm:gap-5 gap-3 mt-3">
//                   <div className="sm:col-span-6 col-span-12">
//                     <p className="font-medium">First Name</p>
//                     <input
//                       type="text"
//                       placeholder="Enter First Name"
//                       className="w-full mt-3 h-11 mb-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
//                       value={donor_first_name}
//                       onChange={(e) => set_donor_first_name(e.target.value)}
//                     />
//                   </div>
//                   <div className="sm:col-span-6 col-span-12">
//                     <p className="font-medium">Last Name</p>
//                     <input
//                       type="text"
//                       placeholder="Enter Last Name"
//                       className="w-full mt-3 h-11 mb-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
//                       value={donor_last_name}
//                       onChange={(e) => set_donor_last_name(e.target.value)}
//                     />
//                   </div>
//                   <div className="sm:col-span-6 col-span-12">
//                     <p className="font-medium">Donor I.D No.</p>
//                     <input
//                       type="text"
//                       placeholder="Enter ID or Number"
//                       className="w-full mt-3 h-11 mb-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
//                       value={donor_id}
//                       onChange={(e) => set_donor_id(e.target.value)}
//                     />
//                   </div>
//                 </div>
//               </div>

//               <div className="dotted-line mt-5"></div>

//               {/* D section */}

//               <div className="bg-[#F4F6F9] p-3 mt-3 ">
//                 <h1 className="text-[#919AA3] font-semibold font-Poppins">
//                   Donor ID verification
//                 </h1>
//                 <div className="mt-2 grid grid-cols-12 gap-4 mb-3 items-center">
//                   <div className="sm:col-span-2 col-span-12">
//                     <input
//                       type="radio"
//                       id="employee"
//                       name="id_type"
//                       value="employee"
//                       className="mr-2 p-1"
//                       checked={id_type === "employee"}
//                       onChange={handleId}
//                     />
//                     <label
//                       htmlFor="UPS"
//                       className="font-normal font-Poppins text-[#919AA2]"
//                     >
//                       employee ID
//                     </label>
//                     <br />
//                   </div>
//                   <div className="sm:col-span-2 col-span-12">
//                     <input
//                       type="radio"
//                       id="representative"
//                       name="id_type"
//                       value="representative"
//                       className="mr-2 p-1"
//                       checked={id_type === "representative"}
//                       onChange={handleId}
//                     />
//                     <label
//                       htmlFor="USPS"
//                       className="font-normal font-Poppins text-[#919AA2]"
//                     >
//                       Representative ID
//                     </label>
//                     <br />
//                   </div>
//                   <div className="sm:col-span-6 col-span-12">
//                     <input
//                       type="text"
//                       placeholder="Enter id"
//                       className="w-full mt-3 h-11 border-none focus:outline-none mb-2 rounded-lg bg-[#E8EEF3] p-2"
//                       value={photo_id}
//                       onChange={(e) => set_photo_id(e.target.value)}
//                     />
//                     <span style={{ fontSize: "0.8rem", color: "blue" }}>
//                       {/* Enter value only in true / false* */}
//                     </span>
//                   </div>
//                 </div>
//               </div>

//               {/*E section */}

//               <div className="bg-[#ECF1F6] p-3 mt-5 ">
//                 <h1 className="text-[#919AA3] font-semibold font-Poppins">
//                   E.Reason for Test :
//                 </h1>
//                 <div className="grid grid-cols-12 gap-4 mt-4">
//                   <div className="sm:col-span-3 col-span-12 gap-3">
//                     <input
//                       type="radio"
//                       id="pre_employment"
//                       name="test_reason"
//                       value="pre_employment"
//                       checked={test_reason === "pre_employment"}
//                       onChange={testReason}
//                     />
//                     <label htmlFor="pre_employment" className="ml-2">
//                       Pre-employement
//                     </label>
//                     <br />
//                   </div>
//                   <div className="sm:col-span-3 col-span-12 gap-3">
//                     <input
//                       type="radio"
//                       id="random"
//                       name="test_reason"
//                       value="random"
//                       checked={test_reason === "random"}
//                       onChange={testReason}
//                     />
//                     <label htmlFor="random" className="ml-2">
//                       Random
//                     </label>
//                     <br />
//                   </div>
//                   <div className="sm:col-span-3 col-span-12 gap-3">
//                     <input
//                       type="radio"
//                       id="resonable_suspicion"
//                       name="test_reason"
//                       value="resonable_suspicion"
//                       checked={test_reason === "resonable_suspicion"}
//                       onChange={testReason}
//                     />
//                     <label htmlFor="resonable_suspicion" className="ml-2">
//                       Resonable Suspicion
//                     </label>
//                     <br />
//                   </div>
//                   <div className="sm:col-span-3 col-span-12 gap-3">
//                     <input
//                       type="radio"
//                       id="post_accident"
//                       name="test_reason"
//                       value="post_accident"
//                       checked={test_reason === "post_accident"}
//                       onChange={testReason}
//                     />
//                     <label htmlFor="post_accident" className="ml-2">
//                       Post-Accident
//                     </label>
//                     <br />
//                   </div>
//                   <div className="sm:col-span-3 col-span-12 gap-3 items-center">
//                     <input
//                       type="radio"
//                       id="promotion"
//                       name="test_reason"
//                       value="promotion"
//                       checked={test_reason === "promotion"}
//                       onChange={testReason}
//                     />
//                     <label htmlFor="promotion" className="ml-2">
//                       Promotion
//                     </label>
//                     <br />
//                   </div>
//                   <div className="sm:col-span-3 col-span-12 gap-3 items-center">
//                     <input
//                       type="radio"
//                       id="return_duty"
//                       name="test_reason"
//                       value="return_duty"
//                       checked={test_reason === "return_duty"}
//                       onChange={testReason}
//                     />
//                     <label htmlFor="return_duty" className="ml-2">
//                       Return to Duty
//                     </label>
//                     <br />
//                   </div>
//                   <div className="sm:col-span-3 col-span-12 gap-3 items-center">
//                     <input
//                       type="radio"
//                       id="follow_up"
//                       name="test_reason"
//                       value="follow_up"
//                       checked={test_reason === "follow_up"}
//                       onChange={testReason}
//                     />
//                     <label htmlFor="follow_up" className="ml-2">
//                       Follow-up
//                     </label>
//                     <br />
//                   </div>
//                   <div className="sm:col-span-3 col-span-12">
//                     <input
//                       type="text"
//                       placeholder="Other Specificity(99)"
//                       className="w-full  h-10 mb-2 rounded-lg border-none focus:outline-none bg-[#D7E0EE] p-2"
//                       value={reason_remark}
//                       onChange={(e) => set_reason_remark(e.target.value)}
//                     />
//                   </div>
//                 </div>
//               </div>

//               {/* f section  */}

//               <div className="bg-[#F4F6F9] p-3 mt-5 ">
//                 <h1 className="text-[#919AA3] font-semibold font-Poppins">
//                   F.Drug Test to be Performed
//                 </h1>
//                 <div className=" w-full flex items-center p-3 rounded-md mt-5 h-10 mb-2 bg-[#1075EC] text-white ">
//                   <h1>DRUG TEST REQUEST</h1>
//                 </div>
//                 <div className="grid grid-cols-12 gap-4 mt-4">
//                   <div className="sm:col-span-6 col-span-12">
//                     <p className="font-medium">Select a pannel name</p>
//                     <select
//                       id="countries"
//                       className="bg-[#E8EEF3] mt-4 border-none focus:outline-none text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
//                       value={custom_drug_test}
//                       onChange={(e) => set_custom_drug_test(e.target.value)}
//                     >
//                       <option selected className="text-[#F3F7FA] bg-[#9facb6] ">
//                         Please select a custom drug test
//                       </option>
//                       <option value="US">United States</option>
//                       <option value="CA">Canada</option>
//                       <option value="FR">France</option>
//                       <option value="DE">Germany</option>
//                     </select>
//                   </div>
//                   <div className="sm:col-span-6 col-span-12">
//                     <p className="font-medium">Additional drug test</p>
//                     <select
//                       id="countries"
//                       className="bg-[#E8EEF3] mt-4 border-none focus:outline-none text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
//                       value={additional_drug_test}
//                       onChange={(e) => set_additional_drug_test(e.target.value)}
//                     >
//                       <option selected className="text-[#F3F7FA] bg-[#F3F7FA]">
//                         Please select a custom panel name
//                       </option>
//                       <option value="US">United States</option>
//                       <option value="CA">Canada</option>
//                       <option value="FR">France</option>
//                       <option value="DE">Germany</option>
//                     </select>
//                   </div>
//                 </div>
//                 {/* <textarea
//                   name=""
//                   id=""
//                   rows="3"
//                   className="mt-6 w-full bg-[#E8EEF3] p-3 border-none focus:outline-none"
//                 ></textarea> */}
//               </div>

//               {/* G section  */}

//               <h1 className="text-[#919AA3] mt-4 font-semibold font-Poppins">
//                 G. Collection site
//               </h1>

//               <div className="grid grid-cols-12 mt-4 sm:gap-5 gap-2">
//                 <div className="sm:col-span-6 col-span-12">
//                   <p className="font-medium">Collection site name</p>
//                   <input
//                     type="text"
//                     placeholder="Enter Collection site name"
//                     className="w-full h-11 mb-2 mt-2 rounded-lg bg-[#F3F7FA] p-2 border-none focus:outline-none"
//                     value={site_name}
//                     onChange={(e) => set_site_name(e.target.value)}
//                   />
//                 </div>
//                 <div className="sm:col-span-6 col-span-12">
//                   <p className="font-medium">Collection site phone number</p>
//                   <input
//                     type="text"
//                     placeholder="Enter Collector phone number"
//                     className="w-full h-11 mb-2 mt-2 rounded-lg bg-[#F3F7FA] p-2 border-none focus:outline-none"
//                     value={site_phone}
//                     onChange={(e) => set_site_phone(e.target.value)}
//                   />
//                 </div>
//                 <div className="sm:col-span-6 col-span-12">
//                   <p className="font-medium">Address</p>
//                   <input
//                     type="text"
//                     placeholder="Enter adress"
//                     className="w-full h-11 mb-2 mt-2 rounded-lg bg-[#F3F7FA] p-2 border-none focus:outline-none"
//                     value={site_addr}
//                     onChange={(e) => set_site_addr(e.target.value)}
//                   />
//                 </div>
//                 <div className="sm:col-span-6 col-span-12">
//                   <p className="font-medium">Collection site fax No.</p>
//                   <input
//                     type="text"
//                     placeholder="Enter Collector Fax No."
//                     className="w-full h-11 mb-2 mt-2 rounded-lg bg-[#F3F7FA] p-2 border-none focus:outline-none"
//                     value={site_fax}
//                     onChange={(e) => set_site_fax(e.target.value)}
//                   />
//                 </div>
//                 <div className="sm:col-span-6 col-span-12">
//                   <p className="font-medium">
//                     City, Provinance and postal code
//                   </p>
//                   <input
//                     type="text"
//                     placeholder="Enter City,Provinance and postal code"
//                     className="w-full h-11 mb-2 mt-2 rounded-lg bg-[#F3F7FA] p-2 border-none focus:outline-none"
//                     value={site_pin}
//                     onChange={(e) => set_site_pin(e.target.value)}
//                   />
//                 </div>
//               </div>
//             </div>
//             {/* step 2 */}
//             <p className="font-poppins mt-4 font-medium text-[18px]">
//               Step 2 : Completed by Collector
//             </p>
//             <div className="grid grid-cols-12  mt-4">
//               <div className="sm:col-span-1 col-span-6">
//                 <input
//                   type="radio"
//                   id="blood"
//                   value="blood"
//                   name="testType"
//                   checked={test_type === "blood"}
//                   onChange={testType}
//                   className="mr-2"
//                 />
//                 <label htmlFor="blood">Blood</label>
//               </div>

//               <div className="sm:col-span-1 col-span-6">
//                 <input
//                   type="radio"
//                   id="urin"
//                   value="urin"
//                   name="testType"
//                   checked={test_type === "urin"}
//                   onChange={testType}
//                   className="mr-2"
//                 />
//                 <label htmlFor="urin">Urin</label>
//               </div>

//               <div className="sm:col-span-1 col-span-6">
//                 <input
//                   type="radio"
//                   id="oral_fluid"
//                   value="oral_fluid"
//                   name="testType"
//                   checked={test_type === "oral_fluid"}
//                   onChange={testType}
//                   className="mr-2"
//                 />
//                 <label htmlFor="oral_fluid">Oral fluid</label>
//               </div>
//             </div>
//             <div className="border border-blue-200 p-4 rounded-lg mt-5">
//               <div className=" w-full flex items-center p-3 rounded-md mt-4 h-10 mb-3 bg-[#1075EC] text-white ">
//                 <h1>Collections:</h1>
//               </div>
//               <div className="grid grid-cols-12 mt-4 ">
//                 <div className="sm:col-span-2 col-span-6">
//                   <input
//                     type="radio"
//                     id="single"
//                     value="single"
//                     checked={collection_type === "single"}
//                     onChange={collectionType}
//                     name="collections"
//                   />
//                   <label htmlFor="single" className="ml-2">
//                     Single
//                   </label>
//                 </div>

//                 <div className="sm:col-span-2 col-span-6">
//                   <input
//                     type="radio"
//                     id="split"
//                     value="split"
//                     checked={collection_type === "split"}
//                     onChange={collectionType}
//                     name="collections"
//                   />
//                   <label htmlFor="split" className="ml-2">
//                     Split
//                   </label>
//                 </div>

//                 <div className="sm:col-span-3 col-span-12">
//                   <input
//                     type="radio"
//                     id="non-provided"
//                     value="non-provided"
//                     checked={collection_type === "non-provided"}
//                     onChange={collectionType}
//                     name="collections"
//                   />
//                   <label htmlFor="non-provided" className="ml-2">
//                     Non-provided
//                   </label>
//                 </div>

//                 <div className="sm:col-span-3 col-span-12">
//                   <input
//                     type="radio"
//                     id="observed"
//                     value="observed"
//                     checked={collection_type === "observed"}
//                     onChange={collectionType}
//                     name="collections"
//                   />
//                   <label htmlFor="observed" className="ml-2">
//                     Observed
//                   </label>
//                 </div>
//               </div>
//               <div className=" w-full flex items-center p-3 rounded-md mt-5 h-10 mb-3 bg-[#1075EC] text-white ">
//                 <h1>Oral fluid:Split type:</h1>
//               </div>
//               <div className="grid grid-cols-12 mt-5 gap-3">
//                 <div className="sm:col-span-2 col-span-6">
//                   <input
//                     type="radio"
//                     id="Serial"
//                     value="Serial"
//                     checked={split_type === "Serial"}
//                     onChange={splitType}
//                     name="oralfluid"
//                   />
//                   <label htmlFor="Serial" className="ml-2">
//                     Serial
//                   </label>
//                 </div>

//                 <div className="sm:col-span-2 col-span-6">
//                   <input
//                     type="radio"
//                     id="concurrent"
//                     value="concurrent"
//                     checked={split_type === "concurrent"}
//                     onChange={splitType}
//                     name="oralfluid"
//                   />
//                   <label htmlFor="concurrent" className="ml-2">
//                     Concurrent
//                   </label>
//                 </div>

//                 <div className="sm:col-span-2 col-span-6">
//                   <input
//                     type="radio"
//                     id="subdivided"
//                     value="subdivided"
//                     checked={split_type === "subdivided"}
//                     onChange={splitType}
//                     name="oralfluid"
//                   />
//                   <label htmlFor="subdivided" className="ml-2">
//                     Subdivided
//                   </label>
//                 </div>
//               </div>
//               <div className="grid grid-cols-12 mt-3 gap-3">
//                 <div className="sm:col-span-6 col-span-12">
//                   <div className=" col-span-12  w-full flex items-center sm:p-5 p-[7vw] rounded-md mt-3 h-10 mb-3 bg-[#1075EC] text-white ">
//                     <h1>Each device within expiration date ?</h1>
//                   </div>
//                   <div className="grid grid-cols-12 ">
//                     <div className="sm:col-span-2 col-span-6">
//                       <input
//                         type="radio"
//                         id="true"
//                         value="true"
//                         checked={expiration_date === "true"}
//                         onChange={expired}
//                         name="expDt"
//                       />
//                       <label htmlFor="true" className="ml-2">
//                         Yes
//                       </label>
//                     </div>

//                     <div className="sm:col-span-4 col-span-6">
//                       <input
//                         type="radio"
//                         id="false"
//                         value="false"
//                         checked={expiration_date === "false"}
//                         onChange={expired}
//                         name="expDt"
//                       />
//                       <label htmlFor="false" className="ml-2">
//                         No
//                       </label>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="sm:col-span-6 col-span-12">
//                   <div>
//                     <div className=" w-full flex items-center sm:p-3 p-[7vw] rounded-md mt-3 h-10 mb-3 bg-[#1075EC] text-white ">
//                       <h1>Volume indicator(s) observed</h1>
//                     </div>
//                     <div>
//                       <input
//                         type="radio"
//                         id="true"
//                         value="true"
//                         checked={volume_indicator === "true"}
//                         onChange={volumeIndicator}
//                         name="volumeindicator"
//                       />
//                       <label htmlFor="true">Yes</label>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <textarea
//                 name=" "
//                 id=""
//                 rows="3"
//                 placeholder="Remark"
//                 className="w-full bg-[#E8EEF3] mt-5 rounded-md p-3 border-none focus:outline-none"
//                 value={remark}
//                 onChange={(e) => set_remark(e.target.value)}
//               ></textarea>
//             </div>

//             <div className="grid grid-cols-1 mt-5">
//               <p className="font-Poppins font-medium mb-4">
//                 Step 3 : Collector affixes bottle seal(s) to bottle(s).
//                 Collector dates seal(s). Donor initials seal(s). Donor Completes
//                 Step 5.
//               </p>
//               <p className="font-Poppins font-medium text-[18px] ">
//                 Step 4 : Chain of Custody - Initiated by Collector and Completed
//                 by Laboratory
//               </p>
//             </div>

//             {/* step-4 */}
//             <div className="border border-blue-200 p-4 rounded-lg mt-5">
//               <p className="grid grid-cols-1 w-full mt-3">
//                 i certify that the specimen given to me by the donor identified
//                 in the certification section on copy 1 of this form was
//                 collected,labeled,sealed and released to the Delivery Service
//                 noted in accordance with application requirement
//               </p>
//               <div className="mt-10 grid grid-cols-12 gap-5 ">
//                 <div className="sm:col-span-5 col-span-12">
//                   <div className="grid grid-cols-1">
//                     <div>
//                       <b>Signature of the collector</b>
//                       <div className="relative">
//                         <SignatureCanvas
//                           ref={signatureRef}
//                           penColor="black"
//                           canvasProps={{
//                             className: "signature-canvas",
//                             width: 300,
//                             height: 100,
//                           }}
//                           className="border border-solid border-black mb-4"
//                         />
//                         {/* Draw the line below the signature */}
//                         <div className="absolute bottom-0 left-0 w-[17vw] border-b border-black"></div>
//                       </div>
//                     </div>

//                     <p className="mt-20 font-medium">
//                       Collector's Name (First,Middle,Last)
//                     </p>
//                     <input
//                       type="text"
//                       placeholder="Enter Full name"
//                       className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
//                       value={collector_name}
//                       onChange={(e) => set_collector_name(e.target.value)}
//                     />
//                   </div>
//                   <div className="grid grid-cols-1">
//                     <div>
//                       <p className="font-medium">Select Date</p>
//                       <input
//                         type="date"
//                         // value={date}
//                         // onChange={(e) => setDate(e.target.value)}
//                         className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
//                         value={collection_date}
//                         onChange={(e) => set_collection_date(e.target.value)}
//                       />
//                     </div>
//                   </div>
//                   <div className="grid grid-cols-12 gap-4">
//                     <div className="sm:col-span-6 col-span-12">
//                       <p className="font-medium">Time of collection</p>
//                       <input
//                         type="time"
//                         // value={time}
//                         // onChange={(e) => setTime(e.target.value)}
//                         className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 mr-2 border-none focus:outline-none"
//                         value={collection_time}
//                         onChange={(e) => set_collection_time(e.target.value)}
//                       />
//                     </div>
//                     <div
//                       onClick={handleStep4Date}
//                       className="sm:col-span-6 mt-3 col-span-12"
//                     >
//                       <p className="mt-[-8px] font-medium ">Choose Default</p>
//                       <div className="mt-2.5 col-span-12 w-full">
//                         <button className="p-1.5 pl-7 pr-7 rounded-lg w-full bg-blue-600 font-normal text-white">
//                           Today's date & time
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="sm:col-span-1 sm:block hidden">
//                   <div class="w-1 h-[500px] border-l-5 border-l-2 border-sienna  border-dotted"></div>
//                 </div>
//                 <div className="sm:col-span-5 col-span-12 sm:ml-[-4vw]">
//                   <h1 className="font-Poppins font-medium text-[19px] mb-4">
//                     Specimen Bottle(s) release to:
//                   </h1>
//                   <div className="mt-5 flex mb-3">
//                     <div className="flex gap-3">
//                       <input
//                         type="radio"
//                         id="UPS"
//                         name="specimenbottle"
//                         value="UPS"
//                         checked={released_to === "UPS"}
//                         onChange={releasedTo}
//                       />
//                       <label htmlFor="UPS">UPS</label>
//                       <br />
//                     </div>
//                     <div className="flex gap-3">
//                       <input
//                         type="radio"
//                         id="USPS"
//                         name="specimenbottle"
//                         value="USPS"
//                         checked={released_to === "USPS"}
//                         onChange={releasedTo}
//                       />
//                       <label htmlFor="USPS">USPS</label>
//                       <br />
//                     </div>
//                     <div className="flex gap-3">
//                       <input
//                         type="radio"
//                         id="FEDEX"
//                         name="specimenbottle"
//                         value="FEDEX"
//                         checked={released_to === "FEDEX"}
//                         onChange={releasedTo}
//                       />
//                       <label htmlFor="FEDEX">FEDEX</label>
//                     </div>
//                   </div>
//                   <h1 className="font-medium">
//                     Name of delivery service transferring specimen to lab
//                   </h1>
//                   {/* <input
//                     type="text"
//                     placeholder="Enter Other's"
//                     className="sm:w-[38vw] w-full h-10 mt-5 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
//                   /> */}
//                 </div>
//               </div>

//               <div className="dotted-line mt-7 text-gray-300"></div>

//               <h2 className=" mt-2 sm:absolute relative  font-Poppins font-medium text-[19px]">
//                 Recieved at lab :
//               </h2>
//               <div className="mt-16 grid grid-cols-12 sm:gap-2 gap-5">
//                 <div className="sm:col-span-5 col-span-12">
//                   <div className="grid grid-cols-1">
//                     <div>
//                       <b>Signature of Accessioner</b>
//                       <div className="relative">
//                         <SignatureCanvas
//                           ref={signatureRef}
//                           penColor="black"
//                           canvasProps={{
//                             className: "signature-canvas",
//                             width: 300,
//                             height: 100,
//                           }}
//                           className="border border-solid border-black mb-4"
//                         />
//                         {/* Draw the line below the signature */}
//                         <div className="absolute bottom-0 left-0 w-[17vw] border-b border-black"></div>
//                       </div>
//                     </div>
//                     <p className="mt-10 font-medium">
//                       Accessiner's Name(First-Middle-Last)
//                     </p>
//                     <input
//                       type="text"
//                       placeholder="Enter Full name"
//                       className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
//                       value={r_collector_name}
//                       onChange={(e) => set_r_collector_name(e.target.value)}
//                     />
//                   </div>

//                   <div className="grid grid-cols-12 gap-4">
//                     <div className="sm:col-span-6 col-span-12">
//                       <p className="font-medium">Select Date</p>
//                       <input
//                         type="date"
//                         // value={accessinerdate}
//                         // onChange={(e) => setTime(e.target.value)}
//                         className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 mr-2 border-none focus:outline-none"
//                         value={r_date}
//                         onChange={(e) => set_r_date(e.target.value)}
//                       />
//                     </div>
//                     <div
//                       onClick={handleStepLabDate}
//                       className="sm:col-span-6 mt-3 col-span-12"
//                     >
//                       <p className="mt-[-8px] font-medium ">Choose Default</p>
//                       <div className="mt-2.5 col-span-12 w-full">
//                         <button className="p-1.5 pl-7 pr-7 rounded-lg w-full bg-blue-600 font-normal text-white">
//                           Today's date
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                   {/* <div className="grid grid-cols-12 gap-4">
//                       <div className="col-span-12">
//                         <p className="font-medium">Select Date</p>
//                         <input
//                           type="date"
//                           value={accessinerdate}
//                           onChange={(e) => setDate(e.target.value)}
//                           className="w-full sm:[12vw] h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
//                         />
//                       </div>
//                       <div
//                         onClick={handleAccessinerdate}
//                         className="sm:col-span-6 mt-3 col-span-12"
//                       >
//                         <p className="mt-[-8px] font-medium ">Choose Default</p>
//                         <div className="mt-2.5 col-span-12 w-full">
//                           <button className="p-1.5 pl-7 pr-7 rounded-lg w-full bg-blue-600 font-normal text-white">
//                             Today's date
//                           </button>
//                         </div>
//                       </div>
//                     </div> */}
//                 </div>
//                 <div className="col-span-1"></div>
//                 <div className="sm:col-span-6 col-span-12 sm:mt-[-8vh] sm:ml-[-4vw]">
//                   <h1 className="font-Poppins font-medium text-[19px] mb-4">
//                     Primary specimen Bottle seal intact
//                   </h1>
//                   <div className="mt-5 flex gap-5 mb-3">
//                     <div className="flex gap-3">
//                       <input
//                         type="radio"
//                         id="true"
//                         name="prmspice"
//                         value="true"
//                         checked={r_seal === "true"}
//                         onChange={sealed}
//                       />
//                       <label htmlFor="true">Yes</label>
//                     </div>
//                     <div className="flex gap-3">
//                       <input
//                         type="radio"
//                         id="false"
//                         name="prmspice"
//                         value="false"
//                         checked={r_seal === "false"}
//                         onChange={sealed}
//                       />
//                       <label htmlFor="false">No</label>
//                     </div>
//                   </div>
//                   <textarea
//                     name=""
//                     id=""
//                     rows="3"
//                     placeholder="Remark"
//                     className="w-full p-3 mt-3 rounded-lg bg-[#ECF1F6]  border-none focus:outline-none relative"
//                     value={r_remark}
//                     onChange={(e) => set_r_remark(e.target.value)}
//                   ></textarea>
//                   <h1 className="font-medium mt-5">
//                     Specimen Bottles released to :
//                   </h1>
//                   <input
//                     type="text"
//                     placeholder="Enter Other's"
//                     className="w-full h-10 mt-5 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
//                     value={r_released_to}
//                     onChange={(e) => set_r_released_to(e.target.value)}
//                   />
//                 </div>
//               </div>

//               <div className="dotted-line mt-9 mb-4"></div>
//               <h1 className="w-full mb-3  font-medium">
//                 Primary/Single Specimen Device Expiration Date :
//               </h1>
//               <div className="grid grid-cols-12 gap-6">
//                 <div className="sm:col-span-6 grid grid-cols-12 col-span-12 gap-2">
//                   <div className="sm:col-span-6 col-span-11">
//                     <p className="font-medium">Select Date</p>
//                     <input
//                       type="date"
//                       // value={singlespecimendate}
//                       // onChange={(e) => setTime(e.target.value)}
//                       className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 mr-2 border-none focus:outline-none"
//                       value={primary_specimen_expiration}
//                       onChange={(e) =>
//                         set_primary_specimen_expiration(e.target.value)
//                       }
//                     />
//                   </div>
//                   <div
//                     onClick={handleSplitExpDate}
//                     className="sm:col-span-6 mt-3 col-span-11"
//                   >
//                     <p className="mt-[-8px] font-medium ">Choose Default</p>
//                     <div className="mt-2.5 sm:col-span-6 col-span-12">
//                       <button className="p-1.5 pl-7 pr-7 rounded-lg w-full bg-blue-600 font-normal text-white">
//                         Today's date
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="sm:col-span-6 col-span-12">
//                   <div>
//                     <h1 className="w-full sm:mt-[-6vh] font-medium">
//                       Split Specimen Device Expiration Date :
//                     </h1>
//                   </div>
//                   <div className="grid grid-cols-12 gap-5 mt-3">
//                     <div className="sm:col-span-6 col-span-11">
//                       <p className="font-medium">Select Date</p>
//                       <input
//                         type="date"
//                         // value={splitspecimendate}
//                         // onChange={(e) => setDate(e.target.value)}
//                         className="w-full relative h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
//                         value={split_specimen_expiration}
//                         onChange={(e) =>
//                           set_split_specimen_expiration(e.target.value)
//                         }
//                       />
//                     </div>

//                     <div
//                       onClick={handleSplitExpDate}
//                       className="sm:col-span-6 sm:mt-6 col-span-11  items-center"
//                     >
//                       <p className="sm:mt-[-3.5vh] font-medium  ">
//                         Choose Default
//                       </p>
//                       <div className="mt-2.5 sm:col-span-6 col-span-12">
//                         <button className="p-1.5 pl-7 pr-7 rounded-lg w-full  bg-blue-600 font-normal text-white">
//                           Today's date
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className=" mt-7 mb-7 font-medium text-[20px] font-Poppins">
//               <h1>Step 5: Complete by Donor</h1>
//             </div>

//             {/* step-5 */}
//             <div className="border border-blue-200 p-4 rounded-lg">
//               <h1 className="mb-6 grid grid-cols-1 mt-1">
//                 I certify that I provided my specimen to the collector; that I
//                 have not adulterated it in any manner; each specimen bottle used
//                 was sealed with a tamper-evident seal in my presence; and that
//                 the information and numbers provided on this form and on the
//                 label affixed to each specimen bottle is correct; and allow my
//                 sample and data to leave the country, if required.
//               </h1>
//               <div className="grid grid-cols-1">
//                 <b>Signature of Accessioner</b>
//                 <div className="relative">
//                   <SignatureCanvas
//                     ref={signatureRef}
//                     penColor="black"
//                     canvasProps={{
//                       className: "signature-canvas",
//                       width: 300,
//                       height: 100,
//                     }}
//                     className="border border-solid border-black mb-4"
//                   />
//                   {/* Draw the line below the signature */}
//                   <div className="absolute bottom-0 left-0 w-[17vw] border-b border-black"></div>
//                 </div>
//               </div>
//               <div className="grid grid-cols-12 mt-5 sm:gap-7 gap-3">
//                 <div className="sm:col-span-3 col-span-12">
//                   <p className=" font-medium mb-2">Daytime phone number </p>
//                   <input
//                     type="text"
//                     placeholder="Enter daytime phone number"
//                     className="w-full h-10 mb-2 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
//                     value={donor_day_no}
//                     onChange={(e) => set_donor_day_no(e.target.value)}
//                   />
//                 </div>
//                 <div className="sm:col-span-3 col-span-12">
//                   <p className=" font-medium mb-2"> Evening phone number</p>
//                   <input
//                     type="text"
//                     placeholder="Evening phone number"
//                     className="w-full h-10 mb-2 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
//                     value={donor_eve_no}
//                     onChange={(e) => set_donor_eve_no(e.target.value)}
//                   />
//                 </div>
//                 <div className="sm:col-span-3 col-span-12">
//                   <p className=" font-medium mb-2">Donor's Full name</p>
//                   <input
//                     type="text"
//                     placeholder="Donor's Full name"
//                     className="w-full h-10 mb-2 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
//                     value={donor_first_name}
//                     onChange={(e) => set_donor_first_name}
//                   />
//                 </div>
//                 <div className="sm:col-span-3 col-span-12">
//                   <p className=" font-medium mb-2"> Donor last name </p>
//                   <input
//                     type="text"
//                     placeholder="Donor last name"
//                     className="w-full h-10 mb-2 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
//                     value={donor_last_name}
//                     onChange={(e) => set_donor_last_name(e.target.value)}
//                   />
//                 </div>
//                 <div className="sm:col-span-3 col-span-12">
//                   <p className=" font-medium mb-2">D.O.B</p>
//                   <input
//                     type="date"
//                     // value={donordate}
//                     // onChange={(e) => setDate(e.target.value)}
//                     placeholder="select date"
//                     className="w-full h-10 mb-4  rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
//                     value={donor_dob}
//                     onChange={(e) => set_donor_dob(e.target.value)}
//                   />
//                 </div>
//                 {/* <div
//                   onClick={handleDonordate}
//                   className="sm:col-span-3 col-span-12"
//                 >
//                   <p className=" font-medium mb-2">Choose Default</p>
//                   <button className="p-1.5 pl-5 pr-5 rounded-lg w-full bg-blue-600 font-normal text-white">
//                     Today
//                   </button>
//                 </div> */}
//               </div>
//             </div>

//             {/* step 5 A */}

//             <div className=" mt-7 mb-7 font-medium text-[20px] font-Poppins">
//               {" "}
//               <h1>
//                 Step 5A : PrimarySpecimen Report - Completed by Test Facility
//               </h1>
//             </div>
//             <div className="border border-blue-200 p-4 rounded-lg">
//               <div className="grid grid-cols-12 ">
//                 <div className="sm:col-span-1 col-span-6">
//                   <input
//                     type="radio"
//                     id="positive"
//                     name="step5a"
//                     value="positive"
//                     checked={report_result === "positive"}
//                     onChange={reportResult}
//                   />
//                   <label htmlFor="positive" className="ml-2">
//                     POSITIVE
//                   </label>
//                   <br />
//                 </div>
//                 <div className="sm:col-span-1 col-span-6">
//                   <input
//                     type="radio"
//                     id="negative"
//                     name="step5a"
//                     value="negative"
//                     checked={report_result === "negative"}
//                     onChange={reportResult}
//                   />
//                   <label htmlFor="negative" className="ml-2">
//                     NEGATIVE
//                   </label>
//                   <br />
//                 </div>
//                 <div className="sm:col-span-1 col-span-6 sm:ml-3">
//                   <input
//                     type="radio"
//                     id="dilute"
//                     name="step5a"
//                     value="dilute"
//                     checked={report_result === "dilute"}
//                     onChange={reportResult}
//                   />
//                   <label htmlFor="dilute" className="ml-2">
//                     DILUTE
//                   </label>
//                 </div>
//                 <div className="sm:col-span-3 col-span-12 ">
//                   <input
//                     type="radio"
//                     id="rejected"
//                     name="step5a"
//                     value="rejected"
//                     checked={report_result === "rejected"}
//                     onChange={reportResult}
//                   />
//                   <label htmlFor="rejected" className="ml-2">
//                     REJECTED FOR TESTING
//                   </label>
//                 </div>
//                 <div className="sm:col-span-2 col-span-12 sm:ml-[-5vw]">
//                   <input
//                     type="radio"
//                     id="substituted"
//                     name="step5a"
//                     value="substituted"
//                     checked={report_result === "substituted"}
//                     onChange={reportResult}
//                   />
//                   <label htmlFor="substituted" className="ml-1">
//                     SUBSTITUTED
//                   </label>
//                 </div>
//                 <div className="sm:col-span-2 col-span-12 sm:ml-[-8vw]">
//                   <input
//                     type="radio"
//                     id="invalid"
//                     name="step5a"
//                     value="invalid"
//                     checked={report_result === "invalid"}
//                     onChange={reportResult}
//                   />
//                   <label htmlFor="invalid" className="ml-2">
//                     INVALID RESULT
//                   </label>
//                 </div>
//               </div>

//               <h1 className="mt-6 font-medium">Remark for POSITIVE</h1>
//               <textarea
//                 name=""
//                 id=""
//                 rows="3"
//                 placeholder="Remarks"
//                 className="mt-3 w-full rounded-lg bg-[#ECF1F6]  p-3 grid grid-cols-1 border-none focus:outline-none"
//                 value={remark_for_positive}
//                 onChange={(e) => set_remark_for_positive(e.target.value)}
//               ></textarea>
//               <h1 className="mt-4 font-medium">Analyte(s) in ng/ML</h1>
//               <textarea
//                 name=""
//                 id=""
//                 rows="3"
//                 placeholder="Remarks"
//                 className="mt-3 w-full rounded-lg bg-[#ECF1F6]  p-3 grid grid-cols-1 border-none focus:outline-none"
//                 value={p_remark}
//                 onChange={(e) => set_p_remark(e.target.value)}
//               ></textarea>
//               <h1 className="mt-4 font-medium">
//                 Test Facility (if differebt from above)
//               </h1>
//               <input
//                 type="text"
//                 placeholder="Enter test facility"
//                 className="w-full h-11 mb-2 mt-4 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
//                 value={p_facility}
//                 onChange={(e) => set_p_facility(e.target.value)}
//               />

//               <h1 className="mt-5">
//                 I certify that the specimen identified on this form was examined
//                 upon receipt, handled using chain of custody procedures,
//                 analyzed, and reported in accordance with applicable federal
//                 requirements.
//               </h1>
//               <div className="grid grid-cols-12 mt-11">
//                 <div className="sm:col-span-4 col-span-12">
//                   <b>Signature of Certifying Technician/Scientist</b>
//                   <div className="relative">
//                     <SignatureCanvas
//                       ref={signatureRef}
//                       penColor="black"
//                       canvasProps={{
//                         className: "signature-canvas",
//                         width: 300,
//                         height: 100,
//                       }}
//                       className="border border-solid border-black mb-4"
//                     />
//                     {/* Draw the line below the signature */}
//                     <div className="absolute bottom-0 left-0 w-[17vw] border-b border-black"></div>
//                   </div>
//                 </div>
//                 <div className="sm:col-span-8 col-span-12 grid grid-cols-12 gap-4 mt-14 sm:ml-[-4vw]">
//                   <div className="sm:col-span-4 mt-[-3vh] col-span-12">
//                     <p className="font-medium">
//                       Certifying Technician/Scientist's Name
//                     </p>
//                     <input
//                       type="text"
//                       placeholder="Enter name"
//                       className="w-full h-10 mt-2 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
//                       value={scientist_name}
//                       onChange={(e) => set_scientist_name(e.target.value)}
//                     />
//                   </div>

//                   <div className="sm:col-span-4 col-span-12">
//                     <p className="font-medium">Select Date</p>
//                     <input
//                       type="date"
//                       // value={techniciandate}
//                       // onChange={(e) => setDate(e.target.value)}
//                       className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
//                       value={p_date}
//                       onChange={(e) => set_p_date(e.target.value)}
//                     />
//                   </div>

//                   <div
//                     onClick={handleTechniDate}
//                     className="sm:col-span-4 mt-3 col-span-12"
//                   >
//                     <p className="mt-[-8px] font-medium ">Choose Default</p>
//                     <div className="mt-2.5 col-span-12 w-full">
//                       <button className="p-1.5 pl-7 pr-7 rounded-lg w-full bg-blue-600 font-normal text-white">
//                         Today's date
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             {/* step-5B */}
//             <div className=" mt-7 mb-7 font-medium text-[20px] font-Poppins">
//               {" "}
//               <h1>Step 5B : Completed by Split Testing Laboratory</h1>
//             </div>

//             <div className="border border-blue-200 p-4 rounded-lg">
//               <div className="grid grid-cols-12 mt-6">
//                 <div className="sm:col-span-5 col-span-12">
//                   <div className="relative sm:mt-80 mt-5">
//                     {/* <h1 className=" mb-2 mt-2 font-medium">
//                       Name of Delivery Service
//                     </h1>
//                     <input
//                       type="text"
//                       placeholder="Enter delivery service"
//                       className="w-full h-10 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
//                     /> */}
//                     <h1 className=" mb-2 font-medium">
//                       Name of Delivery Service
//                     </h1>
//                     <input
//                       type="text"
//                       placeholder="Enter delivery service"
//                       className="w-full h-10 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
//                       value={delivery_service_name}
//                       onChange={(e) =>
//                         set_delivery_service_name(e.target.value)
//                       }
//                     />
//                   </div>
//                 </div>
//                 <div>
//                   <div className="w-1 sm:col-span-1 sm:block hidden   sm:h-[500px] border-l-5 border-l-2 border-sienna sm:ml-7 border-dotted"></div>
//                 </div>
//                 <div className="sm:col-span-6 col-span-12 sm:ml-[-3vw]">
//                   <div className="mt-5 grid grid-cols-12 gap-4 mb-3">
//                     <div className="sm:col-span-3 col-span-12">
//                       <input
//                         type="radio"
//                         id="true"
//                         name="deliverysvc"
//                         value="true"
//                         checked={reconfirm === "true"}
//                         onChange={reconfirmm}
//                       />
//                       <label htmlFor="true" className="ml-2">
//                         RECONFIRMED
//                       </label>
//                       <br />
//                     </div>
//                     <div className="sm:col-span-4 col-span-12">
//                       <input
//                         type="radio"
//                         id="false"
//                         name="deliverysvc"
//                         value="false"
//                         checked={reconfirm === "false"}
//                         onChange={reconfirmm}
//                       />
//                       <label htmlFor="false" className="ml-2">
//                         FAILED TO RECONFIRM
//                       </label>
//                       <br />
//                     </div>
//                   </div>
//                   <div>
//                     <input
//                       type="text"
//                       className="w-full h-11 mb-4 mt-3 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
//                     />
//                   </div>
//                   <h1 className="mb-2 mt-3">
//                     I certify that the specimen identified on this form was
//                     examined upon receipt, handled using chain of custody
//                     procedures, analyzed, and reported in accordance with
//                     applicable federal requirements.
//                   </h1>
//                   <div className="mb-2 mt-5">
//                     <b className="font-medium">
//                       Signature of Certifying Technician
//                       <br /> / Scientist
//                     </b>
//                     <div className="relative">
//                       <SignatureCanvas
//                         ref={signatureRef}
//                         penColor="black"
//                         canvasProps={{
//                           className: "signature-canvas",
//                           width: 300,
//                           height: 100,
//                         }}
//                         className="border border-solid border-black mb-4"
//                       />
//                       {/* Draw the line below the signature */}
//                       <div className="absolute bottom-0 left-0 w-[17vw] border-b border-black"></div>
//                     </div>
//                   </div>
//                   <h1 className="mb-2 mt-7 font-medium">
//                     Certifying Technician/Scientist's Name
//                   </h1>
//                   <input
//                     type="text"
//                     placeholder="Enter technician name"
//                     className="w-full h-10 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
//                     value={s_scientist_name}
//                     onChange={(e) => set_s_scientist_name(e.target.value)}
//                   />
//                   <div className="grid grid-cols-12 gap-5 mt-3">
//                     <div className="sm:col-span-6 col-span-12">
//                       <p className="font-medium">Select Date</p>
//                       <input
//                         type="date"
//                         // value={scientisitdate}
//                         // onChange={(e) => setDate(e.target.value)}
//                         className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
//                         value={s_date}
//                         onChange={(e) => set_s_date(e.target.value)}
//                       />
//                     </div>

//                     <div
//                       onClick={handleSplitTestDate}
//                       className="sm:col-span-6 sm:mt-6 col-span-12  items-center"
//                     >
//                       <p className="sm:mt-[-3.5vh] font-medium  ">
//                         Choose Default
//                       </p>
//                       <div className="mt-2.5 sm:col-span-6 col-span-12">
//                         <button className="p-1.5 pl-7 pr-7 rounded-lg w-full  bg-blue-600 font-normal text-white">
//                           Today's date
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             {/* step- 6 */}

//             <div className=" mt-7 mb-7 font-medium text-[20px] font-Poppins">
//               {" "}
//               <h1>
//                 Step 6 : Completed by Medical Review Officer - Primary Specimen
//               </h1>
//             </div>
//             <div className="border border-blue-200 p-4 rounded-lg">
//               <h1>
//                 In accordance with applicable requirements, my
//                 determination/verification is:
//               </h1>
//               <div className="mt-8 grid grid-cols-12 gap-5 mb-3">
//                 <div className="sm:col-span-2 col-span-12">
//                   <input
//                     type="radio"
//                     id="negative"
//                     name="dtmnvfn"
//                     value="negative"
//                     checked={m_report_result === "negative"}
//                     onChange={reportResultt}
//                   />
//                   <label htmlFor="negative" className="ml-3">
//                     NEGATIVE
//                   </label>
//                   <br />
//                 </div>
//                 <div className="sm:col-span-2 col-span-12">
//                   <input
//                     type="radio"
//                     id="positive"
//                     name="dtmnvfn"
//                     value="positive"
//                     checked={m_report_result === "positive"}
//                     onChange={reportResultt}
//                   />
//                   <label htmlFor="positive" className="ml-3">
//                     POSITIVE
//                   </label>
//                   <br />
//                 </div>
//                 <div className="sm:col-span-2 col-span-12">
//                   <input
//                     type="radio"
//                     id="cancelled"
//                     name="dtmnvfn"
//                     value="cancelled"
//                     checked={m_report_result === "cancelled"}
//                     onChange={reportResultt}
//                   />
//                   <label htmlFor="cancelled" className="ml-3">
//                     TEST CANCELLED
//                   </label>
//                 </div>
//                 <div className="sm:col-span-3 col-span-12">
//                   <input
//                     type="radio"
//                     id="refused"
//                     name="dtmnvfn"
//                     value="refused"
//                     checked={m_report_result === "refused"}
//                     onChange={reportResultt}
//                   />
//                   <label htmlFor="refused" className="ml-3">
//                     REFUSED
//                   </label>
//                 </div>
//               </div>
//               <div className="grid grid-cols-12 gap-4">
//                 <div className="sm:col-span-2 col-span-12">
//                   <input
//                     type="radio"
//                     id="dilute"
//                     name="dtmnvfn"
//                     value="dilute"
//                     checked={m_report_result === "dilute"}
//                     onChange={reportResultt}
//                   />
//                   <label htmlFor="dilute" className="ml-3">
//                     DILUTE
//                   </label>
//                 </div>
//                 <div className="sm:col-span-2 col-span-12">
//                   <input
//                     type="radio"
//                     id="substituted"
//                     name="dtmnvfn"
//                     value="substituted"
//                     checked={m_report_result === "substituted"}
//                     onChange={reportResultt}
//                   />
//                   <label htmlFor="substituted" className="ml-3">
//                     SUBSTITUTED
//                   </label>
//                 </div>
//                 <div className="sm:col-span-2 col-span-12">
//                   <input
//                     type="radio"
//                     id="adultrated"
//                     name="dtmnvfn"
//                     value="adultrated"
//                     checked={m_report_result === "adultrated"}
//                     onChange={reportResultt}
//                   />
//                   <label htmlFor="adultrated" className="ml-3">
//                     ADULTRATED
//                   </label>
//                 </div>
//                 <div className="sm:col-span-2 col-span-12">
//                   <input
//                     type="radio"
//                     id="other"
//                     name="dtmnvfn"
//                     value="other"
//                     checked={m_report_result === "other"}
//                     onChange={reportResultt}
//                   />
//                   <label htmlFor="other" className="ml-3">
//                     OTHER
//                   </label>
//                 </div>
//               </div>
//               <textarea
//                 name=""
//                 id=""
//                 rows="2"
//                 placeholder="Remark for positive"
//                 className="mt-8 w-full rounded-lg bg-[#ECF1F6]  p-3 mb-3 border-none focus:outline-none grid grid-cols-1"
//                 value={m_remark_for_positive}
//                 onChange={(e) => set_m_remark_for_positive(e.target.value)}
//               ></textarea>
//               <textarea
//                 name=""
//                 id=""
//                 rows="3"
//                 placeholder="Remarks"
//                 className=" w-full rounded-lg bg-[#ECF1F6]  p-3 mb-3 border-none focus:outline-none grid grid-cols-1 mt-4"
//                 value={m_remark}
//                 onChange={(e) => set_m_remark(e.target.value)}
//               ></textarea>

//               <div className="mt-5 grid grid-cols-12 sm:gap-6 gap-2">
//                 <div className="sm:col-span-4 col-span-12">
//                   <b>Signature of Medical Review Officer</b>
//                   <div className="relative mt-3">
//                     <SignatureCanvas
//                       ref={signatureRef}
//                       penColor="black"
//                       canvasProps={{
//                         className: "signature-canvas",
//                         width: 300,
//                         height: 100,
//                       }}
//                       className="border border-solid border-black mb-4 "
//                     />
//                     {/* Draw the line below the signature */}
//                     <div className="absolute bottom-0 left-0 w-[17vw] border-b border-black"></div>
//                   </div>
//                 </div>
//                 <div className="sm:col-span-8 col-span-12 grid grid-cols-12 gap-4 mt-14 sm:ml-[-4vw]">
//                   <div className="sm:col-span-4 col-span-12">
//                     <h className="font-medium">Medical Review Officer's name</h>
//                     <input
//                       type="text"
//                       placeholder="Enter name"
//                       className="w-full h-10 mt-2 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
//                       value={m_officer_name}
//                       onChange={(e) => set_m_officer_name(e.target.value)}
//                     />
//                   </div>
//                   <div className="sm:col-span-8 col-span-12 grid grid-cols-12 gap-4">
//                     <div className="sm:col-span-6 col-span-12">
//                       <p className="font-medium">Select Date</p>
//                       <input
//                         type="date"
//                         // value={step6date}
//                         // onChange={(e) => setTime(e.target.value)}
//                         className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 mr-2 border-none focus:outline-none"
//                         value={m_date}
//                         onChange={(e) => setm_date(e.target.value)}
//                       />
//                     </div>
//                     <div
//                       onClick={handleMRODate}
//                       className="sm:col-span-6 mt-3 col-span-12"
//                     >
//                       <p className="mt-[-8px] font-medium  ">Choose Default</p>
//                       <div className="mt-2.5 sm:col-span-6 col-span-12">
//                         <button className="p-1.5 pl-7 pr-7 rounded-lg w-full  bg-blue-600 font-normal col-span-6 text-white">
//                           Today's date
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             {/* step-7 */}
//             <div className=" mt-7 mb-7 font-medium text-[20px] font-Poppins">
//               {" "}
//               <h1>
//                 Step 7 : Completed by Medical Review Officer - Secondary
//                 Specimen
//               </h1>
//             </div>
//             <div className="border border-blue-200 p-4 rounded-lg">
//               <h1 className="mt-8">
//                 In accordance with applicable requirements, my
//                 determination/verification for the split specimen (if tested)
//                 is:
//               </h1>
//               <div className="sm:mt-7 mt-3 grid grid-cols-12  mb-3 gap-1">
//                 <div className="sm:col-span-2 col-span-12">
//                   <input
//                     type="radio"
//                     id="true"
//                     name="acdvvfc"
//                     value="true"
//                     checked={mr_reconfirm === "true"}
//                     onChange={reconfirmedd}
//                   />
//                   <label htmlFor="true" className="ml-2">
//                     RECONFIRMED
//                   </label>
//                   <br />
//                 </div>
//                 <div className="sm:col-span-3 col-span-12">
//                   <input
//                     type="radio"
//                     id="false"
//                     name="acdvvfc"
//                     value="false"
//                     checked={mr_reconfirm === "false"}
//                     onChange={reconfirmedd}
//                   />
//                   <label htmlFor="false" className="ml-2">
//                     FAILED TO CONFIRM
//                   </label>
//                   <br />
//                 </div>
//               </div>

//               <textarea
//                 name=""
//                 id=""
//                 rows="3"
//                 placeholder="Remarks"
//                 className="mt-3 w-full rounded-lg bg-[#ECF1F6]  p-3 mb-3 border-none focus:outline-none"
//                 value={mr_delivery_service_name}
//                 onChange={(e) => set_mr_delivery_service_name(e.target.value)}
//               ></textarea>

//               <div className="mt-5 grid grid-cols-12 gap-4 ">
//                 <div className="sm:col-span-4 col-span-12">
//                   <b>Signature of Medical Review Officer</b>
//                   <div className="relative">
//                     <SignatureCanvas
//                       ref={signatureRef}
//                       penColor="black"
//                       canvasProps={{
//                         className: "signature-canvas",
//                         width: 300,
//                         height: 100,
//                       }}
//                       className="border border-solid border-black mb-4"
//                     />
//                     {/* Draw the line below the signature */}
//                     <div className="absolute bottom-0 left-0 w-[17vw] border-b border-black"></div>
//                   </div>
//                 </div>
//                 <div className="sm:col-span-8 col-span-12 grid grid-cols-12 gap-4 mt-14 sm:ml-[-4vw]">
//                   <div className="sm:col-span-4 col-span-12">
//                     <h1 className="font-medium">
//                       Medical Review Officer's name
//                     </h1>
//                     <input
//                       type="text"
//                       placeholder="Enter name"
//                       className="w-full h-10 mt-2 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
//                       value={mr_officer_name}
//                       onChange={(e) => set_mr_officer_name(e.target.value)}
//                     />
//                   </div>
//                   <div className="sm:col-span-4 col-span-12">
//                     <p className="font-medium">Date</p>
//                     <input
//                       type="date"
//                       // value={step7date}
//                       // onChange={(e) => setDate(e.target.value)}
//                       className="w-full h-10 mb-4 mt-2 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
//                       value={mr_date}
//                       onChange={(e) => set_mr_date(e.target.value)}
//                     />
//                   </div>
//                   <div
//                     onClick={handleMROSDate}
//                     className="sm:col-span-4 col-span-12"
//                   >
//                     <p className="font-medium mb-2">Choose Default</p>
//                     <button className="p-1.5 pl-7 pr-7 rounded-lg w-full bg-blue-600 font-normal text-white">
//                       Today's date
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div className=" mt-4 gap-3 flex sm:justify-start justify-around">
//               <div>
//                 <Cancelsmall onClick={onClose} />
//               </div>
//               <div>
//                 {/* <GreenSubmit /> */}
//                 <button onClick={forensicDrugFromSave} className="savebtn">
//                   {spin ? (
//                     <svg
//                       aria-hidden="true"
//                       class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
//                       viewBox="0 0 100 101"
//                       fill="none"
//                       xmlns="http://www.w3.org/2000/svg"
//                     >
//                       <path
//                         d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
//                         fill="currentColor"
//                       />
//                       <path
//                         d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
//                         fill="currentFill"
//                       />
//                     </svg>
//                   ) : (
//                     "update"
//                   )}
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default ForensicOrderFormEdit;

import React, { useState, useRef, useEffect } from "react";
import mask from "../../assets/Mask group.jpg";
import "./Form.css";
import { LuPencil } from "react-icons/lu";
import SignatureCanvas from "react-signature-canvas";
import Datetime from "../../components/buttons/Datetime";
import Cancel from "../../components/buttons/Cancel";
import GreenSubmit from "../../components/buttons/GreenSubmit";
import Todaydate from "../../components/buttons/Todaydate";
import PreviousButton from "../buttons/PreviousButton";
import Cancelsmall from "../buttons/Cancelsmall";
import Utility from "../../Utils/Utility";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import SelectOption from "@material-tailwind/react/components/Select/SelectOption";
import { useModal } from "../../context/modalContext";

const ForensicOrderFormEdit = ({ onClose }) => {
  const signatureRef = useRef();
  const token = useSelector((state) => state?.user?.token);

  const { userID, setUserID } = useModal();
  console.log("<><><><>", userID);

  const [panelData, setPanelData] = useState([]);
  const [drugdata, setDrugData] = useState([]);

  const [custom_drug_test, set_custom_drug_test] = useState(
    userID?.custom_drug_test
  );
  const [additional_drug_test, set_additional_drug_test] = useState(
    userID?.additional_drug_test
  );

  const [res, setRep] = useState([]);

  useEffect(() => {
    getPanelData();
    getDrugData();
    getRep();
  }, []);

  const getPanelData = async () => {
    await HttpClient.apiCaller("admin/panel-get", "GET", null, token, null)
      .then((res) => {
        // console.log("1<><><>", res);
        setPanelData(res?.data?.code);
      })
      .catch((err) => console.log(err));
  };
  const getDrugData = async () => {
    await HttpClient.apiCaller("admin/code", "GET", null, token, null)
      .then((res) => {
        // console.log("2<><><>", res);
        setDrugData(res?.data?.code);
      })
      .catch((err) => console.log(err));
  };
  const getRep = async () => {
    await HttpClient.apiCaller(
      "admin/user-list?role=physician",
      "GET",
      null,
      token,
      null
    )
      .then((res) => {
        // console.log("?????????", res);
        setRep(res?.data?.user);
      })
      .catch((err) => console.log(err));
  };

  const drugPanelData = panelData?.map((item, i) => ({
    panelName: item?.name,
    id: item?._id,
  }));

  const drugNameData = drugdata?.map((item, i) => ({
    drugName: item?.name,
    id: item?._id,
  }));

  const handleSelect = (selectedOption) => {
    const selectedIds = selectedOption.map((option) => option?.id);
    set_custom_drug_test(selectedIds);
  };
  // console.log("__________>", custom_drug_test);
  const handleSelectt = (SelectOption) => {
    const selectedIdss = SelectOption.map((option) => option?.id);
    set_additional_drug_test(selectedIdss);
  };
  // console.log("2__________>", additional_drug_test);

  // saving & fetching in & from local storage
  // const [localData, setLocalData] = useState({});
  // const saveItem = (keyy, valuee) => {
  //   const existingData =
  //     JSON.parse(localStorage.getItem("forensicDrugForm")) || {};
  //   existingData[keyy] = valuee;
  //   localStorage.setItem("forensicDrugForm", JSON.stringify(existingData));
  // };
  // useEffect(() => {
  //   const getLocalStorageValues = () => {
  //     const data = localStorage.getItem("forensicDrugForm");
  //     if (data) {
  //       const parsedData = JSON.parse(data);
  //       setLocalData(parsedData);
  //       return parsedData;
  //     } else {
  //       return {};
  //     }
  //   };
  //   getLocalStorageValues();
  // }, []);

  const currentDateTime = new Date();
  const formattedDate = currentDateTime.toISOString().slice(0, 10);
  const formattedTime = currentDateTime.toTimeString().slice(0, 5);

  const handleStep4Date = () => {
    set_collection_date(formattedDate);
    set_collection_time(formattedTime);
  };
  const handleStepLabDate = () => {
    set_r_date(formattedDate);
  };
  const handleSplitExpDate = () => {
    set_split_specimen_expiration(formattedDate);
  };

  const handlePrimaryExpiration = () => {
    set_primary_specimen_expiration(formattedDate);
  };
  const handleTechniDate = () => {
    set_p_date(formattedDate);
  };
  const handleSplitTestDate = () => {
    set_s_date(formattedDate);
  };
  const handleMRODate = () => {
    setm_date(formattedDate);
  };
  const handleMROSDate = () => {
    set_mr_date(formattedDate);
  };

  const [specimen_id, set_specimen_id] = useState(userID?.specimen_id);
  const [accession_no, set_accession_no] = useState(userID?.accession_no);
  const [employer_name, set_employer_name] = useState(userID?.employer_name);
  const [employer_code, set_employer_code] = useState(userID?.employer_code);
  const [employer_address, set_employer_address] = useState(
    userID?.employer_address
  );
  const [employer_id, set_employer_id] = useState(userID?.employer_id);
  //mro
  const [mro_name, set_mro_name] = useState(userID?.mro?.name);
  const [mro_phone, set_mro_phone] = useState(userID?.mro?.phone_number);
  const [mro_fax, set_mro_fax] = useState(userID?.mro?.fax_number);
  const [mro_addr, set_mro_addr] = useState(userID?.mro?.address);
  //donor
  const [donor_first_name, set_donor_first_name] = useState(
    userID?.donor_first_name
  );
  const [donor_dob, set_donor_dob] = useState(userID?.donor_dob);
  const [donor_sign, set_donor_sign] = useState(userID?.donor_sign); //signature image
  const [donor_day_no, set_donor_day_no] = useState(userID?.donor_day_no);
  const [donor_eve_no, set_donor_eve_no] = useState(userID?.donor_eve_no);
  const [donor_last_name, set_donor_last_name] = useState(
    userID?.donor_last_name
  );
  const [donor_date, set_donor_date] = useState(userID?.donor_date);
  const [donor_id, set_donor_id] = useState(userID?.donor_id);
  //donor verification
  const [photo_id, set_photo_id] = useState(
    userID?.donor_verification?.photo_id
  );
  // const [employer, set_employer] = useState("");
  // const [representive, set_representive] = useState("");
  const [id_type, set_id_type] = useState(userID?.donor_verification?.id_type);
  const handleId = (e) => set_id_type(e.target.value);

  const [test_reason, set_test_reason] = useState(userID?.test_reason);
  const testReason = (e) => set_test_reason(e.target.value);

  const [reason_remark, set_reason_remark] = useState(userID?.reason_remark);

  // const [custom_drug_test, set_custom_drug_test] = useState("");
  // const [additional_drug_test, set_additional_drug_test] = useState("");
  //site details
  const [site_name, set_site_name] = useState(userID?.site_details?.name);
  const [site_phone, set_site_phone] = useState(
    userID?.site_details?.phone_number
  );
  const [site_fax, set_site_fax] = useState(userID?.site_details?.fax_number);
  const [site_addr, set_site_addr] = useState(userID?.site_details?.address);
  const [site_pin, set_site_pin] = useState(userID?.site_details?.postal_code);

  const [test_type, set_test_type] = useState(userID?.test_type);
  const testType = (e) => set_test_type(e.target.value);

  //collections
  const [collection_type, set_collection_type] = useState(
    userID?.collections?.collection_type
  );
  const collectionType = (e) => set_collection_type(e.target.value);

  const [split_type, set_split_type] = useState(
    userID?.collections?.split_type
  );
  const splitType = (e) => set_split_type(e.target.value);

  const [expiration_date, set_expiration_date] = useState(
    userID?.collections?.expiration_date
  );
  const expired = (e) => set_expiration_date(e.target.value);

  const [urine_temperature, set_urine_temperature] = useState(
    userID?.collections?.urine_temperature
  );
  const urine = (e) => set_urine_temperature(e.target.value);

  const [volume_indicator, set_volume_indicator] = useState(
    userID?.collections?.volume_indicator
  );
  const volumeIndicator = (e) => set_volume_indicator(e.target.value);

  const [remark, set_remark] = useState(userID?.collections?.remark);
  //custody
  const [collector_sign, set_collector_sign] = useState(
    userID?.custody?.collector_sign
  ); //image
  const [collector_name, set_collector_name] = useState(
    userID?.custody?.collector_name
  );
  const [collection_date, set_collection_date] = useState(
    userID?.custody?.date
  );
  const [collection_time, set_collection_time] = useState(
    userID?.custody?.time
  );

  const [released_to, set_released_to] = useState(userID?.custody?.released_to);
  const releasedTo = (e) => set_released_to(e.target.value);

  //received at
  const [r_collector_sign, set_r_collector_sign] = useState(
    userID?.received_at?.r_collector_sign
  ); //image
  const [r_collector_name, set_r_collector_name] = useState(
    userID?.received_at?.collector_name
  );
  const [r_date, set_r_date] = useState(userID?.received_at?.date);
  const [r_time, set_r_time] = useState(userID?.received_at?.time);

  const [r_seal, set_r_seal] = useState(userID?.received_at?.seal);
  const sealed = (e) => set_r_seal(e.target.value);

  const [r_remark, set_r_remark] = useState(userID?.received_at?.remark);
  const [r_released_to, set_r_released_to] = useState(
    userID?.received_at?.released_to
  );
  const [primary_specimen_expiration, set_primary_specimen_expiration] =
    useState(userID?.received_at?.primary_specimen_expiration);
  const [split_specimen_expiration, set_split_specimen_expiration] = useState(
    userID?.received_at?.split_specimen_expiration
  );
  // primary_specimen_report
  const [report_result, set_report_result] = useState(
    userID?.primary_specimen_report?.report_result
  );
  const reportResult = (e) => set_report_result(e.target.value);

  const [remark_for_positive, set_remark_for_positive] = useState(
    userID?.primary_specimen_report?.remark_for_positive
  );
  const [p_remark, set_p_remark] = useState(
    userID?.primary_specimen_report?.remark
  );
  const [p_facility, set_p_facility] = useState(
    userID?.primary_specimen_report?.facility
  );
  const [scientist_name, set_scientist_name] = useState(
    userID?.primary_specimen_report?.scientist_name
  );
  const [p_date, set_p_date] = useState(userID?.primary_specimen_report?.date);
  // split_testing_labrotory
  const [reconfirm, set_reconfirm] = useState(
    userID?.split_testing_labrotory?.reconfirm
  );
  const reconfirmm = (e) => set_reconfirm(e.target.value);

  const [delivery_service_name, set_delivery_service_name] = useState(
    userID?.split_testing_labrotory?.delivery_service_name
  );
  const [s_scientist_name, set_s_scientist_name] = useState(
    userID?.split_testing_labrotory?.scientist_name
  );
  const [s_date, set_s_date] = useState(userID?.split_testing_labrotory?.date);
  // medical_review_officer_primary_specimen
  const [m_report_result, set_m_report_result] = useState(
    userID?.medical_review_officer_primary_specimen?.report_result
  );
  const reportResultt = (e) => set_m_report_result(e.target.value);

  const [m_remark_for_positive, set_m_remark_for_positive] = useState(
    userID?.medical_review_officer_primary_specimen?.remark_for_positive
  );
  const [m_remark, set_m_remark] = useState(
    userID?.medical_review_officer_primary_specimen?.remark
  );
  const [m_signature, set_m_signature] = useState(
    userID?.medical_review_officer_primary_specimen?.m_signature
  );
  const [m_officer_name, set_m_officer_name] = useState(
    userID?.medical_review_officer_primary_specimen?.officer_name
  );
  const [m_date, setm_date] = useState(
    userID?.medical_review_officer_primary_specimen?.date
  );
  // medical_review_officer_secondary_specimen
  const [mr_reconfirm, set_mr_reconfirm] = useState(
    userID?.medical_review_officer_secondary_specimen?.reconfirm
  );
  const reconfirmedd = (e) => set_mr_reconfirm(e.target.value);

  const [mr_delivery_service_name, set_mr_delivery_service_name] = useState(
    userID?.medical_review_officer_secondary_specimen?.delivery_service_name
  );
  const [mr_officer_name, set_mr_officer_name] = useState(
    userID?.medical_review_officer_secondary_specimen?.officer_name
  );
  const [mr_date, set_mr_date] = useState(
    userID?.medical_review_officer_secondary_specimen?.date
  );
  const [otherCourier, setOtherCourier] = useState(userID?.custody?.other);

  const [spin, setSpin] = useState(false);
  // signature refs
  const collectorSignRef = useRef();
  const collectionSiteSignRef = useRef();
  const accessionerSignRef = useRef();
  const certifyingSignRef = useRef();
  const medicalReviewSignRef = useRef();
  const medicalReviewOfficerSignRef = useRef();
  const [collectionSiteSign, setCollectionSiteSign] = useState(null);
  const [accessionerSign, setAccessionerSign] = useState(null);
  const [certifyingSign, setCertifyingSign] = useState(null);
  const [medicalReviewSign, setMedicalReviewSign] = useState(null);
  const [medicalReviewOfficerSign, setMedicalReviewOfficerSign] =
    useState(null);

  const handleCollectorSignClear = () => {
    collectorSignRef.current.clear();
    set_collector_sign(null);
  };
  const handleCollectionSiteSignClear = () => {
    collectionSiteSignRef.current.clear();
    setCollectionSiteSign(null);
  };
  const handleAccessionerSignClear = () => {
    accessionerSignRef.current.clear();
    setAccessionerSign(null);
  };
  const handleCertifyingSignClear = () => {
    certifyingSignRef.current.clear();
    setCertifyingSign(null);
  };
  const handleMedicalReviewSignClear = () => {
    medicalReviewSignRef.current.clear();
    setMedicalReviewSign(null);
  };
  const handleMedicalReviewOfficerSignClear = () => {
    medicalReviewOfficerSignRef.current.clear();
    setMedicalReviewOfficerSign(null);
  };
  const handleCollectorSignSave = () => {
    if (collectorSignRef.current.isEmpty()) {
      alert("Please provide a collector signature.");
    } else {
      const dataUrl = collectorSignRef.current.toDataURL();
      set_collector_sign(dataUrl);
    }
  };
  const handleCollectionSiteSignSave = () => {
    if (collectionSiteSignRef.current.isEmpty()) {
      alert("Please provide a collection site signature.");
    } else {
      const dataUrl = collectionSiteSignRef.current.toDataURL();
      setCollectionSiteSign(dataUrl);
    }
  };
  const handleAccessionerSignSave = () => {
    if (accessionerSignRef.current.isEmpty()) {
      alert("Please provide a Accessioner signature.");
    } else {
      const dataUrl = accessionerSignRef.current.toDataURL();
      setAccessionerSign(dataUrl);
    }
  };
  const handleCertifyingSignSave = () => {
    if (certifyingSignRef.current.isEmpty()) {
      alert("Please provide a certifying signature.");
    } else {
      const dataUrl = certifyingSignRef.current.toDataURL();
      certifyingSign(dataUrl);
    }
  };
  const handleMedicalReviewSignSave = () => {
    if (medicalReviewSignRef.current.isEmpty()) {
      alert("Please provide a Medical Review signature.");
    } else {
      const dataUrl = medicalReviewSignRef.current.toDataURL();
      setMedicalReviewSign(dataUrl);
    }
  };
  const handleMedicalReviewOfficerSignSave = () => {
    if (medicalReviewOfficerSignRef.current.isEmpty()) {
      alert("Please provide a medical officer signature.");
    } else {
      const dataUrl = medicalReviewOfficerSignRef.current.toDataURL();
      setMedicalReviewOfficerSign(dataUrl);
    }
  };

  const validation = () => {
    if (specimen_id === "" || specimen_id === undefined) {
      Utility.eToast("Specimen Id Required!");
      return false;
    } else if (accession_no === "" || accession_no === undefined) {
      Utility.eToast("Accession No required!");
      return false;
    } else if (employer_name === "" || employer_name === undefined) {
      Utility.eToast("Employer name required!");
      return false;
    } else if (employer_code === "" || employer_code === undefined) {
      Utility.eToast("Employer code required!");
      return false;
    } else if (employer_address === "" || employer_address === undefined) {
      Utility.eToast("Employer Address required!");
      return false;
    } else if (employer_id === "" || employer_id === undefined) {
      Utility.eToast("Employer Id required!");
      return false;
    } else if (mro_name === "" || mro_name === undefined) {
      Utility.eToast(" Mro namerequired!");
      return false;
    } else if (mro_phone === "" || mro_phone === undefined) {
      Utility.eToast("Mro Phone required!");
      return false;
    } else if (mro_addr === "" || mro_addr === undefined) {
      Utility.eToast("Mro Addr required!");
      return false;
    } else if (donor_first_name === "" || donor_first_name === undefined) {
      Utility.eToast("Donor first name required!");
      return false;
    } else if (donor_dob === "" || donor_dob === undefined) {
      Utility.eToast("Donor dob required!");
      return false;
    } else if (donor_day_no === "" || donor_day_no === undefined) {
      Utility.eToast(" Donor day no required!");
      return false;
    } else if (donor_eve_no === "" || donor_eve_no === undefined) {
      Utility.eToast("Donor evening no required!");
      return false;
    } else if (donor_last_name === "" || donor_last_name === undefined) {
      Utility.eToast("Donor last name required!");
      return false;
    } else if (donor_id === "" || donor_id === undefined) {
      Utility.eToast(" Donor id required!");
      return false;
    } else if (donor_date === "" || donor_date === undefined) {
      Utility.eToast("Donor date required");
      return false;
    } else if (photo_id === "" || photo_id === undefined) {
      Utility.eToast("Photo id required!");
      return false;
    } else if (id_type === "" || id_type === undefined) {
      Utility.eToast("Id type required!");
      return false;
    } else if (test_reason === "" || test_reason === undefined) {
      Utility.eToast(" Test Reason required!");
      return false;
    } else if (custom_drug_test === "" || custom_drug_test === undefined) {
      Utility.eToast("Custom drug test required!");
      return false;
    } else if (
      additional_drug_test === "" ||
      additional_drug_test === undefined
    ) {
      Utility.eToast("Additional drug test required!");
      return false;
      // } else if (site_name === "" || site_name === undefined) {
      //   Utility.eToast("Site name required!");
      //   return false;
    } else if (site_phone === "" || site_phone === undefined) {
      Utility.eToast("Site phone required!");
      return false;
    } else if (site_addr === "" || site_addr === undefined) {
      Utility.eToast("Site addr required!");
      return false;
    } else if (site_pin === "" || site_pin === undefined) {
      Utility.eToast("Site pin required!");
      return false;
    } else if (test_type === "" || test_type === undefined) {
      Utility.eToast("Test type required!");
      return false;
    } else if (collection_type === "" || collection_type === undefined) {
      Utility.eToast("Collection type required!");
      return false;
    }
    // else if (split_type === "" || split_type === undefined) {
    //   Utility.eToast("Split type required!");
    //   return false;
    // }
    // else if (expiration_date === "" || expiration_date === undefined) {
    //   Utility.eToast("Expiration date required!");
    //   return false;
    // }
    // else if (volume_indicator === "" || volume_indicator === undefined) {
    //   Utility.eToast("Volume indicator required!");
    //   return false;
    // }
    // else if (remark === "" || remark === undefined) {
    //   Utility.eToast("remark required!");
    //   return false;
    // }
    else if (collector_name === "" || collector_name === undefined) {
      Utility.eToast("Collector name required!");
      return false;
    } else if (collection_date === "" || collection_date === undefined) {
      Utility.eToast("Collection date required!");
      return false;
    } else if (collection_time === "" || collection_time === undefined) {
      Utility.eToast("Collection time required!");
      return false;
    } else if (released_to === "" || released_to === undefined) {
      Utility.eToast(" Released to required!");
      return false;
    } else if (r_collector_name === "" || r_collector_name === undefined) {
      Utility.eToast("r_collector_name required!");
      return false;
    } else if (r_date === "" || r_date === undefined) {
      Utility.eToast("Collection date required!");
      return false;
    }
    // else if (r_time === "" || r_time === undefined) {
    //   Utility.eToast("collection time required!");
    //   return false;
    // }
    else if (r_seal === "" || r_seal === undefined) {
      Utility.eToast("seal required!");
      return false;
      // } else if (r_remark === "" || r_remark === undefined) {
      //   Utility.eToast("Seal remark required!");
      //   return false;
    } else if (r_released_to === "" || r_released_to === undefined) {
      Utility.eToast("Released to required!");
      return false;
    } else if (
      primary_specimen_expiration === "" ||
      primary_specimen_expiration === undefined
    ) {
      Utility.eToast("Primary specimen expiration required!");
      return false;
    } else if (
      split_specimen_expiration === "" ||
      split_specimen_expiration === undefined
    ) {
      Utility.eToast("Split specimen expiration required!");
      return false;
    } else if (report_result === "" || report_result === undefined) {
      Utility.eToast("Report result required!");
      return false;
    }
    // else if (
    //   remark_for_positive === "" ||
    //   remark_for_positive === undefined
    // ) {
    //   Utility.eToast("Remark for positive required!");
    //   return false;
    // }
    // else if (p_remark === "" || p_remark === undefined) {
    //   Utility.eToast("positive remark required!");
    //   return false;
    // }
    else if (p_facility === "" || p_facility === undefined) {
      Utility.eToast("Facility required!");
      return false;
    } else if (scientist_name === "" || scientist_name === undefined) {
      Utility.eToast("Scientist name required!");
      return false;
    } else if (p_date === "" || p_date === undefined) {
      Utility.eToast("date required!");
      return false;
    } else if (reconfirm === "" || reconfirm === undefined) {
      Utility.eToast("reconfirm required!");
      return false;
    } else if (
      delivery_service_name === "" ||
      delivery_service_name === undefined
    ) {
      Utility.eToast("Delivery service name required!");
      return false;
    } else if (s_scientist_name === "" || s_scientist_name === undefined) {
      Utility.eToast("scientist name required!");
      return false;
    } else if (s_date === "" || s_date === undefined) {
      Utility.eToast("date required!");
      return false;
    } else if (m_report_result === "" || m_report_result === undefined) {
      Utility.eToast("Report result required!");
      return false;
    }
    // else if (
    //   m_remark_for_positive === "" ||
    //   m_remark_for_positive === undefined
    // ) {
    //   Utility.eToast("Remark for positive required!");
    //   return false;
    // }
    // else if (m_remark === "" || m_remark === undefined) {
    //   Utility.eToast("Remark required!");
    //   return false;
    // }
    else if (m_officer_name === "" || m_officer_name === undefined) {
      Utility.eToast("Officer name required!");
      return false;
    } else if (m_date === "" || m_date === undefined) {
      Utility.eToast("Date required!");
      return false;
    } else if (mr_reconfirm === "" || mr_reconfirm === undefined) {
      Utility.eToast("Reconfirm required!");
      return false;
    } else if (
      mr_delivery_service_name === "" ||
      mr_delivery_service_name === undefined
    ) {
      Utility.eToast("Delivery service name required!");
      return false;
    } else if (mr_officer_name === "" || mr_officer_name === undefined) {
      Utility.eToast("Officer name required!");
      return false;
    } else if (mr_date === "" || mr_date === undefined) {
      Utility.eToast("Date required!");
      return false;
    } else {
      return true;
    }
  };

  const OrderFormEdit = async () => {
    if (validation()) {
      setSpin(true);
      const postData = {
        specimen_id: specimen_id,
        accession_no: accession_no,
        employer_name: employer_name,
        employer_code: employer_code,
        employer_address: employer_address,
        employer_id: employer_id,
        mro: {
          name: mro_name,
          phone_number: mro_phone,
          fax_number: mro_fax,
          address: mro_addr,
        },
        donor_first_name: donor_first_name,
        donor_dob: donor_dob,
        donor_sign: donor_sign,
        donor_day_no: donor_day_no,
        donor_eve_no: donor_eve_no,
        donor_last_name: donor_last_name,
        donor_date: donor_date,
        donor_id: donor_id,
        donor_verification: {
          photo_id: photo_id,
          id_type: id_type,
        },
        test_reason: test_reason,
        reason_remark: reason_remark,
        custom_drug_test: custom_drug_test,
        additional_drug_test: additional_drug_test,
        site_details: {
          name: site_name,
          phone_number: site_phone,
          fax_number: site_fax,
          address: site_addr,
          postal_code: site_pin,
        },
        test_type: test_type,
        collections: {
          collection_type: collection_type,
          split_type: split_type,
          expiration_date: expiration_date,
          urine_temperature: urine_temperature,
          volume_indicator: volume_indicator,
          remark: remark,
        },
        custody: {
          collector_sign: collector_sign,
          collector_name: collector_name,
          date: collection_date,
          time: collection_time,
          released_to: released_to,
        },
        received_at: {
          collector_sign: r_collector_sign,
          collector_name: r_collector_name,
          date: r_date,
          time: r_time,
          seal: r_seal,
          remark: r_remark,
          released_to: r_released_to,
          primary_specimen_expiration: primary_specimen_expiration,
          split_specimen_expiration: split_specimen_expiration,
        },
        primary_specimen_report: {
          report_result: report_result,
          remark_for_positive: remark_for_positive,
          remark: p_remark,
          facility: p_facility,
          scientist_name: scientist_name,
          date: p_date,
        },
        split_testing_labrotory: {
          reconfirm: reconfirm,
          delivery_service_name: delivery_service_name,
          scientist_name: s_scientist_name,
          date: s_date,
        },
        medical_review_officer_primary_specimen: {
          report_result: m_report_result,
          remark_for_positive: m_remark_for_positive,
          remark: m_remark,
          signature: m_signature,
          officer_name: m_officer_name,
          date: m_date,
        },
        medical_review_officer_secondary_specimen: {
          reconfirm: mr_reconfirm,
          delivery_service_name: mr_delivery_service_name,
          officer_name: mr_officer_name,
          date: mr_date,
        },
      };
      // console.log(">>>>>>>>>", postData);
      await HttpClient.apiCaller("admin/test", "POST", postData, token, null)
        .then((res) => {
          // console.log("<><><>", res);
          Utility.sToast("Report saved successfully");
          onClose();
        })
        .catch((err) => console.log("?????", err))
        .finally(() => setSpin(false));
    }
  };

  return (
    <>
      <div className="">
        <div className=" ">
          <div className="p-5 ml-3 bg-white shadow h-fit">
            <div className="grid grid-cols-12 gap-3">
              <div className="col-span-2" onClick={onClose}>
                <PreviousButton />
              </div>
              <div
                className="col-span-10"
                style={{ fontSize: "1.5rem", fontWeight: "500" }}
              >
                NON-FEDERAL DRUG TESTING CUSTODY AND CONTROL FORM
              </div>

              <div className="col-span-2">
                <img src={mask} alt="" className="h-12" />
              </div>
              <div
                className={
                  window.innerWidth <= 600 ? "col-span-5" : "col-span-3"
                }
              >
                <div style={{ color: "rgb(156 165 174)" }}>
                  REQUISITION SPECIMEN ID NO.
                </div>
                <div style={{ position: "relative" }}>
                  <input
                    type="text"
                    className="border-2 focus:outline-none rounded-lg w-full "
                    value={specimen_id}
                    onChange={(e) => {
                      set_specimen_id(e.target.value);
                    }}
                  />
                  <LuPencil
                    style={{ position: "absolute", top: "5px", right: "5px" }}
                  />
                </div>
              </div>
              <div
                className={
                  window.innerWidth <= 600 ? "col-span-5" : "col-span-3"
                }
              >
                <div style={{ color: "rgb(156 165 174)" }}>ACCESSION NO.</div>
                <div style={{ position: "relative" }}>
                  <input
                    type="text"
                    className="border-2 focus:outline-none rounded-lg w-full "
                    value={accession_no}
                    onChange={(e) => {
                      set_accession_no(e.target.value);
                    }}
                  />
                  <LuPencil
                    style={{ position: "absolute", top: "5px", right: "5px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* step-1 containt */}
        <div className=" mt-1">
          <div className="p-6 ml-3 bg-white shadow h-fit">
            {/* A and B section */}
            <p className="font-poppins font-medium text-[18px] mb-3">
              Step 1: Completed By Collector Or Employer Representative
            </p>
            <div className="border border-blue-200 p-4 rounded-lg">
              <div className="grid sm:grid-cols-12 sm:gap-12 gap-4">
                <div className="sm:col-span-6 col-span-6">
                  <h1 className="text-[#919AA3] font-semibold font-Poppins">
                    A. Employer
                  </h1>
                  <div>
                    <p className="font-medium">Employer Name</p>
                    <input
                      type="text"
                      placeholder="Enter Employer name"
                      className="w-full h-11 mb-2 mt-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
                      value={employer_name}
                      onChange={(e) => {
                        set_employer_name(e.target.value);
                      }}
                    />
                    <p className="font-medium">Employer Code</p>
                    <input
                      type="text"
                      placeholder="Enter Employer code"
                      className="w-full h-11 mb-2 mt-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
                      value={employer_code}
                      onChange={(e) => set_employer_code(e.target.value)}
                    />
                    <p className="font-medium">Address</p>
                    <input
                      type="text"
                      placeholder="Enter Address"
                      className="w-full h-11 mb-2 mt-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
                      value={employer_address}
                      onChange={(e) => set_employer_address(e.target.value)}
                    />
                    <p className="font-medium">ID No</p>
                    <input
                      type="text"
                      placeholder="Enter ID No"
                      className="w-full h-11 mb-2 mt-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
                      value={employer_id}
                      onChange={(e) => set_employer_id(e.target.value)}
                    />
                  </div>
                </div>
                <div className="sm:col-span-6 col-span-6">
                  <h1 className="text-[#919AA3] font-semibold font-Poppins">
                    B. MRO
                  </h1>
                  <div>
                    <p className="font-medium mb-3">Select MRO</p>
                    <select
                      id="countries"
                      className="custom-input"
                      // className="bg-[#ECF1F6] h-10 border-none focus:outline-none text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                      value={mro_name}
                      onChange={(e) => set_mro_name(e.target.value)}
                    >
                      <option
                        selected
                        // className="text-[#919AA3] font-semibold font-Poppins w-[5rem]"
                        style={{}}
                      >
                        MRO Name
                      </option>
                      {res?.map((item, i) => (
                        <>
                          <option
                            value={item?._id}
                            key={i}
                          >{`${item?.first_name} ${item?.last_name}`}</option>
                        </>
                      ))}
                    </select>
                    <p className="font-medium mt-2">Address</p>
                    <input
                      type="text"
                      placeholder="Enter Address"
                      className="w-full h-11 mb-2 mt-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
                      value={mro_addr}
                      onChange={(e) => set_mro_addr(e.target.value)}
                    />
                    <p className="font-medium">Phone Number</p>
                    <input
                      type="text"
                      placeholder="Enter Phone Number"
                      className="w-full h-11 mb-2 mt-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
                      value={mro_phone}
                      onChange={(e) => set_mro_phone(e.target.value)}
                    />
                    <p className="font-medium">Fax No.</p>
                    <input
                      type="text"
                      placeholder="Enter Fax No."
                      className="w-full h-11 mb-2 mt-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
                      value={mro_fax}
                      onChange={(e) => set_mro_fax(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="dotted-line mt-5"></div>

              {/* C section */}
              <div className="mt-3">
                <h1 className="text-[#919AA3] font-semibold font-Poppins">
                  C. Donor
                </h1>
                <div className="grid grid-cols-12 sm:gap-5 gap-3 mt-3">
                  <div className="sm:col-span-6 col-span-12">
                    <p className="font-medium">First Name</p>
                    <input
                      type="text"
                      placeholder="Enter First Name"
                      className="w-full mt-3 h-11 mb-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
                      value={donor_first_name}
                      onChange={(e) => set_donor_first_name(e.target.value)}
                    />
                  </div>
                  <div className="sm:col-span-6 col-span-12">
                    <p className="font-medium">Last Name</p>
                    <input
                      type="text"
                      placeholder="Enter Last Name"
                      className="w-full mt-3 h-11 mb-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
                      value={donor_last_name}
                      onChange={(e) => set_donor_last_name(e.target.value)}
                    />
                  </div>
                  <div className="sm:col-span-6 col-span-12">
                    <p className="font-medium">Donor I.D No.</p>
                    <input
                      type="text"
                      placeholder="Enter ID or Number"
                      className="w-full mt-3 h-11 mb-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
                      value={donor_id}
                      onChange={(e) => set_donor_id(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="dotted-line mt-5"></div>

              {/* D section */}

              <div className="bg-[#F4F6F9] p-3 mt-3 ">
                <h1 className="text-[#919AA3] font-semibold font-Poppins">
                  D. Donor ID Verification
                </h1>
                <div className="mt-2 grid grid-cols-12 gap-4 mb-3 items-center">
                  <div className="sm:col-span-2 col-span-12">
                    <input
                      type="radio"
                      id="employee"
                      name="id_type"
                      value="employee"
                      className="mr-2 p-1"
                      checked={id_type === "employee"}
                      onChange={handleId}
                    />
                    <label
                      htmlFor="UPS"
                      className="font-normal font-Poppins text-[#919AA2]"
                    >
                      Employee ID
                    </label>
                    <br />
                  </div>
                  <div className="sm:col-span-2 col-span-12">
                    <input
                      type="radio"
                      id="representative"
                      name="id_type"
                      value="representative"
                      className="mr-2 p-1"
                      checked={id_type === "representative"}
                      onChange={handleId}
                    />
                    <label
                      htmlFor="USPS"
                      className="font-normal font-Poppins text-[#919AA2]"
                    >
                      Representative ID
                    </label>
                    <br />
                  </div>
                  <div className="sm:col-span-6 col-span-12">
                    <input
                      type="text"
                      placeholder="Enter Id"
                      className="w-full mt-3 h-11 border-none focus:outline-none mb-2 rounded-lg bg-[#E8EEF3] p-2"
                      value={photo_id}
                      onChange={(e) => set_photo_id(e.target.value)}
                    />
                    <span style={{ fontSize: "0.8rem", color: "blue" }}>
                      {/* Enter value only in true / false* */}
                    </span>
                  </div>
                </div>
              </div>

              {/*E section */}

              <div className="bg-[#ECF1F6] p-3 mt-5 ">
                <h1 className="text-[#919AA3] font-semibold font-Poppins">
                  E.Reason For Test :
                </h1>
                <div className="grid grid-cols-12 gap-4 mt-4">
                  <div className="sm:col-span-3 col-span-12 gap-3">
                    <input
                      type="radio"
                      id="pre_employment"
                      name="test_reason"
                      value="pre_employment"
                      checked={test_reason === "pre_employment"}
                      onChange={testReason}
                    />
                    <label htmlFor="pre_employment" className="ml-2">
                      Pre-Employement
                    </label>
                    <br />
                  </div>
                  <div className="sm:col-span-3 col-span-12 gap-3">
                    <input
                      type="radio"
                      id="random"
                      name="test_reason"
                      value="random"
                      checked={test_reason === "random"}
                      onChange={testReason}
                    />
                    <label htmlFor="random" className="ml-2">
                      Random
                    </label>
                    <br />
                  </div>
                  <div className="sm:col-span-3 col-span-12 gap-3">
                    <input
                      type="radio"
                      id="resonable_suspicion"
                      name="test_reason"
                      value="resonable_suspicion"
                      checked={test_reason === "resonable_suspicion"}
                      onChange={testReason}
                    />
                    <label htmlFor="resonable_suspicion" className="ml-2">
                      Resonable Suspicion
                    </label>
                    <br />
                  </div>
                  <div className="sm:col-span-3 col-span-12 gap-3">
                    <input
                      type="radio"
                      id="post_accident"
                      name="test_reason"
                      value="post_accident"
                      checked={test_reason === "post_accident"}
                      onChange={testReason}
                    />
                    <label htmlFor="post_accident" className="ml-2">
                      Post-Accident
                    </label>
                    <br />
                  </div>
                  <div className="sm:col-span-3 col-span-12 gap-3 items-center">
                    <input
                      type="radio"
                      id="promotion"
                      name="test_reason"
                      value="promotion"
                      checked={test_reason === "promotion"}
                      onChange={testReason}
                    />
                    <label htmlFor="promotion" className="ml-2">
                      Promotion
                    </label>
                    <br />
                  </div>
                  <div className="sm:col-span-3 col-span-12 gap-3 items-center">
                    <input
                      type="radio"
                      id="return_duty"
                      name="test_reason"
                      value="return_duty"
                      checked={test_reason === "return_duty"}
                      onChange={testReason}
                    />
                    <label htmlFor="return_duty" className="ml-2">
                      Return to Duty
                    </label>
                    <br />
                  </div>
                  <div className="sm:col-span-3 col-span-12 gap-3 items-center">
                    <input
                      type="radio"
                      id="follow_up"
                      name="test_reason"
                      value="follow_up"
                      checked={test_reason === "follow_up"}
                      onChange={testReason}
                    />
                    <label htmlFor="follow_up" className="ml-2">
                      Follow-up
                    </label>
                    <br />
                  </div>
                  <div className="sm:col-span-3 col-span-12">
                    <input
                      type="text"
                      placeholder="Other Specificity(99)"
                      className="w-full  h-10 mb-2 rounded-lg border-none focus:outline-none bg-[#D7E0EE] p-2"
                      value={reason_remark}
                      onChange={(e) => set_reason_remark(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              {/* f section  */}

              <div className="bg-[#F4F6F9] p-3 mt-5 ">
                <h1 className="text-[#919AA3] font-semibold font-Poppins">
                  F.Drug Test to be Performed
                </h1>
                <div className=" w-full flex items-center p-3 rounded-md mt-5 h-10 mb-2 bg-[#1075EC] text-white ">
                  <h1>DRUG TEST REQUEST</h1>
                </div>
                <div className="grid grid-cols-12 gap-4 mt-4">
                  <div className="sm:col-span-6 col-span-12">
                    <p className="font-medium">Select a pannel name</p>
                    {/* <select
                      id="countries"
                      className="bg-[#E8EEF3] mt-4 border-none focus:outline-none text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                      value={custom_drug_test}
                      onChange={(e) => set_custom_drug_test(e.target.value)}
                    >
                      <option selected className="text-[#F3F7FA] bg-[#9facb6] ">
                        Please select a custom drug test
                      </option>
                      <option value="US">United States</option>
                      <option value="CA">Canada</option>
                      <option value="FR">France</option>
                      <option value="DE">Germany</option>
                    </select> */}
                    <Multiselect
                      options={drugPanelData}
                      displayValue="panelName"
                      placeholder="Please select panel name"
                      onSelect={handleSelect}
                    />
                  </div>
                  <div className="sm:col-span-6 col-span-12">
                    <p className="font-medium">Additional Drug Test</p>
                    {/* <select
                      id="countries"
                      className="bg-[#E8EEF3] mt-4 border-none focus:outline-none text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                      value={additional_drug_test}
                      onChange={(e) => set_additional_drug_test(e.target.value)}
                    >
                      <option selected className="text-[#F3F7FA] bg-[#F3F7FA]">
                        Please select a custom panel name
                      </option>
                      <option value="US">United States</option>
                      <option value="CA">Canada</option>
                      <option value="FR">France</option>
                      <option value="DE">Germany</option>
                    </select> */}
                    <Multiselect
                      options={drugNameData}
                      displayValue="drugName"
                      placeholder="Select additional drug test"
                      onSelect={handleSelectt}
                    />
                  </div>
                </div>
                {/* <textarea
                  name=""
                  id=""
                  rows="3"
                  className="mt-6 w-full bg-[#E8EEF3] p-3 border-none focus:outline-none"
                ></textarea> */}
              </div>

              {/* G section  */}

              <h1 className="text-[#919AA3] mt-4 font-semibold font-Poppins">
                G. Collection Site
              </h1>

              <div className="grid grid-cols-12 mt-4 sm:gap-5 gap-2">
                <div className="sm:col-span-6 col-span-12">
                  <p className="font-medium">Collection Site Name</p>
                  <input
                    type="text"
                    placeholder="Enter Collection Site Name"
                    className="w-full h-11 mb-2 mt-2 rounded-lg bg-[#F3F7FA] p-2 border-none focus:outline-none"
                    value={site_name}
                    onChange={(e) => set_site_name(e.target.value)}
                  />
                </div>
                <div className="sm:col-span-6 col-span-12">
                  <p className="font-medium">Collection Site Phone Number</p>
                  <input
                    type="text"
                    placeholder="Collection Site Phone Number"
                    className="w-full h-11 mb-2 mt-2 rounded-lg bg-[#F3F7FA] p-2 border-none focus:outline-none"
                    value={site_phone}
                    onChange={(e) => set_site_phone(e.target.value)}
                  />
                </div>
                <div className="sm:col-span-6 col-span-12">
                  <p className="font-medium">Address</p>
                  <input
                    type="text"
                    placeholder="Enter Address"
                    className="w-full h-11 mb-2 mt-2 rounded-lg bg-[#F3F7FA] p-2 border-none focus:outline-none"
                    value={site_addr}
                    onChange={(e) => set_site_addr(e.target.value)}
                  />
                </div>
                <div className="sm:col-span-6 col-span-12">
                  <p className="font-medium">Collection Site Fax No.</p>
                  <input
                    type="text"
                    placeholder="Enter Collection Site Fax No."
                    className="w-full h-11 mb-2 mt-2 rounded-lg bg-[#F3F7FA] p-2 border-none focus:outline-none"
                    value={site_fax}
                    onChange={(e) => set_site_fax(e.target.value)}
                  />
                </div>
                <div className="sm:col-span-6 col-span-12">
                  <p className="font-medium">
                    City, Provinance And Postal code
                  </p>
                  <input
                    type="text"
                    placeholder="Enter City, Provinance And Postal Code"
                    className="w-full h-11 mb-2 mt-2 rounded-lg bg-[#F3F7FA] p-2 border-none focus:outline-none"
                    value={site_pin}
                    onChange={(e) => set_site_pin(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {/* step 2 */}
            <p className=" mt-4 poppins-bold text-[18px]">
              Step 2 : Completed By Collector
            </p>
            <div className="flex flex-row gap-4 mt-4">
              <div className="sm:col-span-1 col-span-6">
                <input
                  type="radio"
                  id="blood"
                  value="blood"
                  name="testType"
                  checked={test_type === "blood"}
                  onChange={testType}
                  className="mr-2"
                />
                <label htmlFor="blood" className="poppins-medium">
                  Blood
                </label>
              </div>

              <div className="sm:col-span-1 col-span-6">
                <input
                  type="radio"
                  id="urin"
                  value="urine"
                  name="testType"
                  checked={test_type === "urine"}
                  onChange={testType}
                  className="mr-2"
                />
                <label htmlFor="urin" className="poppins-medium">
                  Urine
                </label>
              </div>

              <div className="sm:col-span-1 col-span-6">
                <input
                  type="radio"
                  id="oral_fluid"
                  value="oral_fluid"
                  name="testType"
                  checked={test_type === "oral_fluid"}
                  onChange={testType}
                  className="mr-2"
                />
                <label htmlFor="oral_fluid" className="poppins-medium">
                  Oral Fluid
                </label>
              </div>
            </div>
            {/* changeable part */}
            {test_type === "blood" && (
              <div className="border border-blue-200 p-4 rounded-lg mt-5">
                <div className=" w-full flex items-center p-3 rounded-md mt-4 h-10 mb-3 bg-[#1075EC] text-white ">
                  <h1 className="poppins-medium">Collections :</h1>
                </div>
                <div className="grid grid-cols-12 mt-4 ">
                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="single"
                      value="single"
                      checked={collection_type === "single"}
                      onChange={collectionType}
                      name="collections"
                    />
                    <label htmlFor="single" className="ml-2 poppins-regular">
                      Single
                    </label>
                  </div>

                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="split"
                      value="split"
                      checked={collection_type === "split"}
                      onChange={collectionType}
                      name="collections"
                    />
                    <label htmlFor="split" className="ml-2 poppins-regular">
                      Split
                    </label>
                  </div>

                  <div className="sm:col-span-3 col-span-12">
                    <input
                      type="radio"
                      id="non-provided"
                      value="non-provided"
                      checked={collection_type === "non-provided"}
                      onChange={collectionType}
                      name="collections"
                    />
                    <label
                      htmlFor="non-provided"
                      className="ml-2 poppins-regular"
                    >
                      Non-Provided
                    </label>
                  </div>

                  <div className="sm:col-span-3 col-span-12">
                    <input
                      type="radio"
                      id="observed"
                      value="observed"
                      checked={collection_type === "observed"}
                      onChange={collectionType}
                      name="collections"
                    />
                    <label htmlFor="observed" className="ml-2 poppins-regular">
                      Observed
                    </label>
                  </div>
                </div>

                <div className="grid grid-cols-12 mt-3 gap-3">
                  <div className="sm:col-span-6 col-span-12">
                    <div className=" col-span-12  w-full flex items-center sm:p-5 p-[7vw] rounded-md mt-3 h-10 mb-3 bg-[#1075EC] text-white poppins-medium ">
                      <h1>Each Device Within Expiration Date ?</h1>
                    </div>
                    <div className="grid grid-cols-12 ">
                      <div className="sm:col-span-2 col-span-6">
                        <input
                          type="radio"
                          id="true"
                          value="true"
                          checked={expiration_date === "true"}
                          onChange={expired}
                          name="expDt"
                        />
                        <label htmlFor="true" className="ml-2 poppins-regular">
                          Yes
                        </label>
                      </div>

                      <div className="sm:col-span-4 col-span-6">
                        <input
                          type="radio"
                          id="false"
                          value="false"
                          checked={expiration_date === "false"}
                          onChange={expired}
                          name="expDt"
                        />
                        <label htmlFor="false" className="ml-2 poppins-regular">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="sm:col-span-6 col-span-12">
                    <div>
                      <div className=" w-full flex items-center sm:p-3 p-[7vw] rounded-md mt-3 h-10 mb-3 bg-[#1075EC] text-white poppins-medium">
                        <h1>Volume Indicator(s) Observed</h1>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="true"
                          value="true"
                          checked={volume_indicator === "true"}
                          onChange={volumeIndicator}
                          name="volumeindicator"
                        />
                        <label htmlFor="true" className="poppins-regular mr-2">
                          Yes
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <textarea
                  name=" "
                  id=""
                  rows="3"
                  placeholder="Remark"
                  className="w-full bg-[#E8EEF3] mt-5 rounded-md p-3 border-none focus:outline-none poppins-regular"
                  value={remark}
                  onChange={(e) => set_remark(e.target.value)}
                ></textarea>
              </div>
            )}
            {test_type === "urine" && (
              <div className="border border-blue-200 p-4 rounded-lg mt-5">
                <div className=" w-full flex items-center p-3 rounded-md mt-4 h-10 mb-3 bg-[#1075EC] text-white ">
                  <h1 className="poppins-medium">Collections :</h1>
                </div>
                <div className="grid grid-cols-12 mt-4 ">
                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="single"
                      value="single"
                      checked={collection_type === "single"}
                      onChange={collectionType}
                      name="collections"
                    />
                    <label htmlFor="single" className="ml-2 poppins-regular">
                      Single
                    </label>
                  </div>

                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="split"
                      value="split"
                      checked={collection_type === "split"}
                      onChange={collectionType}
                      name="collections"
                    />
                    <label htmlFor="split" className="ml-2 poppins-regular">
                      Split
                    </label>
                  </div>

                  <div className="sm:col-span-3 col-span-12">
                    <input
                      type="radio"
                      id="non-provided"
                      value="non-provided"
                      checked={collection_type === "non-provided"}
                      onChange={collectionType}
                      name="collections"
                    />
                    <label
                      htmlFor="non-provided"
                      className="ml-2 poppins-regular"
                    >
                      Non-Provided
                    </label>
                  </div>

                  <div className="sm:col-span-3 col-span-12">
                    <input
                      type="radio"
                      id="observed"
                      value="observed"
                      checked={collection_type === "observed"}
                      onChange={collectionType}
                      name="collections"
                    />
                    <label htmlFor="observed" className="ml-2 poppins-regular">
                      Observed
                    </label>
                  </div>
                </div>
                <div className=" w-full flex items-center p-3 rounded-md mt-5 h-10 mb-3 bg-[#1075EC] text-white ">
                  <h1 className="poppins-medium">
                    Urine : Read Specimen Temperature Within 4 Minutes. Is
                    Temperature Between 32°C And 38°C?
                  </h1>
                </div>
                <div className="grid grid-cols-12 mt-5 gap-3">
                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="yes"
                      checked={urine_temperature === "yes"}
                      value="yes"
                      onChange={urine}
                      name="urinetemp"
                    />
                    <label htmlFor="yes" className="ml-2 poppins-regular">
                      Yes
                    </label>
                  </div>

                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="no"
                      checked={urine_temperature === "no"}
                      value="no"
                      onChange={urine}
                      name="urinetemp"
                    />
                    <label htmlFor="no" className="ml-2 poppins-regular">
                      No
                    </label>
                  </div>

                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="observed"
                      checked={urine_temperature === "observed"}
                      value="observed"
                      onChange={urine}
                      name="urinetemp"
                    />
                    <label htmlFor="observed" className="ml-2 poppins-regular">
                      Observed
                    </label>
                  </div>
                </div>

                <textarea
                  name=" "
                  id=""
                  rows="3"
                  placeholder="Remark"
                  className="w-full bg-[#E8EEF3] mt-5 rounded-md p-3 border-none focus:outline-none poppins-regular"
                  value={remark}
                  onChange={(e) => set_remark(e.target.value)}
                ></textarea>
              </div>
            )}
            {test_type === "oral_fluid" && (
              <div className="border border-blue-200 p-4 rounded-lg mt-5">
                <div className=" w-full flex items-center p-3 rounded-md mt-4 h-10 mb-3 bg-[#1075EC] text-white ">
                  <h1 className="poppins-medium">Collections :</h1>
                </div>
                <div className="grid grid-cols-12 mt-4 ">
                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="single"
                      value="single"
                      checked={collection_type === "single"}
                      onChange={collectionType}
                      name="collections"
                    />
                    <label htmlFor="single" className="ml-2 poppins-regular">
                      Single
                    </label>
                  </div>

                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="split"
                      value="split"
                      checked={collection_type === "split"}
                      onChange={collectionType}
                      name="collections"
                    />
                    <label htmlFor="split" className="ml-2 poppins-regular">
                      Split
                    </label>
                  </div>

                  <div className="sm:col-span-3 col-span-12">
                    <input
                      type="radio"
                      id="non-provided"
                      value="non-provided"
                      checked={collection_type === "non-provided"}
                      onChange={collectionType}
                      name="collections"
                    />
                    <label
                      htmlFor="non-provided"
                      className="ml-2 poppins-regular"
                    >
                      Non-Provided (Enter Remark)
                    </label>
                  </div>

                  <div className="sm:col-span-3 col-span-12">
                    <input
                      type="radio"
                      id="observed"
                      value="observed"
                      checked={collection_type === "observed"}
                      onChange={collectionType}
                      name="collections"
                    />
                    <label htmlFor="observed" className="ml-2 poppins-regular">
                      Observed (Enter Remark)
                    </label>
                  </div>
                </div>
                <div className=" w-full flex items-center p-3 rounded-md mt-5 h-10 mb-3 bg-[#1075EC] text-white ">
                  <h1 className="poppins-medium">Oral Fluid: Split Type:</h1>
                </div>
                <div className="grid grid-cols-12 mt-5 gap-3">
                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="Serial"
                      value="Serial"
                      checked={split_type === "Serial"}
                      onChange={splitType}
                      name="oralfluid"
                    />
                    <label htmlFor="Serial" className="ml-2 poppins-regular">
                      Serial
                    </label>
                  </div>

                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="concurrent"
                      value="concurrent"
                      checked={split_type === "concurrent"}
                      onChange={splitType}
                      name="oralfluid"
                    />
                    <label
                      htmlFor="concurrent"
                      className="ml-2 poppins-regular"
                    >
                      Concurrent
                    </label>
                  </div>

                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="subdivided"
                      value="subdivided"
                      checked={split_type === "subdivided"}
                      onChange={splitType}
                      name="oralfluid"
                    />
                    <label
                      htmlFor="subdivided"
                      className="ml-2 poppins-regular"
                    >
                      Subdivided
                    </label>
                  </div>
                </div>
                <div className="grid grid-cols-12 mt-3 gap-3">
                  <div className="sm:col-span-6 col-span-12">
                    <div className=" col-span-12  w-full flex items-center sm:p-5 p-[7vw] rounded-md mt-3 h-10 mb-3 bg-[#1075EC] text-white poppins-medium ">
                      <h1>Each Device Within Expiration Date ?</h1>
                    </div>
                    <div className="grid grid-cols-12 ">
                      <div className="sm:col-span-2 col-span-6">
                        <input
                          type="radio"
                          id="true"
                          value="true"
                          checked={expiration_date === "true"}
                          onChange={expired}
                          name="expDt"
                        />
                        <label htmlFor="true" className="ml-2 poppins-regular">
                          Yes
                        </label>
                      </div>

                      <div className="sm:col-span-4 col-span-6">
                        <input
                          type="radio"
                          id="false"
                          value="false"
                          checked={expiration_date === "false"}
                          onChange={expired}
                          name="expDt"
                        />
                        <label htmlFor="false" className="ml-2 poppins-regular">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="sm:col-span-6 col-span-12">
                    <div>
                      <div className=" w-full flex items-center sm:p-3 p-[7vw] rounded-md mt-3 h-10 mb-3 bg-[#1075EC] text-white poppins-medium">
                        <h1>Volume Indicator(s) Observed</h1>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="true"
                          value="true"
                          checked={volume_indicator === "true"}
                          onChange={volumeIndicator}
                          name="volumeindicator"
                        />
                        <label htmlFor="true" className="poppins-regular mr-2">
                          Yes
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <textarea
                  name=" "
                  id=""
                  rows="3"
                  placeholder="Remark"
                  className="w-full bg-[#E8EEF3] mt-5 rounded-md p-3 border-none focus:outline-none poppins-regular"
                  value={remark}
                  onChange={(e) => set_remark(e.target.value)}
                ></textarea>
              </div>
            )}

            <div className="grid grid-cols-1 mt-5">
              <p className="font-Poppins font-medium mb-4">
                Step 3 : Collector Affixes Bottle Seal(s) to Bottle(s).
                Collector Dates Seal(s). Donor Initials Seal(s). Donor Completes
                Step5.
              </p>
              <p className="font-Poppins font-medium text-[18px] ">
                Step 4 : Chain of Custody - Initiated By Collector And Completed
                By Laboratory
              </p>
            </div>

            {/* step-4 */}
            <div className="border border-blue-200 p-4 rounded-lg mt-5">
              <p className="grid grid-cols-1 w-full mt-3">
                i certify that the specimen given to me by the donor identified
                in the certification section on copy 1 of this form was
                collected,labeled,sealed and released to the Delivery Service
                noted in accordance with application requirement
              </p>
              <div className="mt-10 grid grid-cols-12 gap-5 ">
                <div className="sm:col-span-5 col-span-12">
                  <div className="grid grid-cols-1">
                    <div>
                      <b>Signature Of The Collector</b>
                      <div className="relative">
                        <SignatureCanvas
                          ref={collectorSignRef}
                          penColor="black"
                          canvasProps={{
                            className: "signature-canvas",
                            width: 300,
                            height: 100,
                          }}
                          className="border border-solid border-black mb-4"
                        />
                        {/* Draw the line below the signature */}
                        <div className="absolute bottom-0 left-0 w-[17vw] border-b border-black"></div>
                      </div>
                      <div className="w-[17vw] flex justify-end mt-2 items-center gap-3">
                        <button
                          onClick={handleCollectorSignClear}
                          className="bg-[#E8EEF3] px-2 py-1 rounded poppins-medium hover:opacity-90"
                        >
                          Clear
                        </button>
                        <button
                          onClick={handleCollectorSignSave}
                          className="bg-theme-blue text-white rounded px-2 py-1 poppins-medium hover:opacity-90"
                          style={{ background: "#1db6fa" }}
                        >
                          Save
                        </button>
                      </div>
                    </div>

                    <p className="mt-20 font-medium">
                      Collector's Name (First,Middle,Last)
                    </p>
                    <input
                      type="text"
                      placeholder="Enter Full name"
                      className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                      value={collector_name}
                      onChange={(e) => set_collector_name(e.target.value)}
                    />
                  </div>
                  <div className="grid grid-cols-1">
                    <div>
                      <p className="font-medium">Select Date</p>
                      <input
                        type="date"
                        // value={date}
                        // onChange={(e) => setDate(e.target.value)}
                        className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                        value={collection_date}
                        onChange={(e) => set_collection_date(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-12 gap-4">
                    <div className="sm:col-span-6 col-span-12">
                      <p className="font-medium">Time of Collection</p>
                      <input
                        type="time"
                        // value={time}
                        // onChange={(e) => setTime(e.target.value)}
                        className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 mr-2 border-none focus:outline-none"
                        value={collection_time}
                        onChange={(e) => set_collection_time(e.target.value)}
                      />
                    </div>
                    <div
                      onClick={handleStep4Date}
                      className="sm:col-span-6 mt-3 col-span-12"
                    >
                      <p className="mt-[-8px] font-medium ">Choose Default</p>
                      <div className="mt-2.5 col-span-12 w-full">
                        <button
                          className="p-1.5 pl-7 pr-7 rounded-lg w-full bg-blue-600 font-normal text-white"
                          style={{ background: "#1db6fa" }}
                        >
                          Today's Date & Time
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-1 sm:block hidden">
                  <div class="w-1 h-[500px] border-l-5 border-l-2 border-sienna  border-dotted"></div>
                </div>
                <div className="sm:col-span-5 col-span-12 sm:ml-[-4vw]">
                  <h1 className="font-Poppins font-medium text-[19px] mb-4">
                    Specimen Bottle(s) Release To:
                  </h1>
                  <div className="mt-5 flex mb-3">
                    <div className="flex gap-3">
                      <input
                        type="radio"
                        id="UPS"
                        name="specimenbottle"
                        value="UPS"
                        checked={released_to === "UPS"}
                        onChange={releasedTo}
                      />
                      <label htmlFor="UPS">UPS</label>
                      <br />
                    </div>
                    <div className="flex gap-3">
                      <input
                        type="radio"
                        id="USPS"
                        name="specimenbottle"
                        value="USPS"
                        checked={released_to === "USPS"}
                        onChange={releasedTo}
                      />
                      <label htmlFor="USPS">USPS</label>
                      <br />
                    </div>
                    <div className="flex gap-3">
                      <input
                        type="radio"
                        id="FEDEX"
                        name="specimenbottle"
                        value="FEDEX"
                        checked={released_to === "FEDEX"}
                        onChange={releasedTo}
                      />
                      <label htmlFor="FEDEX">FEDEX</label>
                    </div>
                  </div>
                  <input
                    type="text"
                    placeholder="Other's"
                    className="sm:w-[38vw] w-full h-10 mt-5 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                    value={otherCourier}
                    onChange={(e) => setOtherCourier(e.target.value)}
                  />
                  <h1 className="font-medium" style={{ marginTop: "2rem" }}>
                    Name of delivery service transferring specimen to lab
                  </h1>
                </div>
              </div>

              <div className="dotted-line mt-7 text-gray-300"></div>

              <h2 className=" mt-2 sm:absolute relative  font-Poppins font-medium text-[19px]">
                Recieved At Lab :
              </h2>
              <div className="mt-16 grid grid-cols-12 sm:gap-2 gap-5">
                <div className="sm:col-span-5 col-span-12">
                  <div className="grid grid-cols-1">
                    <div>
                      <b>Signature Of Accessioner</b>
                      <div className="relative">
                        <SignatureCanvas
                          ref={collectionSiteSignRef}
                          penColor="black"
                          canvasProps={{
                            className: "signature-canvas",
                            width: 300,
                            height: 100,
                          }}
                          className="border border-solid border-black mb-4"
                        />
                        {/* Draw the line below the signature */}
                        <div className="absolute bottom-0 left-0 w-[17vw] border-b border-black"></div>
                      </div>
                      <div className="w-[17vw] flex justify-end mt-2 items-center gap-3">
                        <button
                          onClick={handleCollectionSiteSignClear}
                          className="bg-[#E8EEF3] px-2 py-1 rounded poppins-medium hover:opacity-90"
                        >
                          Clear
                        </button>
                        <button
                          onClick={handleCollectionSiteSignSave}
                          className="bg-theme-blue text-white rounded px-2 py-1 poppins-medium hover:opacity-90"
                          style={{ background: "#1db6fa" }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    <p className="mt-10 font-medium">
                      Accessiner's Name(First-Middle-Last)
                    </p>
                    <input
                      type="text"
                      placeholder="Enter Full name"
                      className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                      value={r_collector_name}
                      onChange={(e) => set_r_collector_name(e.target.value)}
                    />
                  </div>

                  <div className="grid grid-cols-12 gap-4">
                    <div className="sm:col-span-6 col-span-12">
                      <p className="font-medium">Select Date</p>
                      <input
                        type="date"
                        // value={accessinerdate}
                        // onChange={(e) => setTime(e.target.value)}
                        className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 mr-2 border-none focus:outline-none"
                        value={r_date}
                        onChange={(e) => set_r_date(e.target.value)}
                      />
                    </div>
                    <div
                      onClick={handleStepLabDate}
                      className="sm:col-span-6 mt-3 col-span-12"
                    >
                      <p className="mt-[-8px] font-medium ">Choose Default</p>
                      <div className="mt-2.5 col-span-12 w-full">
                        <button
                          className="p-1.5 pl-7 pr-7 rounded-lg w-full bg-blue-600 font-normal text-white"
                          style={{ background: "#1db6fa" }}
                        >
                          Today's Date
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <div className="grid grid-cols-12 gap-4">
                      <div className="col-span-12">
                        <p className="font-medium">Select Date</p>
                        <input
                          type="date"
                          value={accessinerdate}
                          onChange={(e) => setDate(e.target.value)}
                          className="w-full sm:[12vw] h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                        />
                      </div>
                      <div
                        onClick={handleAccessinerdate}
                        className="sm:col-span-6 mt-3 col-span-12"
                      >
                        <p className="mt-[-8px] font-medium ">Choose Default</p>
                        <div className="mt-2.5 col-span-12 w-full">
                          <button className="p-1.5 pl-7 pr-7 rounded-lg w-full bg-blue-600 font-normal text-white">
                            Today's date
                          </button>
                        </div>
                      </div>
                    </div> */}
                </div>
                <div className="col-span-1"></div>
                <div className="sm:col-span-6 col-span-12 sm:mt-[-8vh] sm:ml-[-4vw]">
                  <h1 className="font-Poppins font-medium text-[19px] mb-4">
                    Primary Specimen Bottle Seal Intact
                  </h1>
                  <div className="mt-5 flex gap-5 mb-3">
                    <div className="flex gap-3">
                      <input
                        type="radio"
                        id="true"
                        name="prmspice"
                        value="true"
                        checked={r_seal === "true"}
                        onChange={sealed}
                      />
                      <label htmlFor="true">Yes</label>
                    </div>
                    <div className="flex gap-3">
                      <input
                        type="radio"
                        id="false"
                        name="prmspice"
                        value="false"
                        checked={r_seal === "false"}
                        onChange={sealed}
                      />
                      <label htmlFor="false">No</label>
                    </div>
                  </div>
                  <textarea
                    name=""
                    id=""
                    rows="3"
                    placeholder="Remark"
                    className="w-full p-3 mt-3 rounded-lg bg-[#ECF1F6]  border-none focus:outline-none relative"
                    value={r_remark}
                    onChange={(e) => set_r_remark(e.target.value)}
                  ></textarea>
                  <h1 className="font-medium mt-5">
                    Specimen Bottles Released To :
                  </h1>
                  <input
                    type="text"
                    placeholder="Enter Other's"
                    className="w-full h-10 mt-5 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                    value={r_released_to}
                    onChange={(e) => set_r_released_to(e.target.value)}
                  />
                </div>
              </div>

              <div className="dotted-line mt-9 mb-4"></div>
              <h1 className="w-full mb-3  font-medium">
                Primary/Single Specimen Device Expiration Date :
              </h1>
              <div className="grid grid-cols-12 gap-6">
                <div className="sm:col-span-6 grid grid-cols-12 col-span-12 gap-2">
                  <div className="sm:col-span-6 col-span-11">
                    <p className="font-medium">Select Date</p>
                    <input
                      type="date"
                      // value={singlespecimendate}
                      // onChange={(e) => setTime(e.target.value)}
                      className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 mr-2 border-none focus:outline-none"
                      value={primary_specimen_expiration}
                      onChange={(e) =>
                        set_primary_specimen_expiration(e.target.value)
                      }
                    />
                  </div>
                  <div
                    onClick={handlePrimaryExpiration}
                    className="sm:col-span-6 mt-3 col-span-11"
                  >
                    <p className="mt-[-8px] font-medium ">Choose Default</p>
                    <div className="mt-2.5 sm:col-span-6 col-span-12">
                      <button
                        className="p-1.5 pl-7 pr-7 rounded-lg w-full bg-blue-600 font-normal text-white"
                        style={{ background: "#1db6fa" }}
                      >
                        Today's Date
                      </button>
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-6 col-span-12">
                  <div>
                    <h1 className="w-full sm:mt-[-6vh] font-medium">
                      Split Specimen Device Expiration Date :
                    </h1>
                  </div>
                  <div className="grid grid-cols-12 gap-5 mt-3">
                    <div className="sm:col-span-6 col-span-11">
                      <p className="font-medium">Select Date</p>
                      <input
                        type="date"
                        // value={splitspecimendate}
                        // onChange={(e) => setDate(e.target.value)}
                        className="w-full relative h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                        value={split_specimen_expiration}
                        onChange={(e) =>
                          set_split_specimen_expiration(e.target.value)
                        }
                      />
                    </div>

                    <div
                      onClick={handleSplitExpDate}
                      className="sm:col-span-6 sm:mt-6 col-span-11  items-center"
                    >
                      <p className="sm:mt-[-3.5vh] font-medium  ">
                        Choose Default
                      </p>
                      <div className="mt-2.5 sm:col-span-6 col-span-12">
                        <button
                          className="p-1.5 pl-7 pr-7 rounded-lg w-full  bg-blue-600 font-normal text-white"
                          style={{ background: "#1db6fa" }}
                        >
                          Today's Date
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" mt-7 mb-7 font-medium text-[20px] font-Poppins">
              <h1>Step 5: Complete By Donor</h1>
            </div>

            {/* step-5 */}
            <div className="border border-blue-200 p-4 rounded-lg">
              <h1 className="mb-6 grid grid-cols-1 mt-1">
                I certify that I provided my specimen to the collector; that I
                have not adulterated it in any manner; each specimen bottle used
                was sealed with a tamper-evident seal in my presence; and that
                the information and numbers provided on this form and on the
                label affixed to each specimen bottle is correct; and allow my
                sample and data to leave the country, if required.
              </h1>
              <div className="grid grid-cols-1">
                <b>Signature of Donor</b>
                <div className="relative">
                  <SignatureCanvas
                    ref={accessionerSignRef}
                    penColor="black"
                    canvasProps={{
                      className: "signature-canvas",
                      width: 300,
                      height: 100,
                    }}
                    className="border border-solid border-black mb-4"
                  />
                  {/* Draw the line below the signature */}
                  <div className="absolute bottom-0 left-0 w-[17vw] border-b border-black"></div>
                </div>
                <div className="w-[17vw] flex justify-end mt-2 items-center gap-3">
                  <button
                    onClick={handleAccessionerSignClear}
                    className="bg-[#E8EEF3] px-2 py-1 rounded poppins-medium hover:opacity-90"
                  >
                    Clear
                  </button>
                  <button
                    onClick={handleAccessionerSignSave}
                    className="bg-theme-blue text-white rounded px-2 py-1 poppins-medium hover:opacity-90"
                    style={{ background: "#1db6fa" }}
                  >
                    Save
                  </button>
                </div>
              </div>
              <div className="grid grid-cols-12 mt-5 sm:gap-7 gap-3">
                <div className="sm:col-span-3 col-span-12">
                  <p className=" font-medium mb-2">Daytime phone number </p>
                  <input
                    type="text"
                    placeholder="Enter day time phone number"
                    className="w-full h-10 mb-2 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                    value={donor_day_no}
                    onChange={(e) => set_donor_day_no(e.target.value)}
                  />
                </div>
                <div className="sm:col-span-3 col-span-12">
                  <p className=" font-medium mb-2"> Evening phone number</p>
                  <input
                    type="text"
                    placeholder="Evening phone number"
                    className="w-full h-10 mb-2 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                    value={donor_eve_no}
                    onChange={(e) => set_donor_eve_no(e.target.value)}
                  />
                </div>
                <div className="sm:col-span-3 col-span-12">
                  <p className=" font-medium mb-2">Donor's First name</p>
                  <input
                    type="text"
                    placeholder="Donor's First name"
                    className="w-full h-10 mb-2 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                    value={donor_first_name}
                    onChange={(e) => set_donor_first_name}
                  />
                </div>
                <div className="sm:col-span-3 col-span-12">
                  <p className=" font-medium mb-2"> Donor Last Name </p>
                  <input
                    type="text"
                    placeholder="Donor last name"
                    className="w-full h-10 mb-2 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                    value={donor_last_name}
                    onChange={(e) => set_donor_last_name(e.target.value)}
                  />
                </div>
                <div className="sm:col-span-3 col-span-12">
                  <p className=" font-medium mb-2">D.O.B</p>
                  <input
                    type="date"
                    // value={donordate}
                    // onChange={(e) => setDate(e.target.value)}
                    placeholder="Select Date"
                    className="w-full h-10 mb-4  rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                    value={donor_dob}
                    onChange={(e) => set_donor_dob(e.target.value)}
                  />
                </div>
                <div className="sm:col-span-3 col-span-12">
                  <p className=" font-medium mb-2">Date</p>
                  <input
                    type="date"
                    placeholder="select date"
                    className="w-full h-10 mb-4  rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                    value={donor_date}
                    onChange={(e) => set_donor_date(e.target.value)}
                  />
                </div>
                {/* <div
                  onClick={handleDonordate}
                  className="sm:col-span-3 col-span-12"
                >
                  <p className=" font-medium mb-2">Choose Default</p>
                  <button className="p-1.5 pl-5 pr-5 rounded-lg w-full bg-blue-600 font-normal text-white">
                    Today
                  </button>
                </div> */}
              </div>
            </div>

            {/* step 5 A */}

            <div className=" mt-7 mb-7 font-medium text-[20px] font-Poppins">
              {" "}
              <h1>
                Step 5A : Primary Specimen Report - Completed By Test Facility
              </h1>
            </div>
            <div className="border border-blue-200 p-4 rounded-lg">
              <div className="grid grid-cols-12 ">
                <div className="sm:col-span-1 col-span-6">
                  <input
                    type="radio"
                    id="positive"
                    name="step5a"
                    value="positive"
                    checked={report_result === "positive"}
                    onChange={reportResult}
                  />
                  <label htmlFor="positive" className="ml-2">
                    POSITIVE
                  </label>
                  <br />
                </div>
                <div className="sm:col-span-1 col-span-6">
                  <input
                    type="radio"
                    id="negative"
                    name="step5a"
                    value="negative"
                    checked={report_result === "negative"}
                    onChange={reportResult}
                  />
                  <label htmlFor="negative" className="ml-2">
                    NEGATIVE
                  </label>
                  <br />
                </div>
                <div className="sm:col-span-1 col-span-6 sm:ml-3">
                  <input
                    type="radio"
                    id="dilute"
                    name="step5a"
                    value="dilute"
                    checked={report_result === "dilute"}
                    onChange={reportResult}
                  />
                  <label htmlFor="dilute" className="ml-2">
                    DILUTE
                  </label>
                </div>
                <div className="sm:col-span-3 col-span-12 ">
                  <input
                    type="radio"
                    id="rejected"
                    name="step5a"
                    value="rejected"
                    checked={report_result === "rejected"}
                    onChange={reportResult}
                  />
                  <label htmlFor="rejected" className="ml-2">
                    REJECTED FOR TESTING
                  </label>
                </div>
                <div className="sm:col-span-2 col-span-12 sm:ml-[-5vw]">
                  <input
                    type="radio"
                    id="substituted"
                    name="step5a"
                    value="substituted"
                    checked={report_result === "substituted"}
                    onChange={reportResult}
                  />
                  <label htmlFor="substituted" className="ml-1">
                    SUBSTITUTED
                  </label>
                </div>
                <div className="sm:col-span-2 col-span-12 sm:ml-[-8vw]">
                  <input
                    type="radio"
                    id="invalid"
                    name="step5a"
                    value="invalid"
                    checked={report_result === "invalid"}
                    onChange={reportResult}
                  />
                  <label htmlFor="invalid" className="ml-2">
                    INVALID RESULT
                  </label>
                </div>
              </div>

              <h1 className="mt-6 font-medium">Remark for POSITIVE</h1>
              <textarea
                name=""
                id=""
                rows="3"
                placeholder="Remarks"
                className="mt-3 w-full rounded-lg bg-[#ECF1F6]  p-3 grid grid-cols-1 border-none focus:outline-none"
                value={remark_for_positive}
                onChange={(e) => set_remark_for_positive(e.target.value)}
              ></textarea>
              <h1 className="mt-4 font-medium">Analyte(s) in ng/ML</h1>
              <textarea
                name=""
                id=""
                rows="3"
                placeholder="Remarks"
                className="mt-3 w-full rounded-lg bg-[#ECF1F6]  p-3 grid grid-cols-1 border-none focus:outline-none"
                value={p_remark}
                onChange={(e) => set_p_remark(e.target.value)}
              ></textarea>
              <h1 className="mt-4 font-medium">
                Test Facility (if differnt from above)
              </h1>
              <input
                type="text"
                placeholder="Enter test facility"
                className="w-full h-11 mb-2 mt-4 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                value={p_facility}
                onChange={(e) => set_p_facility(e.target.value)}
              />

              <h1 className="mt-5">
                I certify that the specimen identified on this form was examined
                upon receipt, handled using chain of custody procedures,
                analyzed, and reported in accordance with applicable federal
                requirements.
              </h1>
              <div className="grid grid-cols-12 mt-11">
                <div className="sm:col-span-4 col-span-12">
                  <b>
                    Signature of Certifying Technician
                    <br /> / Scientist
                  </b>
                  <div className="relative">
                    <SignatureCanvas
                      ref={certifyingSignRef}
                      penColor="black"
                      canvasProps={{
                        className: "signature-canvas",
                        width: 300,
                        height: 100,
                      }}
                      className="border border-solid border-black mb-4"
                    />
                    {/* Draw the line below the signature */}
                    <div className="absolute bottom-0 left-0 w-[17vw] border-b border-black"></div>
                  </div>
                  <div className="w-[17vw] flex justify-end mt-2 items-center gap-3">
                    <button
                      onClick={handleCertifyingSignClear}
                      className="bg-[#E8EEF3] px-2 py-1 rounded poppins-medium hover:opacity-90"
                    >
                      Clear
                    </button>
                    <button
                      onClick={handleCertifyingSignSave}
                      className="bg-theme-blue text-white rounded px-2 py-1 poppins-medium hover:opacity-90"
                      style={{ background: "#1db6fa" }}
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div className="sm:col-span-8 col-span-12 grid grid-cols-12 gap-4 mt-14 sm:ml-[-4vw]">
                  <div className="sm:col-span-4 mt-[-3vh] col-span-12">
                    <p className="font-medium">
                      Certifying Technician/Scientist's Name
                    </p>
                    <input
                      type="text"
                      placeholder="Enter name"
                      className="w-full h-10 mt-2 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                      value={scientist_name}
                      onChange={(e) => set_scientist_name(e.target.value)}
                    />
                  </div>

                  <div className="sm:col-span-4 col-span-12">
                    <p className="font-medium">Select Date</p>
                    <input
                      type="date"
                      // value={techniciandate}
                      // onChange={(e) => setDate(e.target.value)}
                      className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                      value={p_date}
                      onChange={(e) => set_p_date(e.target.value)}
                    />
                  </div>

                  <div
                    onClick={handleTechniDate}
                    className="sm:col-span-4 mt-3 col-span-12"
                  >
                    <p className="mt-[-8px] font-medium ">Choose Default</p>
                    <div className="mt-2.5 col-span-12 w-full">
                      <button
                        className="p-1.5 pl-7 pr-7 rounded-lg w-full bg-blue-600 font-normal text-white"
                        style={{ background: "#1db6fa" }}
                      >
                        Today's Date
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* step-5B */}
            <div className=" mt-7 mb-7 font-medium text-[20px] font-Poppins">
              {" "}
              <h1>Step 5B : Completed By Split Testing Laboratory</h1>
            </div>

            <div className="border border-blue-200 p-4 rounded-lg">
              <div className="grid grid-cols-12 mt-6">
                <div className="sm:col-span-5 col-span-12">
                  <div className="relative sm:mt-80 mt-5">
                    {/* <h1 className=" mb-2 mt-2 font-medium">
                      Name of Delivery Service
                    </h1>
                    <input
                      type="text"
                      placeholder="Enter delivery service"
                      className="w-full h-10 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                    /> */}
                    <h1 className=" mb-2 font-medium">
                      Name of Delivery Service
                    </h1>
                    <input
                      type="text"
                      placeholder="Enter delivery service"
                      className="w-full h-10 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                      value={delivery_service_name}
                      onChange={(e) =>
                        set_delivery_service_name(e.target.value)
                      }
                    />
                  </div>
                </div>
                <div>
                  <div className="w-1 sm:col-span-1 sm:block hidden   sm:h-[500px] border-l-5 border-l-2 border-sienna sm:ml-7 border-dotted"></div>
                </div>
                <div className="sm:col-span-6 col-span-12 sm:ml-[-3vw]">
                  <div className="mt-5 grid grid-cols-12 gap-4 mb-3">
                    <div className="sm:col-span-3 col-span-12">
                      <input
                        type="radio"
                        id="true"
                        name="deliverysvc"
                        value="true"
                        checked={reconfirm === "true"}
                        onChange={reconfirmm}
                      />
                      <label htmlFor="true" className="ml-2">
                        RECONFIRMED
                      </label>
                      <br />
                    </div>
                    <div className="sm:col-span-4 col-span-12">
                      <input
                        type="radio"
                        id="false"
                        name="deliverysvc"
                        value="false"
                        checked={reconfirm === "false"}
                        onChange={reconfirmm}
                      />
                      <label htmlFor="false" className="ml-2">
                        FAILED TO RECONFIRM
                      </label>
                      <br />
                    </div>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="w-full h-11 mb-4 mt-3 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                    />
                  </div>
                  <h1 className="mb-2 mt-3">
                    I certify that the specimen identified on this form was
                    examined upon receipt, handled using chain of custody
                    procedures, analyzed, and reported in accordance with
                    applicable federal requirements.
                  </h1>
                  <div className="mb-2 mt-5">
                    <b className="font-medium">
                      Signature of Certifying Technician/Scientist
                    </b>
                    <div className="relative">
                      <SignatureCanvas
                        ref={certifyingSignRef}
                        penColor="black"
                        canvasProps={{
                          className: "signature-canvas",
                          width: 300,
                          height: 100,
                        }}
                        className="border border-solid border-black mb-4"
                      />
                      {/* Draw the line below the signature */}
                      <div className="absolute bottom-0 left-0 w-[17vw] border-b border-black"></div>
                    </div>
                    <div className="w-[17vw] flex justify-end mt-2 items-center gap-3">
                      <button
                        onClick={handleCertifyingSignClear}
                        className="bg-[#E8EEF3] px-2 py-1 rounded poppins-medium hover:opacity-90"
                      >
                        Clear
                      </button>
                      <button
                        onClick={handleCertifyingSignSave}
                        className="bg-theme-blue text-white rounded px-2 py-1 poppins-medium hover:opacity-90"
                        style={{ background: "#1db6fa" }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                  <h1 className="mb-2 mt-7 font-medium">
                    Certifying Technician/Scientist's Name
                  </h1>
                  <input
                    type="text"
                    placeholder="Enter technician name"
                    className="w-full h-10 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                    value={s_scientist_name}
                    onChange={(e) => set_s_scientist_name(e.target.value)}
                  />
                  <div className="grid grid-cols-12 gap-5 mt-3">
                    <div className="sm:col-span-6 col-span-12">
                      <p className="font-medium">Select Date</p>
                      <input
                        type="date"
                        // value={scientisitdate}
                        // onChange={(e) => setDate(e.target.value)}
                        className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                        value={s_date}
                        onChange={(e) => set_s_date(e.target.value)}
                      />
                    </div>

                    <div
                      onClick={handleSplitTestDate}
                      className="sm:col-span-6 sm:mt-6 col-span-12  items-center"
                    >
                      <p className="sm:mt-[-3.5vh] font-medium  ">
                        Choose Default
                      </p>
                      <div className="mt-2.5 sm:col-span-6 col-span-12">
                        <button
                          className="p-1.5 pl-7 pr-7 rounded-lg w-full  bg-blue-600 font-normal text-white"
                          style={{ background: "#1db6fa" }}
                        >
                          Today's Date
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* step- 6 */}

            <div className=" mt-7 mb-7 font-medium text-[20px] font-Poppins">
              {" "}
              <h1>
                Step 6 : Completed by Medical Review Officer - Primary Specimen
              </h1>
            </div>
            <div className="border border-blue-200 p-4 rounded-lg">
              <h1>
                In accordance with applicable requirements, my
                determination/verification is:
              </h1>
              <div className="mt-8 grid grid-cols-12 gap-5 mb-3">
                <div className="sm:col-span-2 col-span-12">
                  <input
                    type="radio"
                    id="negative"
                    name="dtmnvfn"
                    value="negative"
                    checked={m_report_result === "negative"}
                    onChange={reportResultt}
                  />
                  <label htmlFor="negative" className="ml-3">
                    NEGATIVE
                  </label>
                  <br />
                </div>
                <div className="sm:col-span-2 col-span-12">
                  <input
                    type="radio"
                    id="positive"
                    name="dtmnvfn"
                    value="positive"
                    checked={m_report_result === "positive"}
                    onChange={reportResultt}
                  />
                  <label htmlFor="positive" className="ml-3">
                    POSITIVE
                  </label>
                  <br />
                </div>
                <div className="sm:col-span-2 col-span-12">
                  <input
                    type="radio"
                    id="cancelled"
                    name="dtmnvfn"
                    value="cancelled"
                    checked={m_report_result === "cancelled"}
                    onChange={reportResultt}
                  />
                  <label htmlFor="cancelled" className="ml-3">
                    TEST CANCELLED
                  </label>
                </div>
                <div className="sm:col-span-3 col-span-12">
                  <input
                    type="radio"
                    id="refused"
                    name="dtmnvfn"
                    value="refused"
                    checked={m_report_result === "refused"}
                    onChange={reportResultt}
                  />
                  <label htmlFor="refused" className="ml-3">
                    REFUSED
                  </label>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4">
                <div className="sm:col-span-2 col-span-12">
                  <input
                    type="radio"
                    id="dilute"
                    name="dtmnvfn"
                    value="dilute"
                    checked={m_report_result === "dilute"}
                    onChange={reportResultt}
                  />
                  <label htmlFor="dilute" className="ml-3">
                    DILUTE
                  </label>
                </div>
                <div className="sm:col-span-2 col-span-12">
                  <input
                    type="radio"
                    id="substituted"
                    name="dtmnvfn"
                    value="substituted"
                    checked={m_report_result === "substituted"}
                    onChange={reportResultt}
                  />
                  <label htmlFor="substituted" className="ml-3">
                    SUBSTITUTED
                  </label>
                </div>
                <div className="sm:col-span-2 col-span-12">
                  <input
                    type="radio"
                    id="adultrated"
                    name="dtmnvfn"
                    value="adultrated"
                    checked={m_report_result === "adultrated"}
                    onChange={reportResultt}
                  />
                  <label htmlFor="adultrated" className="ml-3">
                    ADULTRATED
                  </label>
                </div>
                <div className="sm:col-span-2 col-span-12">
                  <input
                    type="radio"
                    id="other"
                    name="dtmnvfn"
                    value="other"
                    checked={m_report_result === "other"}
                    onChange={reportResultt}
                  />
                  <label htmlFor="other" className="ml-3">
                    OTHER
                  </label>
                </div>
              </div>
              <textarea
                name=""
                id=""
                rows="2"
                placeholder="Remark for positive"
                className="mt-8 w-full rounded-lg bg-[#ECF1F6]  p-3 mb-3 border-none focus:outline-none grid grid-cols-1"
                value={m_remark_for_positive}
                onChange={(e) => set_m_remark_for_positive(e.target.value)}
                disabled={
                  m_report_result === "negative" ||
                  m_report_result === "cancelled" ||
                  m_report_result === "refused" ||
                  m_report_result === "dilute" ||
                  m_report_result === "substituted" ||
                  m_report_result === "adultrated" ||
                  m_report_result === "other"
                }
              ></textarea>
              <textarea
                name=""
                id=""
                rows="3"
                placeholder="Remarks"
                className=" w-full rounded-lg bg-[#ECF1F6]  p-3 mb-3 border-none focus:outline-none grid grid-cols-1 mt-4"
                value={m_remark}
                onChange={(e) => set_m_remark(e.target.value)}
              ></textarea>

              <div className="mt-5 grid grid-cols-12 sm:gap-6 gap-2">
                <div className="sm:col-span-4 col-span-12">
                  <b>Signature of Medical Review Officer</b>
                  <div className="relative mt-3">
                    <SignatureCanvas
                      ref={medicalReviewSignRef}
                      penColor="black"
                      canvasProps={{
                        className: "signature-canvas",
                        width: 300,
                        height: 100,
                      }}
                      className="border border-solid border-black mb-4 "
                    />
                    {/* Draw the line below the signature */}
                    <div className="absolute bottom-0 left-0 w-[17vw] border-b border-black"></div>
                  </div>
                  <div className="w-[17vw] flex justify-end mt-2 items-center gap-3">
                    <button
                      onClick={handleMedicalReviewSignClear}
                      className="bg-[#E8EEF3] px-2 py-1 rounded poppins-medium hover:opacity-90"
                    >
                      Clear
                    </button>
                    <button
                      onClick={handleMedicalReviewSignSave}
                      className="bg-theme-blue text-white rounded px-2 py-1 poppins-medium hover:opacity-90"
                      style={{ background: "#1db6fa" }}
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div className="sm:col-span-8 col-span-12 grid grid-cols-12 gap-4 mt-14 sm:ml-[-4vw]">
                  <div className="sm:col-span-4 col-span-12">
                    <h className="font-medium">Medical Review Officer's name</h>
                    <input
                      type="text"
                      placeholder="Enter name"
                      className="w-full h-10 mt-2 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                      value={m_officer_name}
                      onChange={(e) => set_m_officer_name(e.target.value)}
                    />
                  </div>
                  <div className="sm:col-span-8 col-span-12 grid grid-cols-12 gap-4">
                    <div className="sm:col-span-6 col-span-12">
                      <p className="font-medium">Select Date</p>
                      <input
                        type="date"
                        // value={step6date}
                        // onChange={(e) => setTime(e.target.value)}
                        className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 mr-2 border-none focus:outline-none"
                        value={m_date}
                        onChange={(e) => setm_date(e.target.value)}
                      />
                    </div>
                    <div
                      onClick={handleMRODate}
                      className="sm:col-span-6 mt-3 col-span-12"
                    >
                      <p className="mt-[-8px] font-medium  ">Choose Default</p>
                      <div className="mt-2.5 sm:col-span-6 col-span-12">
                        <button
                          className="p-1.5 pl-7 pr-7 rounded-lg w-full  bg-blue-600 font-normal col-span-6 text-white"
                          style={{ background: "#1db6fa" }}
                        >
                          Today's Date
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* step-7 */}
            <div className=" mt-7 mb-7 font-medium text-[20px] font-Poppins">
              {" "}
              <h1>
                Step 7 : Completed by Medical Review Officer - Secondary
                Specimen
              </h1>
            </div>
            <div className="border border-blue-200 p-4 rounded-lg">
              <h1 className="mt-8">
                In accordance with applicable requirements, my
                determination/verification for the split specimen (if tested)
                is:
              </h1>
              <div className="sm:mt-7 mt-3 grid grid-cols-12  mb-3 gap-1">
                <div className="sm:col-span-2 col-span-12">
                  <input
                    type="radio"
                    id="true"
                    name="acdvvfc"
                    value="true"
                    checked={mr_reconfirm === "true"}
                    onChange={reconfirmedd}
                  />
                  <label htmlFor="true" className="ml-2">
                    RECONFIRMED
                  </label>
                  <br />
                </div>
                <div className="sm:col-span-3 col-span-12">
                  <input
                    type="radio"
                    id="false"
                    name="acdvvfc"
                    value="false"
                    checked={mr_reconfirm === "false"}
                    onChange={reconfirmedd}
                  />
                  <label htmlFor="false" className="ml-2">
                    FAILED TO CONFIRM
                  </label>
                  <br />
                </div>
              </div>

              <textarea
                name=""
                id=""
                rows="3"
                placeholder="Remarks"
                className="mt-3 w-full rounded-lg bg-[#ECF1F6]  p-3 mb-3 border-none focus:outline-none"
                value={mr_delivery_service_name}
                onChange={(e) => set_mr_delivery_service_name(e.target.value)}
              ></textarea>

              <div className="mt-5 grid grid-cols-12 gap-4 ">
                <div className="sm:col-span-4 col-span-12">
                  <b>Signature of Medical Review Officer</b>
                  <div className="relative">
                    <SignatureCanvas
                      ref={medicalReviewOfficerSignRef}
                      penColor="black"
                      canvasProps={{
                        className: "signature-canvas",
                        width: 300,
                        height: 100,
                      }}
                      className="border border-solid border-black mb-4"
                    />
                    {/* Draw the line below the signature */}
                    <div className="absolute bottom-0 left-0 w-[17vw] border-b border-black"></div>
                  </div>
                  <div className="w-[17vw] flex justify-end mt-2 items-center gap-3">
                    <button
                      onClick={handleMedicalReviewOfficerSignClear}
                      className="bg-[#E8EEF3] px-2 py-1 rounded poppins-medium hover:opacity-90"
                    >
                      Clear
                    </button>
                    <button
                      onClick={handleMedicalReviewOfficerSignSave}
                      className="bg-theme-blue text-white rounded px-2 py-1 poppins-medium hover:opacity-90"
                      style={{ background: "#1db6fa" }}
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div className="sm:col-span-8 col-span-12 grid grid-cols-12 gap-4 mt-14 sm:ml-[-4vw]">
                  <div className="sm:col-span-4 col-span-12">
                    <h1 className="font-medium">
                      Medical Review Officer's Name
                    </h1>
                    <input
                      type="text"
                      placeholder="Enter name"
                      className="w-full h-10 mt-2 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                      value={mr_officer_name}
                      onChange={(e) => set_mr_officer_name(e.target.value)}
                    />
                  </div>
                  <div className="sm:col-span-4 col-span-12">
                    <p className="font-medium">Date</p>
                    <input
                      type="date"
                      // value={step7date}
                      // onChange={(e) => setDate(e.target.value)}
                      className="w-full h-10 mb-4 mt-2 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                      value={mr_date}
                      onChange={(e) => set_mr_date(e.target.value)}
                    />
                  </div>
                  <div
                    onClick={handleMROSDate}
                    className="sm:col-span-4 col-span-12"
                  >
                    <p className="font-medium mb-2">Choose Default</p>
                    <button
                      className="p-1.5 pl-7 pr-7 rounded-lg w-full bg-blue-600 font-normal text-white"
                      style={{ background: "#1db6fa" }}
                    >
                      Today's Date
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className=" mt-4 gap-3 flex sm:justify-start justify-around">
              <div>
                <Cancelsmall onClick={onClose} />
              </div>
              <div>
                {/* <GreenSubmit /> */}
                <button onClick={OrderFormEdit} className="savebtn">
                  {spin ? (
                    <svg
                      aria-hidden="true"
                      class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  ) : (
                    "update"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForensicOrderFormEdit;
