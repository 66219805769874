import React from "react";
import Dashboard from "../../components/Dashboard";
import { FaFilePdf } from "react-icons/fa6";
import { IoMdAdd } from "react-icons/io";
import { FaListAlt, FaQrcode } from "react-icons/fa";
import { MdOutlineSchedule } from "react-icons/md";
import { CardWithIcon } from "../../components/Card";

const PatientDashboard = () => {
  const cardsData = [
    {
      label: "My Result Report",
      icon: <FaFilePdf className="textwhite" />,
    },
    {
      label: "My Demographics",
      icon: <IoMdAdd />,
    },
    {
      label: "My QR Details",
      icon: <FaQrcode />,
    },
    {
      label: "Schedule new test",
      icon: <MdOutlineSchedule />,
    },
    {
      label: "My Scheduled Tests",
      icon: <FaListAlt />,
    },
  ];
  return (
    <Dashboard>
      {/* cards */}
      <div className="grid  xs:grid-cols-2 sm:grid-cols-3 w-full gap-2 ">
        {cardsData?.map((item) => {
          return <CardWithIcon item={item} />;
        })}
      </div>
    </Dashboard>
  );
};

export default PatientDashboard;
