import { useEffect, useState } from "react";
import { MdPersonAddAlt1 } from "react-icons/md";
import AddButton from "../../components/buttons/AddButton";
import Dashboard from "../../components/Dashboard";
import Table, { Badge } from "../../components/Table";
import Technician from "../../components/Modals/Technician";
import { useModal } from "../../context/modalContext";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import {
  // getTechnicianTableData,
  technicianTableHeadData,
} from "../../constants/data/techniciansData";
import { Spinner } from "@material-tailwind/react";
import { useSelector } from "react-redux";
import AddTechnician from "./../../components/forms/AddTechnician";
import { useForm } from "../../context/formContext";
import Delete from "../../components/buttons/DeleteButton";
import Edit from "./../../components/buttons/Edit";
import DeleteUserModal from "../../components/Modals/DeleteUserModal";

const TechnicianPage = () => {
  const {
    showEditModal,
    showModal,
    openModal,
    closeModal,
    closeEditModal,
    openDeleteModal,
    closeDeleteModal,
    showDeleteModal,
  } = useModal();
  const { showForm, openForm, closeForm } = useForm();

  const [showSpin, setShowSpin] = useState(false);
  const { token } = useSelector((state) => state.user);
  const [technicianTableData, setTechnicianTableData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setShowSpin(true);
      try {
        const { data } = await HttpClient.apiCaller(
          "admin/user-list?role=technician",
          "GET",
          null,
          token,
          null
        );
        // console.log("::::::", data.user);
        setTechnicianTableData(data.user);
      } catch (error) {
        console.log(error);
      } finally {
        setShowSpin(false);
      }
    };

    fetchData();

    return () => {};
  }, [closeModal, closeForm]);
  const getTechnicianTableData = (dataSet) => {
    const tableData = dataSet?.map((item, idx) => {
      return {
        no: idx + 1,
        uid: item?.uid,
        firstName: item?.first_name || "N/A",
        lastName: item?.last_name || "N/A",
        email: item?.email || "N/A",
        phone: item?.mobile_number || "N/A",
        // status: <Badge status={item?.status} />,
        action: (
          <div className="flex gap-3">
            <Edit user={item} />
            <Delete user={item} />
          </div>
        ),
      };
    });
    return tableData;
  };
  return (
    <Dashboard>
      {showForm ? (
        <AddTechnician onClose={closeForm} />
      ) : (
        <>
          <div className="flex xs:flex-col-reverse xs:gap-2 sm:flex-row sm:items-center sm:justify-between mb-5">
            <div>
              <p className="poppins-medium  leading-[21px]  text-gray-700 mb-2">
                List of Technicians
              </p>
              <p className="poppins-regular text-xs text-[#B5B5C3] ">
                {technicianTableData?.length} available technicians
              </p>
            </div>
            {/*  */}
            <AddButton
              onClick={openForm}
              buttonName={"Add New Technician"}
              icon={<MdPersonAddAlt1 className="size-5" />}
            />
          </div>
          {showSpin ? (
            <div className="w-full mt-20 flex items-center justify-center">
              <Spinner className="h-12 w-12" />{" "}
            </div>
          ) : (
            <Table
              tableData={getTechnicianTableData(technicianTableData)}
              tableHeadData={technicianTableHeadData}
            />
          )}

          {/* {showModal ? <Technician edit={false} onClose={closeModal} /> : <></>} */}
          {showEditModal ? (
            <Technician edit={true} onClose={closeEditModal} />
          ) : (
            <></>
          )}
        </>
      )}
      {/* delete modal */}
      {showDeleteModal && <DeleteUserModal onClose={closeDeleteModal} />}
    </Dashboard>
  );
};

export default TechnicianPage;
