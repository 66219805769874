import AddButton from "../../components/buttons/AddButton";
import PreviousButton from "../../components/buttons/PreviousButton";
import Dashboard from "../../components/Dashboard";
import Table from "../../components/Table";
import { FaPlus } from "react-icons/fa6";

import { FaFileDownload } from "react-icons/fa";
import {
    getCollectionSiteTableData,
    collectionSiteTableHeadData,
} from "../../constants/data/collectionSiteData";

import { useEffect, useState } from "react";
import AddCollectionSite from "../../components/forms/AddCollectionSite";
import { useForm } from "../../context/formContext";
import { useSelector } from "react-redux";
import { useModal } from "../../context/modalContext";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { Spinner } from "@material-tailwind/react";
import CollectionModal from "../../components/Modals/Collectionmodal";

const CollectionSitePage = () => {
    const { showForm, openForm, closeForm } = useForm();
    const { showEditModal, showModal, openModal, closeModal, closeEditModal } =
        useModal();
    const [showSpin, setShowSpin] = useState(false);
    const { token } = useSelector((state) => state.user);
    const [collectionSiteTableData, setCollectionSiteTableData] = useState([]);
    const userDetail = useSelector(
        (state) => state?.user?.userInfo?.data?.user?._id
    );
    useEffect(() => {
        const fetchData = async () => {
            setShowSpin(true);
            try {
                const { data } = await HttpClient.apiCaller(
                    `admin/get-users-createdby/${userDetail}?user_type=collectionsite`,
                    "GET",
                    null,
                    token,
                    null
                );
                console.log(data.user);
                setCollectionSiteTableData(data.user);
            } catch (error) {
                console.log(error);
            } finally {
                setShowSpin(false);
            }
        };

        fetchData();

        return () => { };
    }, [closeEditModal, closeForm]);
    return (
        <Dashboard>
            {showForm ? (
                <AddCollectionSite onClose={closeForm} />
            ) : (
                <>
                    {" "}
                    <div className="flex xs:flex-col-reverse xs:gap-2 sm:flex-row sm:items-center sm:justify-between mb-5">
                        <div>
                            <p className="poppins-medium text-lg leading-[21px]  text-theme-darkBlue mb-2">
                                Manage Collection Site{" "}
                            </p>
                            <p className="poppins-medium text-xs   ">
                                <span className="underline text-theme-blue cursor-pointer">
                                    {" "}
                                    Dashboard
                                </span>{" "}
                                /{" "}
                                <span className="underline text-theme-blue cursor-pointer">
                                    collection site
                                </span>
                            </p>
                        </div>

                        {/*  */}

                        <AddButton
                            onClick={openForm}
                            buttonName={"Add New Collection Site"}
                            icon={<FaPlus className="size-5" />}
                        />
                    </div>
                    {/*  table */}
                    {showSpin ? (
                        <div className="w-full mt-20 flex items-center justify-center">
                            <Spinner className="h-12 w-12" />{" "}
                        </div>
                    ) : (
                        <Table
                            tableData={getCollectionSiteTableData(collectionSiteTableData)}
                            tableHeadData={collectionSiteTableHeadData}
                        />
                    )}
                </>
            )}
            {/*Edit modal */}
            {showEditModal ? (
                <CollectionModal edit={true} onClose={closeEditModal} />
            ) : (
                <></>
            )}
        </Dashboard>
    );
};

export default CollectionSitePage;

// function MolecularTestOrder() {
//   return (
//     <Dashboard>
//       <div className="flex items-center justify-between mb-5">
//         <div className="flex gap-2">
//           {/* prev button */}
//           <PreviousButton />
//           <div>
//             <p className="poppins-medium text-lg leading-[21px]  text-theme-darkBlue mb-2">
//               Manage Test Order{" "}
//             </p>
//             <p className="poppins-medium text-xs  text-theme-blue underline">
//               Dashboard / Manage Test Order
//             </p>
//           </div>
//         </div>
//         {/*  */}
//         <div className="flex gap-5 ">
//           {/* show archive records */}
//           <button
//             className="poppins-medium text-sm text-theme-blue border-theme-blue border-2 p-2  rounded-md flex items-center gap-2 hover:opacity-90"
//             // onClick={onClick}
//           >
//             <FaFileDownload className="size-5 " />
//             show archive records
//           </button>
//           <AddButton
//             buttonName={"Request New Test"}
//             icon={<FaPlus className="size-5" />}
//           />
//         </div>
//       </div>

//       {/* admins table */}
//       <Table
//         tableData={molecularTestOrderTableData}
//         tableHeadData={molecularTestOrderTableHeadData}
//       />
//     </Dashboard>
//   );
// }
// function ToxicologyTestOrder() {
//   return (
//     <Dashboard>
//       <div className="flex items-center justify-between mb-5">
//         <div className="flex gap-2">
//           {/* prev button */}
//           <PreviousButton />
//           <div>
//             <p className="poppins-medium text-lg leading-[21px]  text-theme-darkBlue mb-2">
//               Manage Test Order{" "}
//             </p>
//             <p className="poppins-medium text-xs  text-theme-blue underline">
//               Dashboard / Manage Test Order
//             </p>
//           </div>
//         </div>
//         {/*  */}

//         <div className="flex gap-5 ">
//           {/* show archive records */}
//           <button
//             className="poppins-medium text-sm text-theme-blue border-theme-blue border-2 p-2  rounded-md flex items-center gap-2 hover:opacity-90"
//             // onClick={onClick}
//           >
//             <FaFileDownload className="size-5 " />
//             show archive records
//           </button>
//           <AddButton
//             buttonName={"Request New Test"}
//             icon={<FaPlus className="size-5" />}
//           />
//         </div>
//       </div>

//       {/* admins table */}
//       <Table
//         tableData={toxicologyTestOrderTableData}
//         tableHeadData={toxicologyTestOrderTableHeadData}
//       />
//     </Dashboard>
//   );
// }
