const usaObject = {
  capital: "Washington",
  currency: "USD",
  currency_name: "United States dollar",
  currency_symbol: "$",
  emoji: "🇺🇸",
  id: 233,
  iso2: "US",
  iso3: "USA",
  latitude: "38.00000000",
  longitude: "-97.00000000",
  name: "United States",
  native: "United States",
  numeric_code: "840",
  phone_code: 1,
  region: "Americas",
  subregion: "Northern America",
  tld: ".us",
};

const Constants = {
  usaObject,
};

export default Constants;
