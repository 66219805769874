import React from "react";
import Dashboard from "../../components/Dashboard";
import { BsQrCode } from "react-icons/bs";

const PatientDemographicQr = () => {
  return (
    <Dashboard>
      <div className="w-full mr-2 h-full bg-white p-5 flex flex-col underline">
        <div className="poppins-medium text-xl">PATIENT DETAIL QR CODE</div>
        <div className="flex justify-center items-center mt-10">
          <BsQrCode className="size-72" />
        </div>
        <div className="poppins-medium text-lg flex justify-center items-center underline my-10">
          show this qr code to your clinic/collection site
        </div>
      </div>
    </Dashboard>
  );
};

export default PatientDemographicQr;
