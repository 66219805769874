

import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import CommonModal from "./CommonModal";
import Cancel from "../buttons/Cancel";
import Reset from "./../buttons/Reset";
import Submit from "./../buttons/Submit";
import { useSelector } from "react-redux";
import { Spinner } from "@material-tailwind/react";
import Utility from "../../Utils/Utility";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { SlReload } from "react-icons/sl";
import { useModal } from "../../context/modalContext";
import ToggleButton from "../buttons/Toggle";
import CustomerPaid from "./../buttons/CustomerPaid";

const validationSchema = Yup.object().shape({
  orderName: Yup.string()
    .required("Order name is required"),
  orderCode: Yup.string().required("Order Code is required"),
});

const Diagonisis = ({ onClose, edit }) => {
  const { userID } = useModal();

  const { token } = useSelector((state) => state.user);
  const [showSpin, setShowSpin] = useState(false);

  const formik = useFormik({
    initialValues: {
      orderName: edit ? userID?.name : "",
      orderCode: edit ? userID?.code : "",
    },
    validationSchema: validationSchema,
    onSubmit: edit ? handleEditSubmit : handleSubmit,
  });

  async function handleSubmit(values) {
    setShowSpin(true);
    let userData = {
      name: values?.orderName,
      code: values?.orderCode,
      type: "ORDER",
    };
    try {
      const res = await HttpClient.apiCaller(
        "admin/code",
        "POST",
        userData,
        token,
        null
      );
      console.log(res);
      Utility.sToast("Data posted successfully");
      onClose();
      formik.resetForm();
    } catch (err) {
      console.log(err);
      Utility.eToast(err?.response?.data?.err || "Something went wrong");
    } finally {
      setShowSpin(false);
    }
  }

  async function handleEditSubmit(values) {
    setShowSpin(true);
    let userData = {
      _id: userID._id,
      name: values?.orderName,
      code: values?.orderCode,
    };
    try {
      const res = await HttpClient.apiCaller(
        "admin/code",
        "PUT",
        userData,
        token,
        null
      );
      console.log(res);
      Utility.sToast("User Updated successfully");
      onClose();
      formik.resetForm();
    } catch (err) {
      console.log(err);
      Utility.eToast("Something went wrong");
    } finally {
      setShowSpin(false);
    }
  }

  const handleReset = () => {
    formik.resetForm();
  };

  return (
    <CommonModal
      heading={edit ? "Edit Specimen" : "Create Diagnosis Order Code"}
      onClose={onClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="mt-9">
          <p className="mb-2 poppins-medium">Order name</p>
          <input
            type={"text"}
            placeholder="Enter order code name"
            className="w-full h-10 mb-2 rounded poppins-regular bg-[#ECF1F6] p-2"
            onChange={formik.handleChange}
            name="orderName"
            value={formik.values.orderName}
          />
          {formik.errors.orderName && formik.touched.orderName && (
            <div className="text-red-500 poppins-regular ml-1 text-sm">
              {formik.errors.orderName}
            </div>
          )}
          <p className="mb-2 poppins-regular">Order code</p>
          <input
            type={"text"}
            placeholder="Enter order code"
            className="w-full h-10 mb-2 rounded poppins-regular bg-[#ECF1F6] p-2"
            onChange={formik.handleChange}
            name="orderCode"
            value={formik.values.orderCode}
          />
          {formik.errors.orderCode && formik.touched.orderCode && (
            <div className="text-red-500 poppins-regular ml-1 text-sm">
              {formik.errors.orderCode}
            </div>
          )}
        </div>

        {/* <div className="mt-4 mb-5">
          <CustomerPaid onClick={() => {}} />
        </div>
        <div className="mt-4 mb-5">
          <ToggleButton onClick={() => {}} />
        </div> */}

        {/* Footer */}
        <div className="flex justify-between mt-3">
          <div>
            {!edit ? (
              <button
                type="button"
                onClick={handleReset}
                className="flex items-center px-2 py-1  poppins-medium border-2  border-gray-400 rounded hover:opacity-80 bg-[#ECF1F6]"
              >
                <SlReload className="mr-1" />
                Reset
              </button>
            ) : (
              <></>
            )}
          </div>
          <div className="flex gap-4 ml-auto">
            <Cancel onClick={onClose} />
            <Submit
              label={
                showSpin ? (
                  <Spinner color="white" />
                ) : edit ? (
                  "Save Changes"
                ) : (
                  "submit"
                )
              }
            />
          </div>
        </div>
      </form>
    </CommonModal>
  );
};

export default Diagonisis;
