import React, { useEffect, useState } from "react";
import Dashboard from "../../components/Dashboard";
import { Spinner } from "@material-tailwind/react";
import { MdPersonAddAlt1 } from "react-icons/md";
import AddButton from "../../components/buttons/AddButton";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { useModal } from "../../context/modalContext";
import { useSelector } from "react-redux";
import {
  getPAtientRecordTableData,
  patientRecordTableHeadData,
} from "../../constants/data/physician/patientRecordData";
import Table from "../../components/Table";
import PhysicUploadPatient from "../../components/Modals/PhysicUploadPatient";

const PhysicManageRecord = () => {
  const { showModal, openModal, closeModal } = useModal();
  const [showSpin, setShowSpin] = useState(false);
  const { token } = useSelector((state) => state.user);
  const [patientRecordTableData, setPatientRecordTableData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      setShowSpin(true);
      try {
        const { data } = await HttpClient.apiCaller(
          "admin/user-list?role=clinic",
          "GET",
          null,
          token,
          null
        );
        console.log(data.user);
        setPatientRecordTableData(data.user);
      } catch (error) {
        console.log(error);
      } finally {
        setShowSpin(false);
      }
    };

    fetchData();

    return () => {};
  }, [closeModal]);
  return (
    <Dashboard>
      <div className="flex xs:flex-col-reverse xs:gap-2 sm:flex-row sm:items-center sm:justify-between mb-5">
        <div>
          <p className="poppins-medium  leading-[21px]  text-gray-700 mb-2">
            List of Registered Ptients
          </p>
          <p className="poppins-regular text-xs text-[#B5B5C3] ">
            {patientRecordTableData?.length} available clinics
          </p>
        </div>
        {/*  */}
        <AddButton
          onClick={openModal}
          buttonName={"Upload Patient Record"}
          icon={<MdPersonAddAlt1 className="size-5" />}
        />
      </div>
      {/*  table */}
      {showSpin ? (
        <div className="w-full mt-20 flex items-center justify-center">
          <Spinner className="h-12 w-12" />{" "}
        </div>
      ) : (
        <Table
          tableData={getPAtientRecordTableData(patientRecordTableData)}
          tableHeadData={patientRecordTableHeadData}
        />
      )}
      {/* modal */}
      {showModal ? <PhysicUploadPatient onClose={closeModal} /> : <></>}
    </Dashboard>
  );
};

export default PhysicManageRecord;
