// import React, { useState } from "react";
// import Sidebar from "./Sidebar";
// import Topbar from "./Topbar";
// import { IoReorderThreeSharp } from "react-icons/io5";
// import genePace from "../assets/genpace.png";
// import userImg from "../assets/user.png";
// import { useRef } from 'react';
// import {
//   adminSidebarData,
//   collectorSidebarData,
//   physicianSidebarData,
//   scientistSidebarData,
//   techniciansSidebarData,
// } from "../constants/data/sidebarData";
// import { useSelector } from "react-redux";

// const Dashboard = ({ children }) => {
//   const [showSidebar, setShowsidebar] = useState(false);
//   const [showTopbar, setShowTopbar] = useState(false);

//   const fileInputRef = useRef(null);

//   const handleChooseFile = () => {
//     fileInputRef.current.click();
//   };
//   const handleFileSelected = (event) => {
//     const selectedFile = event.target.files[0];
//     console.log('Selected File:', selectedFile);
//   };
//   const userInfo = useSelector((state) => state?.user);

//   let role = userInfo?.user_type;
//   let user = {
//     admin: {
//       name: "Admin",
//       email: userInfo?.email,
//       lastLogin: "14 Feb 2024, 10:33PM",
//     },
//     technician: {
//       name: "John Doe",
//       email: "edwardssamuels@gmail.com",
//       lastLogin: "14 Feb 2024, 9:35AM",
//     },
//     scientist: {
//       name: "Mr RudraNarayan",
//       email: "Rudrra@gmail.com",
//       lastLogin: "19 Mar 2024, 9:35AM",
//     },
//     physician: {
//       name: "Mr subham",
//       email: "subham@gmail.com",
//       lastLogin: "10 Mar 2024, 9:35AM",
//     },
//     collector: {
//       name: "Mr Abhishek",
//       email: "abhishek@gmail.com",
//       lastLogin: "20 Mar 2024, 9:35AM",
//     },
//   };
//   // function to get sidebar data
//   // ["admin", "technicians", "scientist", "physician", "collector"];
//   function getData() {
//     switch (role) {
//       case "admin":
//         return adminSidebarData;
//       case "technician":
//         return techniciansSidebarData;
//       case "scientist":
//         return scientistSidebarData;
//       case "physician":
//         return physicianSidebarData;
//       case "collector":
//         return collectorSidebarData;
//     }
//   }

//   return (
//     <div className="">
//       <img
//         src={userImg}
//         className="w-10 h-10 rounded-full z-[1000] cursor-pointer xs:fixed lg:hidden right-3 top-2 "
//         onClick={() => setShowTopbar(true)}
//         alt=""
//       />

//       {/* brand icon */}
//       <div className="fixed  flex z-[900] gap-3 xs:ml-1 lg:ml-3 mt-1 items-center xs:h-fit">
//         <IoReorderThreeSharp
//           className="size-8 cursor-pointer xs:block lg:hidden"
//           onClick={() => setShowsidebar(true)}
//         />
//         <div className="flex flex-col" onClick={handleChooseFile}>
//           <img src={genePace} alt="brand logo" />
//           <p className="poppins-extrabold-italic -mt-2 ml-6">{role}</p>
//           {/* Hide the input */}
//           <input
//             type="file"
//             style={{ display: 'none' }}
//             ref={fileInputRef}
//             onChange={handleFileSelected}
//           />
//         </div>
//         {/* <FaUser
//           className="w-8 h-8 rounded-full cursor-pointer absolute top-3 right-5 xs:block lg:hidden"
//           onClick={() => setShowTopbar(true)}
//         /> */}
//       </div>
//       {/*1st grid */}
//       <Sidebar
//         data={getData(role)}
//         showSidebar={showSidebar}
//         onClose={() => setShowsidebar(false)}
//       />
//       {/*2nd grid */}
//       <div className=" ">
//         <Topbar
//           showTopbar={showTopbar}
//           onClose={() => setShowTopbar(false)}
//           user={user[role]}
//         />
//         <div className="p-2 mt-16 absolute xs:w-full lg:ml-[235px] lg:w-[calc(100%-235px)] ">
//           {children}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Dashboard;

import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { IoReorderThreeSharp } from "react-icons/io5";
import genePace from "../assets/genpace.png";
import userImg from "../assets/user.png";
import { IoCameraReverseOutline } from "react-icons/io5";
import { useRef } from "react";
import {
  adminSidebarData,
  clinicSidebarData,
  collectorSidebarData,
  organizationSidebarData,
  patientSidebarData,
  physicianSidebarData,
  scientistSidebarData,
  techniciansSidebarData,
} from "../constants/data/sidebarData";
import { useSelector } from "react-redux";
import { collectionSiteSidebarData } from "./../constants/data/sidebarData";
import { HttpClient } from "../Redux/controllers/HttpClient";
import Utility from "../Utils/Utility";
import axios from "axios";

const Dashboard = ({ children }) => {
  const [showSidebar, setShowsidebar] = useState(false);
  const [showTopbar, setShowTopbar] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const userInfo = useSelector((state) => state?.user);
  let role = userInfo?.user_type;
  const idd = userInfo?.userInfo?.data?.user?._id;
  // console.log("----------+", userInfo?.userInfo?.data?.user?._id);
  // console.log("><><><><><", idd);

  const [logoo, setLogoo] = useState("");
  const [stat, setStat] = useState(false);

  useEffect(() => {
    getLogo();
  }, [stat]);
  const getLogo = async () =>
    await HttpClient.apiCaller(`get-logo/${idd}`, "GET", null, null, null)
      .then((res) => {
        // console.log("1=======", res);
        setLogoo(res?.data?.exisLog?.img_path);
      })
      .catch((err) => console.log("2=====", err));

  // console.log(">>>", logoo);

  const fileInputRef = useRef(null);

  const handleChooseFile = () => {
    fileInputRef.current.click();
  };
  const handleFileSelected = async (event) => {
    const selectedFile = event.target.files[0];
    let furl;
    // console.log("1>>>>>>", selectedFile);

    let dataa = new FormData();
    dataa.append("img", selectedFile);
    // console.log("2>>>>>>", dataa);

    let config = {
      method: "post",
      url: `${HttpClient.API_BASE_URL}upload/single-upload`,
      data: dataa,
    };
    // console.log("3>>>>>>", config);
    await axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        furl = response?.data?.img;
        // console.log("4>>>>>>", furl);
      })
      .catch((error) => {
        console.log(error);
      });
    // await HttpClient.apiCaller(
    //   "upload/single-upload",
    //   "POST",
    //   dataa,
    //   null,
    //   null
    // )
    //   .then((res) => {
    //     console.log("4>>>>>", res);
    //     furl = res?.data;
    //   })
    //   .catch((err) => console.log("66666", err));

    if (logoo) {
      const data = {
        img_path: furl,
        id: idd,
        created_by: idd,
      };
      // console.log("5>>>>>>", data);
      await HttpClient.apiCaller("update-logo", "PUT", data, null, null)
        .then((res) => {
          // console.log("put", res);
          Utility.sToast("Logo Updated");
          setStat(true);
        })
        .catch((err) => console.log(err));
    } else {
      const data = {
        img_path: furl,
        created_by: idd,
      };
      // console.log("6>>>>>>", data);
      await HttpClient.apiCaller("create-logo", "POST", data, null, null)
        .then((res) => {
          // console.log("put", res);
          Utility.sToast("Logo Updated");
          setStat(true);
        })
        .catch((err) => console.log(err));
    }

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = async () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  let user = {
    admin: {
      name: "Admin",
      email: userInfo?.email,
      lastLogin: "14 Feb 2024, 10:33PM",
    },
    technician: {
      name: "John Doe",
      email: "edwardssamuels@gmail.com",
      lastLogin: "14 Feb 2024, 9:35AM",
    },
    scientist: {
      name: "Mr RudraNarayan",
      email: "Rudrra@gmail.com",
      lastLogin: "19 Mar 2024, 9:35AM",
    },
    physician: {
      name: "Mr subham",
      email: "subham@gmail.com",
      lastLogin: "10 Mar 2024, 9:35AM",
    },
    collector: {
      name: "Mr Abhishek",
      email: "abhishek@gmail.com",
      lastLogin: "20 Mar 2024, 9:35AM",
    },
  };
  // function to get sidebar data
  // ["admin", "technicians", "scientist", "physician", "collector"];
  function getData() {
    switch (role) {
      case "admin":
        return adminSidebarData;
      case "mro":
        return physicianSidebarData;
      case "patient":
        return patientSidebarData;
      case "clinic":
        return clinicSidebarData;
      case "collectionsite":
        return collectionSiteSidebarData;
      case "collector":
        return collectorSidebarData;
      case "scientist":
        return scientistSidebarData;
      case "organization":
        return organizationSidebarData;
      case "technician":
        return techniciansSidebarData;
      default:
        return "/";
    }
  }

  return (
    <div className="">
      <img
        src={logoo}
        className="w-10 h-10 rounded-full z-[1000] cursor-pointer xs:fixed lg:hidden right-3 top-2 "
        onClick={() => setShowTopbar(true)}
        alt=""
      />

      {/* brand icon */}
      <div className="fixed  flex z-[900] gap-3 xs:ml-1 lg:ml-3 mt-1 items-center xs:h-fit">
        <IoReorderThreeSharp
          className="size-8 cursor-pointer xs:block lg:hidden"
          onClick={() => setShowsidebar(true)}
        />
        <div className="flex flex-col">
          <img
            // src={selectedImage ? selectedImage : genePace}
            src={logoo ? logoo : genePace}
            alt="brand logo"
            className="h-10 w-48"
            onMouseLeave={handleMouseLeave}
            onClick={handleChooseFile}
          />
          {/* <p className="poppins-extrabold-italic -mt-2 ml-6">{role}</p> */}
          {/* Camera Image */}
          {/* <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="camera-icon relative mt-[-2.8vh] ml-[9vw] cursor-pointer"
            style={{ opacity: isHovered ? 1 : 0.4 }}
          >
            <p>
              <IoCameraReverseOutline />
            </p>
          </div> */}
          {/* Hide the input */}
          <div className="scale-75 ml-auto p-1 relative flex items-center justify-center rounded  mt-1  bg-theme-sidebar">
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileSelected}
              className="absolute opacity-0 w-full"
            />
            <button
              className=" w-full bg-white rounded poppins-medium px-1 py-[2px] text-[10px] "
              onClick={() => fileInputRef.current.click()}
            >
              Change Logo
            </button>
          </div>
        </div>
        {/* <FaUser
          className="w-8 h-8 rounded-full cursor-pointer absolute top-3 right-5 xs:block lg:hidden"
          onClick={() => setShowTopbar(true)}
        /> */}
      </div>
      {/*1st grid */}
      <Sidebar
        data={getData(role)}
        showSidebar={showSidebar}
        onClose={() => setShowsidebar(false)}
      />
      {/*2nd grid */}
      <div className=" ">
        <Topbar
          showTopbar={showTopbar}
          onClose={() => setShowTopbar(false)}
          user={user[role]}
        />
        <div className="p-2 mt-16 absolute xs:w-full lg:ml-[235px] lg:w-[calc(100%-235px)] ">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
