import React, { useEffect, useState } from "react";
import Dashboard from "../../components/Dashboard";
import PhysicianForm from "../../components/forms/PhysicianForm";
import { useForm } from "../../context/formContext";
import { useSelector } from "react-redux";
import { useModal } from "../../context/modalContext";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import AddButton from "../../components/buttons/AddButton";
import { Spinner } from "@material-tailwind/react";
import Table from "../../components/Table";
import {
  getPhysicianTableData,
  physicianTableHeadData,
} from "../../constants/data/physiciansData";
import Physician from "../../components/Modals/Physician";
import { MdPersonAddAlt1 } from "react-icons/md";

const ClinicMedicalReviewOfficer = () => {
  const { showForm, openForm, closeForm } = useForm();
  const [showSpin, setShowSpin] = useState(false);
  const { token, user_id } = useSelector((state) => state.user);
  const [physicianTableData, setPhysicianTableData] = useState([]);
  const [allPhysicianTableData, setAllPhysicianTableData] = useState([]);
  const { showEditModal, closeEditModal } = useModal();
  const userDetails = useSelector(
    (state) => state?.user?.userInfo?.data?.user?._id
  );
  // console.log("first", userDetails);
  useEffect(() => {
    const fetchData = async () => {
      setShowSpin(true);
      try {
        const { data } = await HttpClient.apiCaller(
          `admin/get-users-createdby/${userDetails}?user_type=mro`,
          "GET",
          null,
          token,
          null
        );
        // console.log(data.user);
        setPhysicianTableData(data?.user);

        const mroUnderData = await HttpClient.apiCaller(
          `admin/get-mros-under/${userDetails}`,
          "GET",
          null,
          token,
          null
        );
        // console.log("111=====>", mroUnderData?.data?.users);
        setAllPhysicianTableData(mroUnderData?.data?.users);
      } catch (error) {
        console.log(error);
      } finally {
        setShowSpin(false);
      }
    };

    fetchData();

    return () => {};
  }, [closeForm, closeEditModal]);

  const finalData = [...physicianTableData, ...allPhysicianTableData];
  // console.log(">>>>>>>>>>>>>>>>", finalData);
  return (
    <Dashboard>
      {showForm ? (
        <PhysicianForm onClose={closeForm} />
      ) : (
        <>
          <div className="flex xs:flex-col-reverse xs:gap-2 sm:flex-row sm:items-center sm:justify-between mb-5">
            <div>
              <p className="poppins-medium  leading-[21px]  text-gray-700 mb-2">
                List of Medical Review Officers
              </p>
              <p className="poppins-regular text-xs text-[#B5B5C3] ">
                {finalData?.length} available Medical Review Officers
              </p>
            </div>
            {/*  */}
            <AddButton
              onClick={openForm}
              buttonName={"Add New Medical Review Officer"}
              icon={<MdPersonAddAlt1 className="size-5" />}
            />
          </div>
          {/*  table */}
          {showSpin ? (
            <div className="w-full mt-20 flex items-center justify-center">
              <Spinner className="h-12 w-12" />{" "}
            </div>
          ) : (
            <Table
              tableData={getPhysicianTableData(finalData)}
              tableHeadData={physicianTableHeadData}
            />
          )}
        </>
      )}
      {/*Edit modal */}
      {showEditModal ? (
        <Physician edit={true} onClose={closeEditModal} />
      ) : (
        <></>
      )}
    </Dashboard>
  );
};

export default ClinicMedicalReviewOfficer;
