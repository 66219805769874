import { MdPersonAddAlt1 } from "react-icons/md";
import AddButton from "../../components/buttons/AddButton";
import Dashboard from "../../components/Dashboard";
import Table from "../../components/Table";
// import {
//   clinicalScientistTableHeadData,
//   getClinicalScientistTableData,
// } from "../../constants/data/clinicalScientist";
import { CollectorTestOrederTableHeadData, getCollectorTestOrederTableData } from "./TestData"
import { useEffect, useState } from "react";
import Clinicical from "../../components/Modals/Clinicical";
import { useModal } from "../../context/modalContext";
import { useSelector } from "react-redux";
import { Spinner } from "@material-tailwind/react";
import { HttpClient } from "../../Redux/controllers/HttpClient";

const CTestOrder = () => {
    const { showEditModal, showModal, openModal, closeModal, closeEditModal } =
        useModal();
    const [showSpin, setShowSpin] = useState(false);
    const { token } = useSelector((state) => state.user);
    const [clinicalScientistTableData, setClinicalScientistTableData] = useState(
        []
    );
    // useEffect(() => {
    //     const fetchData = async () => {
    //         setShowSpin(true);
    //         try {
    //             const { data } = await HttpClient.apiCaller(
    //                 "admin/user-list?role=scientist",
    //                 "GET",
    //                 null,
    //                 token,
    //                 null
    //             );
    //             console.log(data.user);
    //             setClinicalScientistTableData(data.user);
    //         } catch (error) {
    //             console.log(error);
    //         } finally {
    //             setShowSpin(false);
    //         }
    //     };

    //     fetchData();

    //     return () => { };
    // }, [closeModal]);
    return (
        <Dashboard>
            <div className="flex xs:flex-col-reverse xs:gap-2 sm:flex-row sm:items-center sm:justify-between mb-5">
                <div>
                    <p className="poppins-medium  leading-[21px]  text-gray-700 mb-2">
                        Manage Patients
                    </p>
                    <p className="poppins-regular text-xs text-[#B5B5C3] ">
                        {clinicalScientistTableData?.length} available clinical scientists
                    </p>
                </div>
                {/*  */}
                <AddButton
                    onClick={openModal}
                    buttonName={"Request New Test"}
                    icon={<MdPersonAddAlt1 className="size-5" />}
                />
            </div>
            {/*  table */}
            {showSpin ? (
                <div className="w-full mt-20 flex items-center justify-center">
                    <Spinner className="h-12 w-12" />{" "}
                </div>
            ) : (
                <Table
                    tableData={getCollectorTestOrederTableData(clinicalScientistTableData)}
                    tableHeadData={CollectorTestOrederTableHeadData}
                />
            )}

            {showModal ? <Clinicical onClose={closeModal} edit={false} /> : <></>}

            {showEditModal ? (
                <Clinicical edit={true} onClose={closeEditModal} />
            ) : (
                <></>
            )}
        </Dashboard>
    );
};

export default CTestOrder;
