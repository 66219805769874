import AddButton from "../../components/buttons/AddButton";
import PreviousButton from "../../components/buttons/PreviousButton";
import Dashboard from "../../components/Dashboard";
import Table, { ActionButton, Badge } from "../../components/Table";
import { FaPlus } from "react-icons/fa6";

import { FaFileDownload } from "react-icons/fa";
import {
  // getCollectionSiteTableData,
  collectionSiteTableHeadData,
} from "../../constants/data/collectionSiteData";

import { useEffect, useState } from "react";
import AddCollectionSite from "../../components/forms/AddCollectionSite";
import { useForm } from "../../context/formContext";
import { useSelector } from "react-redux";
import { useModal } from "../../context/modalContext";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { Spinner } from "@material-tailwind/react";
import CollectionModal from "../../components/Modals/Collectionmodal";
// import DeleteModal from "../../components/Modals/DeleteModal";
import Delete from "./../../components/buttons/DeleteButton";
import DeleteUserModal from "../../components/Modals/DeleteUserModal";

const CollectionSitePage = () => {
  const { showForm, openForm, closeForm } = useForm();
  const { showEditModal, closeDeleteModal, showDeleteModal, closeEditModal } =
    useModal();
  // const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState([]);
  const [showSpin, setShowSpin] = useState(false);
  const { token } = useSelector((state) => state.user);
  const [collectionSiteTableData, setCollectionSiteTableData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      setShowSpin(true);
      try {
        const { data } = await HttpClient.apiCaller(
          "admin/user-list?role=collectionsite",
          "GET",
          null,
          token,
          null
        );
        console.log("????????????", data.user);
        setCollectionSiteTableData(data.user);
      } catch (error) {
        console.log(error);
      } finally {
        setShowSpin(false);
      }
    };

    fetchData();

    return () => {};
  }, [closeEditModal]);
  let getCollectionSiteTableData = (dataSet) => {
    const tableData = dataSet?.map((item, idx) => {
      return {
        no: idx + 1,
        uid: item?.uid,
        firstName: item?.first_name,
        email: item?.email,
        mobile: item?.mobile_number,
        // status: <Badge status={item?.status} />,
        action: (
          <div className="flex gap-3">
            {/* pen */}
            <ActionButton
              action="edit"
              user={item}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="frn"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                  />
                </svg>
              }
            />
            {/* cross */}
            <Delete user={item} />
            {/* <ActionButton
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="frn"
                  onClick={() => {
                    setShowDeleteModal(true);
                    setDeleteItem(item);
                  }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              }
            /> */}
          </div>
        ),
      };
    });
    return tableData;
  };
  return (
    <Dashboard>
      {showForm ? (
        <AddCollectionSite onClose={closeForm} />
      ) : (
        <>
          {" "}
          <div className="flex xs:flex-col-reverse xs:gap-2 sm:flex-row sm:items-center sm:justify-between mb-5">
            <div>
              <p className="poppins-medium text-lg leading-[21px]  text-theme-darkBlue mb-2">
                Manage Collection Site
              </p>
              <p className="poppins-medium text-xs   ">
                <span className="underline text-theme-blue cursor-pointer">
                  {" "}
                  Dashboard
                </span>{" "}
                /{" "}
                <span className="underline text-theme-blue cursor-pointer">
                  collection site
                </span>
              </p>
            </div>

            {/*  */}

            <AddButton
              onClick={openForm}
              buttonName={"Add New Collection Site"}
              icon={<FaPlus className="size-5" />}
            />
          </div>
          {/*  table */}
          {showSpin ? (
            <div className="w-full mt-20 flex items-center justify-center">
              <Spinner className="h-12 w-12" />{" "}
            </div>
          ) : (
            <Table
              tableData={getCollectionSiteTableData(collectionSiteTableData)}
              tableHeadData={collectionSiteTableHeadData}
            />
          )}
        </>
      )}
      {/*Edit modal */}
      {showEditModal ? (
        <CollectionModal edit={true} onClose={closeEditModal} />
      ) : (
        <></>
      )}
      {showDeleteModal && <DeleteUserModal onClose={closeDeleteModal} />}
    </Dashboard>
  );
};

export default CollectionSitePage;

// function MolecularTestOrder() {
//   return (
//     <Dashboard>
//       <div className="flex items-center justify-between mb-5">
//         <div className="flex gap-2">
//           {/* prev button */}
//           <PreviousButton />
//           <div>
//             <p className="poppins-medium text-lg leading-[21px]  text-theme-darkBlue mb-2">
//               Manage Test Order{" "}
//             </p>
//             <p className="poppins-medium text-xs  text-theme-blue underline">
//               Dashboard / Manage Test Order
//             </p>
//           </div>
//         </div>
//         {/*  */}
//         <div className="flex gap-5 ">
//           {/* show archive records */}
//           <button
//             className="poppins-medium text-sm text-theme-blue border-theme-blue border-2 p-2  rounded-md flex items-center gap-2 hover:opacity-90"
//             // onClick={onClick}
//           >
//             <FaFileDownload className="size-5 " />
//             show archive records
//           </button>
//           <AddButton
//             buttonName={"Request New Test"}
//             icon={<FaPlus className="size-5" />}
//           />
//         </div>
//       </div>

//       {/* admins table */}
//       <Table
//         tableData={molecularTestOrderTableData}
//         tableHeadData={molecularTestOrderTableHeadData}
//       />
//     </Dashboard>
//   );
// }
// function ToxicologyTestOrder() {
//   return (
//     <Dashboard>
//       <div className="flex items-center justify-between mb-5">
//         <div className="flex gap-2">
//           {/* prev button */}
//           <PreviousButton />
//           <div>
//             <p className="poppins-medium text-lg leading-[21px]  text-theme-darkBlue mb-2">
//               Manage Test Order{" "}
//             </p>
//             <p className="poppins-medium text-xs  text-theme-blue underline">
//               Dashboard / Manage Test Order
//             </p>
//           </div>
//         </div>
//         {/*  */}

//         <div className="flex gap-5 ">
//           {/* show archive records */}
//           <button
//             className="poppins-medium text-sm text-theme-blue border-theme-blue border-2 p-2  rounded-md flex items-center gap-2 hover:opacity-90"
//             // onClick={onClick}
//           >
//             <FaFileDownload className="size-5 " />
//             show archive records
//           </button>
//           <AddButton
//             buttonName={"Request New Test"}
//             icon={<FaPlus className="size-5" />}
//           />
//         </div>
//       </div>

//       {/* admins table */}
//       <Table
//         tableData={toxicologyTestOrderTableData}
//         tableHeadData={toxicologyTestOrderTableHeadData}
//       />
//     </Dashboard>
//   );
// }
