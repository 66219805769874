import React from "react";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./pages/auth/LoginPage";
import ResetPage from "./pages/auth/ResetPage";
import DashboardPage from "./pages/dashboard/DashboardPage";
// import Testeorder from "./pages/dashboard/Testeorder";
// import Dashboard from "./components/Dashboard";
import CollectionSitePage from "./pages/dashboard/CollectionSitePage";
import ClinicPage from "./pages/dashboard/ClinicPage";
import AdminsPage from "./pages/dashboard/AdminsPage";
import TechnicianPage from "./pages/dashboard/TechnicianPage";
import ClinicalScientist from "./pages/dashboard/ClinicalScientist";
import PhysiciansPage from "./pages/dashboard/PhysiciansPage";
import CollectorPage from "./pages/dashboard/CollectorPage";
import ForensicTestOrderPage from "./pages/dashboard/ForensicTestOrderPage";
import DrugCodePage from "./pages/dashboard/drugs/DrugCodesPage";
import DrugCategoryPage from "./pages/dashboard/drugs/DrugCategoryPage";
import DrugPanelPage from "./pages/dashboard/drugs/DrugPanelPage";
import TestCodePage from "./pages/dashboard/TestCodePage";
import SpecimenInfoPage from "./pages/dashboard/SpecimenInfoPage";
import DiagonisisICD10 from "./pages/dashboard/DiagonisisPage";
import PatientsPage from "./pages/dashboard/PatientsPage";
import { DrugProvider } from "./context/DrugContext";
import { ModalProvider } from "./context/modalContext";
import { FormProvider } from "./context/formContext";
// import { useSelector } from "react-redux";
import AuthRoute from "./routes/AuthRoute";
import ProtectedRoute from "./routes/ProtectedRoute";
import { Toaster } from "react-hot-toast";
import TClinic from "./pages/TechinicianPage.jsx/TClinic";
import Page404 from "./components/Page404";
import SignUp from "./pages/auth/SignUp";
import CDashboard from "./pages/CollectorPages/CDashboard";
import CPatient from "./pages/CollectorPages/CPatient";
import CTestOrder from "./pages/CollectorPages/CTestOrder";
import TDashboard from "./pages/TechinicianPage.jsx/TDashboard";
import Tphysician from "./pages/TechinicianPage.jsx/Tphysician";
import TPatient from "./pages/TechinicianPage.jsx/TPatient";
import TOrganization from "./pages/TechinicianPage.jsx/TOrganization";
// import TClinic from "./pages/TechinicianPage.jsx/TClinic";
import TCollection from "./pages/TechinicianPage.jsx/TCollection";
import TCollector from "./pages/TechinicianPage.jsx/TCollector";

import TForensic from "./pages/TechinicianPage.jsx/TForensic";

import SDashboard from "./pages/Scientistpages/SDashboard";
import SPhysician from "./pages/Scientistpages/SPhysician";
import SPatients from "./pages/Scientistpages/SPatients";
import SOrganization from "./pages/Scientistpages/SOrganization";
import SClinics from "./pages/Scientistpages/SClinics";
import SCollectionSite from "./pages/Scientistpages/SCollectionSite";
import SCollector from "./pages/Scientistpages/SCollector";

import SForensicTest from "./pages/Scientistpages/SForensicTest";

import CForeignsicTestOredr from "./pages/CollectorPages/CForeignsicTestOredr";
import CReferQr from "./pages/CollectorPages/CReferQr";
import PhysicDashboard from "./pages/physician/PhysicDashboard";
import PhysicClinic from "./pages/physician/PhysicClinic";
import PhysicManageRecord from "./pages/physician/PhysicManageRecord";
import PhysicPatient from "./pages/physician/PhysicPatient";
import PhysicForensicTestOrder from "./pages/physician/PhysicForensicTestOrder";
import PhysicScheduleNewTest from "./pages/physician/PhysicScheduleNewTest";
import PatientDashboard from "./pages/patient/PatientDashboard";
import ClinicDashboard from "./pages/clinic/ClinicDashboard";
// import ClinicCollectionSite from "./pages/clinic/ClinicCollectionSite";
// import ClinicCollector from "./pages/clinic/ClinicCollector";
import ClinicForensicTestOrder from "./pages/clinic/ClinicForensicTestOrder";
// import ClinicAppointments from "./pages/clinic/ClinicAppointments";
// import ClinicScheduleTest from "./pages/clinic/ClinicScheduleTest";
import SiteDashboard from "./pages/site/SiteDashboard";
import SitePatient from "./pages/site/SitePatient";
// import SiteTestCenter from "./pages/site/SiteTestCenter";
// import SiteAppointments from "./pages/site/SiteAppointments";
import SiteForensicTestOrder from "./pages/site/SiteForensicTestOrder";
// import SiteScheduleTest from "./pages/site/SiteScheduleTest";
import PatientResultReport from "./pages/patient/PatientResultReport";
// import PatientScheduledTests from "./pages/patient/PatientScheduledTests";
// import PatientScheduleTest from "./pages/patient/PatientScheduleTest";
// import PatientDemographic from "./pages/patient/PatientDemographic";
// import ClinicUpdatedRecord from "./pages/clinic/ClinicUpdatedRecord";
// import ClinicCustomDrugPanel from "./pages/clinic/ClinicCustomDrugPanel";
// import ClinicTestCenter from "./pages/clinic/ClinicTestCenter";
import PhysicForensicQR from "./pages/physician/PhysicForensicQR";
import PatientDemographicQr from "./pages/patient/PatientDemographicQr";
import PatientMyTestQr from "./pages/patient/PatientMyTestQr";
import ClinicForensicQr from "./pages/clinic/ClinicForensicQr";
import ClinicReferQr from "./pages/clinic/ClinicReferQr";
import SiteReferQr from "./pages/site/SiteReferQr";
import SiteTestOrder from "./pages/site/SiteTestOrder";
import SiteCollector from "./pages/site/SiteCollector";
import ClinicManageSite from "./pages/clinic/ClinicManageSite";
import ClinicManageCollector from "./pages/clinic/ClinicManageCollector";
import CCollectionSite from "./pages/CollectorPages/CCollectionSite";
import CMedicalOfficer from "./pages/CollectorPages/CMedicalOfficer";
// import EmployerPage from "./pages/physician/EmployerPage";
import PhysicianCollectionSitePage from "./pages/physician/PhysicianCollectionSitePage";
import PhysicianCollectorPage from "./pages/physician/PhysicianCollectorPage";
import PhysicianTechnicianPage from "./pages/physician/PhysicianTechnicianPage";
import PhysicianPatient from "./pages/physician/PhysicianPatient";
import ClinicManagePatient from "./pages/clinic/ClinicManagePatient";
import ClinicMedicalReviewOfficer from "./pages/clinic/ClinicMedicalReviewOfficer";
import OrgDashboard from "./pages/organization/OrgDashboard";
import OrgForensicQR from "./pages/organization/OrgForensicQR";
import OrgReferQR from "./pages/organization/OrgReferQR";
import OrgManagePatient from "./pages/organization/OrgManagePatient";
import OrgManageCollector from "./pages/organization/OrgManageCollector";
import OrgManageSite from "./pages/organization/OrgManageSite";
import OrgForensicTestOrder from "./pages/organization/OrgForensicTestOrder";
import MroForensicTestOrderPage from "./pages/dashboard/MroForensicTestOrderPage";
import OrganizationPage from "./pages/dashboard/OrganizationPage";
import PhysicOrganization from "./pages/physician/PhysicOrganization";
import PhysicScientist from "./pages/physician/PhysicScientist";
import TTForensic from "./pages/TechinicianPage.jsx/TTForensic";
// import MroForensicOrderForm from "./components/forms/MroForensicOrderForm";

const App = () => {
  return (
    <ModalProvider>
      <DrugProvider>
        <FormProvider>
          <div className="">
            <Routes>
              <Route element={<AuthRoute />}>
                <Route path="/" element={<LoginPage />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/reset" element={<ResetPage />} />
              </Route>
              <Route element={<ProtectedRoute />}>
                <>
                  <Route path="/dashboard" element={<DashboardPage />} />
                  <Route path="/admins" element={<AdminsPage />} />
                  <Route path="/technicians" element={<TechnicianPage />} />
                  <Route
                    path="/clinical-scientists"
                    element={<ClinicalScientist />}
                  />
                  <Route path="/physicians" element={<PhysiciansPage />} />
                  <Route path="/clinic" element={<ClinicPage />} />
                  <Route path="/organization" element={<OrganizationPage />} />
                  <Route path="/collector" element={<CollectorPage />} />
                  <Route path="/scientist" element={<ClinicalScientist />} />
                  <Route
                    path="/collection-site"
                    element={<CollectionSitePage />}
                  />
                  {/* <Route path="/test-eorder" element={<TestEOrderPage />} /> */}
                  <Route
                    path="/forensic-test-order"
                    element={<ForensicTestOrderPage />}
                  />
                  <Route path="/drug-code" element={<DrugCodePage />} />
                  <Route path="/drug-category" element={<DrugCategoryPage />} />
                  <Route path="/drug-panel" element={<DrugPanelPage />} />
                  <Route path="/test-code" element={<TestCodePage />} />
                  <Route path="/specimen-info" element={<SpecimenInfoPage />} />
                  <Route path="/diagnosis-icd" element={<DiagonisisICD10 />} />
                  <Route path="/patients" element={<PatientsPage />} />

                  <Route path="*" element={<Page404 />} />

                  {/* Collector pages */}

                  <Route path="/collector-dashboard" element={<CDashboard />} />
                  <Route path="/collector-patient" element={<CPatient />} />
                  <Route
                    path="/collector-test-order"
                    element={<CTestOrder />}
                  />
                  <Route
                    path="/collector-foreignsic-test-order"
                    element={<CForeignsicTestOredr />}
                  />
                  <Route
                    path="/collector-collection-site"
                    element={<CCollectionSite />}
                  />
                  <Route
                    path="/collector-medical-officer"
                    element={<CMedicalOfficer />}
                  />
                  <Route path="/collector-refer-qr" element={<CReferQr />} />

                  {/* Technician Pages */}

                  <Route
                    path="/technician-dashboard"
                    element={<TDashboard />}
                  />
                  <Route
                    path="/technician-physician"
                    element={<Tphysician />}
                  />
                  {/* <Route
                    path="/technician-diagnosis"
                    element={<TDiagnosis />}
                  /> */}
                  <Route path="/technician-patients" element={<TPatient />} />
                  <Route
                    path="/technician-organizations"
                    element={<TOrganization />}
                  />
                  <Route path="/technician-clinics" element={<TClinic />} />
                  <Route
                    path="/technician-collection-site"
                    element={<TCollection />}
                  />
                  <Route
                    path="/technician-collector"
                    element={<TCollector />}
                  />
                  <Route
                    path="/technician-forensic-test-order"
                    element={<TForensic />}
                  />
                  <Route
                    path="/ttechnician-forensic-test-order"
                    element={<TTForensic />}
                  />

                  {/* <Route
                    path="/technician-performed-in-office"
                    element={<TPerformedInOffice />}
                  />
                  <Route
                    path="/technician-test-code"
                    element={<TTestOrder />}
                  />
                  <Route
                    path="/technician-drug-code"
                    element={<TDrugCodePage />}
                  />
                  <Route
                    path="/technician-drug-category"
                    element={<TDrugCategoryPage />}
                  />
                  <Route
                    path="/technician-drug-panel"
                    element={<TDrugPanelPage />}
                  />
                  <Route
                    path="/technician-specimen-information"
                    element={<TSpecimen />}
                  />
                  <Route
                    path="/technician-insurance-information"
                    element={<TInsurance />}
                  />
                  <Route
                    path="/technician-result-report-pdf"
                    element={<TResult />}
                  />
                 
                  <Route
                    path="/technician-pending-test-order"
                    element={<TPending />}
                  />
                  <Route
                    path="/technician-scheduler"
                    element={<TScheduler />}
                  /> */}
                  {/* <Route
                    path="/technician-organization-requested-test"
                    element={<TOrganizationReq />}
                  />
                  <Route
                    path="/technician-physician-requested-test"
                    element={<TPhysicianReq />}
                  /> */}
                  {/* <Route
                    path="/technician-clinic-requested-test"
                    element={<TClinicReq />}
                  /> */}

                  {/* clinical scientist */}

                  <Route path="/scientist-dashboard" element={<SDashboard />} />

                  <Route path="/scientist-physician" element={<SPhysician />} />
                  <Route path="/scientist-patients" element={<SPatients />} />
                  <Route
                    path="/scientist-organizations"
                    element={<SOrganization />}
                  />
                  <Route path="/scientist-clinics" element={<SClinics />} />
                  <Route
                    path="/scientist-collection-site"
                    element={<SCollectionSite />}
                  />
                  {/* <Route
                    path="/technician-clinic-requested-test"
                    element={<TClinicReq />}
                  /> */}
                  <Route path="/scientist-collector" element={<SCollector />} />
                  {/* <Route
                    path="/scientist-performed-in-office"
                    element={<SPerformedInOffice />}
                  />
                  <Route path="/scientist-diagnosis" element={<SDiagnisis />} />
                  <Route path="/scientist-test-code" element={<STestCode />} />
                  <Route path="/scientist-drugs" element={<SDrugs />} />
                  <Route
                    path="/scientist-specimen-information"
                    element={<SSpecimenInfo />}
                  /> */}
                  {/* <Route
                    path="/scientist-insurance-information"
                    element={<SInsuranceInfo />}
                  /> */}
                  {/* <Route
                    path="/scientist-result-report-pdf"
                    element={<SResultReport />}
                  /> */}
                  <Route
                    path="/scientist-forensic-test-order"
                    element={<SForensicTest />}
                  />
                  {/* <Route
                    path="/scientist-pending-test-order"
                    element={<SPendingTestOredr />}
                  />
                  <Route
                    path="/scientist-organization-requested-test"
                    element={<SOrganizationReq />}
                  />
                  <Route
                    path="/scientist-physician-requested-test"
                    element={<SPhysicianReq />}
                  />
                  <Route
                    path="/scientist-clinic-requested-test"
                    element={<SClinicReq />}
                  />
                  <Route
                    path="/scientist-export-record"
                    element={<SExportRecord />}
                  />
                  <Route
                    path="/scientist-excel-record-update"
                    element={<SExelRecord />}
                  {/* physician routes */}
                  <Route
                    path="/phyic-dashboard"
                    element={<PhysicDashboard />}
                  />
                  <Route
                    path="/physic-forensicQR"
                    element={<PhysicForensicQR />}
                  />
                  <Route path="/physic-clinic" element={<PhysicClinic />} />
                  <Route
                    path="/physic-organization"
                    element={<PhysicOrganization />}
                  />
                  <Route
                    path="/physician-scientist"
                    element={<PhysicScientist />}
                  />
                  <Route
                    path="/physic-manage-record"
                    element={<PhysicManageRecord />}
                  />
                  <Route path="/physic-patient" element={<PhysicPatient />} />
                  <Route
                    path="/physic-collection-site"
                    element={<PhysicianCollectionSitePage />}
                  />
                  <Route
                    path="/physician-collector"
                    element={<PhysicianCollectorPage />}
                  />
                  <Route
                    path="/physician-technician"
                    element={<PhysicianTechnicianPage />}
                  />
                  <Route
                    path="/physician-patient"
                    element={<PhysicianPatient />}
                  />
                  <Route
                    path="/physic-forensic-test-order"
                    element={<PhysicForensicTestOrder />}
                  />
                  <Route
                    path="/physic-schedule-new-test"
                    element={<PhysicScheduleNewTest />}
                  />

                  {/* patient routes */}
                  <Route
                    path="/patient-dashboard"
                    element={<PatientDashboard />}
                  />
                  <Route
                    path="/patient-result-report"
                    element={<PatientResultReport />}
                  />
                  <Route
                    path="/patient-demographic-qr"
                    element={<PatientDemographicQr />}
                  />
                  <Route
                    path="/patient-test-qr-details"
                    element={<PatientMyTestQr />}
                  />
                  {/* <Route
                    path="/patient-schedule-new-test"
                    element={<PatientScheduleTest />}
                  /> */}
                  {/* <Route
                    path="/patient-scheduled-tests"
                    element={<PatientScheduledTests />}
                  />
                  <Route
                    path="/patient-demographic"
                    element={<PatientDemographic />}
                  /> */}

                  {/* clinic routes */}
                  <Route
                    path="/clinic-dashboard"
                    element={<ClinicDashboard />}
                  />
                  <Route
                    path="/clinic-forensic-qr"
                    element={<ClinicForensicQr />}
                  />
                  <Route path="/clinic-refer-qr" element={<ClinicReferQr />} />
                  {/* <Route
                    path="/clinic-upadated-record"
                    element={<ClinicUpdatedRecord />}
                  /> */}
                  {/* <Route
                    path="/clinic-custom-drug-panel"
                    element={<ClinicCustomDrugPanel />}
                  /> */}
                  <Route
                    path="/clinic-manage-mro"
                    element={<ClinicMedicalReviewOfficer />}
                  />
                  <Route
                    path="/clinic-manage-patient"
                    element={<ClinicManagePatient />}
                  />
                  <Route
                    path="/clinic-manage-site"
                    element={<ClinicManageSite />}
                  />
                  <Route
                    path="/clinic-manage-collector"
                    element={<ClinicManageCollector />}
                  />

                  <Route
                    path="/clinic-forensic-test-order"
                    element={<ClinicForensicTestOrder />}
                  />
                  {/* <Route
                    path="/clinic-test-center"
                    element={<ClinicTestCenter />}
                  /> */}
                  {/* <Route
                    path="/clinic-appointments"
                    element={<ClinicAppointments />}
                  /> */}
                  {/* <Route
                    path="/clinic-schedule-test"
                    element={<ClinicScheduleTest />}
                  /> */}

                  {/* collection site */}
                  <Route path="/site-dashboard" element={<SiteDashboard />} />
                  <Route path="/site-refer-qr" element={<SiteReferQr />} />
                  <Route path="/site-patient" element={<SitePatient />} />
                  <Route path="/site-collector" element={<SiteCollector />} />
                  <Route path="/site-test-order" element={<SiteTestOrder />} />
                  {/* <Route
                    path="/site-test-center"
                    element={<SiteTestCenter />}
                  /> */}
                  {/* <Route
                    path="/site-appointments"
                    element={<SiteAppointments />}
                  /> */}
                  <Route
                    path="/site-forensic-test-order"
                    element={<SiteForensicTestOrder />}
                  />
                  {/* <Route
                    path="/site-schedule-test"
                    element={<SiteScheduleTest />}
                  /> */}

                  {/* organization  */}
                  <Route
                    path="/organization-dashboard"
                    element={<OrgDashboard />}
                  />
                  <Route
                    path="/organization-forensic-qr"
                    element={<OrgForensicQR />}
                  />
                  <Route
                    path="/organization-refer-qr"
                    element={<OrgReferQR />}
                  />
                  <Route
                    path="/organization-manage-patient"
                    element={<OrgManagePatient />}
                  />
                  <Route
                    path="/organization-manage-collector"
                    element={<OrgManageCollector />}
                  />
                  <Route
                    path="/organization-manage-site"
                    element={<OrgManageSite />}
                  />
                  <Route
                    path="/organization-forensic-test-order"
                    element={<OrgForensicTestOrder />}
                  />
                  <Route
                    path="/mroftop"
                    element={<MroForensicTestOrderPage />}
                  />
                  {/* <Route path="" element={<MroForensicOrderForm />} /> */}
                </>
              </Route>
            </Routes>
          </div>
        </FormProvider>
      </DrugProvider>
      <Toaster />
    </ModalProvider>
  );
};

export default App;

// import React from "react";
// import ResetPage from "./pages/auth/ResetPage";
// import LoginPage from "./pages/auth/LoginPage";
// const App = () => {
//   return (
//     <div>
//       <ResetPage />
//     </div>
//   );
// };

// export default App;
