import React from "react";
import { Toaster } from "react-hot-toast";

const CommonModal = ({ onClose, children, heading }) => {
  return (
    <div className="fixed top-0 right-0 bottom-0 left-0 z-[2147483647]">
      <Toaster />
      <div
        className="fixed top-0 right-0 bottom-0 left-0 bg-opacity-50 bg-slate-500 flex justify-center items-center overflow-auto"
        onClick={onClose}
      >
        <div
          className="relative p-6 bg-white rounded shadow-md max-w-[90%] max-h-[90%] overflow-auto"
          style={{ width: "80%", minWidth: "320px", maxWidth: "452px" }}
          onClick={(e) => e.stopPropagation()}
        >
          {/* heading */}
          <div className="flex justify-between items-center mb-8">
            <div className="font-bold text-lg">{heading}</div>
            <span className="cursor-pointer text-lg" onClick={onClose}>
              &times;
            </span>
          </div>
          {/* body */}
          <div className="mb-4">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default CommonModal;
