import React, { useState, useRef, useEffect } from "react";
import mask from "../../assets/Mask group.jpg";
import "./Form.css";
import { LuPencil } from "react-icons/lu";
import SignatureCanvas from "react-signature-canvas";
import PreviousButton from "../buttons/PreviousButton";
import Cancelsmall from "../buttons/Cancelsmall";
import Utility from "../../Utils/Utility";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";

const TechnicianForensicOrderForm = ({ onClose }) => {
  const token = useSelector((state) => state?.user?.token);
  const userIdd = useSelector(
    (state) => state?.user?.userInfo?.data?.user?._id
  );
  const userName = useSelector((state) => state?.user?.userInfo?.data?.user);
  // console.log(">>>>>>>>", userName);

  const [logoo, setLogoo] = useState("");

  useEffect(() => {
    getLogo();
  }, []);
  const getLogo = async () =>
    await HttpClient.apiCaller(`get-logo/${userIdd}`, "GET", null, null, null)
      .then((res) => {
        setLogoo(res?.data?.exisLog?.img_path);
      })
      .catch((err) => console.log(err));

  const [reqData, setReqData] = useState([]);
  // console.log("//////", reqData);

  const handleStepLabDate = () => {
    set_r_date(formattedDate);
  };

  const handleSplitExpDate = () => {
    set_split_specimen_expiration(formattedDate);
  };

  const handlePrimaryExpiration = () => {
    set_primary_specimen_expiration(formattedDate);
  };

  const [accession_no, set_accession_no] = useState(reqData?.accession_no);
  const [specimen_id, set_specimen_id] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      getReqData();
    }, 1000);
    return () => clearTimeout(timer);
  }, [specimen_id]);

  const getReqData = async () => {
    await HttpClient.apiCaller(
      `admin/get-test-requisition/${specimen_id}`,
      "GET",
      null,
      null,
      null
    )
      .then((res) => {
        console.log("++++++", res?.data?.test[0]);
        setReqData(res?.data?.test[0]);
        set_accession_no(res?.data?.test[0]?.accession_no);
      })
      .catch((err) => console.log(err));
  };

  const currentDateTime = new Date();
  const formattedDate = currentDateTime.toISOString().slice(0, 10);
  const formattedTime = currentDateTime.toTimeString().slice(0, 5);

  const handleTechniDate = () => {
    set_p_date(formattedDate);
  };
  const handleSplitTestDate = () => {
    set_s_date(formattedDate);
  };

  // primary_specimen_report
  const [report_result, set_report_result] = useState("");
  const reportResult = (e) => set_report_result(e.target.value);

  const [remark_for_positive, set_remark_for_positive] = useState("");
  const [p_remark, set_p_remark] = useState("");
  const [p_facility, set_p_facility] = useState("");
  const [scientist_name, set_scientist_name] = useState(
    `${userName?.first_name} ${userName?.last_name}`
  );
  const [p_date, set_p_date] = useState("");
  // split_testing_labrotory
  const [reconfirm, set_reconfirm] = useState("");
  const reconfirmm = (e) => set_reconfirm(e.target.value);

  const [delivery_service_name, set_delivery_service_name] = useState(
    reqData?.custody?.released_to
  );
  const [s_scientist_name, set_s_scientist_name] = useState(
    `${userName?.first_name} ${userName?.last_name}`
  );
  const [s_date, set_s_date] = useState("");

  const [psr_sign, set_psr_sign] = useState("");
  const [scientist_sign, set_scientist_sign] = useState("");

  //received at
  const [r_collector_sign, set_r_collector_sign] = useState(""); //image
  const [r_collector_name, set_r_collector_name] = useState("");
  const [r_date, set_r_date] = useState("");
  // const [r_time, set_r_time] = useState("");

  const [r_seal, set_r_seal] = useState("");
  const sealed = (e) => set_r_seal(e.target.value);

  const [r_remark, set_r_remark] = useState("");
  const [r_released_to, set_r_released_to] = useState("");
  const [primary_specimen_expiration, set_primary_specimen_expiration] =
    useState("");
  const [split_specimen_expiration, set_split_specimen_expiration] =
    useState("");

  const [spin, setSpin] = useState(false);
  const [load, setLoad] = useState(false);
  // signature refs
  const collectorSignRef = useRef();
  const collectionSiteSignRef = useRef();
  const accessionerSignRef = useRef();
  const certifyingSignRef = useRef();
  const certifyingScientistSignRef = useRef();
  const medicalReviewSignRef = useRef();
  const medicalReviewOfficerSignRef = useRef();
  const [collectionSiteSign, setCollectionSiteSign] = useState(null);
  const [accessionerSign, setAccessionerSign] = useState(null);
  const [certifyingSign, setCertifyingSign] = useState(null);
  const [certifyingSign2, setCertifyingSign2] = useState(null);
  const [medicalReviewSign, setMedicalReviewSign] = useState(null);
  const [medicalReviewOfficerSign, setMedicalReviewOfficerSign] =
    useState(null);
  const [otherCourier, setOtherCourier] = useState("");

  const [selectOptn, setSelectOptn] = useState("step5A");
  const handleSelectOptn = () => {
    setSelectOptn((prevSelectOptn) =>
      prevSelectOptn === "step5A" ? "step5B" : "step5A"
    );
    set_delivery_service_name(reqData?.custody?.released_to);
  };

  const handleCollectionSiteSignClear = () => {
    collectionSiteSignRef.current.clear();
    setCollectionSiteSign(null);
  };
  const handleAccessionerSignClear = () => {
    accessionerSignRef.current.clear();
    setAccessionerSign(null);
  };
  const handleCertifyingSignClear = () => {
    certifyingSignRef.current.clear();
    setCertifyingSign(null);
  };
  const handleCertifyingSignClear2 = () => {
    certifyingScientistSignRef.current.clear();
    setCertifyingSign2(null);
  };
  const handleMedicalReviewSignClear = () => {
    medicalReviewSignRef.current.clear();
    setMedicalReviewSign(null);
  };
  const handleMedicalReviewOfficerSignClear = () => {
    medicalReviewOfficerSignRef.current.clear();
    setMedicalReviewOfficerSign(null);
  };

  const handleAccessionerSignSave = () => {
    if (accessionerSignRef.current.isEmpty()) {
      alert("Please provide a Accessioner signature.");
    } else {
      const dataUrl = accessionerSignRef.current.toDataURL();
      setAccessionerSign(dataUrl);
    }
  };
  let techSign, techSign2;
  const handleCertifyingSignSave = async () => {
    if (certifyingSignRef.current.isEmpty()) {
      alert("Please provide a certifying signature.");
      return;
    } else {
      // certifyingSign(dataUrl);
      setLoad(true);
      const dataUrl = certifyingSignRef.current.toDataURL();
      const blob = await fetch(dataUrl).then((res) => res.blob());
      const file = new File([blob], "image.png", { type: "image/png" });
      let formData = new FormData();
      formData.append("img", file);
      await HttpClient.apiCaller(
        "upload/single-upload",
        "POST",
        formData,
        null,
        "multipart/form-data"
      )
        .then((res) => {
          console.log(res);
          // set_psr_sign(res?.data?.img);
          techSign = res?.data?.img;
        })
        .catch((err) => console.log(err))
        .finally(() => setLoad(false));
    }
  };
  const handleCertifyingSignSave2 = async () => {
    if (certifyingScientistSignRef.current.isEmpty()) {
      alert("Please provide a certifying signature.");
      return;
    } else {
      // certifyingSign(dataUrl);
      setLoad(true);
      const dataUrl = certifyingScientistSignRef.current.toDataURL();
      const blob = await fetch(dataUrl).then((res) => res.blob());
      const file = new File([blob], "image.png", { type: "image/png" });
      let formData = new FormData();
      formData.append("img", file);
      await HttpClient.apiCaller(
        "upload/single-upload",
        "POST",
        formData,
        null,
        "multipart/form-data"
      )
        .then((res) => {
          console.log(res);
          // set_scientist_sign(res?.data?.img);
          techSign2 = res?.data?.img;
        })
        .catch((err) => console.log(err))
        .finally(() => setLoad(false));
    }
  };
  const handleMedicalReviewSignSave = () => {
    if (medicalReviewSignRef.current.isEmpty()) {
      alert("Please provide a Medical Review signature.");
    } else {
      const dataUrl = medicalReviewSignRef.current.toDataURL();
      setMedicalReviewSign(dataUrl);
    }
  };
  const handleMedicalReviewOfficerSignSave = () => {
    if (medicalReviewOfficerSignRef.current.isEmpty()) {
      alert("Please provide a medical officer signature.");
    } else {
      const dataUrl = medicalReviewOfficerSignRef.current.toDataURL();
      setMedicalReviewOfficerSign(dataUrl);
    }
  };

  const validation = () => {
    console.log("acccc", accession_no);
    if (specimen_id === "" || specimen_id === undefined) {
      Utility.eToast("Specimen Id Required!");
      return false;
    } else if (accession_no === "" || accession_no === undefined) {
      Utility.eToast("Accession no Required!");
      return false;
    } else if (userName?.user_type === "scientist") {
      if (selectOptn === "step5A") {
        if (report_result === "" || report_result === undefined) {
          Utility.eToast("Report result required!");
          return false;
        } else if (scientist_name === "" || scientist_name === undefined) {
          Utility.eToast("Scientist name required!");
          return false;
        } else if (p_date === "" || p_date === undefined) {
          Utility.eToast("date required!");
          return false;
        } else {
          return true;
        }
      }

      if (selectOptn === "step5B") {
        if (reconfirm === "" || reconfirm === undefined) {
          Utility.eToast("reconfirm required!");
          return false;
        } else if (
          delivery_service_name === "" ||
          delivery_service_name === undefined
        ) {
          Utility.eToast("Delivery service name required!");
          return false;
        } else if (s_scientist_name === "" || s_scientist_name === undefined) {
          Utility.eToast("scientist name required!");
          return false;
        } else if (s_date === "" || s_date === undefined) {
          Utility.eToast("date required!");
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else if (!reqData?.received_at) {
      if (r_released_to === "" || r_released_to === undefined) {
        Utility.eToast(" Released to required!");
        return false;
      } else if (r_collector_name === "" || r_collector_name === undefined) {
        Utility.eToast("Collector_name required!");
        return false;
      } else if (r_date === "" || r_date === undefined) {
        Utility.eToast("Collection date required!");
        return false;
      } else if (r_seal === "" || r_seal === undefined) {
        Utility.eToast("seal required!");
        return false;
        // } else if (r_remark === "" || r_remark === undefined) {
        //   Utility.eToast("Seal remark required!");
        //   return false;
      } else if (r_released_to === "" || r_released_to === undefined) {
        Utility.eToast("Released to required!");
        return false;
      } else if (
        primary_specimen_expiration === "" ||
        primary_specimen_expiration === undefined
      ) {
        Utility.eToast("Primary specimen expiration required!");
        return false;
      } else if (
        split_specimen_expiration === "" ||
        split_specimen_expiration === undefined
      ) {
        Utility.eToast("Split specimen expiration required!");
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  // console.log("-=-=-=--=", window.innerWidth <= 650 ? "Hello" : "hi");
  let receiver_accessioner;
  const handleCollectionSiteSignSave = async () => {
    if (collectionSiteSignRef.current.isEmpty()) {
      alert("Please provide a collection site signature.");
      return;
    } else {
      setLoad(true);
      const dataUrl = collectionSiteSignRef.current.toDataURL();
      const blob = await fetch(dataUrl).then((res) => res.blob());
      const file = new File([blob], "image.png", { type: "image/png" });
      let formData = new FormData();
      formData.append("img", file);
      await HttpClient.apiCaller(
        "upload/single-upload",
        "POST",
        formData,
        null,
        "multipart/form-data"
      )
        .then((res) => {
          console.log(res);
          // set_r_collector_sign(res?.data?.img);
          receiver_accessioner = res?.data?.img;
        })
        .catch((err) => console.log(err))
        .finally(() => setLoad(false));
    }
  };

  const forensicDrugFromSave = async () => {
    // if (userName?.user_type === "technician") {
    // }
    // await handleCertifyingSignSave();
    // await handleCertifyingSignSave2();
    // console.log(":::::::::::::::::", userName?.user_type);
    if (validation()) {
      if (!reqData?.received_at) {
        await handleCollectionSiteSignSave();
      }

      if (userName?.user_type === "technician") {
        setSpin(true);
        const putData = {
          _id: reqData?._id,
          test_accession_no_by_tech: userIdd,
          specimen_id: specimen_id,
          accession_no: accession_no,
          received_at: reqData?.received_at
            ? {
                collector_sign: reqData?.received_at?.collector_sign,
                collector_name: reqData?.received_at?.collector_name,
                date: reqData?.received_at?.date,
                // time: r_time,
                seal: reqData?.received_at?.seal,
                remark: reqData?.received_at?.remark,
                released_to: reqData?.received_at?.released_to,
                primary_specimen_expiration:
                  reqData?.received_at?.primary_specimen_expiration,
                split_specimen_expiration:
                  reqData?.received_at?.split_specimen_expiration,
              }
            : {
                collector_sign: receiver_accessioner,
                collector_name: r_collector_name,
                date: r_date,
                // time: r_time,
                seal: r_seal,
                remark: r_remark,
                released_to: r_released_to,
                primary_specimen_expiration: primary_specimen_expiration,
                split_specimen_expiration: split_specimen_expiration,
              },
          // primary_specimen_report: {
          //   report_result: report_result,
          //   remark_for_positive: remark_for_positive,
          //   remark: p_remark,
          //   facility: p_facility,
          //   psr_sign: techSign,
          //   scientist_name: scientist_name,
          //   date: p_date,
          // },
          // split_testing_labrotory: {
          //   reconfirm: reconfirm,
          //   delivery_service_name: delivery_service_name,
          //   scientist_sign: techSign2,
          //   scientist_name: s_scientist_name,
          //   date: s_date,
          // },
        };
        console.log("1>>>>>>>>>", putData);
        await HttpClient.apiCaller("admin/test", "PUT", putData, token, null)
          .then((res) => {
            console.log("<><><>", res);
            Utility.sToast("Report Updated successfully");
            onClose();
          })
          .catch((err) => console.log("?????", err))
          .finally(() => setSpin(false));
      }

      if (userName?.user_type === "scientist") {
        if (selectOptn === "step5A") {
          await handleCertifyingSignSave();
        }
        if (selectOptn === "step5B") {
          await handleCertifyingSignSave2();
        }
        setSpin(true);
        const putData = {
          _id: reqData?._id,
          test_accession_no_by: userIdd,
          specimen_id: specimen_id,
          accession_no: accession_no,
          received_at: reqData?.received_at
            ? {
                collector_sign: reqData?.received_at?.collector_sign,
                collector_name: reqData?.received_at?.collector_name,
                date: reqData?.received_at?.date,
                // time: r_time,
                seal: reqData?.received_at?.seal,
                remark: reqData?.received_at?.remark,
                released_to: reqData?.received_at?.released_to,
                primary_specimen_expiration:
                  reqData?.received_at?.primary_specimen_expiration,
                split_specimen_expiration:
                  reqData?.received_at?.split_specimen_expiration,
              }
            : {
                collector_sign: receiver_accessioner,
                collector_name: r_collector_name,
                date: r_date,
                // time: r_time,
                seal: r_seal,
                remark: r_remark,
                released_to: r_released_to,
                primary_specimen_expiration: primary_specimen_expiration,
                split_specimen_expiration: split_specimen_expiration,
              },
          primary_specimen_report: {
            report_result: report_result,
            remark_for_positive: remark_for_positive,
            remark: p_remark,
            facility: p_facility,
            // psr_sign: psr_sign,
            psr_sign: techSign ? techSign : "",
            scientist_name: scientist_name,
            date: p_date,
          },
          split_testing_labrotory: {
            reconfirm: reconfirm,
            delivery_service_name: delivery_service_name,
            // scientist_sign: scientist_sign,
            scientist_sign: techSign2 ? techSign2 : "",
            scientist_name: s_scientist_name,
            date: s_date,
          },
        };
        console.log("2>>>>>>>>>", putData);
        await HttpClient.apiCaller("admin/test", "PUT", putData, token, null)
          .then((res) => {
            console.log("<><><>", res);
            Utility.sToast("Report Updated successfully");
            onClose();
          })
          .catch((err) => console.log("?????", err))
          .finally(() => setSpin(false));
      }
    }
  };

  return (
    <>
      <div className="">
        <div className=" ">
          <div className="p-5 ml-3 bg-white shadow h-fit">
            <div className="grid grid-cols-12 gap-3">
              <div className="col-span-2" onClick={onClose}>
                <PreviousButton />
              </div>
              <div
                className="col-span-10"
                style={{ fontSize: "1.5rem", fontWeight: "500" }}
              >
                NON-FEDERAL DRUG TESTING CUSTODY AND CONTROL FORM
              </div>

              <div className="col-span-2">
                <img src={logoo} alt="" className="h-12" />
              </div>
              <div
                className={
                  window.innerWidth <= 600 ? "col-span-5" : "col-span-3"
                }
              >
                <div style={{ color: "rgb(156 165 174)" }}>
                  REQUISITION SPECIMEN ID NO.
                </div>
                <div style={{ position: "relative" }}>
                  <input
                    type="text"
                    className="border-2 focus:outline-none rounded-lg w-full "
                    value={specimen_id}
                    onChange={(e) => {
                      set_specimen_id(e.target.value);
                    }}
                  />
                  {/* <LuPencil
                    style={{ position: "absolute", top: "5px", right: "5px" }}
                  /> */}
                </div>
              </div>
              <div
                className={
                  window.innerWidth <= 600 ? "col-span-5" : "col-span-3"
                }
              >
                <div style={{ color: "rgb(156 165 174)" }}>ACCESSION NO.</div>
                <div style={{ position: "relative" }}>
                  <input
                    type="text"
                    className="border-2 focus:outline-none rounded-lg w-full "
                    // value={accession_no}
                    value={accession_no || reqData?.accession_no}
                    onChange={(e) => {
                      set_accession_no(e.target.value);
                    }}
                  />
                  {/* <LuPencil
                    style={{ position: "absolute", top: "5px", right: "5px" }}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* step-1 containt */}
        <div className=" mt-1">
          <div className="p-6 ml-3 bg-white shadow h-fit">
            {/* A and B section */}
            <p className="poppins-medium text-[18px] mb-3">
              Step 1: Completed By Collector Or Employer Representative
            </p>
            <div className="border border-blue-200 p-4 rounded-lg">
              <div className="grid sm:grid-cols-12 sm:gap-12 gap-4">
                <div className="sm:col-span-6 col-span-6">
                  <h1 className="text-[#919AA3] poppins-medium">A. Employer</h1>
                  <div>
                    <p className="poppins-medium">Employer name</p>
                    <input
                      type="text"
                      placeholder="Enter Employer name"
                      className="w-full h-11 mb-2 mt-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
                      value={reqData?.employer_name}
                      disabled
                    />
                    <p className="font-medium">Employer code</p>
                    <input
                      type="text"
                      placeholder="Enter Employer code"
                      className="w-full h-11 mb-2 mt-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
                      value={reqData?.employer_code}
                      disabled
                    />
                    <p className="font-medium">Address</p>
                    <input
                      type="text"
                      placeholder="Enter address"
                      className="w-full h-11 mb-2 mt-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
                      value={reqData?.employer_address}
                      disabled
                    />
                    <p className="font-medium">ID No</p>
                    <input
                      type="text"
                      placeholder="Enter ID No"
                      className="w-full h-11 mb-2 mt-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
                      value={reqData?.employer_id}
                      disabled
                    />
                  </div>
                </div>
                <div className="sm:col-span-6 col-span-6">
                  <h1 className="text-[#919AA3] font-semibold font-Poppins">
                    B. MRO
                  </h1>
                  <div>
                    <p className="font-medium mb-3">Select MRO</p>
                    <select id="countries" className="custom-input" disabled>
                      <option>
                        {/* {reqData?.mro?.name?.first_name}
                        {reqData?.mro?.name?.last_name} */}
                        {reqData?.mro?.name}
                      </option>
                    </select>

                    <p className="font-medium mt-2">Address</p>
                    <input
                      type="text"
                      placeholder="Enter Address"
                      className="w-full h-11 mb-2 mt-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
                      // value={mro_addr}
                      // value={reqData?.mro?.name?.address}
                      value={reqData?.mro?.address}
                      disabled
                    />
                    <p className="font-medium">Phone</p>
                    <input
                      type="text"
                      placeholder="Enter Phone Number"
                      className="w-full h-11 mb-2 mt-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
                      // value={mro_phone}
                      // value={reqData?.mro?.name?.mobile_number}
                      value={reqData?.mro?.phone_number}
                      disabled
                    />
                    <p className="font-medium">Fax No.</p>
                    <input
                      type="text"
                      placeholder="Enter Fax No."
                      className="w-full h-11 mb-2 mt-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
                      // value={reqData?.mro?.name?.faxNo}
                      value={reqData?.mro?.fax_number}
                      // value={phyMisc?.mro_fax}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="dotted-line mt-5"></div>

              {/* C section */}
              <div className="mt-3">
                <h1 className="text-[#919AA3] font-semibold font-Poppins">
                  C. Donor
                </h1>
                <div className="grid grid-cols-12 sm:gap-5 gap-3 mt-3">
                  <div className="sm:col-span-6 col-span-12">
                    <p className="font-medium">First Name</p>
                    <input
                      type="text"
                      placeholder="Enter First Name"
                      className="w-full mt-3 h-11 mb-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
                      value={reqData?.donor_first_name}
                      disabled
                    />
                  </div>
                  <div className="sm:col-span-6 col-span-12">
                    <p className="font-medium">Last Name</p>
                    <input
                      type="text"
                      placeholder="Enter Last Name"
                      className="w-full mt-3 h-11 mb-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
                      value={reqData?.donor_last_name}
                      disabled
                    />
                  </div>
                  <div className="sm:col-span-6 col-span-12">
                    <p className="font-medium">Donor I.D No.</p>
                    <input
                      type="text"
                      placeholder="Enter ID or Number"
                      className="w-full mt-3 h-11 mb-2 border-none focus:outline-none rounded-lg bg-[#ECF1F6] p-2"
                      value={reqData?.donor_id}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="dotted-line mt-5"></div>

              {/* D section */}

              <div className="bg-[#F4F6F9] p-3 mt-3 ">
                <h1 className="text-[#919AA3] font-semibold font-Poppins">
                  D. Donor ID Verification
                </h1>
                <div className="mt-2 grid grid-cols-12 gap-4 mb-3 items-center">
                  <div className="sm:col-span-2 col-span-12">
                    <input
                      type="checkbox"
                      id="employer"
                      name="employer"
                      value="true"
                      className="mr-2 p-1"
                      checked={
                        reqData?.donor_verification?.representative === true
                      }
                      disabled
                    />
                    <label
                      htmlFor="employer"
                      className="font-normal font-Poppins text-[#919AA2]"
                    >
                      Photo ID
                    </label>
                    <br />
                  </div>
                  <div className="sm:col-span-2 col-span-12">
                    <input
                      type="checkbox"
                      id="representative"
                      name="representative"
                      value="true"
                      className="mr-2 p-1"
                      checked={reqData?.donor_verification?.employer === true}
                      disabled
                    />
                    <label
                      htmlFor="
                      representative"
                      className="font-normal font-Poppins text-[#919AA2]"
                    >
                      Employer
                    </label>
                    <br />
                  </div>
                  <div className="sm:col-span-6 col-span-12">
                    <input
                      type="text"
                      placeholder="MRO"
                      className="w-full mt-3 h-11 border-none focus:outline-none mb-2 rounded-lg bg-[#E8EEF3] p-2"
                      value={reqData?.donor_verification?.photo_id}
                      disabled
                    />
                    <span style={{ fontSize: "0.8rem", color: "blue" }}></span>
                  </div>
                </div>
              </div>

              {/*E section */}

              <div className="bg-[#ECF1F6] p-3 mt-5 ">
                <h1 className="text-[#919AA3] font-semibold font-Poppins">
                  E. Reason For Test :
                </h1>
                <div className="grid grid-cols-12 gap-4 mt-4">
                  <div className="sm:col-span-3 col-span-12 gap-3">
                    <input
                      type="radio"
                      id="pre_employment"
                      name="test_reason"
                      value="pre_employment"
                      checked={reqData?.test_reason === "pre_employment"}
                      disabled
                    />
                    <label htmlFor="pre_employment" className="ml-2">
                      Pre-Employement(1)
                    </label>
                    <br />
                  </div>
                  <div className="sm:col-span-3 col-span-12 gap-3">
                    <input
                      type="radio"
                      id="random"
                      name="test_reason"
                      value="random"
                      checked={reqData?.test_reason === "random"}
                      disabled
                    />
                    <label htmlFor="random" className="ml-2">
                      Random(3)
                    </label>
                    <br />
                  </div>
                  <div className="sm:col-span-3 col-span-12 gap-3">
                    <input
                      type="radio"
                      id="resonable_suspicion"
                      name="test_reason"
                      value="resonable_suspicion"
                      checked={reqData?.test_reason === "resonable_suspicion"}
                      disabled
                    />
                    <label htmlFor="resonable_suspicion" className="ml-2">
                      Resonable Suspicion/clause(5)
                    </label>
                    <br />
                  </div>
                  <div className="sm:col-span-3 col-span-12 gap-3">
                    <input
                      type="radio"
                      id="post_accident"
                      name="test_reason"
                      value="post_accident"
                      checked={reqData?.test_reason === "post_accident"}
                      disabled
                    />
                    <label htmlFor="post_accident" className="ml-2">
                      Post-Accident(2)
                    </label>
                    <br />
                  </div>
                  <div className="sm:col-span-3 col-span-12 gap-3 items-center">
                    <input
                      type="radio"
                      id="promotion"
                      name="test_reason"
                      value="promotion"
                      checked={reqData?.test_reason === "promotion"}
                      disabled
                    />
                    <label htmlFor="promotion" className="ml-2">
                      Promotion(22)
                    </label>
                    <br />
                  </div>
                  <div className="sm:col-span-3 col-span-12 gap-3 items-center">
                    <input
                      type="radio"
                      id="return_duty"
                      name="test_reason"
                      value="return_duty"
                      checked={reqData?.test_reason === "return_duty"}
                    />
                    <label htmlFor="return_duty" className="ml-2">
                      Return to Duty(6)
                    </label>
                    <br />
                  </div>
                  <div className="sm:col-span-3 col-span-12 gap-3 items-center">
                    <input
                      type="radio"
                      id="follow_up"
                      name="test_reason"
                      value="follow_up"
                      checked={reqData?.test_reason === "follow_up"}
                      disabled
                    />
                    <label htmlFor="follow_up" className="ml-2">
                      Follow-up(23)
                    </label>
                    <br />
                  </div>
                  <div className="sm:col-span-3 col-span-12">
                    <input
                      type="text"
                      placeholder="Other Specificity(99)"
                      className="w-full  h-10 mb-2 rounded-lg border-none focus:outline-none bg-[#D7E0EE] p-2"
                      value={reqData?.reason_remark}
                      disabled
                    />
                  </div>
                </div>
              </div>

              {/* f section  */}

              <div className="bg-[#F4F6F9] p-3 mt-5 ">
                <h1 className="text-[#919AA3] font-semibold font-Poppins">
                  F. Drug Test To Be Performed
                </h1>
                <div className=" w-full flex items-center p-3 rounded-md mt-5 h-10 mb-2 bg-[#1db6fa] text-white ">
                  <h1>DRUG TEST REQUEST</h1>
                </div>
                <div className="grid grid-cols-12 gap-4 mt-4">
                  <div className="sm:col-span-6 col-span-12">
                    <p className="font-medium">Select A Pannel Name</p>
                    <select
                      id="countries"
                      className="bg-[#E8EEF3] mt-4 border-none focus:outline-none text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                      disabled
                    >
                      <option>
                        {reqData?.custom_drug_test?.map((item, i) => (
                          <span key={i}>{item}, </span>
                        ))}
                      </option>
                    </select>
                    {/* <Multiselect
                      options={drugPanelData}
                      displayValue="panelName"
                      placeholder="Select panel name"
                      onSelect={handleSelect}
                    /> */}
                  </div>
                  <div className="sm:col-span-6 col-span-12">
                    <p className="font-medium">Additional Drug Test</p>
                    <select
                      id="countries"
                      className="bg-[#E8EEF3] mt-4 border-none focus:outline-none text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                      disabled
                    >
                      <option>
                        {reqData?.additional_drug_test?.map((item, i) => (
                          <span key={i}>{item}, </span>
                        ))}
                      </option>
                    </select>
                    {/* <Multiselect
                      options={drugNameData}
                      displayValue="drugName"
                      placeholder="Select additional drug test"
                      onSelect={handleSelectt}
                    /> */}
                  </div>
                </div>
                {/* <textarea
                  name=""
                  id=""
                  rows="3"
                  className="mt-6 w-full bg-[#E8EEF3] p-3 border-none focus:outline-none"
                ></textarea> */}
              </div>

              {/* G section  */}

              <h1 className="text-[#919AA3] mt-4 font-semibold font-Poppins">
                G. Collection Site
              </h1>

              <div className="grid grid-cols-12 mt-4 sm:gap-5 gap-2">
                <div className="sm:col-span-6 col-span-12">
                  <p className="font-medium">Collection Site Name</p>
                  {/* <input
                    type="text"
                    placeholder="Enter collection site name"
                    className="w-full h-11 mb-2 mt-2 rounded-lg bg-[#F3F7FA] p-2 border-none focus:outline-none"
                    value={site_name}
                    onChange={(e) => set_site_name(e.target.value)}
                  /> */}
                  <select
                    name="mro"
                    id="mro"
                    // value={site_name}
                    // onChange={(e) => set_site_name(e.target.value)}
                    className="w-full h-11 mb-2 mt-2 rounded-lg bg-[#F3F7FA] p-2 border-none focus:outline-none"
                    disabled
                  >
                    {/* <option value="">Select Collectionsite</option>
                    {sites?.map((item, i) => (
                      <option value={item?._id} key={i}>
                        {item?.first_name}
                      </option>
                    ))} */}
                    <option value="">
                      {/* {reqData?.site_details?.name?.first_name} */}
                      {reqData?.site_details?.name}
                    </option>
                  </select>
                </div>
                <div className="sm:col-span-6 col-span-12">
                  <p className="font-medium">Collection Site Phone</p>
                  <input
                    type="text"
                    placeholder="Enter collection site phone number"
                    className="w-full h-11 mb-2 mt-2 rounded-lg bg-[#F3F7FA] p-2 border-none focus:outline-none"
                    // value={reqData?.site_details?.name?.mobile_number}
                    value={reqData?.site_details?.phone_number}
                    disabled
                  />
                </div>
                <div className="sm:col-span-6 col-span-12">
                  <p className="font-medium">Address</p>
                  <input
                    type="text"
                    placeholder="Enter address"
                    className="w-full h-11 mb-2 mt-2 rounded-lg bg-[#F3F7FA] p-2 border-none focus:outline-none"
                    // value={reqData?.site_details?.name?.address}
                    value={reqData?.site_details?.address}
                    disabled
                  />
                </div>
                <div className="sm:col-span-6 col-span-12">
                  <p className="font-medium">Collection site fax No.</p>
                  <input
                    type="text"
                    placeholder="Enter collection site fax no."
                    className="w-full h-11 mb-2 mt-2 rounded-lg bg-[#F3F7FA] p-2 border-none focus:outline-none"
                    // value={reqData?.site_details?.name?.faxNo}
                    value={reqData?.site_details?.fax_number}
                    disabled
                  />
                </div>
                <div className="sm:col-span-6 col-span-12">
                  <p className="font-medium">
                    City, Provinance And Postal Code
                  </p>
                  <input
                    type="text"
                    placeholder="Enter city, provinance and postal code"
                    className="w-full h-11 mb-2 mt-2 rounded-lg bg-[#F3F7FA] p-2 border-none focus:outline-none"
                    // value={reqData?.site_details?.name?.postal_code}
                    value={reqData?.site_details?.postal_code}
                    disabled
                  />
                </div>
              </div>
            </div>
            {/* step 2 */}
            <p className=" mt-4 poppins-bold text-[18px]">
              Step 2 : Completed By Collector
            </p>
            <div className="flex flex-row gap-4 mt-4">
              <div className="sm:col-span-1 col-span-6">
                <input
                  type="radio"
                  id="blood"
                  value="blood"
                  name="testType"
                  checked={reqData?.test_type === "blood"}
                  className="mr-2"
                  disabled
                />
                <label htmlFor="blood" className="poppins-medium">
                  Blood
                </label>
              </div>

              <div className="sm:col-span-1 col-span-6">
                <input
                  type="radio"
                  id="urin"
                  value="urine"
                  name="testType"
                  checked={reqData?.test_type === "urine"}
                  disabled
                  className="mr-2"
                />
                <label htmlFor="urin" className="poppins-medium">
                  Urine
                </label>
              </div>

              <div className="sm:col-span-1 col-span-6">
                <input
                  type="radio"
                  id="oral_fluid"
                  value="oral_fluid"
                  name="testType"
                  checked={reqData?.test_type === "oral_fluid"}
                  disabled
                  className="mr-2"
                />
                <label htmlFor="oral_fluid" className="poppins-medium">
                  Oral Fluid
                </label>
              </div>
            </div>
            {/* changeable part */}
            {reqData?.test_type === "blood" && (
              <div className="border border-blue-200 p-4 rounded-lg mt-5">
                <div className=" w-full flex items-center p-3 rounded-md mt-4 h-10 mb-3 bg-[#1db6fa] text-white ">
                  <h1 className="poppins-medium">Collections :</h1>
                </div>
                <div className="grid grid-cols-12 mt-4 ">
                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="single"
                      value="single"
                      checked={
                        reqData?.collections?.collection_type === "single"
                      }
                      disabled
                      name="collections"
                    />
                    <label htmlFor="single" className="ml-2 poppins-regular">
                      Single
                    </label>
                  </div>

                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="split"
                      value="split"
                      checked={
                        reqData?.collections?.collection_type === "split"
                      }
                      disabled
                      name="collections"
                    />
                    <label htmlFor="split" className="ml-2 poppins-regular">
                      Split
                    </label>
                  </div>

                  <div className="sm:col-span-3 col-span-12">
                    <input
                      type="radio"
                      id="non-provided"
                      value="non-provided"
                      checked={
                        reqData?.collections?.collection_type === "non-provided"
                      }
                      disabled
                      name="collections"
                    />
                    <label
                      htmlFor="non-provided"
                      className="ml-2 poppins-regular"
                    >
                      Non-Provided
                    </label>
                  </div>

                  <div className="sm:col-span-3 col-span-12">
                    <input
                      type="radio"
                      id="observed"
                      value="observed"
                      checked={
                        reqData?.collections?.collection_type === "observed"
                      }
                      disabled
                      name="collections"
                    />
                    <label htmlFor="observed" className="ml-2 poppins-regular">
                      Observed
                    </label>
                  </div>
                </div>

                <div className="grid grid-cols-12 mt-3 gap-3">
                  <div className="sm:col-span-6 col-span-12">
                    <div className=" col-span-12  w-full flex items-center sm:p-5 p-[7vw] rounded-md mt-3 h-10 mb-3 bg-[#1db6fa] text-white poppins-medium ">
                      <h1>Each Device Within Expiration Date ?</h1>
                    </div>
                    <div className="grid grid-cols-12 ">
                      <div className="sm:col-span-2 col-span-6">
                        <input
                          type="radio"
                          id="true"
                          value="true"
                          checked={
                            reqData?.collections?.expiration_date === true
                          }
                          disabled
                          name="expDt"
                        />
                        <label htmlFor="true" className="ml-2 poppins-regular">
                          Yes
                        </label>
                      </div>

                      <div className="sm:col-span-4 col-span-6">
                        <input
                          type="radio"
                          id="false"
                          value="false"
                          checked={
                            reqData?.collections?.expiration_date === false
                          }
                          disabled
                          name="expDt"
                        />
                        <label htmlFor="false" className="ml-2 poppins-regular">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="sm:col-span-6 col-span-12">
                    <div>
                      <div className=" w-full flex items-center sm:p-3 p-[7vw] rounded-md mt-3 h-10 mb-3 bg-[#1db6fa] text-white poppins-medium">
                        <h1>Volume Indicator(s) Observed</h1>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="true"
                          value="true"
                          checked={
                            reqData?.collections?.volume_indicator === true
                          }
                          disabled
                          name="volumeindicator"
                        />
                        <label htmlFor="true" className="poppins-regular mr-2">
                          Yes
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <textarea
                  name=" "
                  id=""
                  rows="3"
                  placeholder="Remark"
                  className="w-full bg-[#E8EEF3] mt-5 rounded-md p-3 border-none focus:outline-none poppins-regular"
                  value={reqData?.collections?.remark}
                  disabled
                ></textarea>
              </div>
            )}
            {reqData?.test_type === "urine" && (
              <div className="border border-blue-200 p-4 rounded-lg mt-5">
                <div
                  className=" w-full flex items-center p-3 rounded-md mt-4 h-10 mb-3 bg-[#1075EC] text-white "
                  style={{ background: "#1db6fa" }}
                >
                  <h1 className="poppins-medium">Collections :</h1>
                </div>
                <div className="grid grid-cols-12 mt-4 ">
                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="single"
                      value="single"
                      checked={
                        reqData?.collections?.collection_type === "single"
                      }
                      disabled
                      name="collections"
                    />
                    <label htmlFor="single" className="ml-2 poppins-regular">
                      Single
                    </label>
                  </div>

                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="split"
                      value="split"
                      checked={
                        reqData?.collections?.collection_type === "split"
                      }
                      disabled
                      name="collections"
                    />
                    <label htmlFor="split" className="ml-2 poppins-regular">
                      Split
                    </label>
                  </div>

                  <div className="sm:col-span-3 col-span-12">
                    <input
                      type="radio"
                      id="non-provided"
                      value="non-provided"
                      checked={
                        reqData?.collections?.collection_type === "non-provided"
                      }
                      disabled
                      name="collections"
                    />
                    <label
                      htmlFor="non-provided"
                      className="ml-2 poppins-regular"
                    >
                      Non-Provided
                    </label>
                  </div>

                  <div className="sm:col-span-3 col-span-12">
                    <input
                      type="radio"
                      id="observed"
                      value="observed"
                      checked={
                        reqData?.collections?.collection_type === "observed"
                      }
                      disabled
                      name="collections"
                    />
                    <label htmlFor="observed" className="ml-2 poppins-regular">
                      Observed
                    </label>
                  </div>
                </div>
                <div
                  className=" w-full flex items-center p-3 rounded-md mt-5 h-10 mb-3 bg-[#1075EC] text-white "
                  style={{ background: "#1db6fa" }}
                >
                  <h1 className="poppins-medium">
                    Urine : Read Specimen Temperature With 4 Minutes. Is
                    Temperature Between 32°C And 38°C?
                  </h1>
                </div>
                <div className="grid grid-cols-12 mt-5 gap-3">
                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="yes"
                      value="yes"
                      name="urinetemp"
                      checked={
                        reqData?.collections?.urine_temperature === "yes"
                      }
                      disabled
                      className="mr-2"
                    />
                    <label htmlFor="yes" className="ml-2 poppins-regular">
                      Yes
                    </label>
                  </div>

                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="no"
                      checked={reqData?.collections?.urine_temperature === "no"}
                      value="no"
                      disabled
                      name="urinetemp"
                      className="mr-2"
                    />
                    <label htmlFor="no" className="ml-2 poppins-regular">
                      No
                    </label>
                  </div>

                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="observed"
                      checked={
                        reqData?.collections?.urine_temperature === "observed"
                      }
                      value="observed"
                      disabled
                      name="urinetemp"
                      className="mr-2"
                    />
                    <label htmlFor="observed" className="ml-2 poppins-regular">
                      Observed
                    </label>
                  </div>
                </div>

                <textarea
                  name=" "
                  id=""
                  rows="3"
                  placeholder="Remark"
                  className="w-full bg-[#E8EEF3] mt-5 rounded-md p-3 border-none focus:outline-none poppins-regular"
                  value={reqData?.collections?.remark}
                  // onChange={(e) => set_remark(e.target.value)}
                ></textarea>
              </div>
            )}
            {reqData?.test_type === "oral_fluid" && (
              <div className="border border-blue-200 p-4 rounded-lg mt-5">
                <div
                  className=" w-full flex items-center p-3 rounded-md mt-4 h-10 mb-3 bg-[#1075EC] text-white "
                  style={{ background: "#1db6fa" }}
                >
                  <h1 className="poppins-medium">Collections :</h1>
                </div>
                <div className="grid grid-cols-12 mt-4 ">
                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="single"
                      value="single"
                      checked={
                        reqData?.collections?.collection_type === "single"
                      }
                      disabled
                      name="collections"
                    />
                    <label htmlFor="single" className="ml-2 poppins-regular">
                      Single
                    </label>
                  </div>

                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="split"
                      value="split"
                      checked={
                        reqData?.collections?.collection_type === "split"
                      }
                      disabled
                      name="collections"
                    />
                    <label htmlFor="split" className="ml-2 poppins-regular">
                      Split
                    </label>
                  </div>

                  <div className="sm:col-span-3 col-span-12">
                    <input
                      type="radio"
                      id="non-provided"
                      value="non-provided"
                      checked={
                        reqData?.collections?.collection_type === "non-provided"
                      }
                      disabled
                      name="collections"
                    />
                    <label
                      htmlFor="non-provided"
                      className="ml-2 poppins-regular"
                    >
                      Non-Provided (Enter Remark)
                    </label>
                  </div>

                  <div className="sm:col-span-3 col-span-12">
                    <input
                      type="radio"
                      id="observed"
                      value="observed"
                      checked={
                        reqData?.collections?.collection_type === "observed"
                      }
                      disabled
                      name="collections"
                    />
                    <label htmlFor="observed" className="ml-2 poppins-regular">
                      Observed (Enter Remark)
                    </label>
                  </div>
                </div>
                <div
                  className=" w-full flex items-center p-3 rounded-md mt-5 h-10 mb-3 bg-[#1075EC] text-white "
                  style={{ background: "#1db6fa" }}
                >
                  <h1 className="poppins-medium">Oral fluid: Split Type:</h1>
                </div>
                <div className="grid grid-cols-12 mt-5 gap-3">
                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="Serial"
                      value="Serial"
                      checked={reqData?.collections?.split_type === "Serial"}
                      disabled
                      name="oralfluid"
                    />
                    <label htmlFor="Serial" className="ml-2 poppins-regular">
                      Serial
                    </label>
                  </div>

                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="concurrent"
                      value="concurrent"
                      checked={
                        reqData?.collections?.split_type === "concurrent"
                      }
                      disabled
                      name="oralfluid"
                    />
                    <label
                      htmlFor="concurrent"
                      className="ml-2 poppins-regular"
                    >
                      Concurrent
                    </label>
                  </div>

                  <div className="sm:col-span-2 col-span-6">
                    <input
                      type="radio"
                      id="subdivided"
                      value="subdivided"
                      checked={
                        reqData?.collections?.split_type === "subdivided"
                      }
                      disabled
                      name="oralfluid"
                    />
                    <label
                      htmlFor="subdivided"
                      className="ml-2 poppins-regular"
                    >
                      Subdivided
                    </label>
                  </div>
                </div>
                <div className="grid grid-cols-12 mt-3 gap-3">
                  <div className="sm:col-span-6 col-span-12">
                    <div
                      className=" col-span-12  w-full flex items-center sm:p-5 p-[7vw] rounded-md mt-3 h-10 mb-3 bg-[#1075EC] text-white poppins-medium "
                      style={{ background: "#1db6fa" }}
                    >
                      <h1>Each Device Within Expiration Date ?</h1>
                    </div>
                    <div className="grid grid-cols-12 ">
                      <div className="sm:col-span-2 col-span-6">
                        <input
                          type="radio"
                          id="true"
                          value="true"
                          checked={
                            reqData?.collections?.expiration_date === true
                          }
                          disabled
                          name="expDt"
                        />
                        <label htmlFor="true" className="ml-2 poppins-regular">
                          Yes
                        </label>
                      </div>

                      <div className="sm:col-span-4 col-span-6">
                        <input
                          type="radio"
                          id="false"
                          value="false"
                          checked={
                            reqData?.collections?.expiration_date === false
                          }
                          disabled
                          name="expDt"
                        />
                        <label htmlFor="false" className="ml-2 poppins-regular">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="sm:col-span-6 col-span-12">
                    <div>
                      <div
                        className=" w-full flex items-center sm:p-3 p-[7vw] rounded-md mt-3 h-10 mb-3 bg-[#1075EC] text-white poppins-medium"
                        style={{ background: "#1db6fa" }}
                      >
                        <h1>Volume Indicator(s) Observed</h1>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="true"
                          value="true"
                          checked={
                            reqData?.collections?.volume_indicator === true
                          }
                          disabled
                          name="volumeindicator"
                        />
                        <label htmlFor="true" className="poppins-regular mr-2">
                          Yes
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <textarea
                  name=" "
                  id=""
                  rows="3"
                  placeholder="Remark"
                  className="w-full bg-[#E8EEF3] mt-5 rounded-md p-3 border-none focus:outline-none poppins-regular"
                  value={reqData?.collections?.remark}
                  disabled
                ></textarea>
              </div>
            )}

            <div className="grid grid-cols-1 mt-5">
              <p className="font-Poppins font-medium mb-4">
                Step 3 : Collector affixes bottle seal(s) to bottle(s).
                Collector dates seal(s). Donor initials seal(s). Donor Completes
                step5.
              </p>
              <p className="font-Poppins font-medium text-[18px] ">
                Step 4 : Chain of Custody - Initiated by Collector and Completed
                by Laboratory
              </p>
            </div>

            {/* step-4 */}
            <div className="border border-blue-200 p-4 rounded-lg mt-5">
              <p className="grid grid-cols-1 w-full mt-3">
                I certify that the specimen given to me by the donor identified
                in the certification section on copy 1 of this form was
                collected,labeled,sealed and released to the Delivery Service
                noted in accordance with application requirement
              </p>
              <div className="mt-10 grid grid-cols-12 gap-5 ">
                <div className="sm:col-span-5 col-span-12">
                  <div className="grid grid-cols-1">
                    <div>
                      <b>Signature Of The Collector</b>
                      <div className="relative">
                        {/* <SignatureCanvas
                          ref={collectorSignRef}
                          penColor="black"
                          canvasProps={{
                            className: "signature-canvas",
                            width: 400,
                            height: 200,
                          }}
                          className="border border-solid border-black mb-4"
                        /> */}
                        <img
                          src={reqData?.custody?.collector_sign}
                          alt=""
                          style={{ width: 400, height: 200 }}
                        />

                        <div className="absolute bottom-0 left-0 w-[25vw] border-b border-black"></div>
                      </div>
                      {/* <div className="w-[25vw] flex justify-end mt-2 items-center gap-3">
                        <button
                          // onClick={handleCollectorSignClear}
                          className="bg-[#E8EEF3] px-2 py-1 rounded poppins-medium hover:opacity-90"
                          disabled
                        >
                          clear
                        </button>
                        <button
                          // onClick={handleCollectorSignSave}
                          className="bg-theme-blue text-white rounded px-2 py-1 poppins-medium hover:opacity-90"
                          style={{ background: "#1db6fa" }}
                          disabled
                        >
                          save
                        </button>
                      </div> */}
                    </div>

                    <p className="mt-20 font-medium">
                      Collector's Name (First,Middle,Last)
                    </p>
                    <input
                      type="text"
                      placeholder="Enter Full name"
                      className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                      value={reqData?.custody?.collector_name}
                      disabled
                    />
                  </div>
                  <div className="grid grid-cols-1">
                    <div>
                      <p className="font-medium">Select Date</p>
                      <input
                        type="date"
                        // value={date}
                        // onChange={(e) => setDate(e.target.value)}
                        className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                        value={reqData?.custody?.date}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-12 gap-4">
                    <div className="sm:col-span-6 col-span-12">
                      <p className="font-medium">Time of collection</p>
                      <input
                        type="time"
                        // value={time}
                        // onChange={(e) => setTime(e.target.value)}
                        className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 mr-2 border-none focus:outline-none"
                        value={reqData?.custody?.time}
                        disabled
                      />
                    </div>
                    <div
                      // onClick={handleStep4Date}
                      className="sm:col-span-6 mt-3 col-span-12"
                    >
                      <p className="mt-[-8px] font-medium ">Choose Default</p>
                      <div className="mt-2.5 col-span-12 w-full">
                        <button
                          className="p-1.5 pl-7 pr-7 rounded-lg w-full bg-blue-600 font-normal text-white"
                          style={{ background: "#1db6fa" }}
                          disabled
                        >
                          Today's Date & Time
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-1 sm:block hidden">
                  <div class="w-1 h-[500px] border-l-5 border-l-2 border-sienna  border-dotted"></div>
                </div>
                <div className="sm:col-span-5 col-span-12 sm:ml-[-4vw]">
                  <h1 className="font-Poppins font-medium text-[19px] mb-4">
                    Specimen Bottle(s) Release To:
                  </h1>
                  <div className="mt-5 flex mb-3">
                    <div className="flex gap-3">
                      <input
                        type="radio"
                        id="DHL"
                        name="specimenbottle"
                        value="DHL"
                        checked={reqData?.custody?.released_to === "DHL"}
                        disabled
                      />
                      <label htmlFor="DHL">DHL</label>
                      <br />
                    </div>
                    <div className="flex gap-3">
                      <input
                        type="radio"
                        id="Purolator"
                        name="specimenbottle"
                        value="Purolator"
                        checked={reqData?.custody?.released_to === "Purolator"}
                        disabled
                      />
                      <label htmlFor="Purolator">Purolator</label>
                      <br />
                    </div>
                    <div className="flex gap-3">
                      <input
                        type="radio"
                        id="FEDEX"
                        name="specimenbottle"
                        value="FEDEX"
                        checked={reqData?.custody?.released_to === "FEDEX"}
                        disabled
                      />
                      <label htmlFor="FEDEX">FEDEX</label>
                    </div>
                  </div>

                  <input
                    type="text"
                    placeholder="Other's"
                    className="sm:w-[38vw] w-full h-10 mt-5 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                    value={reqData?.custody?.otherCourier}
                    disabled
                  />
                  <h1 className="font-medium" style={{ marginTop: "2rem" }}>
                    Name of Delivery Service Transferring Specimen to Lab
                  </h1>
                </div>
              </div>

              <div className="dotted-line mt-7 text-gray-300"></div>

              {reqData?.received_at ? (
                <>
                  <h2 className=" mt-2 sm:absolute relative  font-Poppins font-medium text-[19px]">
                    Recieved At Lab :
                  </h2>
                  <div className="mt-16 grid grid-cols-12 sm:gap-2 gap-5">
                    <div className="sm:col-span-5 col-span-12">
                      <div className="grid grid-cols-1">
                        <div>
                          <b>Signature of Accessioner</b>
                          <div className="relative">
                            <img
                              src={reqData?.received_at?.collector_sign}
                              alt=""
                              style={{ width: 400, height: 200 }}
                            />
                            <div className="absolute bottom-0 left-0 w-[25vw] border-b border-black"></div>
                          </div>
                          <div className="w-[25vw] flex justify-end mt-2 items-center gap-3">
                            <button
                              onClick={handleCollectionSiteSignClear}
                              className="bg-[#E8EEF3] px-2 py-1 rounded poppins-medium hover:opacity-90"
                              disabled
                            >
                              clear
                            </button>
                          </div>
                        </div>
                        <p className="mt-10 font-medium">
                          Accessioner's Name(First, Middle, Last)
                        </p>
                        <input
                          type="text"
                          placeholder="Enter Full name"
                          className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                          value={reqData?.received_at?.collector_name}
                          // onChange={(e) => set_r_collector_name(e.target.value)}
                        />
                      </div>

                      <div className="grid grid-cols-12 gap-4">
                        <div className="sm:col-span-6 col-span-12">
                          <p className="font-medium">Select Date</p>
                          <input
                            type="date"
                            // value={accessinerdate}
                            // onChange={(e) => setTime(e.target.value)}
                            className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 mr-2 border-none focus:outline-none"
                            value={reqData?.received_at?.date}
                            // onChange={(e) => set_r_date(e.target.value)}
                          />
                        </div>
                        <div
                          onClick={handleStepLabDate}
                          className="sm:col-span-6 mt-3 col-span-12"
                          disabled
                        >
                          <p className="mt-[-8px] font-medium ">
                            Choose Default
                          </p>
                          <div className="mt-2.5 col-span-12 w-full">
                            <button
                              className="p-1.5 pl-7 pr-7 rounded-lg w-full bg-blue-600 font-normal text-white"
                              style={{ background: "#1db6fa" }}
                              disabled
                            >
                              Today's Date
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-1"></div>
                    <div className="sm:col-span-6 col-span-12 sm:mt-[-8vh] sm:ml-[-4vw]">
                      <h1 className="font-Poppins font-medium text-[19px] mb-4">
                        Primary Specimen Bottle Seal Intact
                      </h1>
                      <div className="mt-5 flex gap-5 mb-3">
                        <div className="flex gap-3">
                          <input
                            type="radio"
                            id="true"
                            name="prmspice"
                            value="true"
                            checked={reqData?.received_at?.seal === true}
                            // onChange={sealed}
                          />
                          <label htmlFor="true">Yes</label>
                        </div>
                        <div className="flex gap-3">
                          <input
                            type="radio"
                            id="false"
                            name="prmspice"
                            value="false"
                            checked={reqData?.received_at?.seal === false}
                            onChange={sealed}
                          />
                          <label htmlFor="false">No</label>
                        </div>
                      </div>
                      <textarea
                        name=""
                        id=""
                        rows="3"
                        placeholder="Remark"
                        className="w-full p-3 mt-3 rounded-lg bg-[#ECF1F6]  border-none focus:outline-none relative"
                        value={reqData?.received_at?.remark}
                        // onChange={(e) => set_r_remark(e.target.value)}
                      ></textarea>
                      <h1 className="font-medium mt-5">
                        Specimen Bottles Released To :
                      </h1>
                      <input
                        type="text"
                        placeholder="Other's"
                        className="w-full h-10 mt-5 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                        value={reqData?.received_at?.released_to}
                        // onChange={(e) => set_r_released_to(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="dotted-line mt-9 mb-4"></div>
                  <h1 className="w-full mb-3  font-medium">
                    Primary/Single Specimen Device Expiration Date :
                  </h1>
                  <div className="grid grid-cols-12 gap-6">
                    <div className="sm:col-span-6 grid grid-cols-12 col-span-12 gap-2">
                      <div className="sm:col-span-6 col-span-11">
                        <p className="font-medium">Select Date</p>
                        <input
                          type="date"
                          // value={singlespecimendate}
                          // onChange={(e) => setTime(e.target.value)}
                          className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 mr-2 border-none focus:outline-none"
                          value={
                            reqData?.received_at?.primary_specimen_expiration
                          }
                          // onChange={(e) =>
                          //   set_primary_specimen_expiration(e.target.value)
                          // }
                        />
                      </div>
                      <div
                        onClick={handlePrimaryExpiration}
                        className="sm:col-span-6 mt-3 col-span-11"
                        disabled
                      >
                        <p className="mt-[-8px] font-medium ">Choose Default</p>
                        <div className="mt-2.5 sm:col-span-6 col-span-12">
                          <button
                            className="p-1.5 pl-7 pr-7 rounded-lg w-full bg-blue-600 font-normal text-white"
                            style={{ background: "#1db6fa" }}
                            disabled
                          >
                            Today's date
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="sm:col-span-6 col-span-12">
                      <div>
                        <h1 className="w-full sm:mt-[-5vh] font-medium">
                          Split Specimen Device Expiration Date :
                        </h1>
                      </div>
                      <div className="grid grid-cols-12 gap-5 mt-3">
                        <div className="sm:col-span-6 col-span-11">
                          <p className="font-medium">Select Date</p>
                          <input
                            type="date"
                            // value={splitspecimendate}
                            // onChange={(e) => setDate(e.target.value)}
                            className="w-full relative h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                            value={
                              reqData?.received_at?.split_specimen_expiration
                            }
                            // onChange={(e) =>
                            //   set_split_specimen_expiration(e.target.value)
                            // }
                          />
                        </div>

                        <div
                          onClick={handleSplitExpDate}
                          className="sm:col-span-6 sm:mt-6 col-span-11  items-center"
                          disabled
                        >
                          <p className="sm:mt-[-3vh] font-medium  ">
                            Choose Default
                          </p>
                          <div className="mt-2.5 sm:col-span-6 col-span-12">
                            <button
                              className="p-1.5 pl-7 pr-7 rounded-lg w-full  bg-blue-600 font-normal text-white"
                              style={{ background: "#1db6fa" }}
                              disabled
                            >
                              Today's date
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <h2 className=" mt-2 sm:absolute relative  font-Poppins font-medium text-[19px]">
                    Recieved At Lab :
                  </h2>
                  <div className="mt-16 grid grid-cols-12 sm:gap-2 gap-5">
                    <div className="sm:col-span-5 col-span-12">
                      <div className="grid grid-cols-1">
                        <div>
                          <b>Signature of Accessioner</b>
                          <div className="relative">
                            <SignatureCanvas
                              ref={collectionSiteSignRef}
                              penColor="black"
                              canvasProps={{
                                className: "signature-canvas",
                                width: 400,
                                height: 200,
                              }}
                              className="border border-solid border-black mb-4"
                            />
                            <div className="absolute bottom-0 left-0 w-[25vw] border-b border-black"></div>
                          </div>
                          <div className="w-[25vw] flex justify-end mt-2 items-center gap-3">
                            <button
                              onClick={handleCollectionSiteSignClear}
                              className="bg-[#E8EEF3] px-2 py-1 rounded poppins-medium hover:opacity-90"
                            >
                              clear
                            </button>
                          </div>
                        </div>
                        <p className="mt-10 font-medium">
                          Accessioner's Name(First, Middle, Last)
                        </p>
                        <input
                          type="text"
                          placeholder="Enter Full name"
                          className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                          value={r_collector_name}
                          onChange={(e) => set_r_collector_name(e.target.value)}
                        />
                      </div>

                      <div className="grid grid-cols-12 gap-4">
                        <div className="sm:col-span-6 col-span-12">
                          <p className="font-medium">Select Date</p>
                          <input
                            type="date"
                            // value={accessinerdate}
                            // onChange={(e) => setTime(e.target.value)}
                            className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 mr-2 border-none focus:outline-none"
                            value={r_date}
                            onChange={(e) => set_r_date(e.target.value)}
                          />
                        </div>
                        <div
                          onClick={handleStepLabDate}
                          className="sm:col-span-6 mt-3 col-span-12"
                        >
                          <p className="mt-[-8px] font-medium ">
                            Choose Default
                          </p>
                          <div className="mt-2.5 col-span-12 w-full">
                            <button
                              className="p-1.5 pl-7 pr-7 rounded-lg w-full bg-blue-600 font-normal text-white"
                              style={{ background: "#1db6fa" }}
                            >
                              Today's Date
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-1"></div>
                    <div className="sm:col-span-6 col-span-12 sm:mt-[-8vh] sm:ml-[-4vw]">
                      <h1 className="font-Poppins font-medium text-[19px] mb-4">
                        Primary Specimen Bottle Seal Intact
                      </h1>
                      <div className="mt-5 flex gap-5 mb-3">
                        <div className="flex gap-3">
                          <input
                            type="radio"
                            id="true"
                            name="prmspice"
                            value="true"
                            checked={r_seal === "true"}
                            onChange={sealed}
                          />
                          <label htmlFor="true">Yes</label>
                        </div>
                        <div className="flex gap-3">
                          <input
                            type="radio"
                            id="false"
                            name="prmspice"
                            value="false"
                            checked={r_seal === "false"}
                            onChange={sealed}
                          />
                          <label htmlFor="false">No</label>
                        </div>
                      </div>
                      <textarea
                        name=""
                        id=""
                        rows="3"
                        placeholder="Remark"
                        className="w-full p-3 mt-3 rounded-lg bg-[#ECF1F6]  border-none focus:outline-none relative"
                        value={r_remark}
                        onChange={(e) => set_r_remark(e.target.value)}
                      ></textarea>
                      <h1 className="font-medium mt-5">
                        Specimen Bottles Released To :
                      </h1>
                      <input
                        type="text"
                        placeholder="Other's"
                        className="w-full h-10 mt-5 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                        value={r_released_to}
                        onChange={(e) => set_r_released_to(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="dotted-line mt-9 mb-4"></div>
                  <h1 className="w-full mb-3  font-medium">
                    Primary/Single Specimen Device Expiration Date :
                  </h1>
                  <div className="grid grid-cols-12 gap-6">
                    <div className="sm:col-span-6 grid grid-cols-12 col-span-12 gap-2">
                      <div className="sm:col-span-6 col-span-11">
                        <p className="font-medium">Select Date</p>
                        <input
                          type="date"
                          // value={singlespecimendate}
                          // onChange={(e) => setTime(e.target.value)}
                          className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 mr-2 border-none focus:outline-none"
                          value={primary_specimen_expiration}
                          onChange={(e) =>
                            set_primary_specimen_expiration(e.target.value)
                          }
                        />
                      </div>
                      <div
                        onClick={handlePrimaryExpiration}
                        className="sm:col-span-6 mt-3 col-span-11"
                      >
                        <p className="mt-[-8px] font-medium ">Choose Default</p>
                        <div className="mt-2.5 sm:col-span-6 col-span-12">
                          <button
                            className="p-1.5 pl-7 pr-7 rounded-lg w-full bg-blue-600 font-normal text-white"
                            style={{ background: "#1db6fa" }}
                          >
                            Today's date
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="sm:col-span-6 col-span-12">
                      <div>
                        <h1 className="w-full sm:mt-[-5vh] font-medium">
                          Split Specimen Device Expiration Date :
                        </h1>
                      </div>
                      <div className="grid grid-cols-12 gap-5 mt-3">
                        <div className="sm:col-span-6 col-span-11">
                          <p className="font-medium">Select Date</p>
                          <input
                            type="date"
                            // value={splitspecimendate}
                            // onChange={(e) => setDate(e.target.value)}
                            className="w-full relative h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                            value={split_specimen_expiration}
                            onChange={(e) =>
                              set_split_specimen_expiration(e.target.value)
                            }
                          />
                        </div>

                        <div
                          onClick={handleSplitExpDate}
                          className="sm:col-span-6 sm:mt-6 col-span-11  items-center"
                        >
                          <p className="sm:mt-[-3vh] font-medium  ">
                            Choose Default
                          </p>
                          <div className="mt-2.5 sm:col-span-6 col-span-12">
                            <button
                              className="p-1.5 pl-7 pr-7 rounded-lg w-full  bg-blue-600 font-normal text-white"
                              style={{ background: "#1db6fa" }}
                            >
                              Today's date
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            {userName?.user_type === "scientist" ||
            userName?.user_type === "technician" ? null : (
              <>
                <div className=" mt-7 mb-7 font-medium text-[20px] font-Poppins">
                  <h1>Step 5: Complete by Donor</h1>
                </div>

                {/* step-5 */}

                <div className="border border-blue-200 p-4 rounded-lg">
                  <h1 className="mb-6 grid grid-cols-1 mt-1">
                    I certify that I provided my specimen to the collector; that
                    I have not adulterated it in any manner; each specimen
                    bottle used was sealed with a tamper-evident seal in my
                    presence; and that the information and numbers provided on
                    this form and on the label affixed to each specimen bottle
                    is correct; and allow my sample and data to leave the
                    country, if required.
                  </h1>
                  <div className="grid grid-cols-1">
                    <b>Signature of Donor</b>
                    <div className="relative">
                      {/* <SignatureCanvas
                    ref={accessionerSignRef}
                    penColor="black"
                    canvasProps={{
                      className: "signature-canvas",
                      width: 400,
                      height: 200,
                    }}
                    className="border border-solid border-black mb-4"
                  /> */}
                      <img
                        src={reqData?.donor_sign}
                        alt=""
                        style={{ width: 400, height: 200 }}
                      />
                      {/* Draw the line below the signature */}
                      <div className="absolute bottom-0 left-0 w-[25vw] border-b border-black"></div>
                    </div>
                    {/* <div className="w-[25vw] flex justify-end mt-2 items-center gap-3">
                  <button
                    onClick={handleAccessionerSignClear}
                    className="bg-[#E8EEF3] px-2 py-1 rounded poppins-medium hover:opacity-90"
                    disabled
                  >
                    clear
                  </button>
                  <button
                    onClick={handleAccessionerSignSave}
                    className="bg-theme-blue text-white rounded px-2 py-1 poppins-medium hover:opacity-90"
                    style={{ background: "#1db6fa" }}
                    disabled
                  >
                    save
                  </button>
                </div> */}
                  </div>
                  <div className="grid grid-cols-12 mt-5 sm:gap-7 gap-3">
                    <div className="sm:col-span-3 col-span-12">
                      <p className=" font-medium mb-2">Daytime phone</p>
                      <input
                        type="text"
                        placeholder="Enter day time phone number"
                        className="w-full h-10 mb-2 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                        value={reqData?.donor_day_no}
                        disabled
                      />
                    </div>
                    <div className="sm:col-span-3 col-span-12">
                      <p className=" font-medium mb-2"> Evening phone</p>
                      <input
                        type="text"
                        placeholder="Evening phone number"
                        className="w-full h-10 mb-2 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                        value={reqData?.donor_eve_no}
                        disabled
                      />
                    </div>
                    <div className="sm:col-span-3 col-span-12">
                      <p className=" font-medium mb-2">Donor's First name</p>
                      <input
                        type="text"
                        placeholder="Donor's First name"
                        className="w-full h-10 mb-2 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                        value={reqData?.donor_first_name}
                        disabled
                      />
                    </div>
                    <div className="sm:col-span-3 col-span-12">
                      <p className=" font-medium mb-2"> Donor's last name </p>
                      <input
                        type="text"
                        placeholder="Donor last name"
                        className="w-full h-10 mb-2 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                        value={reqData?.donor_last_name}
                        disabled
                      />
                    </div>
                    <div className="sm:col-span-3 col-span-12">
                      <p className=" font-medium mb-2">D.O.B</p>
                      <input
                        type="date"
                        // value={donordate}
                        // onChange={(e) => setDate(e.target.value)}
                        placeholder="select date"
                        className="w-full h-10 mb-4  rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                        value={reqData?.donor_dob}
                        disabled
                      />
                    </div>
                    <div className="sm:col-span-3 col-span-12">
                      <p className=" font-medium mb-2">Date</p>
                      <input
                        type="date"
                        placeholder="select date"
                        className="w-full h-10 mb-4  rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                        value={reqData?.donor_date}
                        disabled
                      />
                    </div>
                    <div
                      // onClick={handleDonorDate}
                      className="sm:col-span-4 col-span-12"
                    >
                      <p className="font-medium mb-2">Choose Default</p>
                      <button
                        className="p-1.5 pl-7 pr-7 rounded-lg w-full bg-blue-600 font-normal text-white"
                        style={{ background: "#1db6fa" }}
                        disabled
                      >
                        Today's date
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* step 5 A */}
            {userName?.user_type === "technician" ? null : (
              <>
                <div
                  className="border border-blue-200 rounded-lg mt-5 pl-5"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Do you want to fill
                  <span style={{ fontWeight: "500", margin: "0 10px" }}>
                    Step-5A
                  </span>
                  <label class="inline-flex items-center mb-5 cursor-pointer">
                    <input
                      type="checkbox"
                      class="sr-only peer"
                      onClick={handleSelectOptn}
                    />
                    <div class="relative w-9 h-5 bg-green-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-green-600 mt-5"></div>
                  </label>
                  <span style={{ fontWeight: "500", margin: "0 10px" }}>
                    Step-5B
                  </span>
                </div>
                <div className=" mt-7 mb-7 font-medium text-[20px] font-Poppins">
                  {" "}
                  <h1>
                    Step 5A : PrimarySpecimen Report - Completed by Test
                    Facility
                  </h1>
                </div>
                <div className="border border-blue-200 p-4 rounded-lg">
                  <div className="grid grid-cols-12 ">
                    <div className="sm:col-span-2 col-span-6">
                      <input
                        type="radio"
                        id="positive"
                        name="step5a"
                        value="positive"
                        checked={report_result === "positive"}
                        onChange={reportResult}
                        disabled={selectOptn === "step5B"}
                      />
                      <label htmlFor="positive" className="ml-2">
                        POSITIVE
                      </label>
                      <br />
                    </div>
                    <div className="sm:col-span-2 col-span-6">
                      <input
                        type="radio"
                        id="negative"
                        name="step5a"
                        value="negative"
                        checked={report_result === "negative"}
                        onChange={reportResult}
                        disabled={selectOptn === "step5B"}
                      />
                      <label htmlFor="negative" className="ml-2">
                        NEGATIVE
                      </label>
                      <br />
                    </div>
                    <div className="sm:col-span-2 col-span-6">
                      <input
                        type="radio"
                        id="dilute"
                        name="step5a"
                        value="dilute"
                        checked={report_result === "dilute"}
                        onChange={reportResult}
                        disabled={selectOptn === "step5B"}
                      />
                      <label htmlFor="dilute" className="ml-2">
                        DILUTE
                      </label>
                    </div>
                    <div className="sm:col-span-2 col-span-6 sm:ml-[-1vw]">
                      <input
                        type="radio"
                        id="rejected"
                        name="step5a"
                        value="rejected"
                        checked={report_result === "rejected"}
                        onChange={reportResult}
                        disabled={selectOptn === "step5B"}
                      />
                      <label htmlFor="rejected" className="ml-2">
                        REJECTED FOR TESTING
                      </label>
                    </div>
                    <div className="sm:col-span-2 col-span-6">
                      <input
                        type="radio"
                        id="substituted"
                        name="step5a"
                        value="substituted"
                        checked={report_result === "substituted"}
                        onChange={reportResult}
                        disabled={selectOptn === "step5B"}
                      />
                      <label htmlFor="substituted" className="ml-1">
                        SUBSTITUTED
                      </label>
                    </div>
                    <div className="sm:col-span-2 col-span-6">
                      <input
                        type="radio"
                        id="invalid"
                        name="step5a"
                        value="invalid"
                        checked={report_result === "invalid"}
                        onChange={reportResult}
                        disabled={selectOptn === "step5B"}
                      />
                      <label htmlFor="invalid" className="ml-2">
                        INVALID RESULT
                      </label>
                    </div>
                  </div>

                  <h1 className="mt-6 font-medium">Remark for POSITIVE</h1>
                  <textarea
                    name=""
                    id=""
                    rows="3"
                    placeholder="Remarks"
                    className="mt-3 w-full rounded-lg bg-[#ECF1F6]  p-3 grid grid-cols-1 border-none focus:outline-none"
                    value={remark_for_positive}
                    onChange={(e) => set_remark_for_positive(e.target.value)}
                    disabled={
                      report_result === "negative" ||
                      report_result === "dilute" ||
                      report_result === "rejected" ||
                      report_result === "substituted" ||
                      report_result === "invalid" ||
                      selectOptn === "step5B"
                    }
                  ></textarea>
                  <h1
                    className="mt-4"
                    style={{
                      color: "grey",
                      fontStyle: "italic",
                      marginTop: "-0.4rem",
                      marginBottom: "2rem",
                    }}
                  >
                    Analyte(s) in ng/mL
                  </h1>
                  <h1 className="mt-4 font-medium">Remark</h1>
                  <textarea
                    name=""
                    id=""
                    rows="3"
                    placeholder="Remarks"
                    className="mt-3 w-full rounded-lg bg-[#ECF1F6]  p-3 grid grid-cols-1 border-none focus:outline-none"
                    value={p_remark}
                    onChange={(e) => set_p_remark(e.target.value)}
                    disabled={selectOptn === "step5B"}
                  ></textarea>
                  <h1 className="mt-4 font-medium">
                    Test Facility (If Differnt From Above)
                  </h1>
                  <input
                    type="text"
                    placeholder="Enter test facility"
                    className="w-full h-11 mb-2 mt-4 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                    value={p_facility}
                    onChange={(e) => set_p_facility(e.target.value)}
                    disabled={selectOptn === "step5B"}
                  />

                  <h1 className="mt-5">
                    I certify that the specimen identified on this form was
                    examined upon receipt, handled using chain of custody
                    procedures, analyzed, and reported in accordance with
                    applicable federal requirements.
                  </h1>
                  <div className="sm:col-span-4 col-span-12 mt-5">
                    <b>Signature of Certifying Technician / Scientist</b>
                    <div className="relative">
                      {selectOptn === "step5B" ? (
                        <img
                          style={{
                            width: 400,
                            height: 200,
                          }}
                        />
                      ) : (
                        <SignatureCanvas
                          ref={certifyingSignRef}
                          penColor="black"
                          canvasProps={{
                            className: "signature-canvas",
                            width: 400,
                            height: 200,
                          }}
                          className="border border-solid border-black mb-4"
                        />
                      )}
                      {/* Draw the line below the signature */}
                      <div className="absolute bottom-0 left-0 w-[25vw] border-b border-black"></div>
                    </div>
                    <div className="w-[25vw] flex justify-end mt-2 items-center gap-3">
                      <button
                        onClick={handleCertifyingSignClear}
                        className="bg-[#E8EEF3] px-2 py-1 rounded poppins-medium hover:opacity-90"
                        disabled={selectOptn === "step5B"}
                      >
                        clear
                      </button>
                      {/* <button
                    onClick={handleCertifyingSignSave}
                    className="bg-theme-blue text-white rounded px-2 py-1 poppins-medium hover:opacity-90"
                    style={{ background: "#1db6fa" }}
                  >
                    {load ? (
                      <svg
                        aria-hidden="true"
                        class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-purple-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    ) : (
                      "save"
                    )}
                  </button> */}
                    </div>
                  </div>
                  <div className="grid grid-cols-12 mt-11">
                    <div className="col-span-12 grid grid-cols-12 gap-4 mt-10">
                      <div className="sm:col-span-4 col-span-12">
                        <p className="font-medium">
                          Certifying Technician/Scientist's Name
                        </p>
                        <input
                          type="text"
                          placeholder="Enter name"
                          className="w-full h-10 mt-2 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                          // value={scientist_name}
                          value={`${userName?.first_name} ${userName?.last_name}`}
                          onChange={(e) => set_scientist_name(e.target.value)}
                          disabled={selectOptn === "step5B"}
                        />
                      </div>

                      <div className="sm:col-span-4 col-span-12">
                        <p className="font-medium">Select Date</p>
                        <input
                          type="date"
                          // value={techniciandate}
                          // onChange={(e) => setDate(e.target.value)}
                          className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                          value={p_date}
                          onChange={(e) => set_p_date(e.target.value)}
                          disabled={selectOptn === "step5B"}
                        />
                      </div>

                      <div
                        onClick={handleTechniDate}
                        className="sm:col-span-4 mt-3 col-span-12"
                      >
                        <p className="mt-[-8px] font-medium ">Choose Default</p>
                        <div className="mt-2.5 col-span-12 w-full">
                          <button
                            className="p-1.5 pl-7 pr-7 rounded-lg w-full bg-blue-600 font-normal text-white"
                            style={{ background: "#1db6fa" }}
                            disabled={selectOptn === "step5B"}
                          >
                            Today's date
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* step-5B */}
                <div className=" mt-7 mb-7 font-medium text-[20px] font-Poppins">
                  {" "}
                  <h1>Step 5B : Completed by Split Testing Laboratory</h1>
                </div>

                <div className="border border-blue-200 p-4 rounded-lg">
                  <div className="grid grid-cols-12 mt-6">
                    <div className="sm:col-span-5 col-span-12">
                      <div className="relative sm:mt-80 mt-5">
                        {/* <h1 className=" mb-2 mt-2 font-medium">
                      Name of Delivery Service
                    </h1>
                    <input
                      type="text"
                      placeholder="Enter delivery service"
                      className="w-full h-10 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                    /> */}
                        <h1 className=" mb-2 font-medium">
                          Name of Delivery Service
                        </h1>
                        <input
                          type="text"
                          placeholder="Enter delivery service"
                          className="w-full h-10 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                          // value={delivery_service_name}
                          value={reqData?.custody?.released_to}
                          onChange={(e) =>
                            set_delivery_service_name(e.target.value)
                          }
                          disabled={selectOptn === "step5A"}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="w-1 sm:col-span-1 sm:block hidden   sm:h-[500px] border-l-5 border-l-2 border-sienna sm:ml-7 border-dotted"></div>
                    </div>
                    <div className="sm:col-span-6 col-span-12 sm:ml-[-3vw]">
                      <div className="mt-5 grid grid-cols-12 gap-4 mb-3">
                        <div className="sm:col-span-3 col-span-12">
                          <input
                            type="radio"
                            id="true"
                            name="deliverysvc"
                            value="true"
                            checked={reconfirm === "true"}
                            onChange={reconfirmm}
                            disabled={selectOptn === "step5A"}
                          />
                          <label htmlFor="true" className="ml-2">
                            RECONFIRMED
                          </label>
                          <br />
                        </div>
                        <div className="sm:col-span-6 col-span-12">
                          <input
                            type="radio"
                            id="false"
                            name="deliverysvc"
                            value="false"
                            checked={reconfirm === "false"}
                            onChange={reconfirmm}
                            disabled={selectOptn === "step5A"}
                          />
                          <label htmlFor="false" className="ml-2">
                            FAILED TO RECONFIRM
                          </label>
                          <br />
                        </div>
                      </div>
                      <div>
                        <input
                          type="text"
                          className="w-full h-11 mb-4 mt-3 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                          disabled={selectOptn === "step5A"}
                        />
                      </div>
                      <h1 className="mb-2 mt-3">
                        I certify that the specimen identified on this form was
                        examined upon receipt, handled using chain of custody
                        procedures, analyzed, and reported in accordance with
                        applicable federal requirements.
                      </h1>
                      <div className="mb-4 mt-2">
                        <b className="font-medium">
                          Signature of Certifying Technician/Scientist
                        </b>
                        <div className="relative">
                          {selectOptn === "step5A" ? (
                            <img
                              style={{
                                width: 400,
                                height: 200,
                              }}
                            />
                          ) : (
                            <SignatureCanvas
                              ref={certifyingScientistSignRef}
                              penColor="black"
                              canvasProps={{
                                className: "signature-canvas",
                                width: 400,
                                height: 200,
                              }}
                              className="border border-solid border-black mb-4"
                            />
                          )}

                          {/* Draw the line below the signature */}
                          <div className="absolute bottom-0 left-0 w-[25vw] border-b border-black"></div>
                        </div>
                        <div className="w-[25vw] flex justify-end mt-2 items-center gap-3">
                          <button
                            onClick={handleCertifyingSignClear2}
                            className="bg-[#E8EEF3] px-2 py-1 rounded poppins-medium hover:opacity-90"
                            disabled={selectOptn === "step5A"}
                          >
                            clear
                          </button>
                          {/* <button
                        onClick={handleCertifyingSignSave2}
                        className="bg-theme-blue text-white rounded px-2 py-1 poppins-medium hover:opacity-90"
                        style={{ background: "#1db6fa" }}
                      >
                        {load ? (
                          <svg
                            aria-hidden="true"
                            class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-purple-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                        ) : (
                          "save"
                        )}
                      </button> */}
                        </div>
                      </div>
                      <h1 className="mb-2 mt-7 font-medium">
                        Certifying Technician/Scientist's Name
                      </h1>
                      <input
                        type="text"
                        placeholder="Enter technician name"
                        className="w-full h-10 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                        // value={s_scientist_name}
                        value={`${userName?.first_name} ${userName?.last_name}`}
                        onChange={(e) => set_s_scientist_name(e.target.value)}
                        disabled={selectOptn === "step5A"}
                      />
                      <div className="grid grid-cols-12 gap-5 mt-3">
                        <div className="sm:col-span-6 col-span-12">
                          <p className="font-medium">Select Date</p>
                          <input
                            type="date"
                            // value={scientisitdate}
                            // onChange={(e) => setDate(e.target.value)}
                            className="w-full h-10 mb-2 mt-3 rounded-lg bg-[#ECF1F6] p-2 border-none focus:outline-none"
                            value={s_date}
                            onChange={(e) => set_s_date(e.target.value)}
                            disabled={selectOptn === "step5A"}
                          />
                        </div>

                        <div
                          onClick={handleSplitTestDate}
                          className="sm:col-span-6 sm:mt-6 col-span-12  items-center"
                        >
                          <p className="sm:mt-[-3.5vh] font-medium  ">
                            Choose Default
                          </p>
                          <div className="mt-2.5 sm:col-span-6 col-span-12">
                            <button
                              className="p-1.5 pl-7 pr-7 rounded-lg w-full  bg-blue-600 font-normal text-white"
                              style={{ background: "#1db6fa" }}
                              disabled={selectOptn === "step5A"}
                            >
                              Today's date
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* other */}

            <div className=" mt-4 gap-3 flex sm:justify-start justify-around">
              <div>
                <Cancelsmall onClick={onClose} />
              </div>
              <div>
                {/* <GreenSubmit /> */}
                <button onClick={forensicDrugFromSave} className="savebtn">
                  {load || spin ? (
                    <svg
                      aria-hidden="true"
                      class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  ) : (
                    "submit"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TechnicianForensicOrderForm;
