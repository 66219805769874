import PreviousButton from "../../../components/buttons/PreviousButton";
import {
  drugCategoryTableData,
  drugCateogryTableHeadData,
} from "../../../constants/data/drugs/drugCategoryData";
import { FaPlus } from "react-icons/fa";
import Dashboard from "./../../../components/Dashboard";
import AddButton from "./../../../components/buttons/AddButton";
import Table, { ActionButton, Badge } from "./../../../components/Table";
import { useEffect, useState } from "react";
import DrugCategory from "../../../components/Modals/DrugCategory";
import { useModal } from "../../../context/modalContext";
import { HttpClient } from "../../../Redux/controllers/HttpClient";
import { useSelector } from "react-redux";
import penicon from "../../../assets/table/penIcon.png";
import axios from "axios";
import Utility from "../../../Utils/Utility";

const DrugCategoryPage = () => {
  const [catData, setCatData] = useState([]);
  const { showEditModal, showModal, openModal, closeModal, closeEditModal } =
    useModal();

  const token = useSelector((state) => state?.user?.token);
  const [stateUpdate, setStateUpdate] = useState(false);

  useEffect(() => {
    categoryData();
  }, [closeModal, stateUpdate]);

  const categoryData = async () => {
    await HttpClient.apiCaller("admin/category-get", "GET", null, token, null)
      .then((res) => {
        console.log(res);
        setCatData(res?.data?.code);
      })
      .catch((err) => console.log(err));
  };

  const deleteData = async (id) => {
    let config = {
      method: "delete",
      url: `${HttpClient.API_BASE_URL}admin/category-delete/${id}`,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        Utility.sToast("Data deleted");
        setStateUpdate(true);
      })
      .catch((error) => {
        console.log(error);
        Utility.eToast("Failed!");
      });
    // .finally(() => setStateUpdate(false));
  };

  const drugCateogryTableHeadData = [
    {
      accessorKey: "catName",
      header: "Category name",
    },
    {
      accessorKey: "status",
      header: "Status",
    },
    {
      accessorKey: "action",
      header: "Action",
    },
  ];

  const drugCategoryDetails = catData?.map((item, i) => ({
    catName: item?.name,
    status: <Badge status={item.status} />,
    // action: <ActionButton icon={penicon} action={"edit"} user={item} />,
    action: (
      <div className="flex gap-3">
        {/* pen */}
        <ActionButton
          action="edit"
          user={item}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="frn"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
              />
            </svg>
          }
        />
        {/* cross */}
        <ActionButton
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="frn"
              onClick={() => deleteData(item?._id)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          }
        />
      </div>
    ),
  }));

  return (
    <Dashboard>
      <div className="flex xs:flex-col-reverse xs:gap-2 sm:flex-row sm:items-center sm:justify-between mb-5">
        <div>
          <p className="poppins-medium text-lg leading-[21px]  text-theme-darkBlue mb-2">
            Manage Custom Drug Category{" "}
          </p>
          <p className="poppins-medium text-xs   ">
            <span className="underline text-theme-blue cursor-pointer">
              {" "}
              Drug
            </span>{" "}
            /{" "}
            <span className="underline text-theme-blue cursor-pointer">
              Manage Drug Category
            </span>
          </p>
        </div>
        {/*  */}

        <AddButton
          onClick={openModal}
          buttonName={"Add Drug Category"}
          icon={<FaPlus className="size-5" />}
        />
      </div>
      {/* admins table */}
      <Table
        tableData={drugCategoryDetails}
        tableHeadData={drugCateogryTableHeadData}
      />
      {/* modal  */}
      {showModal ? <DrugCategory onClose={closeModal} /> : <></>}
      {showEditModal ? (
        <DrugCategory edit={true} onClose={closeEditModal} />
      ) : (
        <></>
      )}
    </Dashboard>
  );
};

export default DrugCategoryPage;
