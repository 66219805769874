import Table from "../../components/Table";
import Dashboard from "./../../components/Dashboard";
import AddButton from "./../../components/buttons/AddButton";
import { FaPlus } from "react-icons/fa6";
import { useSelector } from "react-redux";
import {
    getPatientTableData,
    patientTableHeadData,
} from "../../constants/data/patientData";
import { useForm } from "../../context/formContext";
import AddPatient from "../../components/forms/AddPatient";
import { useEffect, useState } from "react";
import { useModal } from "../../context/modalContext";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { Spinner } from "@material-tailwind/react";
import PatientModal from "../../components/Modals/PatientModal";

function PatientsPage() {
    const { showForm, openForm, closeForm } = useForm();
    const [showSpin, setShowSpin] = useState(false);
    const { token } = useSelector((state) => state.user);
    const [patientTableData, setPatientTableData] = useState([]);
    const { showEditModal, closeEditModal } = useModal();

    const userDetail = useSelector(
        (state) => state?.user?.userInfo?.data?.user?._id
    );
    useEffect(() => {
        const fetchData = async () => {
            setShowSpin(true);
            try {
                const { data } = await HttpClient.apiCaller(
                    `admin/get-users-createdby/${userDetail}?user_type=patient`,
                    "GET",
                    null,
                    token,
                    null
                );
                console.log(data?.user);
                setPatientTableData(data?.user);
            } catch (error) {
                console.log(error);
            } finally {
                setShowSpin(false);
            }
        };

        fetchData();

        return () => { };
    }, [closeForm, closeEditModal]);
    return (
        <Dashboard>
            {showForm ? (
                <AddPatient onClose={closeForm} />
            ) : (
                <>
                    <div className="flex xs:flex-col-reverse xs:gap-2 sm:flex-row sm:items-center sm:justify-between mb-5">
                        <div>
                            <p className="poppins-medium text-lg leading-[21px]  text-theme-darkBlue mb-2">
                                Manage Patients{" "}
                            </p>
                            <p className="poppins-medium text-xs  ">
                                <span className="underline  text-theme-blue cursor-pointer">
                                    Dashboard
                                </span>{" "}
                                /{" "}
                                <span className="underline  text-theme-blue cursor-pointer">
                                    Manage Patients
                                </span>
                            </p>
                        </div>
                        {/*  */}

                        <AddButton
                            onClick={openForm}
                            buttonName={"Add New Patient"}
                            icon={<FaPlus className="size-5" />}
                        />
                    </div>

                    {/*  table */}
                    {showSpin ? (
                        <div className="w-full mt-20 flex items-center justify-center">
                            <Spinner className="h-12 w-12" />{" "}
                        </div>
                    ) : (
                        <Table
                            tableData={getPatientTableData(patientTableData)}
                            tableHeadData={patientTableHeadData}
                        />
                    )}
                </>
            )}
            {/* show edit modal */}
            {showEditModal ? (
                <PatientModal edit={true} onClose={closeEditModal} />
            ) : (
                <></>
            )}
        </Dashboard>
    );
}

export default PatientsPage;
