import React from "react";
import p404 from "../assets/404.gif";
import { useNavigate } from "react-router-dom";

export default function Page404() {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90vh",
        marginTop: "2rem",
        cursor: "pointer",
      }}
    >
      <img src={p404} alt="404" onClick={() => navigate(-1)} />
    </div>
  );
}
