export const patientRecordTableHeadData = [
  {
    accessorKey: "dateSubmitted",
    header: "DAte Submitted",
    size: 50,
  },
  {
    accessorKey: "firstName",
    header: "First Name",
    size: 150,
  },
  {
    accessorKey: "secondName",
    header: "Second Name",
    size: 150,
  },

  {
    accessorKey: "email",
    header: "Email",
    size: 150,
  },
  {
    accessorKey: "dob",
    header: "DOB",
    size: 100,
  },
  {
    accessorKey: "phoneNumber",
    header: "Phone Number",
    size: 100,
  },
  {
    accessorKey: "testRequest",
    header: "Test Request",
    size: 50,
  },
  {
    accessorKey: "submissionstatus",
    header: "submission Status",
    size: 50,
  },
];

export const getPAtientRecordTableData = (dataSet) => {
  const tableData = dataSet.map((item, idx) => {
    return {
      dateSubmitted: item?.dateSubmitted,
      email: item?.firstName,
      secondName: item?.secondName,
      email: item?.email,
      dob: item?.dob,
      phoneNumber: item?.phoneNumber,
      testRequest: item?.testRequest,
      submissionstatus: item?.submissionstatus,
    };
  });
  return tableData;
};
