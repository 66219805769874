import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import CommonModal from "./CommonModal";
import Cancel from "../buttons/Cancel";
import Reset from "./../buttons/Reset";
import Submit from "./../buttons/Submit";
import { useSelector } from "react-redux";
import { Spinner } from "@material-tailwind/react";
import Utility from "../../Utils/Utility";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { SlReload } from "react-icons/sl";
import { useModal } from "../../context/modalContext";
import { IoIosEye, IoMdEyeOff } from "react-icons/io";

const ClinicModal = ({ onClose, edit }) => {
  const validationSchema = Yup.object().shape({
    clinicName: Yup.string().required("Clinic  Name is required"),
    npi: Yup.string().required("NPI number is required"),
    mobileNo: Yup.string()
      .matches(/^[0-9]+$/, "Mobile number must contain only digits")
      .min(10, "Mobile number must be at least 10 digits")
      .max(10, "Mobile number must not exceed 10 digits")
      .required("Mobile number is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email address is required"),
    password: !edit
      ? Yup.string()
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number and one special character"
          )
          .required("Password is required")
      : Yup.string(),
    confirmPassword: !edit
      ? Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Please confirm your password")
      : Yup.string(),
  });
  const { userID } = useModal();
  console.log("++++++++", userID);

  const { token } = useSelector((state) => state.user);
  const [showSpin, setShowSpin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: edit ? userID?.email : "",
      password: "",
      confirmPassword: "",
      mobileNo: edit ? userID?.mobile_number : "",
      clinicName: edit ? userID.clinicName : "",
      npi: edit ? userID.npi : "",
    },
    validationSchema: validationSchema,
    onSubmit: edit ? handleEditSubmit : handleSubmit,
  });

  async function handleSubmit(values) {
    setShowSpin(true);
    let userData = {
      ...values,
      email: values?.email,
      password: values?.password,

      user_type: "clinic",
      mobile_number: values?.mobileNo,
    };
    try {
      const res = await HttpClient.apiCaller(
        "register",
        "POST",
        userData,
        token,
        null
      );
      console.log(res);
      Utility.sToast("Data posted successfully");
      // onClose();
      formik.resetForm();
    } catch (err) {
      console.log(err);
      Utility.eToast(err?.response?.data?.err || "Something went wrong");
    } finally {
      setShowSpin(false);
    }
  }

  async function handleEditSubmit(values) {
    setShowSpin(true);
    let userData = {
      _id: userID._id,
      email: values?.email,
      password: values?.password,
      first_name: values?.firstName,
      last_name: values?.lastName,
      user_type: "clinic",
      mobile_number: values?.mobileNo,
      clinicName: values?.clinicName,
      npi: values?.npi,
    };
    try {
      const res = await HttpClient.apiCaller(
        "profile",
        "PUT",
        userData,
        token,
        null
      );
      console.log(res);
      Utility.sToast("User Updated successfully");
      onClose();
      formik.resetForm();
    } catch (err) {
      console.log(err);
      Utility.eToast("Something went wrong");
    } finally {
      setShowSpin(false);
    }
  }

  const handleReset = () => {
    formik.resetForm();
  };

  return (
    <CommonModal
      heading={edit ? "Edit Clinic" : "Add a Clinic"}
      onClose={onClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col mb-2">
          <label className="mb-1 poppins-medium">Clinic Name</label>
          <input
            type="text"
            name="clinicName"
            placeholder="Enter Clinic name"
            className="w-full h-10  rounded bg-[#ECF1F6] p-2 poppins-regular"
            onChange={formik.handleChange}
            value={formik.values.clinicName}
          />
          {formik.errors.clinicName && formik.touched.clinicName && (
            <div className="text-red-500 poppins-regular ml-1 text-sm">
              {formik.errors.clinicName}
            </div>
          )}
        </div>
        <div className="flex flex-col mb-2">
          <label className="mb-1 poppins-medium">NPI Number</label>
          <input
            type="text"
            name="npi"
            placeholder="Enter NPI Number"
            className="w-full h-10  rounded bg-[#ECF1F6] p-2 poppins-regular"
            onChange={formik.handleChange}
            value={formik.values.npi}
          />
          {formik.errors.npi && formik.touched.npi && (
            <div className="text-red-500 poppins-regular ml-1 text-sm">
              {formik.errors.npi}
            </div>
          )}
        </div>

        {/* Email*/}
        <div className="flex flex-col mb-2">
          <label className="mb-1 poppins-medium">Email Address</label>
          <input
            type="text"
            name="email"
            placeholder="Enter email address"
            className="w-full h-10  rounded bg-[#ECF1F6] p-2 poppins-regular"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          {formik.errors.email && formik.touched.email && (
            <div className="text-red-500 poppins-regular ml-1 text-sm">
              {formik.errors.email}
            </div>
          )}
        </div>
        {/* Mobile no */}
        <div className="flex flex-col mb-2">
          <label className="mb-1 poppins-medium">Mobile No</label>
          <input
            type="number"
            name="mobileNo"
            placeholder="Enter mobile no"
            className="w-full h-10  rounded bg-[#ECF1F6] p-2 poppins-regular"
            onChange={formik.handleChange}
            value={formik.values.mobileNo}
          />
          {formik.errors.mobileNo && formik.touched.mobileNo && (
            <div className="text-red-500 poppins-regular ml-1 text-sm">
              {formik.errors.mobileNo}
            </div>
          )}
        </div>
        {/* Password */}
        <div className="flex flex-col mb-2 relative">
          <label className="mb-1 poppins-medium">Password</label>
          <input
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder="Enter Password"
            className="w-full h-10  rounded bg-[#ECF1F6] p-2 poppins-regular"
            onChange={formik.handleChange}
            value={formik.values.password}
          />
          {showPassword ? (
            <IoMdEyeOff
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-3 top-10 cursor-pointer"
            />
          ) : (
            <IoIosEye
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-3 top-10 cursor-pointer"
            />
          )}

          {formik.errors.password && formik.touched.password && (
            <div className="text-red-500 poppins-regular ml-1 text-sm">
              {formik.errors.password}
            </div>
          )}
        </div>
        {/* Confirm password */}
        <div className="flex flex-col mb-2 relative">
          <label className="mb-1 poppins-medium">Confirm password</label>
          <input
            type={showConfirmPassword ? "text" : "password"}
            name="confirmPassword"
            placeholder="Confirm Password"
            className="w-full h-10  rounded bg-[#ECF1F6] p-2 poppins-regular"
            onChange={formik.handleChange}
            value={formik.values.confirmPassword}
          />
          {showConfirmPassword ? (
            <IoMdEyeOff
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              className="absolute right-3 top-10 cursor-pointer"
            />
          ) : (
            <IoIosEye
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              className="absolute right-3 top-10 cursor-pointer"
            />
          )}
          {formik.errors.confirmPassword && formik.touched.confirmPassword && (
            <div className="text-red-500 poppins-regular ml-1 text-sm">
              {formik.errors.confirmPassword}
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="flex justify-between mt-3">
          <div>
            {!edit ? (
              <button
                type="button"
                onClick={handleReset}
                className="flex items-center px-2 py-1  poppins-medium border-2  border-gray-400 rounded hover:opacity-80 bg-[#ECF1F6]"
              >
                <SlReload className="mr-1" />
                Reset
              </button>
            ) : (
              <></>
            )}
          </div>
          <div className="flex gap-4 ml-auto">
            <Cancel onClick={onClose} />
            <Submit
              label={
                showSpin ? (
                  <Spinner color="white" />
                ) : edit ? (
                  "Save Changes"
                ) : (
                  "submit"
                )
              }
            />
          </div>
        </div>
      </form>
    </CommonModal>
  );
};

export default ClinicModal;
