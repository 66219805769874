import React, { useState } from "react";
import { useEffect } from "react";
import Dashboard from "../../components/Dashboard";
import { SlArrowLeft, SlReload, SlArrowDown, SlArrowUp } from "react-icons/sl";
// import MultiSelect from 'react-multiselect-dropdown';
// import Multiselect from 'multiselect-react-dropdown';
import Multiselect from "multiselect-react-dropdown";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
  LanguageSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { IoIosEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";
import Reset from "../buttons/Reset";

import Cancelsmall from "../buttons/Cancelsmall";
import "./Physician.css";
import Utility from "../../Utils/Utility";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { useSelector } from "react-redux";
import PreviousButton from "./../buttons/PreviousButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Spinner } from "@material-tailwind/react";
import ToggleComp from "../buttons/Toggle";
import { useNavigate } from "react-router-dom";
import Constants from "../../Utils/Constants";

const AddClinic = ({ toggle, onClose, isSignUpPage = false }) => {
  // const navigate = useNavigate();
  // mutlti select option
  // const [physicianTableData, setPhysicianTableData] = useState([]);
  // const [collectionTableData, setCollectionTableData] = useState([]);
  // const [collectorTableData, setCollectorTableData] = useState([]);

  // const [selectedPhysician, setSelectedPhysician] = useState([]);
  // const [selectedCollectors, setSelectedCollectors] = useState([]);
  // const [selectedCollectionSites, setSelectedCollectionSites] = useState([]);
  const [isToggled, setToggled] = useState(false);

  // const [employer_type, set_employer_type] = useState("clinic");
  // const employerType = (e) => set_employer_type(e.target.value);

  // const [employerName, setEmployerName] = useState("");
  // const [npiNo, setNpiNo] = useState("");
  // const [orgaName, setOrgaName] = useState("");
  // const [empCode, setEmpCode] = useState("");

  // console.log("isTTTT", isToggled);

  // console.log("selected", selectedPhysician);
  // console.log("selected 1", selectedCollectors);
  // console.log("selected 2", selectedCollectionSites);

  // const handleToggle = () => {
  //   setToggled((prev) => !prev);
  // };

  // const data = [
  //   physicianTableData.map((item, index) => ({
  //     clinic: item.first_name,
  //     id: index + 1
  //   })),
  //   { clinic: "ABCD1", id: "2" },
  //   { clinic: "ABCD2", id: "3" }
  // ];

  // fetching physician data

  // useEffect(() => {
  //   const fetchData = async () => {
  //     // setShowSpin(true);
  //     try {
  //       const { data } = await HttpClient.apiCaller(
  //         "admin/user-list?role=mro",
  //         "GET",
  //         null,
  //         token,
  //         null
  //       );
  //       console.log(data.user);
  //       setPhysicianTableData(data.user);
  //     } catch (error) {
  //       console.log(error);
  //     } finally {
  //       // setShowSpin(false);
  //     }
  //   };

  //   fetchData();

  //   return () => { };
  // }, []);

  // useEffect(() => {
  //   const fetchCollectionData = async () => {
  //     // setShowSpin(true);
  //     try {
  //       const { data } = await HttpClient.apiCaller(
  //         "admin/user-list?role=collectionsite",
  //         "GET",
  //         null,
  //         token,
  //         null
  //       );
  //       console.log(data.user);
  //       setCollectionTableData(data.user);
  //     } catch (error) {
  //       console.log(error);
  //     } finally {
  //       // setShowSpin(false);
  //     }
  //   };

  //   fetchCollectionData();

  //   return () => { };
  // }, []);

  // useEffect(() => {
  //   const fetchCollectorData = async () => {
  //     // setShowSpin(true);
  //     try {
  //       const { data } = await HttpClient.apiCaller(
  //         "admin/user-list?role=collector",
  //         "GET",
  //         null,
  //         token,
  //         null
  //       );
  //       console.log(data.user);
  //       setCollectorTableData(data.user);
  //     } catch (error) {
  //       console.log(error);
  //     } finally {
  //       // setShowSpin(false);
  //     }
  //   };

  //   fetchCollectorData();

  //   return () => { };
  // }, []);

  const { token } = useSelector((state) => state.user);
  const [showSpin, setShowSpin] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [countryid, setCountryid] = useState(233);
  const [stateid, setStateid] = useState("");

  const validationSchema = Yup.object().shape({
    clinicName: Yup.string().required("Clinic  Name is required"),
    npi: Yup.string().required("NPI number is required"),
    streetAddress: Yup.string().required("Street address is required"),
    country: Yup.string().required("Country is required"),
    // collectionSite: Yup.string().required("Collection site is required"),
    // forensicPhysician: Yup.string().required("field is required"),
    // collectionSite: Yup.string().required("Collection site is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    zip: Yup.string()
      .required("Zip code is required")
      .matches(/^\d{5}$/, "Zip code must be a 5-digit number"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    mobile: Yup.string().required("Mobile number is required").min(10).max(10),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
    faxno: Yup.string().required("Fax number is required"),
  });

  const userDetail = useSelector(
    (state) => state?.user?.userInfo?.data?.user?._id
  );

  // const checkClinicOrORga = () => {
  //   if (employer_type === "clinic") {
  //     if (employerName === "" || employerName === undefined) {
  //       Utility.eToast("Employer name required!");
  //       return false;
  //     } else if (npiNo === "" || npiNo === undefined) {
  //       Utility.eToast("Npi No required!");
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   } else if (employer_type === "organization") {
  //     if (orgaName === "" || orgaName === undefined) {
  //       Utility.eToast("Organization name required!");
  //       return false;
  //     } else if (empCode === "" || empCode === undefined) {
  //       Utility.eToast("Employer code required!");
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   } else {
  //     return true;
  //   }
  // };
  const navigate = useNavigate();

  async function handleSubmit() {
    setShowSpin(true);
    let userData = {
      ...formik.values,
      user_type: "clinic",
      created_by: userDetail ? userDetail : "",
      email: formik.values?.email,
      // status: isSignUpPage ? "inactive" : isToggled ? "active" : "inactive",
      status: "active",
      // physicians: selectedPhysician,
      // collectionSite: selectedCollectionSites,
      // collectors: selectedCollectors,
      mobile_number: formik?.values?.mobile,
      address: formik?.values?.streetAddress,
      postal_code: formik?.values?.zip,
      country: formik?.values?.country,
      state: formik?.values?.state,
      city: formik?.values?.city,
      // employer_type: employer_type,
      clinicName: formik?.values?.clinicName,
      npi: formik?.values?.npi,
      address: formik?.values?.streetAddress,
      mobile_number: formik?.values?.mobile,
      password: formik?.values?.password,
      faxNo: formik?.values?.faxno,
    };
    // console.log("userData=========>", userData);
    try {
      const res = await HttpClient.apiCaller(
        "register",
        "POST",
        userData,
        token,
        null
      )
        .then((res) => {
          formik.resetForm();
          console.log(res);

          Utility.sToast("Data posted successfully");
          // navigate("-1");
        })
        .catch((err) => console.log(err));

      // onClose();
    } catch (err) {
      console.log(err);
      // Utility.eToast(err?.data?.response?.err || "Something went wrong");
    } finally {
      setShowSpin(false);
    }
  }
  const formik = useFormik({
    initialValues: {
      clinicName: "",
      npi: "",
      streetAddress: "",
      country: "United States",
      state: "",
      city: "",
      // forensicPhysician: "",
      zip: "",
      email: "",
      mobile: "",
      // collectionSite: "",
      password: "",
      confirmPassword: "",
      employertype: "clinic",
      faxno: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  // const handleSelect = (selectedList, selectedItem) => {
  //   const selectedIds = selectedList.map((item) => item._id);
  //   setSelectedPhysician(selectedIds);
  //   console.log("selectedIds", selectedIds);
  // };

  // const handleCollectorSelect = (selectedList, selectedItem) => {
  //   const selectedIds = selectedList.map((item) => item._id);
  //   setSelectedCollectors(selectedIds);
  //   console.log("selectedCollectors", selectedIds);
  // };

  // // Handle select for collection sites
  // const handleCollectionSiteSelect = (selectedList, selectedItem) => {
  //   const selectedIds = selectedList.map((item) => item._id);
  //   setSelectedCollectionSites(selectedIds);
  //   console.log("selectedCollectionSites", selectedIds);
  // };
  return (
    <form
      onSubmit={formik.handleSubmit}
      className={`${isSignUpPage ? "-mt-4" : ""}`}
    >
      <div className=" p-2   bg-white shadow h-full">
        {/* heading  section */}

        {!isSignUpPage && (
          <div className="flex justify-between">
            <div>
              <div className="flex gap-2 items-center">
                <PreviousButton onClick={onClose} />
                <div onClick={toggle} className="">
                  <p className="text-[18px] poppins-medium">Create Clinic</p>

                  <p className="poppins-medium text-xs  ">
                    <span className="underline  text-theme-blue cursor-pointer">
                      Clinic
                    </span>{" "}
                    /{" "}
                    <span className="underline  text-theme-blue cursor-pointer">
                      Create Clinic
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="">
              <Reset onClick={() => formik.resetForm()} />
            </div>
          </div>
        )}

        <div
          className={`grid sm:grid-cols-12 relative ${
            isSignUpPage ? "" : "mt-4"
          } sm:gap-[4vw] gap-3`}
        ></div>

        <div
          className={`grid sm:grid-cols-12 relative ${
            isSignUpPage ? "" : "mt-4"
          } sm:gap-[4vw] gap-3`}
        >
          <div className="font-normal sm:col-span-8 relative col-span-12">
            <p className=" poppins-medium">Clinic Name</p>
            <input
              type="text"
              placeholder="Enter clinic name"
              className="rounded-md border-none poppins-regular focus:outline-none sm:full w-full bg-[#ECF1F6] p-2"
              name="clinicName"
              value={formik.values.clinicName}
              onChange={formik.handleChange}
            />
            {formik.touched.clinicName && formik.errors.clinicName && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.clinicName}
              </div>
            )}
          </div>
          {/* select npi number */}
          <div className="sm:col-span-4 relative mt-[-0.5vh] col-span-12">
            <p className=" poppins-medium">NPI number</p>
            <input
              type="text"
              placeholder="Enter Npi number"
              className=" w-full rounded-md p-2 border-none focus:outline-none bg-[#ECF1F6] poppins-regular"
              name="npi"
              value={formik.values.npi}
              onChange={formik.handleChange}
            />
            {formik.touched.npi && formik.errors.npi && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.npi}
              </div>
            )}
          </div>
        </div>

        {/* select street address */}
        <div className={`mt-3`}>
          <p className=" mb-1 poppins-medium">Street address</p>
          <textarea
            className="w-full p-1 rounded border-none focus:outline-none mt-22 bg-[#ECF1F6] poppins-regular"
            id=""
            cols="15"
            rows="2"
            placeholder="Add your address"
            name="streetAddress"
            value={formik.values.streetAddress}
            onChange={formik.handleChange}
          ></textarea>
          {formik.touched.streetAddress && formik.errors.streetAddress && (
            <div className="text-red-500 poppins-regular text-sm ml-1">
              {formik.errors.streetAddress}
            </div>
          )}
        </div>

        {/* select country */}
        <div className="grid sm:grid-cols-12 sm:gap-[3vw] gap-2">
          <div className="sm:col-span-4 col-span-12">
            <h6 className="poppins-medium mb-1">Country One</h6>
            <div className="poppins-medium">
              <CountrySelect
                defaultValue={Constants.usaObject}
                value={formik.values.country}
                onChange={(e) => {
                  console.log(e);
                  formik.setFieldValue("country", e.name);
                  setCountryid(e.id);
                }}
                placeHolder="Select Country"
                showFlag={true}
              />
            </div>
            {formik.touched.country && formik.errors.country && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.country}
              </div>
            )}
          </div>

          {/* select state */}
          <div className="sm:col-span-4 col-span-12">
            <div>
              <h6 className=" mb-1 poppins-medium">State</h6>
              <div className="poppins-medium">
                {/* <StateSelect
                  value={formik.values.state}
                  onChange={(e) => {
                    formik.setFieldValue("state", e.name);
                    setStateid(e.id);
                  }}
                  countryid={countryid}
                  placeHolder="Select State"
                /> */}
                <input
                  type="text"
                  value={formik.values.state}
                  onChange={(e) => {
                    formik.setFieldValue("state", e.target.value);
                  }}
                  placeHolder="Select State"
                  className="h-11 w-full rounded border-none focus:outline-none bg-[#ECF1F6] p-2 poppins-regular"
                />
              </div>
              {formik.touched.state && formik.errors.state && (
                <div className="text-red-500 poppins-regular text-sm ml-1">
                  {formik.errors.state}
                </div>
              )}
            </div>
          </div>

          {/* select city */}
          <div className="sm:col-span-4 col-span-12">
            <h6 className=" mb-1 poppins-medium">City</h6>
            <div className="poppins-regular">
              {/* <CitySelect
                value={formik.values.city}
                onChange={(e) => {
                  formik.setFieldValue("city", e.name);
                }}
                countryid={countryid}
                stateid={stateid}
                placeHolder="Select City"
              /> */}
              <input
                type="text"
                value={formik.values.city}
                onChange={(e) => {
                  formik.setFieldValue("city", e.target.value);
                }}
                placeHolder="Select City"
                className="h-11 w-full rounded border-none focus:outline-none bg-[#ECF1F6] p-2 poppins-regular"
              />
            </div>
            {formik.touched.city && formik.errors.city && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.city}
              </div>
            )}
          </div>
        </div>

        <>
          <div className=" grid sm:grid-cols-12 mt-[4vh] sm:gap-[3vw] gap-2">
            <div className="sm:col-span-4  col-span-12 ">
              <p className="poppins-medium">Zip or postal code</p>
              <input
                type="text"
                className="h-11 w-full border-none focus:outline-none rounded bg-[#ECF1F6] p-2 font-normal"
                placeholder="Enter zip or postal code"
                name="zip"
                value={formik.values.zip}
                onChange={formik.handleChange}
              />
              {formik.touched.zip && formik.errors.zip && (
                <div className="text-red-500 poppins-regular text-sm ml-1">
                  {formik.errors.zip}
                </div>
              )}
            </div>

            <div className="sm:col-span-4  col-span-12 ">
              <p className="poppins-medium ">Phone number</p>
              <input
                type="text"
                className="w-full border-none focus:outline-none h-11 rounded bg-[#ECF1F6] p-2 font-normal"
                placeholder="Enter your phone number"
                name="mobile"
                value={formik.values.mobile}
                onChange={formik.handleChange}
              />
              {formik.touched.mobile && formik.errors.mobile && (
                <div className="text-red-500 poppins-regular text-sm ml-1">
                  {formik.errors.mobile}
                </div>
              )}
            </div>

            <div className="sm:col-span-4  col-span-12 ">
              {/* fax no */}
              <p className="poppins-medium">Fax No</p>
              <input
                type="text"
                placeholder="Fax No"
                className="h-11 rounded w-full border-none focus:outline-none bg-[#ECF1F6] p-2 font-normal"
                name="faxno"
                value={formik.values.faxno}
                onChange={formik.handleChange}
              />
              {formik.touched.faxno && formik.errors.faxno && (
                <div className="text-red-500 poppins-regular text-sm ml-1">
                  {formik.errors.faxno}
                </div>
              )}
            </div>
          </div>

          {/* <div className=" col-span-12 mt-4 ">
            <h1 className="poppins-medium mb-1">Select a forensic Physician</h1>
            <Multiselect
              options={physicianTableData}
              displayValue="first_name"
              onSelect={handleSelect}
              onRemove={(selectedList, selectedItem) => {
                const selectedIds = selectedList.map((item) => item._id);
                setSelectedPhysician(selectedIds);
                console.log("selectedIds", selectedIds);
              }}
            />
          </div>

          <div className="flex xs:flex-col sm:flex-row mt-5 sm:items-center sm:justify-between gap-5 ">
            <div className="xs:w-full sm:w-1/2 ">
              <div className="flex gap-3  items-center">
                <p>
                  <b>Do you have a collection site? </b>
                </p>
                <div className="flex gap-3">
                  <button
                    type="button"
                    className={`px-2 py-1 rounded poppins-medium  ${
                      yesClicked ? "bg-theme-blue text-white" : "bg-[#ECF1F6]"
                    } `}
                    onClick={() => {
                      formik.setFieldValue("haveCollectionSite", "yes");
                      setYesClicked(true);
                      setNoClicked(false);
                    }}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      formik.setFieldValue("haveCollectionSite", "no", false);
                      setYesClicked(false);
                      setNoClicked(true);
                    }}
                    className={`px-2 py-1 poppins-medium border-2  border-gray-400 rounded bg-[#ECF1F6]   ${
                      noClicked ? "bg-theme-blue text-white" : "bg-[#ECF1F6]"
                    }`}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>

            {noClicked ? (
              ""
            ) : (
              <div className="xs:w-full sm:w-1/2">
                <p className="poppins-medium">Choose Collection site</p>
                <Multiselect
                  options={collectionTableData}
                  displayValue="first_name"
                  onSelect={handleCollectionSiteSelect}
                  onRemove={(selectedList, removedItem) => {
                    const updatedSelectedCollectionSites =
                      selectedCollectionSites.filter(
                        (id) => id !== removedItem._id
                      );
                    setSelectedCollectionSites(updatedSelectedCollectionSites);
                  }}
                />
              </div>
            )}
          </div>

          <div className="flex xs:flex-col sm:flex-row mt-5 sm:items-center sm:justify-between gap-5 ">
            <div className="xs:w-full sm:w-1/2 ">
              <div className="flex gap-3  items-center">
                <p>
                  <b>Do you have a Collector site? </b>
                </p>
                <div className="flex gap-3">
                  <button
                    type="button"
                    className={`px-2 py-1 rounded poppins-medium  ${
                      CollectoryesClicked
                        ? "bg-theme-blue text-white"
                        : "bg-[#ECF1F6]"
                    } `}
                    onClick={() => {
                      formik.setFieldValue("haveCollectionSite", "yes");
                      setCollectorYesClicked(true);
                      setCollectorNoClicked(false);
                    }}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      formik.setFieldValue("haveCollectionSite", "no");
                      setCollectorYesClicked(false);
                      setCollectorNoClicked(true);
                    }}
                    className={`px-2 py-1 poppins-medium border-2  border-gray-400 rounded bg-[#ECF1F6]   ${
                      CollectornoClicked
                        ? "bg-theme-blue text-white"
                        : "bg-[#ECF1F6]"
                    }`}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>

            {CollectornoClicked ? (
              ""
            ) : (
              <div className="xs:w-full sm:w-1/2">
                <p className="poppins-medium">Choose Collector site</p>
                <Multiselect
                  options={collectorTableData}
                  displayValue="first_name"
                  onSelect={handleCollectorSelect}
                  onRemove={(selectedList, removedItem) => {
                    const updatedSelectedCollectors = selectedCollectors.filter(
                      (id) => id !== removedItem._id
                    );
                    setSelectedCollectors(updatedSelectedCollectors);
                  }}
                />
              </div>
            )}
          </div> */}
        </>

        {/* password */}
        <div className="grid xs:grid-cols-1 sm:grid-cols-3 sm:gap-9 xs:gap-3 mt-5">
          <div className="">
            <p className="poppins-medium ">Email</p>
            <input
              type="email"
              className="w-full h-11 border-none focus:outline-none rounded bg-[#ECF1F6] p-2 font-normal"
              placeholder="Enter your email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.email}
              </div>
            )}
          </div>
          <div className=" ">
            <p className="  poppins-medium">Password</p>
            <div className="flex items-center">
              <input
                type={showPassword ? "text" : "password"}
                className="w-full h-11 border-none focus:outline-none rounded bg-[#ECF1F6] p-2 poppins-regular"
                placeholder="Enter password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
              />

              {showPassword ? (
                <IoIosEye
                  className="relative sm:ml-[-3vw] ml-[-5vw]"
                  onClick={() => setShowPassword(!showPassword)}
                />
              ) : (
                <IoMdEyeOff
                  className="relative sm:ml-[-2vw] ml-[-5vw]"
                  onClick={() => setShowPassword(!showPassword)}
                />
              )}
            </div>
            {formik.touched.password && formik.errors.password && (
              <div className="text-red-500 poppins-regular text-sm ml-1">
                {formik.errors.password}
              </div>
            )}
          </div>

          {/* confirm password */}
          <div className=" ">
            <p className="  poppins-medium">Confirm Password</p>
            <div className="flex items-center">
              <input
                type={showConfirmPassword ? "text" : "password"}
                className="w-full h-11 rounded border-none focus:outline-none bg-[#ECF1F6] p-2 poppins-regular"
                placeholder="Confirm password"
                name="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
              />

              {showConfirmPassword ? (
                <IoIosEye
                  className="relative sm:ml-[-2vw] ml-[-5vw]"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              ) : (
                <IoMdEyeOff
                  className="relative sm:ml-[-2vw] ml-[-5vw]"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              )}
            </div>
            {formik.touched.confirmPassword &&
              formik.errors.confirmPassword && (
                <div className="text-red-500 poppins-regular text-sm ml-1">
                  {formik.errors.confirmPassword}
                </div>
              )}
          </div>
        </div>

        {/* footer section*/}
        <div className="grid grid-cols-12 mt-2 gap-2 pb-2">
          {!isSignUpPage && (
            <div className="sm:col-span-6 col-span-12 grid grid-cols-12 items-center">
              <div className="sm:col-span-4 col-span-6">
                <p className=" poppins-medium">
                  <b className="poppins-medium">
                    {/* Physician is {isToggled ? "active" : "not active"} */}
                  </b>
                </p>
              </div>
              <div className="sm:col-span-4 col-span-6">
                {/* <ToggleComp isToggled={isToggled} handleToggle={handleToggle} /> */}
              </div>
            </div>
          )}

          {isSignUpPage ? (
            <div className=" mt-3 flex justify-end gap-2 col-span-12">
              <div>
                <Cancelsmall onClick={onClose} />
              </div>
              <div className="col-span-6">
                <button
                  type="submit"
                  className="px-2 py-1  poppins-medium rounded bg-blue-600 hover:opacity-80 text-white"
                >
                  {showSpin ? <Spinner color="white" /> : "submit"}
                </button>
              </div>
            </div>
          ) : (
            <div className=" mt-4  gap-4 flex sm:justify-end justify-around sm:col-span-6 col-span-12">
              <div>
                <Cancelsmall onClick={onClose} />
              </div>
              <div>
                <button
                  type="submit"
                  className="px-2 py-1  poppins-medium rounded bg-blue-600 hover:opacity-80 text-white"
                >
                  {showSpin ? <Spinner color="white" /> : "submit"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default AddClinic;
